import * as MCRandom from "../MCRandom";

function TopicSelector(topicArray) {
  // removes all objects in the Topic Array that have any of the removeStrings in there name prop. removeStrings is an array of strings
  function removeTopics(topicArr, removeStrings) {
    for (let j = removeStrings.length - 1; j >= 0; j -= 1) {
      for (let i = topicArr.length - 1; i >= 0; i -= 1) {
        if (topicArr[i].name.indexOf(removeStrings[j]) > -1) {
          topicArr.splice(i, 1);
        }
      }
    }
    return topicArr;
  }

  let topicArr = [...topicArray];
  const AS = 0.65;
  const n = topicArray.length;
  const Q1 = Math.round(n / 4);
  const Q2 = Math.round(n / 2);
  const Q3 = Math.round((3 * n) / 4);
  const removeStrings = [];
  let containsA2 = false;

  // each of these is probability the named is removed from the array of possible selected questions where appropriate, for example AS only removed in case where A2 exists.

  const MostRecent4 = 1;
  const MostRecentQ2 = 0.5;
  const MostRecentQ3 = 0.1;
  const HighestScoreQ1 = 0.7;
  const HighestScoreQ2 = 0.6;
  const HighestScoreQ3 = 0.5;
  const forceUnansweredTopic = Math.random() < 0.1;
  // const Applied = 0.6;
  // const Core = 0.2;

  MCRandom.shuffle(topicArr);
  for (let i = topicArray.length - 1; i >= 0; i -= 1) {
    if (topicArray[i].name.indexOf("A2") > -1) {
      containsA2 = true;
    }

    if (
      topicArray[i].numberOfQuestionsCompleted === 0 &&
      forceUnansweredTopic
    ) {
      return topicArray[i].name;
    }
  }
  if (containsA2 && Math.random() < AS) {
    removeStrings.push("AS");
  }
  // Deal with small number of topics selected
  if (topicArray.length < 5) {
    const topic =
      topicArray[Math.round(Math.random() * (topicArray.length - 1))];
    return topic.name;
  }

  // remove based on P()s

  // sort by score
  topicArray.sort((a, b) => {
    return parseFloat(b.score) - parseFloat(a.score);
  });

  // remove based on score
  if (Math.random() < HighestScoreQ3) {
    for (let j = Q3 - 1; j >= 0; j -= 1) {
      removeStrings.push(topicArray[j].name);
    }
  } else if (
    Math.random() <
    (HighestScoreQ2 - HighestScoreQ3) / (1 - HighestScoreQ3)
  ) {
    for (let j = Q2 - 1; j >= 0; j -= 1) {
      removeStrings.push(topicArray[j].name);
    }
  } else if (
    Math.random() <
    (HighestScoreQ1 - HighestScoreQ2) / (1 - HighestScoreQ2)
  ) {
    for (let j = Q1 - 1; j >= 0; j -= 1) {
      removeStrings.push(topicArray[j].name);
    }
  }

  // sort by "age"
  topicArray.sort((b, a) => {
    return (
      parseFloat(b.questionsSinceCompleted) -
      parseFloat(a.questionsSinceCompleted)
    );
  });
  // remove based on "age"
  if (Math.random() < MostRecentQ3) {
    for (let j = Q3 - 1; j >= 0; j -= 1) {
      removeStrings.push(topicArray[j].name);
    }
  } else if (Math.random() < MostRecentQ2 / (1 - MostRecentQ3)) {
    for (let j = Q2 - 1; j >= 0; j -= 1) {
      removeStrings.push(topicArray[j].name);
    }
  } else if (
    Math.random() <
    MostRecent4 / ((1 - MostRecentQ2) * (1 - MostRecentQ3))
  ) {
    for (let j = 3; j >= 0; j -= 1) {
      removeStrings.push(topicArray[j].name);
    }
  }

  topicArr = removeTopics(topicArr, removeStrings);
  const topic = topicArr[Math.round(Math.random() * (topicArr.length - 1))];
  if (typeof topic === "undefined") {
    return null;
  }
  return topic.name;
}
export { TopicSelector };
