import { randomInt } from "./randomInt";

/**
 * @summary Randomly shuffles an array.
 *
 * @description Uses the Fisher-Yates shuffle algorithm to return elements of the input array in
 *              a random order. Throws an error when the input is not an array.
 *
 * @since 0.1.0
 *
 * @memberof module:MCRandom
 *
 * @param   {Array} elements    An array of any type to be shuffled.
 * @returns {Array}             The shuffled input array.
 * @throws  {TypeError}         Input was not an array.
 *
 * @example
 * // shuffle an array of numbers
 * const shuffled = MCRandom.shuffle([1, 2, 3, 4, 5]);
 * console.log(shuffled) // Output: [4, 2, 5 ,3, 1] (random shuffle)
 */
function shuffle(elements) {
  if (!Array.isArray(elements)) {
    throw new TypeError(`expected array but got ${typeof elements} instead`);
  }
  const array = [...elements];
  for (let i = elements.length - 1; i > 0; i -= 1) {
    const j = randomInt(0, i);
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export { shuffle };
