import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";
import RoundedBtn from "./RoundedBtn";
import PlainInput from "./PlainInput";
import Alert from "./Alert";
import TeacherList from "./TeacherList";
import AddRelationModal from "./AddRelationModal";
import StyledInput from "./StyledInput";
import checkToken from "../system/auth/checkToken";
// eslint-disable-next-line import/no-cycle
import { AppContext } from "../App";

const UserModal = ({ show, onHide, userData, schools, anon }) => {
  const { adminData, setAdminData } = useContext(AppContext);
  const [userValues, setUserValues] = useState({
    firstName: { value: userData.fname, changed: false },
    lastName: { value: userData.lname, changed: false },
    email: { value: userData.email, changed: false },
    password: { value: "", changed: false },
    roleName: { value: userData.roleName, changed: false },
    schoolEmail: { value: userData.schoolEmail, changed: false },
  });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const roles = ["DEV", "PUPIL", "SITE_ADMIN", "STAFF", "STAFF_ADMIN"];
  const [relations, setRelations] = useState(null);
  const [email, setEmail] = useState("");
  const [addingRel, setAddingRel] = useState(false);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const emails = [];
    for (let i = 0; i < schools.length; i += 1) {
      const school = schools[i];
      emails.push(school.email);
    }
    setSchoolOptions(emails);
  }, [schools]);

  const startLoad = () => {
    return setTimeout(() => {
      setLoading(true);
    }, 500);
  };

  const stopLoad = (timer) => {
    clearTimeout(timer);
    setLoading(false);
  };

  const setError = (msg) => {
    setAlert({ variant: "danger", message: msg });
  };

  const validateEmail = (e) => {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(String(e).toLowerCase());
  };

  const validate = () => {
    const errs = [];
    const lower = /[a-z]/;
    const upper = /[A-Z]/;
    const number = /[0-9]/;

    if (userValues.firstName.value === "") {
      errs.push("Please enter a valid name");
    }
    if (userValues.lastName.value === "") {
      errs.push("Please enter a valid name");
    }
    if (!validateEmail(userValues.email.value)) {
      errs.push("Please enter a valid email address");
    }
    if (userValues.password.changed) {
      if (!lower.test(userValues.password.value)) {
        errs.push("Password should contain at least one lowercase letter");
      }
      if (!upper.test(userValues.password.value)) {
        errs.push("Password should contain at least one uppercase letter");
      }
      if (!number.test(userValues.password.value)) {
        errs.push("Password should contain at least one number");
      }
      if (userValues.password.value.length < 8) {
        errs.push("Password should have 8 or more characters");
      }
    }
    if (!roles.includes(userValues.roleName.value)) {
      errs.push("Please choose a valid role.");
    }
    if (errs.length !== 0) {
      setError(errs[0]);
      return false;
    }
    return true;
  };

  const editUser = async () => {
    const start = startLoad();
    setAlert({});
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    if (!validate()) {
      stopLoad(start);
      return false;
    }
    const data = {};
    const keys = Object.keys(userValues);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (userValues[key].changed) {
        data[key] = userValues[key].value;
      }
    }
    if (Object.keys(data).length === 0) {
      setError("Nothing changed.");
      stopLoad(start);
      return false;
    }
    data.userId = userData.userId;
    try {
      await axios.patch(`${process.env.REACT_APP_DOMAIN}/admin-user`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
      });
      const newVersion = {};
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (key === "firstName") {
          newVersion.fname = userValues.firstName.value;
        } else if (key === "lastName") {
          newVersion.lname = userValues.lastName.value;
        } else {
          newVersion[key] = userValues[key].value;
        }
      }
      newVersion.userId = userData.userId;
      newVersion.createdAt = userData.createdAt;
      const newUsers = [];
      for (let i = 0; i < adminData.users.length; i += 1) {
        if (adminData.users[i].userId === userData.userId) {
          newUsers.push(newVersion);
        } else {
          newUsers.push(adminData.users[i]);
        }
      }
      setAdminData({ ...adminData, users: newUsers });
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setError(JSON.stringify(e.response.data.error));
      } else {
        setError("A problem occurred. Please try again.");
      }
      stopLoad(start);
      return false;
    }
    stopLoad(start);
    setAlert({ variant: "success", message: "Details changed." });
    return true;
  };

  const getRelations = async () => {
    const start = startLoad();
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_DOMAIN}/admin-relations`,
        {
          params: {
            userId: userData.userId,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
        }
      );
      setRelations(res.data);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setError(JSON.stringify(e.response.data.error));
      } else {
        setError("A problem occurred. Please try again.");
      }
      stopLoad(start);
      return false;
    }
    stopLoad(start);
    return true;
  };

  const onDelete = async (other) => {
    const start = startLoad();
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    try {
      await axios.delete(`${process.env.REACT_APP_DOMAIN}/admin-relations`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
        data: {
          pupilId:
            userData.roleName === "PUPIL" ? userData.userId : other.pupilId,
          staffId:
            userData.roleName === "PUPIL" ? other.staffId : userData.userId,
        },
      });
      const data = [...relations];
      let ind = -1;
      for (let i = 0; i < data.length; i += 1) {
        if (userData.roleName === "PUPIL") {
          if (data[i].staffId === other.staffId) {
            ind = i;
          }
        } else if (data[i].pupilId === other.pupilId) {
          ind = i;
        }
      }
      data.splice(ind, 1);
      setAlert({
        variant: "success",
        message: `Successfully removed ${other.fname} ${other.lname}.`,
      });
      setRelations(data);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setAlert({
          variant: "danger",
          message: JSON.stringify(e.response.data.error),
        });
      } else {
        setAlert({
          variant: "danger",
          message: "A problem occurred. Please try again.",
        });
      }
    }
    stopLoad(start);
    return true;
  };

  const addRelation = async () => {
    setAlert({});
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    if (!validateEmail(email)) {
      setAlert({
        variant: "danger",
        modal: true,
        message: "Please enter a valid email address.",
      });
      return false;
    }
    setAddingRel(false);
    const start = startLoad();
    try {
      await axios.post(
        `${process.env.REACT_APP_DOMAIN}/admin-relations`,
        { userId: userData.userId, email },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
        }
      );
      setRelations([{ confirmed: false, email }, ...relations]);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setAlert({ variant: "danger", message: e.response.data.error });
      } else {
        setAlert({
          variant: "danger",
          message: "A problem occurred. Please try again.",
        });
      }
      stopLoad(start);
      return false;
    }
    setAlert({ variant: "success", message: "Invitation sent!" });
    setEmail("");
    stopLoad(start);
    return true;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <AddRelationModal
        danger={alert.modal ? alert.msg : ""}
        show={addingRel}
        onHide={() => setAddingRel(false)}
        onAction={() => addRelation()}
        email={email}
        emailChanged={setEmail}
      />
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="my-5 text-center">
            <ClipLoader loading color="#74c5ed" size="3em" />
          </div>
        ) : (
          <div>
            {alert.message && (
              <Alert variant={alert.variant}>{alert.message}</Alert>
            )}
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <PlainInput
                    label="First Name"
                    width={-1}
                    value={anon ? "**********" : userValues.firstName.value}
                    handleChange={(val) => {
                      setUserValues({
                        ...userValues,
                        firstName: { value: val, changed: true },
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <PlainInput
                    label="Last Name"
                    width={-1}
                    value={anon ? "**********" : userValues.lastName.value}
                    handleChange={(val) => {
                      setUserValues({
                        ...userValues,
                        lastName: { value: val, changed: true },
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <PlainInput
                    label="Email"
                    width={-1}
                    value={anon ? "**********" : userValues.email.value}
                    handleChange={(val) => {
                      setUserValues({
                        ...userValues,
                        email: { value: val, changed: true },
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <PlainInput
                    label="New Password"
                    width={-1}
                    value={userValues.password.value}
                    handleChange={(val) => {
                      setUserValues({
                        ...userValues,
                        password: { value: val, changed: true },
                      });
                    }}
                    obscure
                  />
                </div>
                <div className="form-group col-md-6">
                  <PlainInput
                    label="Type"
                    width={-1}
                    value={userValues.roleName.value}
                    handleChange={(val) => {
                      setUserValues({
                        ...userValues,
                        roleName: { value: val, changed: true },
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <StyledInput
                    label="School Email"
                    width={-1}
                    value={userValues.schoolEmail.value}
                    handleChange={(val) => {
                      setUserValues({
                        ...userValues,
                        schoolEmail: { value: val, changed: true },
                      });
                    }}
                    autoCompleteOptions={schoolOptions}
                  />
                </div>
              </div>
            </form>
            <hr />
            {relations === null ? (
              <RoundedBtn
                text="Show Relations"
                width={-1}
                onClick={getRelations}
              />
            ) : (
              <div>
                <h4>Relations</h4>
                <RoundedBtn
                  text="Add Relation"
                  width={-1}
                  onClick={() => setAddingRel(true)}
                />
                <div className="my-3">
                  <TeacherList
                    data={relations}
                    onDelete={onDelete}
                    anon={anon}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <RoundedBtn
          text="Save"
          onClick={editUser}
          variant="success"
          disabled={anon}
        />
      </Modal.Footer>
    </Modal>
  );
};

UserModal.propTypes = {
  // data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  userData: PropTypes.objectOf(PropTypes.any).isRequired,
  schools: PropTypes.arrayOf(PropTypes.object).isRequired,
  anon: PropTypes.bool,
};
UserModal.defaultProps = {
  show: false,
  anon: false,
};
export default UserModal;
