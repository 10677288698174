import { IndexError } from "../MCError";
import { randomInt } from "./randomInt";
/**
 * @summary Returns a random element from a non-empty array.
 *
 * @description Returns an element chosen at random from the input array. If the input array is empty then an IndexError is raised.
 *
 * @since 0.1.0
 *
 * @memberof module:MCRandom
 *
 * @param   {Array} elements  An array of any type.
 * @returns {*}               An element of the array.
 * @throws  {IndexError}      Array should be non-empty.
 * @throws  {TypeError}       Argument elements should be an array.
 *
 * @example
 * const naturals = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
 * const randomNatural = MCRandom.choseElement(naturals);
 * console.log(randomNatural); // Output: 4 (element chosen at random)
 */
function chooseElement(elements) {
  if (!Array.isArray(elements)) {
    throw new TypeError(`expected an array but got ${typeof elements} instead`);
  }
  if (elements.length === 0) {
    throw new IndexError("expected a non-empty array");
  }
  const index = randomInt(0, elements.length - 1);
  return elements[index];
}

export { chooseElement };
