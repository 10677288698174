import { NumberSetError, ArgumentError } from "../MCError";
import { cleaner } from "./cleaner";
import { Graph } from "../MCQuestion";
/**
 * Exponential Object.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Creates 5e^{3x+2}
 * const e1 = new exponential(5,'e',3,'x',2)
 */
class Exponential {
  /**
   * Creates an exponential in the form a*k_(bx+c) where:
   * a - coefficient
   * k - base
   * b - variable coefficient
   * x - variable
   * c - offset
   *
   * @param {number} [coefficient = 1]            The coefficient of the exponential object.
   * @param {number | string} [base = 'e']        The base of the exponential, use 'e' for the Exp.
   * @param {number} [variableCoefficient = 1]    The coefficient of the variable of the exponential object.
   * @param {string} [variable = 'x']             The variable of the exponential object.
   * @param {string} [offset = 0]                 The value of any constant added to the variable.
   *
   * @returns {Exponential}  A new exponential object.
   * @throws {TypeError}   The argument should be number/strings as specified.
   */
  constructor(
    coefficient = 1,
    base = "e",
    variableCoefficient = 1,
    variable = "x",
    offset = 0
  ) {
    if (typeof coefficient !== "number") {
      throw new NumberSetError(`Expected a number, got ${coefficient} instead`);
    }
    if (typeof base !== "number" && base !== "e") {
      throw new NumberSetError(`Expected a number, got ${base} instead`);
    }
    if (typeof variableCoefficient !== "number") {
      throw new NumberSetError(
        `Expected a number, got ${variableCoefficient} instead`
      );
    }
    if (typeof variable !== "string") {
      throw new NumberSetError(`Expected a string, got ${variable} instead`);
    }
    if (typeof offset !== "number") {
      throw new NumberSetError(`Expected a number, got ${offset} instead`);
    }
    this.coefficient = coefficient;
    this.base = base;
    this.variableCoefficient = variableCoefficient;
    this.variable = variable;
    this.offset = offset;
  }

  /**
   * Prints the exponential object as a cleaned string.
   *
   * @returns {string} A display ready string.
   *
   * @example
   * //Prints 0.5e^x as \\frac{1}{2}e^{x}
   * let e1 = new exponential(0.5)
   * console.log(e1.toString())
   */
  toString() {
    let coef = this.coefficient;
    if (typeof this.base === "number") {
      coef += "\\cdot";
    }
    if (this.offset !== 0) {
      return cleaner(
        `${coef}${this.base}^{${this.variableCoefficient}${this.variable}+${this.offset}}`
      );
    }
    return cleaner(
      `${coef}${this.base}^{${this.variableCoefficient}${this.variable}}`
    );
  }

  /**
   * Evaluates the exponential object at a given value.
   *
   * @param {number} The value to evalue the exponential at.
   * @returns {number} The value of the exponential object at the given value.
   * @throws {TypeError}   The value should be a number.
   */

  evaluate(value) {
    if (typeof value !== "number") {
      throw new NumberSetError(`Expected a number, got ${value} instead`);
    }
    if (this.base === "e") {
      return (
        this.coefficient *
        Math.E ** (this.variableCoefficient * value + this.offset)
      );
    }
    return (
      this.coefficient *
      this.base ** (this.variableCoefficient * value + this.offset)
    );
  }

  /**
   * Returns the derivative of the exponential. Only works for exponentials base e so far.
   *
   * @returns {Exponential} The derivative of the exponential as a new exponential object.
   * @throws {ArgumentError} Will not work if the base is not 'e'.
   */
  derivative() {
    if (this.base === "e") {
      return new Exponential(
        this.coefficient * this.variableCoefficient,
        "e",
        this.variableCoefficient,
        this.variable,
        this.offset
      );
    }
    throw new ArgumentError(
      `Only works for base 'e', got ${this.base} instead`
    );
  }

  /**
   * Returns the integral of the exponential. Only works for exponentials base e so far.
   *
   * @returns {Exponential} The integral of the exponential as a new exponential object.
   * @throws {ArgumentError} Will not work if the base is not 'e'.
   */
  integral() {
    if (this.base === "e") {
      return new Exponential(
        this.coefficient / this.variableCoefficient,
        "e",
        this.variableCoefficient,
        this.variable,
        this.offset
      );
    }
    throw new ArgumentError(
      `Only works for base 'e', got ${this.base} instead`
    );
  }

  graph() {
    const p = 3 * this.evaluate(0);
    const sizes = [
      [5, 1],
      [10, 2],
      [20, 5],
      [50, 10],
      [100, 20],
      [200, 20],
      [500, 100],
      [1000, 200],
    ];
    let size = 1;
    while (p >= sizes[size][0]) {
      size += 1;
      if (size === sizes.length - 1) {
        break;
      }
    }
    const graph = new Graph(
      sizes[size][0],
      -sizes[size][0],
      sizes[size][0],
      -sizes[size][0],
      sizes[size][1],
      sizes[size][1]
    );
    const k = this;
    function f(x) {
      return k.evaluate(x);
    }
    graph.plot(f, -sizes[size][0], sizes[size][0]);
    return graph;
  }

  functionString() {
    let b = this.base;
    if (b === "e") {
      b = Math.E;
    }
    return `${this.coefficient}*${b}**(${this.variableCoefficient}*x+${this.offset})`;
  }
}

export { Exponential };
