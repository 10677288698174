import React from "react";
import PropTypes from "prop-types";
import styles from "./styles/PlainInput.module.css";

const PlainInput = ({
  obscure,
  handleChange,
  width,
  label,
  autoFocus,
  value,
  onEnterPressed,
  placeholder,
}) => {
  const enterPressed = (e) => {
    if (e.key === "Enter") {
      if (onEnterPressed) {
        onEnterPressed();
      }
    }
  };
  return (
    <div>
      <div className={`${styles.label}`}>{label}</div>
      <input
        placeholder={placeholder}
        value={value}
        type={obscure ? "password" : ""}
        autoFocus={autoFocus}
        className={`${styles.input}`}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        style={{
          width: `${width === -1 ? `100%` : `${width}em`}`,
        }}
        onKeyPress={enterPressed}
      />
    </div>
  );
};

PlainInput.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  obscure: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  width: PropTypes.number,
  autoFocus: PropTypes.bool,
  onEnterPressed: PropTypes.func,
  placeholder: PropTypes.string,
};

PlainInput.defaultProps = {
  label: "",
  obscure: false,
  width: 2,
  autoFocus: false,
  onEnterPressed: undefined,
  placeholder: "",
};

export default PlainInput;
