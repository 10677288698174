/**
 * Integration By Parts.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Input two functions, will return the integral by parts of their product.
 * // I.e. for $2e^{3x}\\sin(x)$, you would use two functions, $2e^{3x}$ and $\\sin(x)$.
 * let p1 = new Exponential(2,'e',3)
 * let s1 = new Trig(1,'sin',1,1)
 * let cr = new ProductRule(p1,s1)
 */

class ByParts {
  /**
   * Creates a ByParts object.
   *
   * @param {Object} func1  The first term of the product.
   * @param {Object} func2  The second term of the product.
   * @param {number} [a='']  The lower bound of a definite integral.
   * @param {number} [b='']  The upper bound of a definite integral.
   * @param {string} [variable = 'x']  The integration variable, used for $dx$ vs $dt$ etc.
   */
  constructor(func1, func2, a = "", b = "", variable = "x") {
    this.func1 = func1;
    this.func2 = func2;
    this.a = a;
    this.b = b;
    this.variable = variable;
  }

  /**
   * Prints the product of functions as a string.
   * @returns {string}
   */
  toString() {
    let output = "";
    if (this.a === "" && this.b === "") {
      output += "\\int";
    } else {
      output += `\\int_{${this.a}}^{${this.b}}`;
    }

    if (this.func1.constructor.name === "Polynomial") {
      output += `\\left(${this.func1.toString()}\\right)`;
    } else {
      output += this.func1.toString();
    }

    if (this.func2.constructor.name === "Polynomial") {
      output += `\\left(${this.func2.toString()}\\right)`;
    } else {
      output += this.func2.toString();
    }

    output += `d${this.variable}`;
    return output;
  }

  /**
   * Returns the integration by parts in the form $uv - \\int{vdu}$
   *
   * @returns {string}
   */
  integral(last = false, brackets = true) {
    const u = this.func1;
    const du = this.func1.derivative();
    const v = this.func2.integral();
    const vi = v.integral();
    let output = "";
    if (this.a !== "" && this.b !== "" && brackets === true) {
      output += "\\left[";
    }

    output += `\\left(${u.toString()}\\right)`;
    output += `\\left(${v.toString()}\\right)`;

    if (last === false) {
      if (this.a !== "" && this.b !== "" && brackets === true) {
        output += `\\right]_{${this.a}}^{${this.b}}`;
      }
      output += "-";
      if (this.a !== "" && this.b !== "") {
        output += `\\int_{${this.a}}^{${this.b}}`;
      } else {
        output += "\\int";
      }

      output += `\\left(${v.toString()}\\right)`;
      output += `\\left(${du.toString()}\\right)`;

      output += `d${this.variable}`;
    } else {
      if (du.toString() === "1" || du.toString() === "1 ") {
        output += "-";
      } else {
        output += `-${du.toString()}`;
      }
      output += `\\left(${vi.toString()}\\right)`;
      if (this.a !== "" && this.b !== "" && brackets === true) {
        output += `\\right]_{${this.a}}^{${this.b}}`;
      }
    }
    return output;
  }

  evaluate() {
    const u = this.func1;
    const du = this.func1.derivative();
    const v = this.func2.integral();
    const vi = v.integral();
    return (
      u.evaluate(this.b) * v.evaluate(this.b) -
      du.evaluate(this.b) * vi.evaluate(this.b) -
      u.evaluate(this.a) * v.evaluate(this.a) +
      du.evaluate(this.a) * vi.evaluate(this.a)
    );
  }

  /**
   * Returns the working for integration by parts in the form $uv - \\int{vdu}$
   *
   * @param {boolean} [evaluate=false] should the $uv$ part of the integral be evaluated.
   *
   * @returns {string}
   */
  integralWorking() {
    const output = [
      "HEADING Integration by parts:",
      `$u = ${this.func1.toString()}$`,
      `$u' = ${this.func1.derivative().toString()}$`,
      `$v = ${this.func2.integral().toString()}$`,
      `$v' = ${this.func2.toString()}$`,
      `$\\displaystyle uv-\\int vdu = ${this.integral()}$`,
      `HEADING Second Integral:`,
      `$\\displaystyle =${this.integral(true)}$`,
      `HEADING Evaluating:`,
      `$\\displaystyle = \\left(${this.integral(true, false)
        .replace(/\d\s*(?=x)/g, function rep(x) {
          return `${x}\\cdot `;
        })
        .replace(/x/g, this.b)} \\right)$`,
      `$\\displaystyle -\\left(${this.integral(true, false)
        .replace(/\d\s*(?=x)/g, function rep(x) {
          return `${x}\\cdot `;
        })
        .replace(/x/g, this.a)} \\right)$`,
      `$\\approx ${Math.round(1000 * this.evaluate()) / 1000}$`,
    ];
    return output;
  }

  /**
   * Returns the integration by parts twice.
   *
   * @param {bool} [last = false] DO the last integral
   * @param {bool} [lastBrackets = false] Add brackets to the string
   *
   * @returns {string}
   */
  doubleIntegral(last = false, lastBrackets = true) {
    const u = this.func1;
    const du = this.func1.derivative();
    const ddu = this.func1.derivative().derivative();
    const vi = this.func2.integral();
    const vii = this.func2.integral().integral();
    const viii = this.func2.integral().integral().integral();
    let output = "";
    if (this.a !== "" && this.b !== "" && lastBrackets === true) {
      output += "\\left[";
    }

    output += `\\left(${u.toString()}\\right)`;
    output += `\\left(${vi.toString()}\\right)`;

    output += `-\\left(${du.toString()}\\right)`;
    output += `\\left(${vii.toString()}\\right)`;

    if (last === true) {
      if (ddu.toString !== "" && ddu.toString !== 1) {
        output += `+\\left(${ddu.toString()}\\right)\\left(${viii.toString()}\\right)`;
      } else {
        output += `+\\left(${viii.toString()}\\right)`;
      }
    }

    if (this.a !== "" && this.b !== "" && lastBrackets === true) {
      output += `\\right]_{${this.a}}^{${this.b}}`;
    }

    if (last === false) {
      output += "+";
      if (this.a !== "" && this.b !== "") {
        output += `\\int_{${this.a}}^{${this.b}}`;
      } else {
        output += "\\int";
      }

      output += `\\left(${vii.toString()}\\right)`;
      if (ddu.toString() !== "") {
        output += `\\left(${ddu.toString()}\\right)`;
      }

      output += `d${this.variable}`;
    }
    return output;
  }

  /**
   * Evaluates Double Integral
   */
  doubleEvaluate() {
    const u = this.func1;
    const du = this.func1.derivative();
    const ddu = this.func1.derivative().derivative();
    const vi = this.func2.integral();
    const vii = this.func2.integral().integral();
    const viii = this.func2.integral().integral().integral();
    return (
      u.evaluate(this.b) * vi.evaluate(this.b) -
      du.evaluate(this.b) * vii.evaluate(this.b) +
      ddu.evaluate(this.b) * viii.evaluate(this.b) -
      (u.evaluate(this.a) * vi.evaluate(this.a) -
        du.evaluate(this.a) * vii.evaluate(this.a) +
        ddu.evaluate(this.a) * viii.evaluate(this.a))
    );
  }

  /**
   * Returns the working for integration by parts twice.
   *
   * @returns {string}
   */
  doubleIntegralWorking(evaluate = false) {
    const du = this.func1.derivative();
    const ddu = this.func1.derivative().derivative();
    const vi = this.func2.integral();
    const vii = this.func2.integral().integral();
    const output = [
      "HEADING Integration by parts once:",
      this.integralWorking()[1],
      this.integralWorking()[2],
      this.integralWorking()[3],
      this.integralWorking()[4],
      this.integralWorking()[5],
      "HEADING Integration by parts again:",
      `$ u = ${du.toString()}$`,
      `$ u' = ${ddu.toString()}$`,
      `$ v = ${vii.toString()}$`,
      `$ v' = ${vi.toString()}$`,
      `$\\displaystyle uv-\\int vdu = ${this.doubleIntegral()}$`,
      `HEADING Last Integral:`,
      `$\\displaystyle=${this.doubleIntegral(true)}$`,
    ];
    if (evaluate === true) {
      output.push(`HEADING Evaluating:`);
      output.push(
        `$\\displaystyle =\\left(${this.doubleIntegral(true, false)
          .replace(/\d\s*(?=x)/g, function rep(x) {
            return `${x}\\cdot `;
          })
          .replace(/x/g, this.b)}\\right)$`
      );
      output.push(
        `$\\displaystyle -\\left(${this.doubleIntegral(true, false)
          .replace(/\d\s*(?=x)/g, function rep(x) {
            return `${x}\\cdot `;
          })
          .replace(/x/g, this.a)}\\right)$`
      );
      output.push(
        `$\\approx ${Math.round(this.doubleEvaluate() * 1000) / 1000}$`
      );
    }
    return output;
  }

  /**
   * Evaluates $uv$ in $uv-\\int vdu$
   *
   * @returns {number}
   */
  evaluateUV() {
    let { a } = this;
    let { b } = this;
    if (this.a === "\\pi") {
      a = Math.PI;
    }
    if (this.b === "\\pi") {
      b = Math.PI;
    }
    return (
      this.func1.evaluate(b) * this.func2.integral().evaluate(b) -
      this.func1.evaluate(a) * this.func2.integral().evaluate(a)
    );
  }
}

export { ByParts };
