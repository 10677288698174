import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./styles/RoundedBtn.module.css";

const RoundedBtn = ({
  text,
  variant,
  width,
  onClick,
  fontSize,
  icon,
  className,
  subtext,
  badge,
  disabled,
}) => {
  return (
    <button
      className={`btn ${width === -1 && "btn-block"} ${styles.btn} ${
        variant === "dark" && styles.dark
      } ${variant === "light" && styles.light} ${className} ${
        disabled && styles.disabled
      } ${variant === "danger" && styles.danger} ${
        variant === "success" && styles.success
      }`}
      type="button"
      style={{ width: `${width}em` }}
      onClick={onClick}
      disabled={disabled}
    >
      {icon &&
        (typeof icon.position === "undefined" || icon.position === "top") && (
          <div className="py-3">
            <FontAwesomeIcon
              icon={icon.icon}
              size={
                icon.size ? `${icon.size}x` : `${Math.ceil(fontSize * 2.5)}x`
              }
              color={disabled ? "#bbb" : icon.color}
            />
          </div>
        )}
      <div>
        {icon && icon.position === "left" && (
          <FontAwesomeIcon
            icon={icon.icon}
            size={icon.size ? `${icon.size}x` : "1x"}
            color={icon.color}
          />
        )}
        <span
          style={{
            fontSize: `${fontSize}em`,
            marginLeft: `${icon && icon.position === "left" ? "0.5em" : ""}`,
          }}
        >
          {text}
        </span>
        {typeof badge !== "undefined" && (
          <span
            className="badge ml-2"
            style={{ backgroundColor: badge.bg, color: badge.color }}
          >
            {badge.text}
          </span>
        )}
      </div>
      {subtext && <div className="py-3">{subtext}</div>}
    </button>
  );
};

RoundedBtn.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["default", "dark", "light", "danger", "success"]),
  width: PropTypes.number,
  onClick: PropTypes.func,
  fontSize: PropTypes.number,
  icon: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  subtext: PropTypes.string,
  badge: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
};

RoundedBtn.defaultProps = {
  variant: "default",
  width: 5,
  onClick: undefined,
  fontSize: 1,
  icon: undefined,
  className: "",
  subtext: undefined,
  badge: undefined,
  disabled: false,
};

export default RoundedBtn;
