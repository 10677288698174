import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";

const Dropzone = ({ setFile }) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{`${file.path} - ${file.size} bytes`}</li>
  ));

  return (
    <section className="container">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div {...getRootProps({ className: "dropzone" })}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input {...getInputProps()} />
        <p>Drag and drop a .csv, or click to select .csv</p>
      </div>
      <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside>
    </section>
  );
};

Dropzone.propTypes = {
  setFile: PropTypes.func.isRequired,
};

export default Dropzone;
