import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./styles/StyledInput.module.css";

const StyledInput = ({
  width,
  icon,
  title,
  handleChange,
  label,
  obscure,
  value,
  centerText,
  autoCompleteOptions,
  disabled,
}) => {
  const [isTabbing, setTabbing] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [cursor, setCursor] = useState(0);
  const wrapperRef = useRef(null);

  const onInputChange = (e) => {
    setOpen(true);
    handleChange(e.target.value);
    let opts = [];
    const filterStr = (entry) => {
      if (entry.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1) {
        return true;
      }
      return false;
    };
    if (e.target.value !== "") {
      opts = autoCompleteOptions.filter(filterStr);
    }
    setOptions(opts);
  };

  const handleKeyDown = (e) => {
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
    if (e.keyCode === 38 && cursor > 0) {
      setCursor(cursor - 1);
    } else if (e.keyCode === 40 && cursor < options.length - 1) {
      setCursor(cursor + 1);
    }
    if (e.key === "Enter" && options.length !== 0) {
      handleChange(options[cursor]);
      setOpen(false);
      e.target.blur();
    }
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const toggleOpen = () => {
    if (!disabled) {
      if (value === "") {
        setOpen(!isOpen);
      }
    }
  };

  const handleKeyPressToggle = (e) => {
    if (e.key === "Tab") {
      setTabbing(true);
    }
    if (e.key === "Enter") {
      toggleOpen();
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    setCursor(0);
  }, [options]);

  useEffect(() => {
    setOptions([...autoCompleteOptions]);
  }, [autoCompleteOptions]);

  return (
    <div ref={wrapperRef} style={{ position: "relative" }}>
      <div style={{ textAlign: "left" }}>{label}</div>
      <div
        className={`${!isOpen && value === "" ? styles.title : ""} ${
          !isTabbing ? styles.noTabs : ""
        } ${title === "" ? styles.setHeight : ""} ${
          disabled && styles.disabled
        }`}
        onClick={() => toggleOpen()}
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyPressToggle}
        style={{ width: `${width === -1 ? "100%" : `${width}em`}` }}
      >
        {!isOpen && value === "" && (
          <>
            {icon && (
              <FontAwesomeIcon
                style={{ marginRight: "0.4em", fontSize: "0.9em" }}
                icon={icon}
              />
            )}
            {title}
          </>
        )}
        {(isOpen || value !== "") && (
          <input
            value={value}
            type={obscure ? "password" : ""}
            className={`${styles.input} ${!isTabbing ? styles.noTabs : ""}`}
            autoFocus
            onChange={onInputChange}
            style={{
              width: `${width === -1 ? "100%" : `${width}em`}`,
              textAlign: `${centerText && "center"}`,
            }}
            onKeyDown={handleKeyDown}
          />
        )}
        {isOpen && options.length !== 0 && (
          <div className={`${styles.ddItems}`}>
            {options.map((item, i) => (
              <div
                className={`${styles.ddItem} ${
                  !isTabbing ? styles.noTabs : ""
                } ${cursor === i && styles.ddItemActive}`}
                key={item.id}
                role="button"
                tabIndex={0}
                onClick={() => {
                  handleChange(item);
                  setOpen(false);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleChange(item);
                    setOpen(false);
                  }
                  if (e.key === "Tab") {
                    setTabbing(true);
                  }
                }}
                onMouseEnter={() => setCursor(i)}
              >
                {item}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

StyledInput.propTypes = {
  icon: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  width: PropTypes.number,
  label: PropTypes.string,
  obscure: PropTypes.bool,
  value: PropTypes.string.isRequired,
  centerText: PropTypes.bool,
  autoCompleteOptions: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
};

StyledInput.defaultProps = {
  title: "",
  icon: undefined,
  width: 12.5,
  label: "",
  obscure: false,
  centerText: false,
  autoCompleteOptions: [],
  disabled: false,
};

export default StyledInput;
