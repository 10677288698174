import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ClipLoader from "react-spinners/ClipLoader";
import { faSort, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

import ReactPaginate from "react-paginate";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopicProgress from "./TopicProgress";
import Dropdown from "./Dropdown";
import StyledInput from "./StyledInput";
import GeneralModal from "./GeneralModal";

const TopicBreakdown = ({ topics, loading }) => {
  const [filter, setFilter] = useState("");
  const [sortBy, setSortBy] = useState(1);
  const [ddTitle, setDDTitle] = useState("Score: High to Low");
  const [breakdownSlices, setBreakdownSlices] = useState([[]]);
  const [pageNumber, setPageNumber] = useState(0);
  const [showInfo, setShowInfo] = useState(false);
  const options = [
    { id: 0, title: "Score: High to Low", value: 1 },
    { id: 1, title: "Score: Low to High", value: 2 },
    { id: 2, title: "Topic: A-Z", value: 3 },
  ];

  const handleChangePage = (data) => {
    setPageNumber(data.selected);
  };

  const lowToHigh = (a, b) => {
    return a[1] - b[1];
  };

  const highToLow = (a, b) => {
    return b[1] - a[1];
  };

  const alphabetical = (a, b) => {
    const textA = a[0];
    const textB = b[0];
    if (textA < textB) {
      return -1;
    }
    if (textB > textA) {
      return 1;
    }
    return 0;
  };

  const setFilterInSearch = (str) => {
    setFilter(str);
  };

  const setSortCriteria = (val) => {
    setSortBy(val);
    for (let i = 0; i < options.length; i += 1) {
      if (options[i].value === val) {
        setDDTitle(options[i].title);
      }
    }
  };

  const display = (str) => {
    let theTitle = _.startCase(str);
    theTitle = theTitle.replace("And", "and");
    theTitle = theTitle.replace("By", "by");
    theTitle = theTitle.replace("Of", "of");
    theTitle = theTitle.replace("Newtons", "Newton's");
    theTitle = theTitle.replace("A 2 ", "");
    theTitle = theTitle.replace("3 D", "3D");
    theTitle = theTitle.replace("Counter Example", "Counterexample");
    return theTitle;
  };

  useEffect(() => {
    const sortable = [];
    Object.entries(topics).forEach((entry) => {
      sortable.push(entry);
    });
    if (sortBy === 1) {
      sortable.sort(highToLow);
    } else if (sortBy === 2) {
      sortable.sort(lowToHigh);
    } else if (sortBy === 3) {
      sortable.sort(alphabetical);
    }
    const filterStr = (entry) => {
      if (entry[0].toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
        return true;
      }
      return false;
    };
    let filtered = [...sortable];
    if (filter !== "") {
      filtered = sortable.filter(filterStr);
    }
    const perPage = 12;
    const slices = [];
    while (filtered.length) slices.push(filtered.splice(0, perPage));
    setBreakdownSlices(slices);
  }, [topics, sortBy, filter]);

  const text = () => {
    return (
      <div>
        <p>
          Your scores are calculated using your previous performance and are
          used to determine your next steps.
        </p>
        <p>
          These are an approximation of how strong we currently perceive you are
          on any given topic.
        </p>
      </div>
    );
  };

  return (
    <div className="card">
      <GeneralModal
        show={showInfo}
        onHide={() => setShowInfo(false)}
        text={text()}
        title="Topic Scores"
      />
      <h5 className="card-header text-center">
        <span>Topic Breakdown</span>
        <FontAwesomeIcon
          style={{ marginLeft: "0.2em", fontSize: "0.8em", cursor: "pointer" }}
          icon={faQuestionCircle}
          onClick={() => setShowInfo(true)}
        />
      </h5>
      <div className="card-body">
        {loading ? (
          <div className=" text-center my-5">
            <ClipLoader loading color="#74c5ed" size="3em" />
          </div>
        ) : (
          <div>
            <div className="text-center">
              <div style={{ display: "inline-block", margin: "0.5em" }}>
                <StyledInput
                  icon={faSearch}
                  title="Search"
                  handleChange={setFilterInSearch}
                  value={filter}
                  centerText
                />
              </div>
              <div style={{ display: "inline-block", margin: "0.5em" }}>
                <Dropdown
                  title={ddTitle}
                  icon={faSort}
                  options={options}
                  handleChange={setSortCriteria}
                />
              </div>
            </div>
            <div className="row text-center">
              {breakdownSlices.length !== 0 &&
                breakdownSlices[pageNumber].map((topic, index) => {
                  return (
                    <div className="col-md-4 my-3" key={index}>
                      <TopicProgress
                        topic={display(topic[0])}
                        score={topic[1]}
                      />
                    </div>
                  );
                })}
            </div>
            {breakdownSlices.length > 1 && (
              <ReactPaginate
                previousLabel="&laquo;"
                nextLabel="&raquo;"
                breakLabel="..."
                breakClassName="page-item"
                pageCount={breakdownSlices.length}
                breakLinkClassName="page-link"
                marginPagesDisplayed={2}
                pageRangeDisplayed={1}
                containerClassName="pagination"
                subContainerClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                nextClassName="page-item"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
                activeClassName="page-item active"
                disabledClassName="page-item disabled"
                onPageChange={handleChangePage}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

TopicBreakdown.propTypes = {
  topics: PropTypes.objectOf(PropTypes.number).isRequired,
  loading: PropTypes.bool,
};

TopicBreakdown.defaultProps = {
  loading: false,
};

export default TopicBreakdown;
