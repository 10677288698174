/**
 * A class that allows developers to easily add tables to a question.
 * @memberof module:MCQuestion
 * @author Declan Clark <dec@dclark.dev>
 * @since 0.1.0
 * @example
 * const myTable = new MCQuestion.Table();
 */
class Table {
  /**
   * Creates a new Table instance.
   *
   * @param {...string} headings The headings of the columns.
   */
  constructor(...headings) {
    this.headings = headings;
    this.rows = [];
  }

  /**
   * @summary Returns the table as an array of rows.
   *
   * @since 0.1.0
   *
   * @returns {Array.<*>}
   */
  getTable() {
    return [this.headings, ...this.rows];
  }

  /**
   * @summary Adds a row to the table.
   *
   * @since 0.1.0
   *
   * @param {...*} attributes The attributes for a row in the order defined by the headings.
   * @throws {TypeError} The number of attributes must match the number of columns.
   */
  addRow(...attributes) {
    if (attributes.length !== this.headings.length) {
      throw new TypeError(
        "Number of attributes does not match number of columns"
      );
    }
    this.rows.push(attributes);
  }

  /**
   * @summary Adds multiple rows to the table.
   *
   * @since 0.1.0
   *
   * @param {Array.<Array.<*>>} rows An array of rows to add to the table.
   * @throws {TypeError} The number of attributes in a row does not match the number of columns.
   */
  addRows(rows) {
    rows.forEach((row) => {
      this.addRow(...row);
    });
  }
}

export { Table };
