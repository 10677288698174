import React from "react";
import PropTypes from "prop-types";
import ExamQuestion from "../question_types/ExamQuestion";
import styles from "../styles/QuestionDisplay.module.css";

const ModuleDisplay = ({ question }) => {
  switch (question.type) {
    case "exam": {
      return (
        <>
          <div className="px-2">
            <h6>You are currently in module development mode.</h6>
          </div>
          <hr />
          <div className={`${styles.padout}`}>
            <ExamQuestion type="question" data={question.content.question} />
          </div>
        </>
      );
    }
    default:
      return <p>There was an error with this question.</p>;
  }
};

ModuleDisplay.propTypes = {
  question: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ModuleDisplay;
