export const topicsOrdered = [
  "OrderingNumbers",
  "OperationsAndPlaceValue",
  "OrderOfOperations",
  "FactorsMultiplesAndPrimes",
  "SystematicListing",
  "Powers",
  "ExactValues",
  "StandardForm",
  "ConvertingFractions",
  "FractionsAndRatio",
  "FractionsAndPercentages",
  "Measures",
  "Estimation",
  "Rounding",
  "Bounds",
  "Notation",
  "Substitution",
  "Vocabulary",
  "Manipulation",
  "ChangingTheSubject",
  "IdentityAndProof",
  "Functions",
  "Coordinates",
  "Lines",
  "GradientAndIntercept",
  "Quadratics",
  "FunctionGraphs",
  "Transformations",
  "GraphProblems",
  "AreaAndGradient",
  "Circles",
  "LinearEquations",
  "SimultaneousEquations",
  "Iteration",
  "AlgebraProblems",
  "Inequalities",
  "Terms",
  "AdvancedSequences",
  "NthTerm",
  "ConvertingUnits",
  "Scale",
  "FractionsOfQuantities",
  "Ratio",
  "RatioProblems",
  "ComparingQuantity",
  "Proportion",
  "RatioAndLinearFunctions",
  "Percentages",
  "ProportionProblems",
  "CompoundUnits",
  "MeasuresAndRatio",
  "InverseProportion",
  "ProportionAndGraphs",
  "Gradients",
  "GrowthAndDecay",
  "Terminology",
  "Construction",
  "Angles",
  "Shapes",
  "Congruence",
  "Triangles",
  "ShapeTransformations",
  "CombinedTransformations",
  "Circles",
  "CircleTheorems",
  "GeometricalProblems",
  "Shapes3D",
  "Plans3D",
  "Measures",
  "Measuring",
  "AreaAndVolume",
  "PerimeterAndSurfaceArea",
  "CircleMeasurments",
  "Congruence",
  "TriangleTheorems",
  "TrigExactValues",
  "TrigRules",
  "TriangleArea",
  "Vectors",
  "VectorArithmetic",
  "BasicProbability",
  "Randomness",
  "ExpectedFrequencies",
  "SumsOfProbabilities",
  "ProbabilitiesFromSamples",
  "ProbabilityDiagrams",
  "ProbabilitySpaces",
  "DependentProbabilities",
  "Sampling",
  "StatisticalDiagrams",
  "HistogramsAndCumulativeFrequency",
  "StatisticalMeasures",
  "Populations",
  "ScatterGraphs",
];

// ----- Number ------

export const OrderingNumbers = [];
export const OperationsAndPlaceValue = [];
export const OrderOfOperations = [];
export const FactorsMultiplesAndPrimes = [];
export const SystematicListing = [];
export const Powers = [];
export const ExactValues = [];
export const StandardForm = [];
export const ConvertingFractions = [];
export const FractionsAndRatio = [];
export const FractionsAndPercentages = [];
export const Measures = [];
export const Estimation = [];
export const Rounding = [];
export const Bounds = [];

// ------ Algebra -----
export const Notation = [];
export const Substitution = [];
export const Vocabulary = [];
export const Manipulation = [];
export const ChangingTheSubject = [];
export const IdentityAndProof = [];
export const Functions = [];
export const Coordinates = [];
export const Lines = [];
export const GradientAndIntercept = [];
export const Quadratics = [];
export const FunctionGraphs = [];
export const Transformations = [];
export const GraphProblems = [];
export const AreaAndGradient = [];
export const Circles = [];
export const LinearEquations = [];
export const SimultaneousEquations = [];
export const Iteration = [];
export const AlgebraProblems = [];
export const Inequalities = [];
export const Terms = [];
export const AdvancedSequences = [];
export const NthTerm = [];

// ----- RatioProportionAndRatesOfChange -----
export const ConvertingUnits = [];
export const Scale = [];
export const FractionsOfQuantities = [];
export const Ratio = [];
export const RatioProblems = [];
export const ComparingQuantity = [];
export const Proportion = [];
export const RatioAndLinearFunctions = [];
export const Percentages = [];
export const ProportionProblems = [];
export const CompoundUnits = [];
export const MeasuresAndRatio = [];
export const InverseProportion = [];
export const ProportionAndGraphs = [];
export const Gradients = [];
export const GrowthAndDecay = [];

// ----- Geometry ----
export const Terminology = [];
export const Construction = [];
export const Angles = [];
export const Shapes = [];
export const Congruence = [];
export const Triangles = [];
export const ShapeTransformations = [];
export const CombinedTransformations = [];
export const CircleTheorems = [];
export const GeometricalProblems = [];
export const Shapes3D = [];
export const Plans3D = [];
export const Measuring = [];
export const AreaAndVolume = [];
export const PerimeterAndSurfaceArea = [];
export const CircleMeasurments = [];
export const TriangleTheorems = [];
export const TrigExactValues = [];
export const TrigRules = [];
export const TriangleArea = [];
export const Vectors = [];
export const VectorArithmetic = [];

// ---- ProbabilityAndStatistics ---
export const BasicProbability = [];
export const Randomness = [];
export const ExpectedFrequencies = [];
export const SumsOfProbabilities = [];
export const ProbabilitiesFromSamples = [];
export const ProbabilityDiagrams = [];
export const ProbabilitySpaces = [];
export const DependentProbabilities = [];
export const Sampling = [];
export const StatisticalDiagrams = [];
export const HistogramsAndCumulativeFrequency = [];
export const StatisticalMeasures = [];
export const Populations = [];
export const ScatterGraphs = [];
