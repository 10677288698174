export default [
  { level: "AS", major: "AlgebraAndFunctions", minor: "SimultaneousEquations" },
  { level: "AS", major: "Data", minor: "Data" },
  { level: "AS", major: "Measures", minor: "Coding" },
  { level: "AS", major: "Representations", minor: "Outliers" },
  { level: "AS", major: "Representations", minor: "Histograms" },
  { level: "AS", major: "Correlation", minor: "Correlation" },
  { level: "AS", major: "Correlation", minor: "LinearRegression" },
  { level: "AS", major: "Probability", minor: "Probabilities" },
  { level: "AS", major: "Probability", minor: "VennDiagrams" },
  { level: "AS", major: "Probability", minor: "MutuallyExclusive" },
  { level: "AS", major: "Probability", minor: "TreeDiagrams" },
  { level: "AS", major: "Distributions", minor: "Probability" },
  {
    level: "AS",
    major: "Distributions",
    minor: "BinomialBinomialDistribution",
  },
  { level: "AS", major: "Distributions", minor: "Cumulative" },
  { level: "AS", major: "Hypothesis", minor: "HypothesisTesting" },
  { level: "AS", major: "ConstantAcceleration", minor: "SUVATGraphs" },
  { level: "A2", major: "AlgebraAndFunctions", minor: "AlgebraicDivision" },
  { level: "A2", major: "AlgebraAndFunctions", minor: "PartialFractions" },
  { level: "A2", major: "SequencesAndSeries", minor: "BinomialExpansion" },
  { level: "A2", major: "Differentiation", minor: "QuotientRule" },
  { level: "A2", major: "Integration", minor: "FunctionIntegration" },
  { level: "A2", major: "Regression", minor: "ExponentialModels" },
  { level: "A2", major: "Regression", minor: "MeasuringCorrelation" },
  { level: "A2", major: "Probability", minor: "SetNotation" },
  { level: "A2", major: "Probability", minor: "ConditionalProbability" },
  { level: "A2", major: "Probability", minor: "ProbabilityFormulae" },
  { level: "A2", major: "Probability", minor: "A2TreeDiagrams" },
  { level: "A2", major: "NormalDistribution", minor: "BinomialApproximation" },
  {
    level: "A2",
    major: "NormalDistribution",
    minor: "NormalHypothesisTesting",
  },
  { level: "A2", major: "Moments", minor: "MomentsSkills" },
  { level: "A2", major: "Moments", minor: "MomentsProblems" },
  { level: "A2", major: "Forces", minor: "ResolvingForces" },
  { level: "A2", major: "Projectiles", minor: "ProjectileSkills" },
  { level: "A2", major: "Projectiles", minor: "ProjectileProblems" },
  { level: "A2", major: "AppliedForces", minor: "Statics" },
  { level: "A2", major: "AppliedForces", minor: "A2ConnectedParticles" },
  { level: "A2", major: "FurtherKinematics", minor: "FurtherVectors" },
  { level: "A2", major: "FurtherKinematics", minor: "VariableAcceleration" },
  { level: "A2", major: "FurtherKinematics", minor: "VectorCalculus" },
];
