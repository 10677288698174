import { cleaner } from "./cleaner";
import { Graph } from "../MCQuestion";
/**
 * Product Rule.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Input two functions of t, will return the parametric function.
 * // I.e. for $(t^2+1,2sin(t))$, you would use two functions, $t^2+1$ and $2sin(t))$.
 * const param1 = new MCMaths.Polynomial([1, 0, 1]);
 * const param2 = new MCMaths.Trig(2, "sin");
 * const param = new MCMaths.Parametric(param1, param2, -Math.PI, Math.PI);
 */

class Parametric {
  /**
   * Creates a new parametric in the form x=func1, y=funct. Use either x or t for the variables for these functions.
   *
   * @param {function} func1 The function for x.
   * @param {function} func2 The function for y.
   * @param {number} [t1=0]  The start value of the parameter (mainly for graphing)
   * @param {number} [t2=1]  The  end  value of the parameter (mainly for graphing)
   *
   * @returns {object} Returns parametric object
   */
  constructor(func1, func2, t1 = 0, t2 = 1) {
    this.func1 = func1;
    this.func2 = func2;
    this.t1 = t1;
    this.t2 = t2;
  }

  /**
   * Prints the product of functions as a string.
   * @returns {string}
   */
  toString() {
    return cleaner(
      `\\left(${this.func1
        .toString()
        .replace(/x/g, "t")} , ${this.func2
        .toString()
        .replace(/x/g, "t")} \\right)`
    );
  }

  evaluate(t = this.t1) {
    return [this.func1.evaluate(t), this.func2.evaluate(t)];
  }

  /**
   * Returns graph of the equations
   *
   * @param {Number} [Range = 0] The range of the axis to plon on.
   *
   * @returns {Graph}
   */
  // Known bug with this, waiting on Dec for a fix
  graph(range = 10) {
    const stepSize = Math.ceil(range / 10);
    const graph = new Graph(range, -range, range, -range, stepSize, stepSize);

    const j = this.func1;
    function f(x) {
      return j.evaluate(x);
    }

    const k = this.func2;
    function g(x) {
      return k.evaluate(x);
    }

    graph.addParametric(f, g, this.t1, this.t2);
    return graph;
  }

  /**
   * Returns the derivative of func1 as an object
   *
   * @returns {object}
   */

  derivativeX() {
    return this.func1.derivative();
  }

  /**
   * Returns the derivative of func1 as an object
   *
   * @returns {object}
   */

  derivativeY() {
    return this.func2.derivative();
  }

  /**
   * Returns the derivative of the equations as a string
   *
   * @returns {string}
   */

  derivative() {
    return `\\frac{${this.derivativeY()
      .toString()
      .replace(/x/g, "t")}}{${this.derivativeX()
      .toString()
      .replace(/x/g, "t")}}`;
  }

  /**
   * Returns the working for the derivative
   *
   * @returns {string[]}
   */
  derivativeWorking() {
    const output = [
      "HEADING Finding $\\frac{dx}{dt}$:",
      `$\\frac{d}{dt}\\left(${this.func1
        .toString()
        .replace(/x/g, "t")}\\right) = ${this.derivativeX()
        .toString()
        .replace(/x/g, "t")} $`,
      "HEADING Finding $\\frac{dy}{dt}$:",
      `$\\frac{d}{dt}\\left(${this.func2
        .toString()
        .replace(/x/g, "t")}\\right) = ${this.derivativeY()
        .toString()
        .replace(/x/g, "t")} $`,
      "HEADING Parametric Derivative:",
      `$\\frac{dy}{dx}= \\frac{dy}{dt} \\big/ \\frac{dx}{dt}$`,
      `$=${this.derivative()}$`,
    ];
    return output;
  }
}

export { Parametric };
