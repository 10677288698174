import React from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import RoundedBtn from "./RoundedBtn";

const AcceptModal = ({
  show,
  onHide,
  onAction,
  personName,
  invitationFrom,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Accept Invitation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <b>
          {`By accepting this invitation you are consenting to share your full name ${
            invitationFrom === "staff" ? "and your review data" : ""
          } with ${personName}.`}
        </b>
      </Modal.Body>
      <Modal.Footer>
        <RoundedBtn text="Close" onClick={onHide} />
        <RoundedBtn text="Accept" onClick={onAction} variant="dark" width={8} />
      </Modal.Footer>
    </Modal>
  );
};

AcceptModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  personName: PropTypes.string,
  invitationFrom: PropTypes.string.isRequired,
};

AcceptModal.defaultProps = {
  personName: "",
};

export default AcceptModal;
