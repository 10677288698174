import { ArgumentError, IndexError, QuestionError } from "../MCError";
import { Image } from "./Image";
import { InputGroup } from "./InputGroup";
import { Graph } from "./Graph";
import { Table } from "./Table";

/**
 * A class that supports the development of exam style questions.
 * @memberof module:MCQuestion
 * @author Declan Clark <dec@dclark.dev>
 * @since 0.1.0
 * @example
 * const question = new MCQuestion.ExamQuestion();
 */
class ExamQuestion {
  /**
   * Creates a new exam question relating to a specific topic.
   */
  constructor() {
    this.questionSequence = [];
    this.solutionSequence = [];
    this.inputGroups = [];
    this.selfMarkingParts = false;
  }

  /**
   * @summary Adds a paragraph to the exam question.
   *
   * @description Creates a paragraph object and adds it to the current question/solution sequence.
   *              Font size can be changed with the optional fontSize parameter.
   *
   * @since 0.1.0
   *
   * @param   {('question' | 'solution')} section      Whether the paragraph is added to the question or solution.
   * @param   {string}                    paragraph    The string for the paragraph being added.
   * @param   {number}                    [fontSize]   The size of the font when displaying the paragraph.
   * @throws  {ArgumentError}                          Paragraph string and section are required.
   * @throws  {TypeError}                              Parameters were the wrong type.
   *
   * @example
   * const question = new MCQuestion.ExamQuestion();
   * question.addParagraph('question', 'State the Cosine rule.', 2);
   */
  addParagraph(section, paragraph, fontSize) {
    if (typeof section === "undefined") {
      throw new ArgumentError(`section is required`);
    }
    if (typeof section !== "string") {
      throw new TypeError(
        `expected a string but got ${typeof section} instead`
      );
    }
    if (section !== "question" && section !== "solution") {
      throw new RangeError(
        `expected 'question' or 'solution' but got ${section} instead`
      );
    }
    if (typeof paragraph === "undefined") {
      throw new ArgumentError(`paragraph is required`);
    }
    if (typeof paragraph !== "string") {
      throw new TypeError(
        `expected a string but got ${typeof paragraph} instead`
      );
    }
    if (typeof fontSize !== "undefined") {
      if (typeof fontSize !== "number") {
        throw new TypeError(
          `expected a number but got ${typeof fontSize} instead`
        );
      }
      if (paragraph.includes("HEADING")) {
        const par = paragraph.replace("HEADING", "").trim();
        if (section === "question") {
          this.questionSequence.push({
            type: "paragraph",
            content: { p: par, size: fontSize, heading: true },
          });
        } else {
          this.solutionSequence.push({
            type: "paragraph",
            content: { p: par, size: fontSize, heading: true },
          });
        }
      } else if (section === "question") {
        this.questionSequence.push({
          type: "paragraph",
          content: { p: paragraph, size: fontSize },
        });
      } else {
        this.solutionSequence.push({
          type: "paragraph",
          content: { p: paragraph, size: fontSize },
        });
      }
    } else if (paragraph.includes("HEADING")) {
      const par = paragraph.replace("HEADING", "").trim();
      if (section === "question") {
        this.questionSequence.push({
          type: "paragraph",
          content: { p: par, heading: true },
        });
      } else {
        this.solutionSequence.push({
          type: "paragraph",
          content: { p: par, heading: true },
        });
      }
    } else if (section === "question") {
      this.questionSequence.push({
        type: "paragraph",
        content: { p: paragraph },
      });
    } else {
      this.solutionSequence.push({
        type: "paragraph",
        content: { p: paragraph },
      });
    }
  }

  /**
   * @summary Adds multiple paragraphs to the specified section.
   *
   * @description Takes an array of strings and adds each as a new paragraph. All paragraphs
   *              will have the given font size.
   *
   * @since 0.1.0
   *
   * @param {('question' | 'solution')}   section     The section to add the paragraphs to.
   * @param {string[]}                    paragraphs  The paragraphs to be added, packed in an array.
   * @param {number}                      [fontSize]  The font size (in em) to be applied to paragraphs.
   * @throws  {ArgumentError}                          Paragraph strings and section are required.
   * @throws  {TypeError}                              Parameters were the wrong type.
   *
   * @example
   * const question = new MCQuestion.ExamQuestion();
   * const someArrayOfText = ['hello', 'world'];
   * question.addMultipleParagraphs('solution', someArrayOfText, 2);
   */
  addMultipleParagraphs(section, paragraphs, fontSize) {
    if (paragraphs.length === 0) {
      throw new IndexError("expected non-empty array of strings");
    }
    for (let i = 0; i < paragraphs.length; i += 1) {
      this.addParagraph(section, paragraphs[i], fontSize);
    }
  }

  /**
   * @summary Adds a heading to the specified sequence.
   *
   * @description Adds a paragraph to the sequence which is then styled as a heading.
   *
   * @since 0.1.0
   *
   * @param {('question'|'solution')} section     The section to add the heading to.
   * @param {string}                  heading     The content of the heading.
   * @param {number}                  [fontSize]  Font size of the heading (in em).
   * @throws  {ArgumentError}                          Heading string and section are required.
   * @throws  {TypeError}                              Parameters were the wrong type.
   *
   * @example
   * const question = new MCQuestion.ExamQuestion();
   * question.addHeading('question', 'State the Cosine rule.', 2);
   */
  addHeading(section, heading, fontSize) {
    const p = `HEADING ${heading}`;
    this.addParagraph(section, p, fontSize);
  }

  /**
   * @summary Adds an image to the question/solution sequence.
   *
   * @description Takes an image object and adds it and its overlays to the section provided.
   *
   * @since 0.1.0
   *
   * @param   {('question' | 'solution')} section The section to add the image to.
   * @param   {Image}                     image   The image to add.
   * @throws  {ArgumentError}                     Required arguments were not supplied.
   * @throws  {TypeError}                         Arguments were not of the correct type.
   * @throws  {RangeError}                        Argument section was not an accepted value.
   * @example
   * // adding the image to the solution sequence
   * question.addImage('solution', myImage);
   */
  addImage(section, image) {
    if (typeof section === "undefined" || typeof image === "undefined") {
      throw new ArgumentError(`must supply required arguments`);
    }
    if (typeof section !== "string") {
      throw new TypeError(
        `expected a string but got ${typeof section} instead`
      );
    }
    if (!(image instanceof Image)) {
      throw new TypeError(
        `expected an Image object but got ${typeof image} instead`
      );
    }
    if (section !== "question" && section !== "solution") {
      throw new RangeError(
        `expected 'question' or 'solution' but got ${section} instead`
      );
    }
    if (section === "question") {
      this.questionSequence.push({
        type: "image",
        content: {
          src: image.getSrc(),
          measure: image.getMeasure(),
          size: image.getSize(),
          overlays: image.getOverlays(),
        },
      });
    } else {
      this.solutionSequence.push({
        type: "image",
        content: {
          src: image.getSrc(),
          measure: image.getMeasure(),
          size: image.getSize(),
          overlays: image.getOverlays(),
        },
      });
    }
  }

  /**
   * @summary Adds a graph to the question/solution sequence.
   *
   * @description Takes a graph object and adds it to the section provided.
   *
   * @since 0.1.0
   *
   * @param   {('question' | 'solution')} section The section to add the graph to.
   * @param   {Graph}                     graph   The graph to add.
   * @throws  {ArgumentError}                     Required arguments were not supplied.
   * @throws  {TypeError}                         Arguments were not of the correct type.
   * @throws  {RangeError}                        Argument section was not an accepted value.
   * @example
   * // adding the graph to the solution sequence
   * question.addGraph('solution', myGraph);
   */
  addGraph(section, graph) {
    if (typeof section === "undefined" || typeof graph === "undefined") {
      throw new ArgumentError(`must supply required arguments`);
    }
    if (typeof section !== "string") {
      throw new TypeError(
        `expected a string but got ${typeof section} instead`
      );
    }
    if (!(graph instanceof Graph)) {
      throw new TypeError(
        `expected a Graph object but got ${typeof graph} instead`
      );
    }
    if (section !== "question" && section !== "solution") {
      throw new RangeError(
        `expected 'question' or 'solution' but got ${section} instead`
      );
    }
    if (section === "question") {
      this.questionSequence.push({
        type: "graph",
        content: {
          axis: graph.getAxisValues(),
          funcs: graph.getFunctions(),
          steps: graph.getSteps(),
        },
      });
    } else {
      this.solutionSequence.push({
        type: "graph",
        content: {
          axis: graph.getAxisValues(),
          funcs: graph.getFunctions(),
          steps: graph.getSteps(),
        },
      });
    }
  }

  /**
   * @summary Adds a table to the question/solution sequence.
   *
   * @since 0.1.0
   *
   * @param   {('question' | 'solution')} section The section to add the table to.
   * @param   {Table}                     table   The table to add.
   * @throws  {ArgumentError}                     Required arguments were not supplied.
   * @throws  {TypeError}                         Arguments were not of the correct type.
   * @throws  {RangeError}                        Argument section was not an accepted value.
   * @example
   * // adding the table to the solution sequence
   * question.addTable('solution', myTable);
   */
  addTable(section, table) {
    if (typeof section === "undefined" || typeof table === "undefined") {
      throw new ArgumentError(`must supply required arguments`);
    }
    if (typeof section !== "string") {
      throw new TypeError(
        `expected a string but got ${typeof section} instead`
      );
    }
    if (!(table instanceof Table)) {
      throw new TypeError(
        `expected a Table object but got ${typeof table} instead`
      );
    }
    if (section !== "question" && section !== "solution") {
      throw new RangeError(
        `expected 'question' or 'solution' but got ${section} instead`
      );
    }
    if (section === "question") {
      this.questionSequence.push({
        type: "table",
        content: {
          table: table.getTable(),
        },
      });
    } else {
      this.solutionSequence.push({
        type: "table",
        content: {
          table: table.getTable(),
        },
      });
    }
  }

  /**
   * @summary Informs the application that there are some parts in the question that must be marked by the user.
   *
   * @since 0.1.0
   *
   * @example
   * question.addSelfMarking();
   */
  requiresSelfMarking() {
    this.selfMarkingParts = true;
  }

  /**
   * @summary Adds a collection of related inputs to the exam question.
   *
   * @since 0.1.0
   *
   * @param {InputGroup} inputGroup The collection of inputs as an Input Group object.
   * @throws {TypeError}            Expected an InputGroup.
   *
   * @example
   * question.addInputGroup(group);
   */
  addInputGroup(inputGroup) {
    if (!(inputGroup instanceof InputGroup)) {
      throw TypeError(`expected an input group but got ${typeof inputGroup}`);
    }
    this.inputGroups.push({
      perLine: inputGroup.getPerLine(),
      inputs: inputGroup.getInputs(),
    });
  }

  /**
   * @summary Use this function when returning the result of your question.
   *
   * @description Packages all question attributes into an object ready to be consumed by the application.
   *
   * @since 0.1.0
   *
   * @throws {QuestionError}  The question is not suitable (see error for exact reason).
   * @returns {Object} The packaged question.
   */
  makeQuestion() {
    this.questionSequence.forEach((elem) => {
      if (elem.type === "paragraph") {
        if (/\$[0-9]{8,}\$/.test(elem.content.p)) {
          throw new QuestionError(`${elem.content.p} (integer >= 8 digits)`);
        }
      }
    });
    this.solutionSequence.forEach((elem) => {
      if (elem.type === "paragraph") {
        if (/\$[0-9]{8,}\$/.test(elem.content.p)) {
          throw new QuestionError(`${elem.content.p} (integer >= 8 digits)`);
        }
      }
    });
    this.inputGroups.forEach((group) => {
      group.inputs.forEach((inp) => {
        if (Array.isArray(inp.content.answer)) {
          const { tolerance } = inp.content;
          if (tolerance !== 0) {
            for (let i = 0; i < inp.content.answer.length; i += 1) {
              const expectingExact = inp.content.answer[i];
              const upperbound = expectingExact + tolerance;
              const lowerbound = expectingExact - tolerance;
              for (let j = 0; j < inp.content.answer.length; j += 1) {
                if (i !== j) {
                  if (
                    upperbound >= inp.content.answer[j] &&
                    lowerbound <= inp.content.answer[j]
                  ) {
                    throw new QuestionError(
                      "values that have their tolerances overlapping are not permitted"
                    );
                  }
                }
              }
            }
          }
        }
      });
    });
    return {
      type: "exam",
      self: this.selfMarkingParts,
      content: {
        question: this.questionSequence,
        solution: this.solutionSequence,
        inputGroups: this.inputGroups,
      },
    };
  }
}

export { ExamQuestion };
