import { TopicSelector } from "./TopicSelector";
import { CalculateScores } from "./CalculateScores";
import entries from "../../questions/entries";
import * as MCRandom from "../MCRandom";

function QuestionSelector(qual, contentCovered, questionsAnswered, givenTopic) {
  // ----------- PARAMS --------------
  const skillsFrequency = 0.2;
  // ---------------------------------
  let topic = givenTopic;
  let idx = 2;
  if (qual !== "ALEVEL") idx = 4;
  if (typeof givenTopic === "undefined") {
    if (contentCovered.length === 0) {
      return null;
    }
    const scores = CalculateScores(questionsAnswered);
    const topics = [];

    for (let i = 0; i < contentCovered.length; i += 1) {
      let numberOfQuestionsCompleted = 0;
      let questionsSinceCompleted = 0;
      let foundFirstInstance = false;

      for (let j = 0; j < questionsAnswered.length; j += 1) {
        if (questionsAnswered[j].minor === contentCovered[i].split("_")[idx]) {
          if (!foundFirstInstance) {
            foundFirstInstance = true;
            questionsSinceCompleted = j + 1;
          }
          numberOfQuestionsCompleted += 1;
        }
      }
      topics.push({
        name: contentCovered[i].replace(/ /g, ""),
        score: scores[contentCovered[i].split("_")[idx]],
        numberOfQuestionsCompleted,
        questionsSinceCompleted,
      });
    }
    topic = TopicSelector(topics);
    while (topic === null) {
      topic = TopicSelector(topics);
    }
  }
  const topicAttributes = topic.split("_");
  let level = topicAttributes[0];
  let major = topicAttributes[1];
  const minor = topicAttributes[2];
  const thisTopicQuestions = { exam: 0, mcq: 0, skills: 0 };
  let toplevel = {};
  let gcseLevel = "Foundation";
  if (level !== "AS" && level !== "A2") {
    if (qual === "HIGH") {
      toplevel = entries.Higher[level];
      gcseLevel = "Higher";
    } else {
      toplevel = entries.Foundation[level];
    }
  } else {
    toplevel = entries[level];
  }
  if (gcseLevel === "Foundation") {
    if (major === "NotationVocabularyAndManipulation") major = "Nvam";
    if (major === "MensurationAndCalculation") major = "Mac";
    if (major === "PropertiesAndConstructions") major = "Pac";
    if (major === "Vectors") major = "V";
    if (major === "FractionsDecimalsAndPercentages") major = "Fdap";
    if (major === "MeasuresAndAccuracy") major = "Maa";
    if (major === "RatioProportionAndRatesOfChange") major = "Rparoc";
  }
  if (gcseLevel === "Higher") {
    if (major === "StructureAndCalculation") major = "Sac";
    if (major === "FractionsDecimalsAndPercentages") major = "Fdap";
    if (major === "MeasuresAndAccuracy") major = "Maa";
    if (major === "MensurationAndCalculation") major = "Mac";
    if (major === "PropertiesAndConstructions") major = "Pac";
    if (major === "NotationVocabularyAndManipulation") major = "Nvam";
    if (major === "SolvingEquationsAndInequalities") major = "Seai";
  }
  if (level === "RatioProportionAndRatesOfChange") level = "Rparoc";
  topic = `${level}_${major}_${minor}`;
  // possible for toplevel to be undefined if there are no entries for a particular GCSE topic i.e. Higher-Rparoc
  // able to force a reload by ensuring question selector does not error by setting empty object
  if (!toplevel) {
    toplevel = {};
  }
  if (!(major in toplevel)) {
    if (givenTopic !== null) {
      return null;
    }
    return QuestionSelector(qual, contentCovered, questionsAnswered);
  }
  if (!(minor in toplevel[major])) {
    if (givenTopic !== null) {
      return null;
    }
    return QuestionSelector(qual, contentCovered, questionsAnswered);
  }
  if ("Exam" in toplevel[major][minor]) {
    thisTopicQuestions.exam = toplevel[major][minor].Exam.length;
  }
  if ("MCQ" in toplevel[major][minor]) {
    thisTopicQuestions.mcq = toplevel[major][minor].MCQ.length;
  }
  if ("Skills" in toplevel[major][minor]) {
    thisTopicQuestions.skills = toplevel[major][minor].Skills.length;
  }
  if (
    thisTopicQuestions.exam === 0 &&
    thisTopicQuestions.mcq === 0 &&
    thisTopicQuestions.skills === 0
  ) {
    if (typeof givenTopic === "undefined") {
      return QuestionSelector(qual, contentCovered, questionsAnswered);
    }
    return null;
  }
  // filter questions answered to only this topic
  const filterQuestions = (...types) => {
    const questions = [];
    for (let i = 0; i < questionsAnswered.length; i += 1) {
      if (
        level === questionsAnswered[i].level &&
        major === questionsAnswered[i].major &&
        minor === questionsAnswered.minor &&
        types.includes(questionsAnswered[i].type)
      ) {
        questions.push(questionsAnswered[i]);
      }
    }
    return questions;
  };
  const getKeysWithMinValues = (obj) => {
    let min = Number.MAX_SAFE_INTEGER;
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i += 1) {
      if (obj[keys[i]] < min) {
        min = obj[keys[i]];
      }
    }
    const minKeys = [];
    for (let i = 0; i < keys.length; i += 1) {
      if (obj[keys[i]] === min) {
        minKeys.push(keys[i]);
      }
    }
    return minKeys;
  };
  // if there is at least one skills question and probability of skills comes into play
  if (
    (thisTopicQuestions.exam === 0 && thisTopicQuestions.mcq === 0) ||
    (thisTopicQuestions.skills > 0 && Math.random() < skillsFrequency)
  ) {
    // filter down to only skills questions for this topic
    const questions = filterQuestions("Skills");
    // get count for each question
    const counts = {};
    for (let i = 0; i < thisTopicQuestions.skills; i += 1) {
      counts[`Q${i + 1}`] = 0;
    }
    for (let i = 0; i < questions.length; i += 1) {
      counts[`Q${questions.qNumber}`] += 1;
    }
    // choose a random question from the lowest counts and return
    const minCounts = getKeysWithMinValues(counts);
    return `${topic}_Skills_${MCRandom.chooseElement(minCounts)}`;
  }
  // same as above treating exam and mcq as the same and return
  let questions;
  if (thisTopicQuestions.exam !== 0 && thisTopicQuestions.mcq !== 0) {
    questions = filterQuestions("Exam", "MCQ");
  } else if (thisTopicQuestions.exam === 0) {
    questions = filterQuestions("MCQ");
  } else {
    questions = filterQuestions("Exam");
  }
  const counts = {};
  for (let i = 0; i < thisTopicQuestions.exam; i += 1) {
    counts[`EQ${i + 1}`] = 0;
  }
  for (let i = 0; i < thisTopicQuestions.mcq; i += 1) {
    counts[`MQ${i + 1}`] = 0;
  }
  for (let i = 0; i < questions.length; i += 1) {
    if (questions[i].type === "Exam") {
      counts[`EQ${questions.qNumber}`] += 1;
    } else {
      counts[`MQ${questions.qNumber}`] += 1;
    }
  }
  const minCounts = getKeysWithMinValues(counts);
  const question = MCRandom.chooseElement(minCounts);
  if (idx === 4) {
    return question[0] === "E"
      ? `GCSE_${gcseLevel}_${topic}_Exam_${question.substring(1)}`
      : `GCSE_${gcseLevel}_${topic}_MCQ_${question.substring(1)}`;
  }
  return question[0] === "E"
    ? `${topic}_Exam_${question.substring(1)}`
    : `${topic}_MCQ_${question.substring(1)}`;
}

export { QuestionSelector };
