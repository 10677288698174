import { ArgumentError, IndexError, QuestionError } from "../MCError";
import { MultipleChoiceAnswer } from "./MultipleChoiceAnswer";
import { Graph, Image } from ".";
/**
 * A class that supports the development of multiple choice style questions.
 * @memberof module:MCQuestion
 * @author Declan Clark <dec@dclark.dev>
 * @since 0.1.0
 * @example
 * const question = new MCQuestion.MultipleChoiceQuestion();
 */
class MultipleChoiceQuestion {
  /**
   * Creates a new multiple choice question.
   *
   * @param {number} ansPerLine Number of answer boxes to use per line.
   * @param {('left' | 'center')} [alignment='center']  Alignment of elements inside of the answer boxes.
   * @throws {TypeError}  Arguments are not of the correct type.
   * @throws {RangeError} Alignment is not an accepted value.
   */
  constructor(ansPerLine, alignment = "center") {
    if (typeof ansPerLine !== "number") {
      throw new TypeError(`expected a number but got ${typeof ansPerLine}`);
    }
    if (typeof alignment !== "string") {
      throw new TypeError(
        `expected 'left' or 'center' but got ${typeof alignment}`
      );
    }
    if (alignment !== "left" && alignment !== "center") {
      throw new RangeError(`expected 'left' or 'center' but got ${alignment}`);
    }
    this.question = [];
    this.answers = [];
    this.perLine = ansPerLine;
    this.aligns = alignment;
  }

  /**
   * @summary Adds a paragraph to the actual question section.
   *
   * @description Creates a paragraph object and adds it to the question part.
   *              Font size can be changed with the optional fontSize parameter.
   *
   * @since 0.1.0
   *
   * @param   {string}                    paragraph    The string for the paragraph being added.
   * @param   {number}                    [fontSize]   The size of the font when displaying the paragraph.
   * @throws  {ArgumentError}                          Paragraph string is required.
   * @throws  {TypeError}                              Parameters were the wrong type.
   *
   * @example
   * const question = new MCQuestion.MultipleChoiceQuestion();
   * question.addParagraph('Which of the following graphs shows f(x) = 5 * sin(x).', 1.3);
   */
  addParagraph(paragraph, fontSize) {
    if (typeof paragraph === "undefined") {
      throw new ArgumentError(`paragraph is required`);
    }
    if (typeof paragraph !== "string") {
      throw new TypeError(
        `expected a string but got ${typeof paragraph} instead`
      );
    }
    if (typeof fontSize !== "undefined") {
      if (typeof fontSize !== "number") {
        throw new TypeError(
          `expected a number but got ${typeof fontSize} instead`
        );
      }
      if (paragraph.includes("HEADING")) {
        const par = paragraph.replace("HEADING", "").trim();
        this.question.push({
          type: "paragraph",
          content: { p: par, size: fontSize, heading: true },
        });
      } else {
        this.question.push({
          type: "paragraph",
          content: { p: paragraph, size: fontSize },
        });
      }
    } else if (paragraph.includes("HEADING")) {
      const par = paragraph.replace("HEADING", "").trim();
      this.question.push({
        type: "paragraph",
        content: { p: par, heading: true },
      });
    } else {
      this.question.push({
        type: "paragraph",
        content: { p: paragraph },
      });
    }
  }

  /**
   * @summary Adds multiple paragraphs to the question part.
   *
   * @description Takes an array of strings and adds each as a new paragraph. All paragraphs
   *              will have the given font size.
   *
   * @since 0.1.0
   *
   * @param {string[]}                    paragraphs  The paragraphs to be added, packed in an array.
   * @param {number}                      [fontSize]  The font size (in em) to be applied to paragraphs.
   * @throws  {ArgumentError}                          Paragraph strings are required.
   * @throws  {TypeError}                              Parameters were the wrong type.
   * @throws  {IndexError}                             Array is empty.
   *
   * @example
   * const question = new MCQuestion.MultipleChoiceQuestion();
   * const someArrayOfText = ['hello', 'world'];
   * question.addMultipleParagraphs(someArrayOfText, 2);
   */
  addMultipleParagraphs(paragraphs, fontSize) {
    if (paragraphs.length === 0) {
      throw new IndexError("expected non-empty array of strings");
    }
    for (let i = 0; i < paragraphs.length; i += 1) {
      this.addParagraph(paragraphs[i], fontSize);
    }
  }

  /**
   * @summary Adds a heading to the question part.
   *
   * @description Adds a paragraph to the question which is then styled as a heading.
   *
   * @since 0.1.0
   *
   * @param {string}                  heading     The content of the heading.
   * @param {number}                  [fontSize]  Font size of the heading (in em).
   * @throws  {ArgumentError}                          Heading string is required.
   * @throws  {TypeError}                              Parameters were the wrong type.
   *
   * @example
   * const question = new MCQuestion.ExamQuestion();
   * question.addHeading('Something to be styled as a heading.', 2);
   */
  addHeading(heading, fontSize) {
    const p = `HEADING ${heading}`;
    this.addParagraph(p, fontSize);
  }

  /**
   * @summary Adds an image to the question part.
   *
   * @description Takes an image object and adds it and its overlays to the question.
   *
   * @since 0.1.0
   *
   * @param   {Image}                     image   The image to add.
   * @throws  {ArgumentError}                     Required arguments were not supplied.
   * @throws  {TypeError}                         Arguments were not of the correct type.
   * @example
   * // adding the image
   * question.addImage(myImage);
   */
  addImage(image) {
    if (typeof image === "undefined") {
      throw new ArgumentError(`must supply required arguments`);
    }
    if (!(image instanceof Image)) {
      throw new TypeError(
        `expected an Image object but got ${typeof image} instead`
      );
    }
    this.question.push({
      type: "image",
      content: {
        src: image.getSrc(),
        measure: image.getMeasure(),
        size: image.getSize(),
        overlays: image.getOverlays(),
      },
    });
  }

  /**
   * @summary Adds a graph to the question part.
   *
   * @description Takes a graph object and adds it and its overlays to the question.
   *
   * @since 0.1.0
   *
   * @param   {Graph}                     graph   The graph to add.
   * @throws  {ArgumentError}                     Required arguments were not supplied.
   * @throws  {TypeError}                         Arguments were not of the correct type.
   * @example
   * // adding the graph
   * question.addGraph(myGraph);
   */
  addGraph(graph) {
    if (typeof graph === "undefined") {
      throw new ArgumentError(`must supply required arguments`);
    }
    if (!(graph instanceof Graph)) {
      throw new TypeError(
        `expected a Graph object but got ${typeof graph} instead`
      );
    }
    this.question.push({
      type: "graph",
      content: {
        axis: graph.getAxisValues(),
        funcs: graph.getFunctions(),
        steps: graph.getSteps(),
      },
    });
  }

  /**
   * @summary Adds an MultipleChoiceAnswer to the answer section.
   *
   * @since 0.1.0
   *
   * @param {MultipleChoiceAnswer} ans  A possible answer to the question.
   * @param {boolean} correct           Whether this answer is a correct answer.
   * @throws {TypeError}                Arguments were not of the correct type.
   */
  addAnswer(ans, correct) {
    if (!(ans instanceof MultipleChoiceAnswer)) {
      throw new TypeError(
        `expected a multiple choice answer but got ${typeof ans} isntead`
      );
    }
    if (typeof correct !== "boolean") {
      throw new TypeError(
        `expected correct to be boolean but got ${typeof correct} instead`
      );
    }
    this.answers.push({ correct, sequence: ans.getSequence() });
  }

  /**
   * @summary Use this function when returning the result of your question.
   *
   * @description Packages all question attributes into an object ready to be consumed by the application.
   *
   * @since 0.1.0
   *
   * @throws {QuestionError}  The question is not suitable (see error presented for why).
   * @returns {Object} The packaged question.
   */
  makeQuestion() {
    this.question.forEach((elem) => {
      if (elem.type === "paragraph") {
        if (/\$[0-9]{8,}\$/.test(elem.content.p)) {
          throw new QuestionError(`${elem.content.p} (integer >= 8 digits)`);
        }
      }
    });
    this.answers.forEach((ans) => {
      ans.sequence.forEach((elem) => {
        if (elem.type === "paragraph") {
          if (/\$[0-9]{8,}\$/.test(elem.content.p)) {
            throw new QuestionError(`${elem.content.p} (integer >= 8 digits)`);
          }
        }
      });
    });
    return {
      type: "mcq",
      content: {
        question: this.question,
        answers: { ans: this.answers, pLine: this.perLine, align: this.aligns },
      },
    };
  }
}

export { MultipleChoiceQuestion };
