import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import Papa from "papaparse";
import axios from "axios";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import RoundedBtn from "./RoundedBtn";
import Dropzone from "./Dropzone";
import checkToken from "../system/auth/checkToken";
import Alert from "./Alert";
import PlainInput from "./PlainInput";
import StyledInput from "./StyledInput";

const AdminAddUserModal = ({ show, onHide, schools }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addPossible, setAddPossible] = useState(false);
  const [alert, setAlert] = useState({});
  const [role, setRole] = useState("");
  const [school, setSchool] = useState("");
  const [schoolOptions, setSchoolOptions] = useState([]);
  const roles = [
    "DEV",
    "PUPIL",
    "SITE_ADMIN",
    "STAFF",
    "STAFF_ADMIN",
    "HIGH_PUPIL",
    "FOUND_PUPIL",
  ];
  const history = useHistory();
  useEffect(() => {
    if (file !== null) {
      if (file.path.slice(file.path.length - 4) === ".csv") {
        setAddPossible(true);
      } else {
        setAlert({
          variant: "danger",
          msg: "Please upload .csv",
        });
        setAddPossible(false);
      }
    }
  }, [file]);

  useEffect(() => {
    if (schools) {
      const emails = [];
      for (let i = 0; i < schools.length; i += 1) {
        const s = schools[i];
        emails.push(s.email);
      }
      setSchoolOptions(emails);
    }
  }, [schools]);

  const startLoad = () => {
    return setTimeout(() => {
      setLoading(true);
    }, 500);
  };

  const stopLoad = (timer) => {
    clearTimeout(timer);
    setLoading(false);
  };

  const onAdd = async () => {
    const start = startLoad();
    setAddPossible(false);
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    if (!roles.includes(role)) {
      setAlert({
        variant: "danger",
        msg: "Please choose a valid role.",
      });
      stopLoad(start);
      return false;
    }
    Papa.parse(file, {
      header: true,
      skipEmptyLines: "greedy",
      complete: async (results) => {
        if (results.errors.length > 0) {
          console.log(results.errors);
          setAlert({
            variant: "danger",
            msg: "Error in file.",
          });
          stopLoad(start);
          return false;
        }
        try {
          await axios.post(
            `${process.env.REACT_APP_DOMAIN}/admin-user`,
            { users: results.data, role, schoolEmail: school },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
              },
            }
          );
          setAlert({
            variant: "success",
            msg: "Successfully added.",
          });
          stopLoad(start);
        } catch (e) {
          if (e.response && e.response.status === 400) {
            if (typeof e.response.data.error.summary !== "undefined") {
              let failures = "";
              for (let i = 0; i < e.response.data.error.failed.length; i += 1) {
                failures += `${e.response.data.error.failed[i].email}\n`;
              }
              setAlert({
                variant: "danger",
                msg: `${e.response.data.error.summary}\n${failures}`,
              });
            } else {
              setAlert({
                variant: "danger",
                msg: JSON.stringify(e.response.data.error),
              });
            }
          } else {
            setAlert({
              variant: "danger",
              msg: "A problem occurred. Please try again.",
            });
          }
          stopLoad(start);
          return false;
        }
        return true;
      },
    });
    return true;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alert.msg && <Alert variant={alert.variant}>{alert.msg}</Alert>}
        {loading ? (
          <div className="my-5">
            <ClipLoader loading color="#74c5ed" size="3em" />
          </div>
        ) : (
          <div>
            <Dropzone setFile={setFile} />
            <PlainInput
              value={role}
              handleChange={setRole}
              width={-1}
              label="User Type"
            />
            <StyledInput
              value={school}
              handleChange={setSchool}
              width={-1}
              label="School Email"
              autoCompleteOptions={schoolOptions}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <RoundedBtn text="Close" onClick={onHide} />
        <RoundedBtn
          text="Add"
          onClick={onAdd}
          variant="dark"
          width={8}
          disabled={!addPossible}
        />
      </Modal.Footer>
    </Modal>
  );
};

AdminAddUserModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  schools: PropTypes.arrayOf(PropTypes.object).isRequired,
};

AdminAddUserModal.defaultProps = {
  show: false,
};

export default AdminAddUserModal;
