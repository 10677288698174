import React, { useState } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import PlainInput from "../PlainInput";
import RoundedBtn from "../RoundedBtn";
import Alert from "../Alert";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});

  const startLoad = () => {
    return setTimeout(() => {
      setLoading(true);
    }, 500);
  };

  const stopLoad = (timer) => {
    clearTimeout(timer);
    setLoading(false);
  };

  const validateEmail = (e) => {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(String(e).toLowerCase());
  };

  const requestReset = async () => {
    const start = startLoad();
    setAlert({});
    if (!validateEmail(email)) {
      setAlert({
        variant: "danger",
        msg: "Please enter a valid email address.",
      });
      stopLoad(start);
      return false;
    }
    try {
      await axios.post(`${process.env.REACT_APP_DOMAIN}/users`, {
        email,
      });
    } catch (e) {
      setAlert({
        msg: "A problem occurred. Please try again.",
        variant: "danger",
      });
      stopLoad(start);
      return false;
    }
    setEmail("");
    stopLoad(start);
    setAlert({
      variant: "success",
      msg:
        "Request sent. Please check your email for instructions on resetting your password.",
    });
    return true;
  };

  return (
    <div className="container text-center my-5">
      {alert.msg && <Alert variant={alert.variant}>{alert.msg}</Alert>}
      <h4>Forgot Password</h4>
      {loading ? (
        <div className="my-5">
          <ClipLoader loading color="#74c5ed" size="3em" />
        </div>
      ) : (
        <div>
          <div
            className="my-3"
            style={{ display: "inline-block", width: "20em" }}
          >
            <PlainInput
              value={email}
              handleChange={(v) => setEmail(v)}
              label="Email"
              width={-1}
            />
          </div>
          <div>
            <RoundedBtn
              text="Request Password Reset"
              variant="dark"
              width={20}
              onClick={requestReset}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
