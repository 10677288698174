import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import _ from "lodash";
import styles from "./styles/ParentAccordion.module.css";
import Accordion from "./Accordion";

const ParentAccordion = ({
  title,
  topics,
  togglerLevel,
  togglerMajor,
  togglerMinor,
  startOpen,
  disabled,
}) => {
  // State for whether content should be displayed
  const [isOpen, setIsOpen] = useState(startOpen);
  // State for whether title is checked
  const [isChecked, setIsChecked] = useState(false);
  const [tabbed, setTabbed] = useState(false);
  // State for whether child should have all checked
  // const [checkAll, setCheckAll] = useState(false);
  // // State for whether child should have all unchecked
  // const [uncheckAll, setUncheckAll] = useState(false);

  const display = (str) => {
    let theTitle = _.startCase(str);
    theTitle = theTitle.replace("And", "and");
    theTitle = theTitle.replace("By", "by");
    theTitle = theTitle.replace("Of", "of");
    theTitle = theTitle.replace("Newtons", "Newton's");
    theTitle = theTitle.replace("A 2 ", "");
    theTitle = theTitle.replace("3 D", "3D");
    theTitle = theTitle.replace("Counter Example", "Counterexample");
    theTitle = theTitle.replace(
      "Ratio Proportion and Rates of Change",
      "Ratio, Proportion and Rates of Change"
    );
    theTitle = theTitle.replace("A 2", "A2");
    return theTitle;
  };

  useEffect(() => {
    let isAll = true;
    const majors = Object.keys(topics[title]);
    majors.forEach((major) => {
      if (
        !Object.values(topics[title][major]).every((minor) => minor === true)
      ) {
        isAll = false;
      }
    });
    if (isAll) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [topics, title]);

  // Function to check the title
  const performCheck = (e) => {
    // Stop toggle of displayed content
    e.stopPropagation();
    togglerLevel(title, !isChecked);
  };

  const toggleMajor = (major, majorVal) => {
    togglerMajor(title, major, majorVal);
  };

  const toggleMinor = (major, minor) => {
    togglerMinor(title, major, minor);
  };

  const keyPressHandler = (e) => {
    if (e.key === "Tab") {
      setTabbed(true);
    }
    if (e.key === "Enter" || e.key === " ") {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className={`${styles.wrapper}`}>
      <div
        role="button"
        tabIndex={0}
        className={`${styles.title} ${isOpen && styles.open} ${
          tabbed ? "" : styles.noTab
        }`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onKeyDown={keyPressHandler}
      >
        <FontAwesomeIcon
          className={`fa-fw ${styles.check}`}
          icon={isChecked ? faCheckSquare : faSquare}
          onClick={disabled ? null : performCheck}
        />
        {display(title)}
        <div className={`${styles.icon} ${isOpen && styles.rotate}`}>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
      <div className={`${styles.item} ${!isOpen && styles.collapsed}`}>
        <div className={styles.content}>
          {Object.keys(topics[title]).map((key) => {
            return (
              <Accordion
                title={key}
                listItems={topics[title][key]}
                parent={title}
                topics={topics}
                togglerMajor={toggleMajor}
                togglerMinor={toggleMinor}
                indexable={isOpen}
                disabled={disabled}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

ParentAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  topics: PropTypes.objectOf(PropTypes.object).isRequired,
  togglerLevel: PropTypes.func.isRequired,
  togglerMajor: PropTypes.func.isRequired,
  togglerMinor: PropTypes.func.isRequired,
  startOpen: PropTypes.bool,
  disabled: PropTypes.bool,
};

ParentAccordion.defaultProps = {
  startOpen: false,
  disabled: false,
};

export default ParentAccordion;
