import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import _ from "lodash";
import styles from "./styles/Checklist.module.css";

const Checklist = ({ items, togglerMinor, indexable, disabled }) => {
  const [tabbed, setTabbed] = useState(false);
  // Function to perform ticking/unticking a box
  // set the checked items to what they were previosuly, only updating
  // the one which was clicked (set to negation of current value)
  const performCheck = (item) => {
    togglerMinor(item);
  };

  const keyPressHandler = (e, item) => {
    if (e.key === "Tab") {
      setTabbed(true);
    }
    if (e.key === "Enter" || e.key === " ") {
      performCheck(item);
    }
  };

  const display = (str) => {
    let theTitle = _.startCase(str);
    theTitle = theTitle.replace("And", "and");
    theTitle = theTitle.replace("By", "by");
    theTitle = theTitle.replace("Of", "of");
    theTitle = theTitle.replace("Newtons", "Newton's");
    theTitle = theTitle.replace("A 2 ", "");
    theTitle = theTitle.replace("3 D", "3D");
    theTitle = theTitle.replace("Counter Example", "Counterexample");
    theTitle = theTitle.replace("Factors Multiples", "Factors, Multiples");
    theTitle = theTitle.replace("With", "with");
    theTitle = theTitle.replace("The", "the");
    theTitle = theTitle.replace("theorem", "Theorem");
    theTitle = theTitle.replace("From", "from");
    return theTitle;
  };

  return (
    <>
      {
        // for each item in input list, create HTML
        Object.keys(items).map((item) => {
          return (
            <div>
              <span
                role="button"
                tabIndex={indexable ? 0 : -1}
                onClick={disabled ? null : () => performCheck(item)}
                onKeyPress={(e) => keyPressHandler(e, item)}
                className={`${styles.checkableItem} ${
                  tabbed ? "" : styles.noTab
                }`}
              >
                <FontAwesomeIcon
                  className={`fa-fw ${styles.checkbox}`}
                  // tick the box if item in object has true value
                  icon={items[item] ? faCheckSquare : faSquare}
                />
                {display(item)}
              </span>
            </div>
          );
        })
      }
    </>
  );
};

Checklist.propTypes = {
  items: PropTypes.objectOf(PropTypes.bool).isRequired,
  togglerMinor: PropTypes.func.isRequired,
  indexable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

Checklist.defaultProps = {
  disabled: false,
};

export default Checklist;
