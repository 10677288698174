import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import checkToken from "../system/auth/checkToken";

const AuthVerifier = ({ setAuth }) => {
  const history = useHistory();
  history.listen(() => {
    // <--- Here you subscribe to the route change
    if (!checkToken()) {
      localStorage.clear();
      setAuth(false);
    }
  });
  return <div />;
};

AuthVerifier.propTypes = {
  setAuth: PropTypes.func.isRequired,
};

export default AuthVerifier;
