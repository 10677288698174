import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ClipLoader from "react-spinners/ClipLoader";
import UserTable from "../UserTable";
import PlainInput from "../PlainInput";
import RoundedBtn from "../RoundedBtn";
import SchoolModal from "../SchoolModal";
import SchoolTable from "../SchoolTable";
import AdminAddUserModal from "../AdminAddUserModal";
import NumberStat from "../NumberStat";
import RegisterModal from "../RegisterModal";

const Admin = ({ data }) => {
  const [ANON, SET_ANON] = useState(false);
  // INFO ALREADY IN FUNCTION TAKEN FROM /ACCOUNT
  // const history = useHistory();
  // const removeToken = () => {
  // localStorage.removeItem("mcmToken");
  // handleAuthentication();
  // return true;
  // };
  // const { userName } = jwt.decode(localStorage.getItem("mcmToken"));
  // ///////////////////////////////////////////////

  // FILTERING userData ON EMAIL OR NAME BASED ON SEARCH TERM
  const [filterInput, setFilterInput] = useState("");
  const [filteredData, setfilteredData] = useState(data.users);
  const [schoolInput, setSchoolInput] = useState("");
  const [filteredSchools, setFilteredSchools] = useState(data.schools);
  const [addingSchool, setAddingSchool] = useState(false);
  const [batchModal, setBatchModal] = useState(false);
  const [singleModal, setSingleModal] = useState(false);
  const [summary, setSummary] = useState({
    pupil: 0,
    staff: 0,
    staffAdmin: 0,
    school: 0,
  });

  const handleFilterChange = (val) => {
    setFilterInput(val);
  };

  const handleSchoolFilter = (val) => {
    setSchoolInput(val);
  };

  useEffect(() => {
    if (
      typeof data.users !== "undefined" &&
      typeof data.schools !== "undefined"
    ) {
      let pupils = 0;
      let staff = 0;
      let staffAdmin = 0;
      for (let i = 0; i < data.users.length; i += 1) {
        const user = data.users[i];
        if (user.roleName === "PUPIL") {
          pupils += 1;
        }
        if (user.roleName === "STAFF") {
          staff += 1;
        }
        if (user.roleName === "STAFF_ADMIN") {
          staffAdmin += 1;
        }
      }
      setSummary({
        pupil: pupils,
        staff,
        staffAdmin,
        school: data.schools.length,
      });
    }
  }, [data]);

  useEffect(() => {
    if (typeof data.users !== "undefined") {
      const results = data.users.filter((user) => {
        const name = `${user.fname} ${user.lname}`;
        return (
          name.toLowerCase().includes(filterInput.toLowerCase()) ||
          user.email.toLowerCase().includes(filterInput.toLowerCase()) ||
          (user.schoolEmail !== null &&
            user.schoolEmail.toLowerCase().includes(filterInput.toLowerCase()))
        );
      });
      setfilteredData(results);
    }
  }, [data, filterInput]);

  useEffect(() => {
    if (typeof data.schools !== "undefined") {
      const results = data.schools.filter((school) => {
        return (
          school.schoolName.toLowerCase().includes(schoolInput.toLowerCase()) ||
          school.email.toLowerCase().includes(schoolInput.toLowerCase())
        );
      });
      setFilteredSchools(results);
    }
  }, [data, schoolInput]);
  // ///////////////////////////////////////////////////////

  return (
    <div className="container">
      <button
        type="button"
        className="btn btn-block mcm-btn mt-3"
        onClick={() => SET_ANON((anon) => !anon)}
      >
        Toggle Redacted Mode
      </button>
      {addingSchool && (
        <SchoolModal
          show={addingSchool}
          onHide={() => setAddingSchool(false)}
          users={data.users}
        />
      )}
      <AdminAddUserModal
        show={batchModal}
        onHide={() => setBatchModal(false)}
        schools={data.schools}
      />
      <RegisterModal show={singleModal} onHide={() => setSingleModal(false)} />
      {typeof data.users === "undefined" ? (
        <div className="my-5 text-center">
          <ClipLoader loading color="#74c5ed" size="3em" />
        </div>
      ) : (
        <div>
          <div className="my-3">
            <div className="card">
              <h5 className="card-header text-center">Summary</h5>
              <div className="card-body text-center">
                <div className="row">
                  <div className="col-md-3">
                    <NumberStat label="pupils" number={summary.pupil} />
                  </div>
                  <div className="col-md-3">
                    <NumberStat label="staff members" number={summary.staff} />
                  </div>
                  <div className="col-md-3">
                    <NumberStat
                      label="staff admins"
                      number={summary.staffAdmin}
                    />
                  </div>
                  <div className="col-md-3">
                    <NumberStat label="schools" number={summary.school} />
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-3">
                    <NumberStat
                      label="questions answered"
                      number={data.analytics.totals[0].total}
                    />
                  </div>
                  <div className="col-md-3">
                    <NumberStat
                      label={
                        ANON
                          ? "questions answered (exc. ************)"
                          : "questions answered (exc. Ridgewood School)"
                      }
                      number={
                        data.analytics.totals[0].total -
                        data.analytics.totals[1].total
                      }
                    />
                  </div>
                  <div className="col-md-3 my-4">
                    <h5>most questions answered</h5>
                    <h5>
                      {`by ${
                        ANON
                          ? "************"
                          : data.analytics.mostActive.schoolName
                      }`}
                    </h5>
                  </div>
                  <div className="col-md-3">
                    <NumberStat
                      label={`questions answered by ${
                        ANON
                          ? "************"
                          : data.analytics.mostActive.schoolName
                      }`}
                      number={data.analytics.mostActive.total}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col-md-6">
                <RoundedBtn
                  text="Add Single User"
                  width={-1}
                  variant="light"
                  onClick={() => setSingleModal(true)}
                />
              </div>
              <div className="col-md-6">
                <RoundedBtn
                  text="Add Multiple Users"
                  width={-1}
                  variant="light"
                  onClick={() => setBatchModal(true)}
                />
              </div>
            </div>
          </div>

          <div className="my-3">
            <div className="card">
              <h5 className="card-header text-center">Users</h5>
              <div className="card-body">
                <div style={{ marginBottom: "0.5em" }}>
                  <PlainInput
                    value={filterInput}
                    handleChange={handleFilterChange}
                    width={-1}
                    placeholder="Start typing a name, email or school email..."
                  />
                </div>
                <UserTable
                  data={filteredData}
                  schools={data.schools}
                  anon={ANON}
                />
              </div>
            </div>
          </div>

          <div className="my-3">
            <div className="card">
              <h5 className="card-header text-center">Schools</h5>
              <div className="card-body">
                <div className="text-center my-2">
                  <RoundedBtn
                    text="Add School"
                    width={12}
                    onClick={() => setAddingSchool(true)}
                  />
                </div>

                <div style={{ marginBottom: "0.5em" }}>
                  <PlainInput
                    value={schoolInput}
                    handleChange={handleSchoolFilter}
                    width={-1}
                    placeholder="Start typing a school name or email address..."
                  />
                </div>
                <SchoolTable
                  data={filteredSchools}
                  users={data.users}
                  anon={ANON}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Admin.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Admin;
