import { ArgumentError, IndexError } from "../MCError";
import { Graph, Image } from ".";
/**
 * A class that supports the development of subparts of skills questions.
 * @memberof module:MCQuestion
 * @author Declan Clark <dec@dclark.dev>
 * @since 0.1.0
 * @example
 * const partA = new MCQuestion.SkillsPart();
 */
class SkillsPart {
  /**
   * Creates a new part for a skills question.
   */
  constructor() {
    this.questionSequence = [];
    this.solutionSequence = [];
    this.inputs = [];
  }

  getQuestion() {
    return this.questionSequence;
  }

  getSolution() {
    return this.solutionSequence;
  }

  getAnswer() {
    return this.inputs;
  }

  /**
   * @summary Adds a paragraph to the part.
   *
   * @description Creates a paragraph object and adds it to the current question/solution sequence.
   *              Font size can be changed with the optional fontSize parameter.
   *
   * @since 0.1.0
   *
   * @param   {('question' | 'solution')} section      Whether the paragraph is added to the question or solution.
   * @param   {string}                    paragraph    The string for the paragraph being added.
   * @param   {number}                    [fontSize]   The size of the font when displaying the paragraph.
   * @throws  {ArgumentError}                          Paragraph string and section are required.
   * @throws  {TypeError}                              Parameters were the wrong type.
   *
   * @example
   * const partA = new MCQuestion.SkillsPart();
   * partA.addParagraph('question', 'Some short question goes in here...', 2);
   */
  addParagraph(section, paragraph, fontSize) {
    if (typeof section === "undefined") {
      throw new ArgumentError(`section is required`);
    }
    if (typeof section !== "string") {
      throw new TypeError(
        `expected a string but got ${typeof section} instead`
      );
    }
    if (section !== "question" && section !== "solution") {
      throw new RangeError(
        `expected 'question' or 'solution' but got ${section} instead`
      );
    }
    if (typeof paragraph === "undefined") {
      throw new ArgumentError(`paragraph is required`);
    }
    if (typeof paragraph !== "string") {
      throw new TypeError(
        `expected a string but got ${typeof paragraph} instead`
      );
    }
    if (typeof fontSize !== "undefined") {
      if (typeof fontSize !== "number") {
        throw new TypeError(
          `expected a number but got ${typeof fontSize} instead`
        );
      }
      if (paragraph.includes("HEADING")) {
        const par = paragraph.replace("HEADING", "").trim();
        if (section === "question") {
          this.questionSequence.push({
            type: "paragraph",
            content: { p: par, size: fontSize, heading: true },
          });
        } else {
          this.solutionSequence.push({
            type: "paragraph",
            content: { p: par, size: fontSize, heading: true },
          });
        }
      } else if (section === "question") {
        this.questionSequence.push({
          type: "paragraph",
          content: { p: paragraph, size: fontSize },
        });
      } else {
        this.solutionSequence.push({
          type: "paragraph",
          content: { p: paragraph, size: fontSize },
        });
      }
    } else if (paragraph.includes("HEADING")) {
      const par = paragraph.replace("HEADING", "").trim();
      if (section === "question") {
        this.questionSequence.push({
          type: "paragraph",
          content: { p: par, heading: true },
        });
      } else {
        this.solutionSequence.push({
          type: "paragraph",
          content: { p: par, heading: true },
        });
      }
    } else if (section === "question") {
      this.questionSequence.push({
        type: "paragraph",
        content: { p: paragraph },
      });
    } else {
      this.solutionSequence.push({
        type: "paragraph",
        content: { p: paragraph },
      });
    }
  }

  /**
   * @summary Adds multiple paragraphs to the specified section.
   *
   * @description Takes an array of strings and adds each as a new paragraph. All paragraphs
   *              will have the given font size.
   *
   * @since 0.1.0
   *
   * @param {('question' | 'solution')}   section     The section to add the paragraphs to.
   * @param {string[]}                    paragraphs  The paragraphs to be added, packed in an array.
   * @param {number}                      [fontSize]  The font size (in em) to be applied to paragraphs.
   * @throws  {ArgumentError}                          Paragraph strings and section are required.
   * @throws  {TypeError}                              Parameters were the wrong type.
   *
   * @example
   * const partA = new MCQuestion.SkillsPart();
   * const someArrayOfText = ['hello', 'world'];
   * partA.addMultipleParagraphs('solution', someArrayOfText, 2);
   */
  addMultipleParagraphs(section, paragraphs, fontSize) {
    if (paragraphs.length === 0) {
      throw new IndexError("expected non-empty array of strings");
    }
    for (let i = 0; i < paragraphs.length; i += 1) {
      this.addParagraph(section, paragraphs[i], fontSize);
    }
  }

  /**
   * @summary Adds a heading to the specified sequence.
   *
   * @description Adds a paragraph to the sequence which is then styled as a heading.
   *
   * @since 0.1.0
   *
   * @param {('question'|'solution')} section     The section to add the heading to.
   * @param {string}                  heading     The content of the heading.
   * @param {number}                  [fontSize]  Font size of the heading (in em).
   * @throws  {ArgumentError}                          Heading string and section are required.
   * @throws  {TypeError}                              Parameters were the wrong type.
   *
   * @example
   * const partA = new MCQuestion.SkillsPart();
   * partA.addHeading('question', 'Some heading...', 2);
   */
  addHeading(section, heading, fontSize) {
    const p = `HEADING ${heading}`;
    this.addParagraph(section, p, fontSize);
  }

  /**
   * @summary Adds an image to the question/solution sequence.
   *
   * @description Takes an image object and adds it and its overlays to the section provided.
   *
   * @since 0.1.0
   *
   * @param   {('question' | 'solution')} section The section to add the image to.
   * @param   {Image}                     image   The image to add.
   * @throws  {ArgumentError}                     Required arguments were not supplied.
   * @throws  {TypeError}                         Arguments were not of the correct type.
   * @throws  {RangeError}                        Argument section was not an accepted value.
   * @example
   * // adding the image to the solution sequence
   * partA.addImage('solution', myImage);
   */
  addImage(section, image) {
    if (typeof section === "undefined" || typeof image === "undefined") {
      throw new ArgumentError(`must supply required arguments`);
    }
    if (typeof section !== "string") {
      throw new TypeError(
        `expected a string but got ${typeof section} instead`
      );
    }
    if (!(image instanceof Image)) {
      throw new TypeError(
        `expected an Image object but got ${typeof image} instead`
      );
    }
    if (section !== "question" && section !== "solution") {
      throw new RangeError(
        `expected 'question' or 'solution' but got ${section} instead`
      );
    }
    if (section === "question") {
      this.questionSequence.push({
        type: "image",
        content: {
          src: image.getSrc(),
          measure: image.getMeasure(),
          size: image.getSize(),
          overlays: image.getOverlays(),
        },
      });
    } else {
      this.solutionSequence.push({
        type: "image",
        content: {
          src: image.getSrc(),
          measure: image.getMeasure(),
          size: image.getSize(),
          overlays: image.getOverlays(),
        },
      });
    }
  }

  /**
   * @summary Adds a graph to the skills part.
   *
   * @description Takes a graph object and adds it to the part.
   *
   * @since 0.1.0
   *
   * @param   {Graph}                     graph   The graph to add.
   * @throws  {ArgumentError}                     Required arguments were not supplied.
   * @throws  {TypeError}                         Arguments were not of the correct type.
   * @example
   * // adding the graph
   * part1.addGraph(myGraph);
   */
  addGraph(graph) {
    if (typeof graph === "undefined") {
      throw new ArgumentError(`must supply required argument`);
    }
    if (!(graph instanceof Graph)) {
      throw new TypeError(
        `expected a Graph object but got ${typeof graph} instead`
      );
    }
    this.mainSequence.push({
      type: "graph",
      content: {
        axis: graph.getAxisValues(),
        funcs: graph.getFunctions(),
        steps: graph.getSteps(),
      },
    });
  }

  /**
   * @summary Adds an input for this part.
   *
   * @description Adds an input box to the question where inputting expectedAnswer will
   *              mark the question as correct and wrong otherwise. The input label adds
   *              a label before or after the input box depending on the position specified.
   *              This allows for boxes that start with 'A =' for example or that end in units
   *              like '...cm'. Errors are thrown when expectedAnswer is not passed as
   *              an argument, is not a string or appears to contain LaTeX. Errors are also
   *              thrown when inputLabel and labelPosition are of the wrong type or not accepted values.
   *
   * @since 0.1.0
   *
   * @param   {number | number[]}                expectedAnswer              An answer for the input box, array if multiple answers required. If tolerance is non-zero then bounds must not overlap.
   * @param   {number}                tolerance                   Answer plus/minus this will be accepted.
   * @param   {string}                [inputLabel]                The label on the input box.
   * @param   {('before' | 'after')}  [labelPosition='before']    The position of the label.
   * @throws  {ArgumentError}                                     Required arguments not supplied, contains LaTeX.
   * @throws  {TypeError}                                         Arguments are not correct type.
   * @throws  {RangeError}                                        Argument is not an accepted value.
   * @example
   * // adding an answer box which will display like so: [      ] cm
   * partA.addAnswer(0.5, 0, 'cm', 'after');
   */
  addAnswer(expectedAnswer, tolerance, inputLabel, labelPosition) {
    if (
      typeof expectedAnswer === "undefined" ||
      typeof tolerance === "undefined"
    ) {
      throw new ArgumentError(`argument is required`);
    }
    if (typeof expectedAnswer !== "number" && !Array.isArray(expectedAnswer)) {
      throw new TypeError(
        `expected a number or array but got ${typeof expectedAnswer} instead`
      );
    }
    if (Array.isArray(expectedAnswer)) {
      expectedAnswer.forEach((ans) => {
        if (typeof ans !== "number") {
          throw new TypeError(
            `expected array of numbers but found element of type ${typeof ans}`
          );
        }
      });
    }
    if (typeof tolerance !== "number") {
      throw new TypeError(
        `expected a string but got ${typeof tolerance} instead`
      );
    }
    if (
      typeof inputLabel === "undefined" &&
      typeof labelPosition === "undefined"
    ) {
      if (Array.isArray(expectedAnswer)) {
        expectedAnswer.forEach(() => {
          this.inputs.push({
            type: "input",
            content: {
              answer: expectedAnswer,
              tolerance,
            },
          });
        });
      } else {
        this.inputs.push({
          type: "input",
          content: {
            answer: expectedAnswer,
            tolerance,
          },
        });
      }
    } else if (
      typeof inputLabel !== "undefined" &&
      typeof labelPosition === "undefined"
    ) {
      if (typeof inputLabel !== "string") {
        throw new TypeError(
          `expected a string but got ${typeof inputLabel} instead`
        );
      }
      if (Array.isArray(expectedAnswer)) {
        expectedAnswer.forEach(() => {
          this.inputs.push({
            type: "input",
            content: {
              answer: expectedAnswer,
              tolerance,
              label: inputLabel,
              labelPos: "before",
            },
          });
        });
      } else {
        this.inputs.push({
          type: "input",
          content: {
            answer: expectedAnswer,
            tolerance,
            label: inputLabel,
            labelPos: "before",
          },
        });
      }
    } else if (labelPosition !== "before" && labelPosition !== "after") {
      throw new RangeError(
        `expected 'before' or 'after' but got ${labelPosition} instead`
      );
    } else if (Array.isArray(expectedAnswer)) {
      expectedAnswer.forEach(() => {
        this.inputs.push({
          type: "input",
          content: {
            answer: expectedAnswer,
            tolerance,
            label: inputLabel,
            labelPos: labelPosition,
          },
        });
      });
    } else {
      this.inputs.push({
        type: "input",
        content: {
          answer: expectedAnswer,
          tolerance,
          label: inputLabel,
          labelPos: labelPosition,
        },
      });
    }
  }
}

export { SkillsPart };
