import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import styles from "./styles/UserTable.module.css";
import SchoolModal from "./SchoolModal";

const SchoolTable = ({ data, users, anon }) => {
  // PAGINATION

  const [schoolSlices, setSchoolSlices] = useState([[]]);
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    if (typeof data !== "undefined" && data.length !== 0) {
      const perPage = 20;
      const slices = [];
      const schoolCopy = [...data];
      while (schoolCopy.length) slices.push(schoolCopy.splice(0, perPage));
      setSchoolSlices(slices);
    }
  }, [data]);

  const handleChangePage = (schoolData) => {
    setPageNumber(schoolData.selected);
  };
  // ///////////////////////////////////////////////////////

  // OPEN MODAL
  const [showModal, setShowModal] = useState(false);
  const [school, setSchool] = useState({});

  // //////////////////////////////
  return (
    <div>
      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody className={styles.tableBody}>
            {schoolSlices[pageNumber].map((schoolData) => {
              const created = new Date(schoolData.createdAt.replace(/-/g, "/"));
              return (
                <tr
                  className={styles.tableRow}
                  onClick={() => {
                    setSchool(schoolData);
                    setShowModal(true);
                  }}
                  key={schoolData.schoolId}
                >
                  <td>{schoolData.schoolId}</td>
                  <td>{anon ? "**********" : schoolData.schoolName}</td>
                  <td>{anon ? "**********@*******" : schoolData.email}</td>
                  <td>{anon ? "***********" : schoolData.phone}</td>
                  <td>{`${created.toLocaleDateString()} - ${created.toLocaleTimeString()}`}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {schoolSlices.length > 1 && (
        <ReactPaginate
          previousLabel="&laquo;"
          nextLabel="&raquo;"
          breakLabel="..."
          breakClassName="page-item"
          pageCount={schoolSlices.length}
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          containerClassName="pagination"
          subContainerClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          nextClassName="page-item"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          activeClassName="page-item active"
          disabledClassName="page-item disabled"
          onPageChange={handleChangePage}
        />
      )}
      {showModal && (
        <SchoolModal
          show={showModal}
          onHide={() => setShowModal(false)}
          schoolData={school}
          users={users}
          data={data}
          anon={anon}
        />
      )}
    </div>
  );
};

SchoolTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  users: PropTypes.arrayOf(PropTypes.object),
  anon: PropTypes.bool,
};

SchoolTable.defaultProps = {
  data: [],
  users: [],
  anon: false,
};

export default SchoolTable;
