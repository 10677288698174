import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./styles/Dropdown.module.css";

const Dropdown = ({
  icon,
  title,
  options,
  handleChange,
  width,
  centered,
  disabled,
}) => {
  const [isTabbing, setTabbing] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const toggleDropdown = () => {
    setOpen(!isOpen);
  };

  const handleItemClick = (value) => {
    toggleDropdown();
    handleChange(value);
  };

  const handleKeyPressToggle = (e) => {
    if (e.key === "Tab") {
      setTabbing(true);
    }
    if (e.key === "Enter" || e.key === " ") {
      toggleDropdown();
    }
  };

  const handleKeyPressItems = (e, val) => {
    if (e.key === "Tab") {
      setTabbing(true);
    }
    if (e.key === "Enter" || e.key === " ") {
      handleItemClick(val);
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div
      className={`${styles.wrapper} `}
      ref={wrapperRef}
      style={{
        width: `${width === -1 ? "100%" : `${width}em`}`,
        marginLeft: `${centered && "auto"}`,
        marginRight: `${centered && "auto"}`,
      }}
    >
      <div
        className={`${styles.title} ${!isTabbing ? styles.noTabs : ""} ${
          disabled && styles.disabled
        }`}
        onClick={() => {
          if (!disabled) {
            toggleDropdown();
          }
        }}
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyPressToggle}
      >
        {icon && (
          <FontAwesomeIcon
            style={{ marginRight: "0.4em", fontSize: "0.9em" }}
            icon={icon}
          />
        )}
        {title}
      </div>
      {isOpen && (
        <div className={`${styles.ddItems}`}>
          {options.map((item) => (
            <div
              className={`${styles.ddItem} ${!isTabbing ? styles.noTabs : ""}`}
              key={item.id}
              onClick={() => handleItemClick(item.value)}
              role="button"
              onKeyDown={(e) => handleKeyPressItems(e, item.value)}
              tabIndex={0}
            >
              {item.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  icon: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func.isRequired,
  width: PropTypes.number,
  centered: PropTypes.bool,
  disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  icon: undefined,
  width: 12.5,
  centered: false,
  disabled: false,
};

export default Dropdown;
