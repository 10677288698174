/**
 * Defines an error for when a number parameter is of the wrong sub-type.
 * @memberof module:MCError
 * @author Declan Clark <dec@dclark.dev>
 * @example
 * throw new MCError.NumberSetError('expected a number belonging to the set of integers');
 */
class NumberSetError extends Error {
  /**
   * Initialises a NumberSetError.
   * @param {string} message  The error message.
   */
  constructor(message) {
    super(message);
    this.name = "NumberSetError";
  }
}

export { NumberSetError };
