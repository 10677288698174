import { Polynomial } from "./Polynomial";
import { NumberSetError } from "../MCError";
/**
 * Class Handling Square roots of polynomials Polynomials.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @author Darren Carter
 * @example
 * // Creates the square root of $3x^2+2x+1$
 * p1 = new Sqrt([3,2,1])
 * // Creates the squre root of $4y^4+3$
 * p2 = new Sqrt([4,0,0,0,3],'y')
 */

class Sqrt {
  /**
   * Creates a Square Root of a Polynomial.
   * @param {number[]} coefficients The coefficients of the polynomial in decreasing power.
   * @param {string} [variable='x'] The varible of the polynomial.
   */
  constructor(coefficients, variable = "x") {
    if (!Array.isArray(coefficients)) {
      throw new NumberSetError(
        `Expected an array, got ${coefficients} instead`
      );
    }
    if (typeof variable !== "string") {
      throw new NumberSetError(`Expected a string, got ${variable} instead`);
    }
    this.coefficients = coefficients;
    while (this.coefficients[0] === 0) {
      this.coefficients.shift();
    }
    this.variable = variable;
  }

  /**
   * Outputs the polynomial as a string.
   *
   * @returns {string} The polynomial wrote as a string.
   *
   * @example
   * // Writes $\\sqrt{x^2+3x+1}$ as a string
   * let p1 = new Sqrt([1,3,1])
   * console.log(p1.toString())
   * // Writes $\\sqrt{(2)^2+3(2)+1}$ as a string
   * let p2 = new Sqrt([1,3,1])
   * console.log(p2.toString('('+2+')'))
   */

  toString() {
    return `\\sqrt{${new Polynomial(
      this.coefficients,
      this.variable
    ).toString()}}`;
  }

  /**
   * Evaluates the sqrt.
   *
   * @param {number} coordinate Coordinate to evaluate at.
   * @returns {number} The value of the instance evaluated at the input value.
   * @throws {NumberSetError} The coordinate should be a number.
   *
   * @example
   * // Evaluate $x^2-2x+1$ at $x=2$
   * let p1 = new Sqrt([1,-2,1])
   * console.log(p1.evaluate(2))
   */
  evaluate(coordinate) {
    return Math.sqrt(
      new Polynomial(this.coefficients, this.variable).evaluate(coordinate)
    );
  }
}

export { Sqrt };
