/**
 * Class Handling Binomial Distributions.
 * @memberof module:MCMaths
 * @author James Pickersgill
 */

class BinomialDistribution {
  constructor(n, p) {
    this.n = n;
    this.p = p;
  }

  pdf(x) {
    return (
      (this.factorial(this.n) * this.p ** x * (1 - this.p) ** (this.n - x)) /
      (this.factorial(x) * this.factorial(this.n - x))
    );
  }

  cdf(x) {
    let out = 0;
    for (let i = 0; i <= x; i += 1) {
      out += this.pdf(i);
    }
    return out;
  }

  factorial(n1) {
    let out = 1 + 0 * this.n;
    for (let i = 2; i <= n1; i += 1) {
      out *= i;
    }
    return out;
  }
}

export { BinomialDistribution };
