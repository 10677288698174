import { cleaner } from "./cleaner";
import { Graph } from "../MCQuestion";
/**
 * Modulus.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Input a functions, will return the modulus of it. Option argument for $f(|x|)$ instead of $|f(x)|$.
 * const trig = new MCMaths.Trig(2, "sin");
 * const mod  = new MCMaths.Modulus(trig);
 */

class Modulus {
  /**
   * Creates a new parametric in the form x=func1, y=funct. Use either x or t for the variables for these functions.
   *
   * @param {function} func The function for x.
   * @param {bool} [modX = false] For $f(|x|)$ instead of $|f(x)|$
   *
   * @returns {object} Returns modulus object
   */
  constructor(func, modX = false) {
    this.func = func;
    this.modX = modX;
  }

  /**
   * Prints the product of functions as a string.
   * @returns {string}
   */
  toString() {
    if (this.modX === false) {
      return cleaner(`\\left| ${this.func.toString()} \\right|`);
    }
    return cleaner(` ${this.func.toString().replace(/x/g, "|x|")}`);
  }

  evaluate(x) {
    if (this.modX === false) {
      return Math.abs(this.func.evaluate(x));
    }
    return this.func.evaluate(Math.abs(x));
  }

  /**
   * Returns graph
   *
   * @param {Number} [Range = 0] The range of the axis to plon on.
   *
   * @returns {Graph}
   */
  graph(range = 5) {
    const stepSize = Math.ceil(range / 10);
    const graph = new Graph(range, -range, range, -range, stepSize, stepSize);

    let f;
    const j = this.func;
    if (this.modX === false) {
      f = function f1(x) {
        return Math.abs(j.evaluate(x));
      };
    } else {
      f = function f1(x) {
        return j.evaluate(Math.abs(x));
      };
    }

    graph.plot(f, -range, range);
    return graph;
  }
}

export { Modulus };
