import React from "react";
import { Link } from "react-router-dom";
import useWindowDimensions from "../system/hooks/useWindowDimensions";

const Footer = () => {
  const { width } = useWindowDimensions();
  const year = new Date().getFullYear();
  const copyText = `\u00A9 MrCarterMaths LTD ${year}`;
  // return (
  //   <div
  //     className="text-center"
  //     style={{
  //       position: "absolute",
  //       left: 0,
  //       bottom: 0,
  //       right: 0,
  //       height: width > 750 ? `50px` : `150px`,
  //       lineHeight: `50px`,
  //       width: "100%",
  //       backgroundColor: "#EBEBEB",
  //     }}
  //   >
  //     {width > 750 && (
  //       <div>
  //         <span className="mx-5">
  //           <Link to="/terms" style={{ color: "grey" }}>
  //             Terms &amp; Conditions
  //           </Link>
  //         </span>
  //         <span className="mx-5" style={{ color: "grey" }}>
  //           {copyText}
  //         </span>
  //         <span className="mx-5">
  //           <Link to="/privacy-policy" style={{ color: "grey" }}>
  //             Privacy Policy
  //           </Link>
  //         </span>
  //       </div>
  //     )}
  // {width < 750 && (
  //   <div>
  //     <div>
  //       <span className="mx-5" style={{ color: "grey" }}>
  //         {copyText}
  //       </span>
  //     </div>
  //     <div>
  //       <span className="mx-5">
  //         <Link to="/terms" style={{ color: "grey" }}>
  //           Terms &amp; Conditions
  //         </Link>
  //       </span>
  //     </div>
  //     <div>
  //       <span className="mx-5">
  //         <Link to="/privacy-policy" style={{ color: "grey" }}>
  //           Privacy Policy
  //         </Link>
  //       </span>
  //     </div>
  //   </div>
  // )}
  //   </div>
  // );
  if (width >= 750) {
    return (
      <div
        style={{
          position: "absolute",
          left: 0,
          bottom: 0,
          right: 0,
          height: width > 750 ? `50px` : `150px`,
          lineHeight: `50px`,
          width: "100%",
          backgroundColor: "#EBEBEB",
        }}
      >
        <div
          className="container"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span style={{ color: "grey" }}>{copyText}</span>
          <div>
            <Link to="/terms" style={{ color: "grey" }}>
              Terms &amp; Conditions
            </Link>
            <Link
              to="/privacy-policy"
              style={{ color: "grey", marginLeft: "1em" }}
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className="text-center"
      style={{
        position: "absolute",
        left: 0,
        bottom: 0,
        right: 0,
        height: width > 750 ? `50px` : `120px`,
        lineHeight: `30px`,
        width: "100%",
        backgroundColor: "#EBEBEB",
      }}
    >
      <div>
        <div>
          <span className="mx-5" style={{ color: "grey" }}>
            {copyText}
          </span>
        </div>
        <div>
          <span className="mx-5">
            <Link to="/terms" style={{ color: "grey" }}>
              Terms &amp; Conditions
            </Link>
          </span>
        </div>
        <div>
          <span className="mx-5">
            <Link to="/privacy-policy" style={{ color: "grey" }}>
              Privacy Policy
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
