import React, { useContext } from "react";
import {
  faBrain,
  faLightbulb,
  faGraduationCap,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import ClipLoader from "react-spinners/ClipLoader";
import * as jwt from "jsonwebtoken";
import Alert from "../Alert";
import RoundedBtn from "../RoundedBtn";
// eslint-disable-next-line import/no-cycle
import { AppContext } from "../../App";

const StudentMenu = ({ info, role, setView }) => {
  const accInfo = jwt.decode(localStorage.getItem("mcmToken"));
  let oneWeek = new Date();
  oneWeek.setDate(oneWeek.getDate() + 7);
  if (typeof accInfo.accountCreated !== "undefined") {
    oneWeek = new Date(
      jwt
        .decode(localStorage.getItem("mcmToken"))
        .accountCreated.replace(/-/g, "/")
    );
    oneWeek.setDate(oneWeek.getDate() + 7);
  }
  const history = useHistory();
  const { pupilData } = useContext(AppContext);
  const yourChoice = () => {
    history.push("/your-choice", {
      content: pupilData.content,
      answers: pupilData.answers,
    });
  };

  const workSmart = () => {
    history.push("/work-smart", {
      content: pupilData.content,
      answers: pupilData.answers,
    });
  };

  const review = () => {
    history.push("/review", { answers: pupilData.answers });
  };

  return (
    <div className="container">
      <div className="my-3">
        {(role === "STAFF" || role === "STAFF_ADMIN") && (
          <div className="my-3">
            <RoundedBtn
              text="Back to Teacher Hub"
              width={12}
              onClick={() => setView(1)}
            />
          </div>
        )}
        {oneWeek > new Date() && (
          <div className="alert alert-primary" role="alert">
            To make the most out of our site make sure you keep your content
            covered list up to date.
          </div>
        )}
        {info !== "" && <Alert variant="warning">{info}</Alert>}
        {pupilData.content === null ? (
          <div className="my-5 text-center">
            <ClipLoader loading color="#74c5ed" size="3em" />
          </div>
        ) : (
          <div className="row">
            <div className="col-md-6 my-3">
              <RoundedBtn
                text="Work Smart"
                width={-1}
                fontSize={1.5}
                icon={{ icon: faBrain, color: "#74c5ed" }}
                className="py-3"
                subtext="Targeted questions on your strengths and weaknesses"
                onClick={workSmart}
                disabled={pupilData.content.length === 0}
              />
            </div>
            <div className="col-md-6 my-3">
              <RoundedBtn
                text="Your Choice"
                width={-1}
                fontSize={1.5}
                icon={{ icon: faLightbulb, color: "#74c5ed" }}
                className="py-3"
                subtext="You can choose a single topic to focus your study"
                onClick={yourChoice}
                disabled={pupilData.content.length === 0}
              />
            </div>
            <div className="col-md-6 my-3">
              <RoundedBtn
                text="Review Learning"
                width={-1}
                fontSize={1.5}
                icon={{ icon: faGraduationCap, color: "#74c5ed" }}
                className="py-3"
                subtext="Revisit the work you have done so far here"
                onClick={review}
                disabled={pupilData.content.length === 0}
              />
            </div>
            <div className="col-md-6 my-3">
              <RoundedBtn
                text="Content Covered"
                width={-1}
                fontSize={1.5}
                icon={{ icon: faCheckCircle, color: "#74c5ed" }}
                className="py-3"
                subtext="Let us know which topics you are ready to practise"
                onClick={() => history.push("/content-covered")}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

StudentMenu.propTypes = {
  info: PropTypes.string,
  role: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
};

StudentMenu.defaultProps = {
  info: "",
};

export default StudentMenu;
