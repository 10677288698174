import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import Register from "./pages/Register";
import { AppContext } from "../App";

const RegisterModal = ({ show, onHide }) => {
  const { adminData, setAdminData } = useContext(AppContext);
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Register
          handleAuthentication={null}
          adminFunction={(user) => {
            setAdminData({ ...adminData, users: [user, ...adminData.users] });
            onHide();
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

RegisterModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default RegisterModal;
