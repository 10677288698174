/**
 * Defines an error for when a something is fundamentally wrong within the question.
 * @memberof module:MCError
 * @author Declan Clark <dec@dclark.dev>
 * @example
 * throw new MCError.QuestionError('contained an integer of greater than 8 digits);
 */
class QuestionError extends Error {
  /**
   * Initialises a QuestionError.
   * @param {string} message  The error message.
   * @param {string} submessage An extra message that contains extra information.
   */
  constructor(message) {
    super(message);
    this.name = "QuestionError";
  }
}

export { QuestionError };
