import React from "react";

const Privacy = () => {
  return (
    <div className="container my-4">
      <h4>Privacy Policy</h4>
      <p>
        This privacy policy will explain how our MrCarterMaths Ltd. uses the
        personal data we collect from you when you use our website.
      </p>
      <p>Topics:</p>
      <ol>
        <li>What data do we collect?</li>
        <li>How do we collect your data?</li>
        <li>How will we use your data?</li>
        <li>How do we store your data?</li>
        <li>Marketing</li>
        <li>What are your data protection rights?</li>
        <li>What are cookies?</li>
        <li>How do we use cookies?</li>
        <li>What types of cookies do we use?</li>
        <li>How to manage your cookies</li>
        <li>Privacy policies of other websites</li>
        <li>Changes to our privacy policy</li>
        <li>How to contact us</li>
        <li>How to contact the appropriate authorities</li>
      </ol>
      <h5>What data do we collect?</h5>
      <p>MrCarterMaths Ltd. collects the following data:</p>
      <ul>
        <li>Personal identification information (Name, email address, etc.)</li>
        <li>School information (Name, address, phone number, etc.)</li>
        <li>
          Student information where applicable (Name, school email address,
          etc.)
        </li>
      </ul>
      <h5>How do we collect your data?</h5>
      <p>
        You directly provide MrCarterMaths with most of the data we collect. We
        collect data and process data when you:
      </p>
      <ul>
        <li>
          Register online or place an order for any of our products or services.
        </li>
        <li>
          Voluntarily complete a customer survey or provide feedback via email.
        </li>
        <li>Use or view our website via your browser’s cookies.</li>
        <li>
          Send information to allow us to create logins for you and your pupils.
        </li>
      </ul>
      <h5>How will we use your data?</h5>
      <p>MrCarterMaths collects your data so that we can:</p>
      <ul>
        <li>Process your order and manage your account.</li>
        <li>
          Email you with special offers on other products and services we think
          you might like.
        </li>
      </ul>
      <p>Your data will not be shared outside of MrCarterMaths Ltd..</p>
      <h5>How do we store your data?</h5>
      <p>
        MrCarterMaths Ltd. securely stores your data at Amazon Web Services
        [AWS].
      </p>
      <p>
        MrCarterMaths Ltd. will keep your data until you request us to delete
        it.
      </p>
      <h5>Marketing</h5>
      <p>
        MrCarterMaths Ltd. would like to send you information about our products
        and services.
        <br />
        If you have agreed to receive marketing, you may always opt out at a
        later date.
        <br />
        You have the right at any time to stop MrCarterMaths from contacting you
        for marketing purposes.
        <br />
        If you no longer wish to be contacted for marketing purposes, please
        email contact@mrcartermaths.com.
      </p>
      <h5>What are your data protection rights?</h5>
      <p>
        MrCarterMaths Ltd. would like to make sure you are fully aware of all of
        your data protection rights. Every user is entitled to the following:
      </p>
      <p>
        <b>The right to access </b>
        <span>
          – You have the right to request MrCarterMaths Ltd. for copies of your
          personal data. We may charge you a small fee for this service.
        </span>
      </p>
      <p>
        <b>The right to rectification </b>
        <span>
          – You have the right to request that MrCarterMaths Ltd. correct any
          information you believe is inaccurate. You also have the right to
          request MrCarterMaths Ltd. to complete the information you believe is
          incomplete.
        </span>
      </p>
      <p>
        <b>The right to erasure </b>
        <span>
          – You have the right to request that MrCarterMaths Ltd. erase your
          personal data, under certain conditions.
        </span>
      </p>
      <p>
        <b>The right to restrict processing </b>
        <span>
          – You have the right to request that MrCarterMaths Ltd. restrict the
          processing of your personal data, under certain conditions.
        </span>
      </p>
      <p>
        <b>The right to object to processing </b>
        <span>
          – You have the right to object to MrCarterMaths Ltd.’s processing of
          your personal data, under certain conditions.
        </span>
      </p>
      <p>
        <b>The right to data portability </b>
        <span>
          – You have the right to request that MrCarterMaths Ltd. transfer the
          data that we have collected to another organization, or directly to
          you, under certain conditions.
        </span>
      </p>
      <p>
        If you make a request, we have one month to respond to you. If you would
        like to exercise any of these rights, please contact us at our email:
        <br />
        contact@mrcartermaths.com
      </p>
      <p>Call us at: 01709323888</p>
      <p>Or write to us: 8 Castleton Way, Waverley, Rotherham S60 8AQ</p>
      <h5>Cookies</h5>
      <p>
        Cookies are text files placed on your computer to collect standard
        Internet log information and visitor behaviour information. When you
        visit our websites, we may collect information from you automatically
        through cookies or similar technology
      </p>
      <p>For further information, visit allaboutcookies.org.</p>
      <h5>How do we use cookies?</h5>
      <p>
        MrCarterMaths Ltd. uses cookies in a range of ways to improve your
        experience on our website, including:
      </p>
      <ul>
        <li>Keeping you signed in</li>
        <li>Understanding how you use our website</li>
      </ul>
      <h5>What types of cookies do we use?</h5>
      <p>
        There are a number of different types of cookies, however, our website
        uses:
      </p>
      <ul>
        <li>
          Functionality – MrCarterMaths Ltd. uses these cookies so that we
          recognize you on our website and remember your previously selected
          preferences. These could include what language you prefer and location
          you are in. A mix of first-party and third-party cookies are used.
        </li>
      </ul>
      <h5>How to manage cookies</h5>
      <p>
        You can set your browser not to accept cookies, and the above website
        tells you how to remove cookies from your browser. However, in a few
        cases, some of our website features may not function as a result.
      </p>
      <h5>Privacy policies of other websites</h5>
      <p>
        The MrCarterMaths Ltd. website contains links to other websites. Our
        privacy policy applies only to our website, so if you click on a link to
        another website, you should read their privacy policy.
      </p>
      <h5>Changes to our privacy policy</h5>
      <p>
        MrCarterMaths Ltd. keeps its privacy policy under regular review and
        places any updates on this web page. This privacy policy was last
        updated on 07/09/2020.
      </p>
      <h5>How to contact us</h5>
      <p>
        If you have any questions about MrCarterMaths Ltd.’s privacy policy, the
        data we hold on you, or you would like to exercise one of your data
        protection rights, please do not hesitate to contact us.
      </p>
      <p>Email us at: contact@MrCarterMaths.com</p>
      <p>Call us: 01709323888</p>
      <p>Or write to us at: 8 Castleton Way, Waverley, Rotherham S60 8AQ</p>
      <h5>How to contact the appropriate authority</h5>
      <p>
        Should you wish to report a complaint or if you feel that MrCarterMaths
        Ltd. has not addressed your concern in a satisfactory manner, you may
        contact the Information Commissioner’s Office.
      </p>
    </div>
  );
};

export default Privacy;
