// const correct = {
//   type: "exam",
//   qNumber: 1,
//   age: 5,
//   correct: true,
//   repeated: false,
// };
// const correctR = {
//   type: "exam",
//   qNumber: 1,
//   age: 5,
//   correct: true,
//   repeated: true,
// };
// const incorrect = {
//   type: "exam",
//   qNumber: 2,
//   age: 10,
//   correct: false,
//   repeated: false,
// };
// const incorrectR = {
//   type: "exam",
//   qNumber: 2,
//   age: 10,
//   correct: false,
//   repeated: true,
// };
// // Question Array, all questions from a given minor topic
// const questionArray = [
//   correct,
//   incorrect,
//   incorrect,
//   incorrect,
//   correct,
//   incorrect,
//   correct,
//   incorrect,
// ];
// const dependantsMean = 0.4;

function GenerateScore(questionArray, dependantsMean) {
  let MCMScore = 0;
  let increment = 0;

  let baseline = dependantsMean;
  if (baseline > 0.5) {
    baseline = (baseline + 0.5) / 2;
  }
  if (baseline < 0.25) {
    baseline = 0.25;
  }

  if (questionArray.length < 6) {
    increment = (1 - baseline) / 5;
    MCMScore = baseline;
    for (let i = 0; i <= questionArray.length - 1; i += 1) {
      if (
        questionArray[i].correct >= 1 &&
        questionArray[i].type === "skills" &&
        MCMScore > 0.5
      ) {
        MCMScore += 0.05;
      } else if (questionArray[i].correct >= 1) {
        MCMScore += increment;
      } else if (i < 2 && baseline > 0.5) {
        MCMScore -= 2 * increment;
      } else {
        MCMScore -= increment;
      }
      if (MCMScore < 0.05) {
        MCMScore = 0.05;
      }
    }
  } else {
    let countOlderCorrect = 0;
    for (let i = 0; i <= questionArray.length - 6; i += 1) {
      if (questionArray[i].correct >= 1) {
        countOlderCorrect += 1;
      }
    }
    const proportionOlderCorrect =
      countOlderCorrect / (questionArray.length - 5);
    let weightedEstimate = proportionOlderCorrect;
    if (questionArray.length <= 10) {
      weightedEstimate =
        (proportionOlderCorrect * (questionArray.length - 5) + 5 * baseline) /
        questionArray.length;
    }
    MCMScore = weightedEstimate;
    increment = (1 - weightedEstimate) / 5;
    if (increment < 0.05) {
      increment = 0.05;
    }
    let countRepeatedCorrect = 0;
    for (
      let i = questionArray.length - 5;
      i <= questionArray.length - 1;
      i += 1
    ) {
      if (questionArray[i].correct >= 1 && questionArray[i].repeated) {
        countRepeatedCorrect += 1;
      }
    }

    for (
      let i = questionArray.length - 5;
      i <= questionArray.length - 1;
      i += 1
    ) {
      if (
        questionArray[i].correct >= 1 &&
        questionArray[i].type === "skills" &&
        MCMScore > 0.5
      ) {
        MCMScore += 0.05;
      } else if (questionArray[i].correct >= 1 && questionArray[i].repeated) {
        MCMScore += increment * 0.5 ** countRepeatedCorrect;
      } else if (questionArray[i].correct >= 1) {
        MCMScore += increment;
      } else if (questionArray[i].correct < 1 && MCMScore < 0.5) {
        MCMScore -= increment * 0.5;
      } else {
        MCMScore -= increment;
      }
      if (MCMScore < 0.05) {
        MCMScore = 0.05;
      }
    }
  }

  if (MCMScore < 0.05) {
    MCMScore = 0.05;
  }
  if (MCMScore > 1) {
    MCMScore = 1;
  }
  return MCMScore;
}

export { GenerateScore };
