import { NumberSetError } from "../MCError";
import { Fraction } from "./Fraction";
/**
 * Geometric Sequence Object.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Creates a new geometric sequence with first term $5$ and ratio $0.5$
 * const g1 = new GeometricSequence(5,0.5)
 */

class GeometricSequence {
  /**
   * Creates a Geometric Sequence $a\\cdot r^{n-1}$.
   *
   * @param {number} 				a The first term.
   * @param {number} 				r The common ratio.
   * @returns {GeometricSequence} A new geometric sequence.
   * @throws {TypeError}   		The argument should be numbers.
   */
  constructor(a, r) {
    if (typeof a !== "number") {
      throw new NumberSetError(`Expected a number, got ${a} instead`);
    }
    if (typeof r !== "number") {
      throw new NumberSetError(`Expected a number, got ${r} instead`);
    }
    this.a = a;
    this.r = r;
  }

  /**
   * Returns term n in the sequence.
   *
   * @param  {number} 	n The number of the term to calculate.
   * @throws {TypeError}  The argument should be a number.
   * @returns {number} 	Term n.
   *
   * @example
   * //Finds the 3rd term in the sequence $4\\cdot 2^{n-1}$
   * let g1 = new GeometricSequence(4,2)
   * console.log(g1.term(3))
   */
  term(n) {
    if (typeof n !== "number") {
      throw new NumberSetError(`Expected a number, got ${n} instead`);
    }
    return this.a * this.r ** (n - 1);
  }

  /**
   * Returns multiple terms in the sequence as a string.
   *
   * @param  {number} 	start The number of the first term to calculate.
   * @param  {number} 	end The number of the last term to calculate.
   * @throws {TypeError}  The arguments should be numbers.
   * @returns {string} 	The terms as a comma seperated string.
   *
   * @example
   * //Finds the Fisrt 4 terms in the sequence $4\\cdot 2^{n-1}$
   * let g1 = new GeometricSequence(4,2)
   * console.log(g1.terms(1,4))
   */
  terms(start, end) {
    if (typeof start !== "number") {
      throw new NumberSetError(`Expected a number, got ${start} instead`);
    }
    if (typeof end !== "number") {
      throw new NumberSetError(`Expected a number, got ${end} instead`);
    }
    let out = "";
    for (let i = start; i <= end; i += 1) {
      out += `${new Fraction(this.term(i)).toString()}, `;
    }
    return out.substring(0, out.length - 2);
  }

  /**
   * Sums the first n terms of the sequence.
   *
   * @param  {number} 	n The number of the term to be summerd.
   * @throws {TypeError}  The argument should be a number.
   * @returns {number} 	The sum of the first n terms.
   *
   * @example
   * //Finds the sum of the first four terms in the sequence $4\\cdot 2^{n-1}$
   * let g1 = new GeometricSequence(4,2)
   * console.log(g1.sum(4))
   */
  sum(n) {
    if (typeof n !== "number") {
      throw new NumberSetError(`Expected a number, got ${n} instead`);
    }
    if (this.r === 1) {
      return undefined;
    }
    return (this.a * (1 - this.r ** n)) / (1 - this.r);
  }

  /**
   * Shows Wroking for the sum of the first n terms of the sequence.
   *
   * @param  {number}     n The number of the term to be summerd.
   * @throws {TypeError}  The argument should be a number.
   * @returns {number}    The sum of the first n terms.
   *
   * @example
   * //Finds the sum of the first four terms in the sequence $4\\cdot 2^{n-1}$
   * let g1 = new GeometricSequence(4,2)
   * console.log(g1.sum(4))
   */
  sumWorking(n) {
    if (typeof n !== "number") {
      throw new NumberSetError(`Expected a number, got ${n} instead`);
    }
    return [
      `HEADING Sum of first $${n}$ terms.`,
      "$S_n=\\frac{a(1-r^n)}{1-r}$",
      `$S_{${n}} = \\frac{${new Fraction(
        this.a
      ).toString()}\\left(1-${new Fraction(
        this.r
      ).toString()}^{${n}}\\right)}{1-${new Fraction(this.r).toString()}}$`,
      `$S_{${n}} = ${new Fraction(this.sum(n)).toString()}$`,
    ];
  }

  /**
   * Sums the entire sequence up to infinity.
   *
   * @returns {number} The sum of the terms.
   *
   * @example
   * //Finds the sum of the the sequence $4\\cdot 0.5^{n-1}$
   * let g1 = new GeometricSequence(4,0.5)
   * console.log(g1.sumInfinity())
   */
  sumInfinity() {
    if (Math.abs(this.r) < 1) {
      return this.a / (1 - this.r);
    }
    if (this.r === -1) {
      return undefined;
    }
    return Infinity;
  }

  /**
   * Shows working for summing the entire sequence up to infinity.
   *
   * @returns {string[]} The lines of working.
   *
   * @example
   * //Shows working for the sum of the the sequence $4\\cdot 0.5^{n-1}$
   * let g1 = new GeometricSequence(4,0.5)
   * console.log(g1.sumInfinityWorking())
   */
  sumInfinityWorking() {
    if (Math.abs(this.r) >= 1) {
      return ["Error, $r>1$, so this makes no sense."];
    }
    return [
      "HEADING Infinte sum of $U_n$.",
      "$S_\\infty = \\frac{a}{1-r}$ for $|r|<1$",
      `$\\ \\ \\ \\ \\ \\ = \\frac{${new Fraction(
        this.a
      ).toString()}}{1-${new Fraction(this.r).toString()}}$`,
      `$\\ \\ \\ \\ \\ \\ = ${new Fraction(this.sumInfinity()).toString()}$`,
    ];
  }

  /**
   * Creats a string for the nth term.
   *
   * @returns {string} The nth term formula.
   *
   * @example
   * //Print the nth term formula of the the sequence $4\\cdot 0.5^{n-1}$
   * let g1 = new GeometricSequence(4,0.5)
   * console.log(g1.nthTerm())
   */
  nthTerm() {
    if (this.r > 0) {
      return `${new Fraction(this.a).toString()}\\cdot${new Fraction(
        this.r
      ).toString()}^{n-1}`;
    }
    return `${new Fraction(this.a).toString()}\\left(${new Fraction(
      this.r
    ).toString()}\\right)^{n-1}`;
  }

  /**
   * Finds the sum of terms in the range.
   *
   * @param  {number}     start The term to start the sum on.
   * @param  {number}     end The number of the last term to calculate.
   * @throws {TypeError}  The arguments should be numbers.
   * @returns {number}    The result of the sum.
   *
   * @example
   * //Finds the sum of terms 4 to 6 in the sequence $4\\cdot 2^{n-1}$
   * let g1 = new GeometricSequence(4,2)
   * console.log(g1.sumBetween(4,6))
   */
  sumBetween(start, end) {
    if (typeof start !== "number") {
      throw new NumberSetError(`Expected a number, got ${start} instead`);
    }
    if (typeof end !== "number") {
      throw new NumberSetError(`Expected a number, got ${end} instead`);
    }
    return this.sum(end) - this.sum(start - 1);
  }

  /**
   * Shows working for the sum of terms in the range.
   *
   * @param  {number}     start The term to start the sum on.
   * @param  {number}     end The number of the last term to calculate.
   * @throws {TypeError}  The arguments should be numbers.
   * @returns {string[]}  Lines of working.
   *
   * @example
   * //Shows the working for the sum of terms 4 to 6 in the sequence $4\\cdot 2^{n-1}$
   * let g1 = new GeometricSequence(4,2)
   * console.log(g1.sumBetweenWorking(4,6))
   */
  sumBetweenWorking(start, end) {
    if (typeof start !== "number") {
      throw new NumberSetError(`Expected a number, got ${start} instead`);
    }
    if (typeof end !== "number") {
      throw new NumberSetError(`Expected a number, got ${end} instead`);
    }
    let out = [];
    out.push(
      `HEADING Sum of terms between $${start}$ and $${end}$`,
      `$\\displaystyle\\sum_{n=${start}}^{${end}}U_n=S_{${end}}-S_{${start}-1}=S_{${end}}-S_{${
        start - 1
      }}$`
    );
    out = out.concat(this.sumWorking(end));
    out = out.concat(this.sumWorking(start - 1));
    out.push(
      `$\\therefore\\displaystyle\\sum_{n=${start}}^{${end}}U_n=${new Fraction(
        this.sum(end)
      ).toString()}-${new Fraction(this.sum(start - 1)).toString()}$`,
      `$=${this.sumBetween(start, end)}$`
    );
    return out;
  }

  /**
   * Finds the sum of terms between start and infinity.
   *
   * @param  {number}     start The term to start the sum on.
   * @throws {TypeError}  The arguments should be numbers.
   * @returns {number}    The result of the sum.
   *
   * @example
   * //Finds the sum of terms 5 to infinity in the sequence $4\\cdot 0.5^{n-1}$
   * let g1 = new GeometricSequence(4,.5)
   * console.log(g1.sumBetweenInfinity(5))
   */
  sumBetweenInfinity(start) {
    if (typeof start !== "number") {
      throw new NumberSetError(`Expected a number, got ${start} instead`);
    }
    return this.sumInfinity() - this.sum(start - 1);
  }

  /**
   * Shows working for the sum of terms between start and infinity.
   *
   * @param  {number}     start The term to start the sum on.
   * @throws {TypeError}  The arguments should be numbers.
   * @returns {string[]}  Lines of working.
   *
   * @example
   * //Shows the working for the sum of terms 5 to infinity in the sequence $4\\cdot 0.52^{n-1}$
   * let g1 = new GeometricSequence(4,.5)
   * console.log(g1.sumBetweenInfinityWorking(5))
   */
  sumBetweenInfinityWorking(start) {
    if (typeof start !== "number") {
      throw new NumberSetError(`Expected a number, got ${start} instead`);
    }
    let out = [];
    out.push(
      `HEADING Sum of terms between $${start}$ and $\\infty$.`,
      `$\\displaystyle\\sum_{n=${start}}^{\\infty}U_n=S_\\infty-S_{${start}-1}=S_\\infty-S_{${
        start - 1
      }}$`
    );
    out = out.concat(this.sumInfinityWorking());
    out = out.concat(this.sumWorking(start - 1));
    out.push(
      `$\\therefore\\displaystyle\\sum_{n=${start}}^{\\infty}U_n=${new Fraction(
        this.sumInfinity()
      ).toString()}-${new Fraction(this.sum(start - 1)).toString()}$`,
      `$=${this.sumBetweenInfinity(start)}$`
    );
    return out;
  }
}

export { GeometricSequence };
