import { Fraction } from "./Fraction";

/**
 * Function to clean strings, like changing +-1x to -x. Also converts an decimals to fractions.
 * @memberof module:MCMaths
 * @author James Pickersgill
 *
 * @param {string}                  string String to clean.
 * @param {bool} [fraction=true]    If true, converts any decimals to fractions.
 * @param {bool} [fancy=true]       If true, converts any multiples of pi and surds.
 *
 * @returns {string}   A cleaned up string.
 *
 * @example
 * // converts +-0.5x--1+1\\sin(x) to -\\fract{1}{2}x+1+\\sin(x)
 * let test = '+-0.5x--1+1\\sin(x)'
 * console.log(cleaner(test))
 */

function cleaner(
  Inputstring,
  fraction = true,
  fancy = true,
  RoundingErrorMode = false
) {
  function RoundingError(x) {
    return Math.round(100000000 * x) / 100000000;
  }

  // Converts number to the form frac*sqrt{frac}, used by cleaner, don't use sperately.
  function surdSorterOuter(inputNumber) {
    const input = new Fraction(RoundingError(parseFloat(inputNumber) ** 2));
    let outputNumerator = 1;
    let outputDenominator = 1;
    let inputNumerator = input.numerator;
    let inputDenominator = input.denominator;

    for (let i = Math.round(Math.sqrt(inputNumerator)) + 1; i > 0; i -= 1) {
      if (
        Math.round(RoundingError(inputNumerator / i ** 2)) ===
        RoundingError(inputNumerator / i ** 2)
      ) {
        inputNumerator = RoundingError(inputNumerator / i ** 2);
        outputNumerator *= i;
      }
    }

    for (let i = Math.round(Math.sqrt(inputDenominator)) + 1; i > 0; i -= 1) {
      if (
        Math.round(RoundingError(inputDenominator / i ** 2)) ===
        RoundingError(inputDenominator / i ** 2)
      ) {
        inputDenominator = RoundingError(inputDenominator / i ** 2);
        outputDenominator *= i;
      }
    }

    if (inputDenominator > 1) {
      inputNumerator *= inputDenominator;
      outputDenominator *= inputDenominator;
      inputDenominator = 1;
    }
    let sign = "";
    if (inputNumber < 0) {
      sign = "-";
    }

    let outputFactor = "";
    if (outputNumerator !== 1 || outputDenominator !== 1) {
      outputFactor = new Fraction(
        outputNumerator / outputDenominator
      ).toString();
    }
    return `${sign + outputFactor}\\sqrt{${new Fraction(
      inputNumerator / inputDenominator
    ).toString()}}`;
  }

  let string = Inputstring;

  // Removes +-
  const plusMin = new RegExp("\\+\\-", "g");
  string = string.replace(plusMin, "-");

  // Removes --
  const minMin = new RegExp("\\-\\-", "g");
  string = string.replace(minMin, "+");

  // Removes -+
  const minPlus = new RegExp("\\-\\+", "g");
  string = string.replace(minPlus, "-");

  // Removes ++
  const plusPlus = new RegExp("\\+\\+", "g");
  string = string.replace(plusPlus, "+");

  // ------------------------------------- Cleaner V2 section ----------------------
  // Sorts Math.PI
  const mathPi = new RegExp("Math.PI", "g");
  string = string.replace(mathPi, "'\\pi'");

  if (fancy === true) {
    const decimal = new RegExp("(\\-?)(\\d+)\\.(\\d+)", "g");
    string = string.replace(decimal, function replacer(match) {
      // Sorts Multiples of Pi
      if (new Fraction(parseFloat(match) / Math.PI).denominator < 100) {
        if (new Fraction(parseFloat(match) / Math.PI).toString() === 1) {
          return "\\pi";
        }
        if (new Fraction(parseFloat(match) / Math.PI).toString() === -1) {
          return "-\\pi";
        }
        return `${new Fraction(parseFloat(match) / Math.PI).toString()}\\pi`;
      }
      // Sorts surds
      if (
        new Fraction(RoundingError(parseFloat(match) ** 2)).denominator <
          1000 &&
        new Fraction(RoundingError(parseFloat(match))).denominator > 100000
      ) {
        return surdSorterOuter(match);
      }
      return match;
    });
  }
  // ------------------------------------- Cleaner V2 section ----------------------

  if (fraction === true) {
    // Sorts Decimal To fraction
    const decimal = new RegExp("(\\-?)(\\d+)\\.(\\d+)", "g");
    string = string.replace(decimal, function replacer(match) {
      return new Fraction(parseFloat(match)).toString();
    });
  }

  // Removes 1x            1(letter or slash) but not \\right
  // Function checks preceding digit isn't a number to strop 21x etc. being converted to 2x.
  // Done like this because apparently negative lookbehinds aren't implimented in most browsers.
  const oneX = new RegExp("1(?=[a-zA-Z\\\\])(?!\\\\(right|cdot))", "g");
  string = string.replace(oneX, function replaceOne(x, y, z, w) {
    if (w.substring(z - 1, z).match(/[0-9]/g) === null) {
      return "";
    }
    return 1;
  });

  // ------------------------------------- Cleaner V3 section ----------------------
  if (RoundingErrorMode === true) {
    const decimal = new RegExp("(\\-?)(\\d+)\\.(\\d+)", "g");
    string = string.replace(decimal, function roundingErrorFunction(match) {
      return RoundingError(parseFloat(match));
    });
  }

  return string;
}

export { cleaner };
