import React from "react";

const Terms = () => {
  return (
    <div className="container my-4">
      <h4>Terms and Conditions</h4>
      <p>
        By using ALevel.MrCarterMaths.com you agree to abide by the below terms
        and conditions. We may change these terms and conditions from time to
        time; any changes that affect users will be e-mailed to the contact
        address.
      </p>
      <p>
        The following definitions apply when used in these terms and conditions:
        <br />
        &apos;we&apos;, &apos;us&apos;, &apos;our&apos; refer to
        &apos;MrCarterMaths Ltd&apos;
        <br />
        &apos;you&apos;, &apos;your&apos; refer to the person or institution
        using the materials
        <br />
        &apos;fee&apos;, refers to the fee payable by you to use or access to
        the materials
        <br />
        &apos;website&apos; refers to &apos;ALevel.MrCarterMaths.com&apos;
        <br />
        &apos;materials&apos; are the materials to which you buy access through
        the website
      </p>
      <ol>
        <li>
          <h5>The Website</h5>
          <p>
            By using the website you agree to abide by these terms and
            conditions. If you disagree with any of the terms or conditions on
            this page then please do not use the website. These may change in
            the future.
            <br />
            We reserve the right to alter the access arrangements, appearance or
            content available on the website.
          </p>
        </li>
        <li>
          <h5>Using the Website</h5>
          <p>
            All the content displayed on the website is the property of
            MrCarterMaths Ltd.
            <br />
            You may not (without written permission from MrCarterMaths Ltd.):
            <ul>
              <li>
                Redistribute or share the login information with anyone outside
                of those given permission within your subscription
              </li>
              <li>
                Create a database or &apos;collection&apos; in electronic or
                manual form, by systematically storing or copying the website
                content
              </li>
              <li>
                Reproduce or in any way commercially exploit any of the content
              </li>
            </ul>
            Whilst every effort has been made to ensure the quality and accuracy
            of the content on ALevel.MrCarterMaths.com some errors may occur. It
            is the responsibility of the user to ensure that the content they
            are using is accurate. Any errors discovered and reported will be
            rectified as soon as possible.
            <br />
            All efforts have been made to ensure the security of the content on
            the website. We offer no protection (above that offered by law) for
            damage done by viruses or other destructive programmes. The user
            accepts full responsibility for any damage done to their computer
            system whilst using our website.
          </p>
        </li>
        <li>
          <h5>Law</h5>
          <p>
            All terms and conditions set out on this page are subject to UK Law.
          </p>
        </li>
        <li>
          <h5>Right to be forgotten</h5>
          <p>
            You have the right to have all of your data removed from Mr Carter
            Maths. If you wish to do this you need to email
            Contact@MrCarterMaths.com with “Right to be forgotten” as the
            subject, we will ensure this request is met in one month. This
            request may take up to one month to process.
            <br />
            If you have an active subscription and you use your right to be
            forgotten your access to the site will be lost. In this case a
            refund will not be made.
          </p>
        </li>
        <li>
          <h5>Right of Access</h5>
          <p>
            You have a right to access all your data held by MrCarterMaths LTD.
            This can be done by emailing Contact@MrCarterMaths.com with “Right
            of access” as the subject. This request may take up to one month to
            process.
          </p>
        </li>
        <li>
          <h5>Right to Object</h5>
          <p>
            You can object to any use of your personal data, for example you can
            object to us emailing you. If you wish to object please email
            Contact@MrCarterMaths.com with “Right to Object” as the subject and
            state what action you are objecting to. This request may take up to
            one month to process.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default Terms;
