import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import axios from "axios";
import { useHistory } from "react-router-dom";
import AddRelationModal from "./AddRelationModal";
import RoundedBtn from "./RoundedBtn";
import Alert from "./Alert";
import StaffList from "./StaffList";
import checkToken from "../system/auth/checkToken";

const Staff = ({ staff }) => {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [staffList, setStaffList] = useState([]);
  const [alert, setAlert] = useState({});
  const history = useHistory();

  useEffect(() => {
    if (staff) {
      setLoading(false);
      setStaffList([...staff]);
    }
  }, [staff]);

  const startLoad = () => {
    return setTimeout(() => {
      setLoading(true);
    }, 500);
  };

  const stopLoad = (timer) => {
    clearTimeout(timer);
    setLoading(false);
  };

  const validateEmail = (e) => {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(String(e).toLowerCase());
  };

  const addStaff = async (newAccountData) => {
    setAlert({});
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    if (typeof newAccountData === "undefined") {
      if (!validateEmail(email)) {
        setAlert({
          variant: "danger",
          modal: true,
          msg: "Please enter a valid email address.",
        });
        return false;
      }
    }
    setShowModal(false);
    const start = startLoad();
    const data =
      typeof newAccountData === "undefined"
        ? { email }
        : {
            fname: newAccountData.firstName,
            lname: newAccountData.lastName,
            email: newAccountData.email,
            password: newAccountData.password,
            admin: newAccountData.admin,
          };
    try {
      await axios.post(`${process.env.REACT_APP_DOMAIN}/staff-admin`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
      });
      setStaffList([{ email: data.email }, ...staffList]);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setAlert({
          variant: "danger",
          msg: JSON.stringify(e.response.data.error),
        });
      } else {
        setAlert({
          variant: "danger",
          msg: "A problem occurred. Please try again.",
        });
      }
      stopLoad(start);
      return false;
    }
    setAlert({ variant: "success", msg: `Successfully added ${email}!` });
    setEmail("");
    stopLoad(start);
    return true;
  };

  const onDelete = async (member) => {
    const start = startLoad();
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    try {
      await axios.delete(`${process.env.REACT_APP_DOMAIN}/staff-admin`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
        data: {
          staffId: member.userId,
        },
      });
      const data = [...staff];
      let ind = -1;
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].userId === member.userId) {
          ind = i;
        }
      }
      data.splice(ind, 1);
      setAlert({
        variant: "success",
        msg: `Successfully removed ${member.fname} ${member.lname}.`,
      });
      setStaffList(data);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setAlert({
          variant: "danger",
          msg: JSON.stringify(e.response.data.error),
        });
      } else {
        setAlert({
          variant: "danger",
          msg: "A problem occurred. Please try again.",
        });
      }
    }
    stopLoad(start);
    return true;
  };

  return (
    <div className="card text-center">
      <h4 className="card-header">Staff</h4>
      <div className="card-body">
        <AddRelationModal
          danger={alert.modal ? alert.msg : ""}
          show={showModal}
          onHide={() => setShowModal(false)}
          onAction={addStaff}
          email={email}
          emailChanged={setEmail}
          entity="sadmin"
        />
        <div>
          <div>
            <div className="text-center">
              {loading ? (
                <div className="my-5">
                  <ClipLoader loading color="#74c5ed" size="3em" />
                </div>
              ) : (
                <div>
                  <div className="mt-1">
                    <RoundedBtn
                      text="Add Staff Member"
                      width={15}
                      icon={{ icon: faPlus, position: "left" }}
                      onClick={() => setShowModal(true)}
                    />
                    {(typeof alert.modal === "undefined" || !alert.modal) &&
                      typeof alert.msg !== "undefined" && (
                        <div className="mt-3">
                          <Alert variant={alert.variant}>{alert.msg}</Alert>
                        </div>
                      )}
                  </div>
                  <div className="text-left mt-3">
                    <StaffList data={staffList} onDelete={onDelete} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Staff.propTypes = {
  staff: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Staff;
