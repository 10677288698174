import * as ModuleDependencies from "./ModuleDependencies";
import * as GCSEDependencies from "./gcseDependencies";
import { GenerateScore } from "./GenerateScore";
import { getSiteID } from "../../utils/getSite";

function CalculateScores(questions) {
  const site = getSiteID();
  let modDependencies = {};
  if (site === 0) modDependencies = GCSEDependencies;
  else modDependencies = ModuleDependencies;
  const scores = {};
  const defaultDepMean = 0.4;
  for (let i = 0; i < modDependencies.topicsOrdered.length; i += 1) {
    // calculate mean dependency score
    const topic = modDependencies.topicsOrdered[i];
    const deps = modDependencies[topic];
    if (typeof deps === "undefined") {
      throw new Error(`Check module dependencies for ${topic}!`);
    }
    let mean;
    if (deps.length !== 0) {
      let sum = 0;
      for (let j = 0; j < deps.length; j += 1) {
        sum += scores[deps[j]];
        if (Number.isNaN(sum)) {
          throw new Error(`Check module dependencies for ${topic}!`);
        }
      }
      mean = sum / deps.length;
    } else {
      mean = defaultDepMean;
    }
    // filter question list - include only given topic
    const questionArray = [];
    for (let j = 0; j < questions.length; j += 1) {
      if (questions[j].minor.replace(" ", "") === topic) {
        questionArray.push(questions[j]);
      }
    }
    // generate score and store
    scores[topic] = GenerateScore(questionArray, mean);
  }
  return scores;
}

export { CalculateScores };
