import React, { useState } from "react";
import * as jwt from "jsonwebtoken";
import PropTypes from "prop-types";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import styles from "./styles/PupilList.module.css";
import AcceptModal from "./AcceptModal";
import DeleteRelationModal from "./DeleteRelationModal";

const PupilList = ({ data, allAnswerData, onRespondToInvite, onDelete }) => {
  const history = useHistory();
  const uid = jwt.decode(localStorage.getItem("mcmToken")).userId;
  const [isTabbing, setTabbing] = useState(false);
  const [accepting, setAccepting] = useState([false]);
  const [deleting, setDeleting] = useState([false]);

  const keyPressed = (e /* staffId */) => {
    if (e.key === "Tab") {
      setTabbing(true);
    }
    if (e.key === "Enter" || e.key === " ") {
      // do thing
    }
  };

  const accept = () => {
    onRespondToInvite(true, accepting[1].pupilId);
    setAccepting([false]);
  };

  const deleteRel = () => {
    onDelete(deleting[1]);
    setDeleting([false]);
  };

  const displayPupil = (relation) => {
    if (
      !relation.confirmed &&
      (typeof relation.createdBy === "undefined" || relation.createdBy === uid)
    ) {
      return relation.email;
    }
    return `${relation.fname} ${relation.lname}`;
  };

  const summarise = (id) => {
    // let total = 0;
    // let correct = 0;
    // if (summaryPeriod === -1) {
    //   answers.forEach((entry) => {
    //     total += 1;
    //     if (entry.correct) {
    //       correct += 1;
    //     }
    //   });
    // } else {
    //   const dateRange = new Date();
    //   dateRange.setDate(dateRange.getDate() - summaryPeriod);
    //   answers.forEach((entry) => {
    //     const qDate = entry.dateAnswered.split(/[- :]/);
    //     const date = new Date(
    //       qDate[0],
    //       qDate[1] - 1,
    //       qDate[2],
    //       qDate[3],
    //       qDate[4],
    //       qDate[5]
    //     );
    //     if (date >= dateRange) {
    //       total += 1;
    //       if (entry.correct) {
    //         correct += 1;
    //       }
    //     }
    //   });
    // }
    let total = 0;
    let correct = 0;
    const required = allAnswerData.filter((entry) => entry.userId === id);
    if (required.length > 0) {
      const [info] = required;
      total = info.total;
      correct = info.correct;
    }

    return { total, correct };
  };

  return (
    <div>
      <AcceptModal
        show={accepting[0]}
        onHide={() => setAccepting([false])}
        onAction={accept}
        personName={
          accepting[0] ? `${accepting[1].fname} ${accepting[1].lname}` : ""
        }
        invitationFrom="pupil"
      />
      <DeleteRelationModal
        show={deleting[0]}
        onHide={() => setDeleting([false])}
        onAction={deleteRel}
        personName={
          deleting[0] ? `${deleting[1].fname} ${deleting[1].lname}` : ""
        }
        entity="pupil"
      />
      <ul className="list-group">
        {data.map((relation) => {
          const summary = relation.confirmed ? summarise(relation.pupilId) : {};
          return (
            <li className="list-group-item" key={relation.pupilId}>
              {!relation.confirmed ? (
                <div style={{ display: "inline" }}>
                  <span className={styles.pupilText}>
                    {displayPupil(relation)}
                  </span>
                  {typeof relation.createdBy === "undefined" ||
                  uid === relation.createdBy ? (
                    <span
                      className="badge badge-primary mt-1 float-right"
                      style={{ backgroundColor: "#74c5ed" }}
                    >
                      PENDING
                    </span>
                  ) : (
                    <div className="float-right">
                      <span
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          onRespondToInvite(false, relation.pupilId);
                        }}
                        className={`badge badge-danger mr-1 ${
                          styles.response
                        } ${!isTabbing && styles.noTab}`}
                        onKeyPress={(e) => keyPressed(e, relation.pupilId)}
                      >
                        REJECT
                      </span>
                      <span
                        role="button"
                        tabIndex={0}
                        onClick={() => setAccepting([true, relation])}
                        className={`badge badge-success ml-1 ${
                          styles.response
                        } ${!isTabbing && styles.noTab}`}
                        onKeyPress={(e) => keyPressed(e, relation.pupilId)}
                      >
                        ACCEPT
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => {
                      history.push("/review", {
                        name: displayPupil(relation),
                        id: relation.pupilId,
                      });
                    }}
                  >
                    {displayPupil(relation)}
                  </button>
                  {!relation.createdBy && relation.pupilId !== -1 && (
                    <div className={`${styles.delete}`}>
                      <FontAwesomeIcon
                        icon={faTimes}
                        color="#d9534f"
                        onClick={() => setDeleting([true, relation])}
                      />
                    </div>
                  )}
                  <span className={styles.summary}>
                    {`${summary.total} questions answered ${String.fromCharCode(
                      0x000b7
                    )} ${
                      summary.total === 0
                        ? 0
                        : Math.round((summary.correct / summary.total) * 100)
                    }% correct`}
                  </span>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

PupilList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  allAnswerData: PropTypes.arrayOf(PropTypes.any).isRequired,
  onRespondToInvite: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PupilList;
