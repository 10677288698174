import { MALE_NAMES, FEMALE_NAMES } from "./constants";
import { randomInt, chooseElement } from "../MCRandom";
/**
 * @memberof module:MCMisc
 * @typedef     {Object}    Name
 * @property    {string}    name    The name of the person.
 * @property    {string}    sex     The sex of the person ('male' or 'female').
 * @property    {string}    heshe   'he' or 'she'.
 * @property    {string}    HeShe   'He' or 'She'.
 * @property    {string}    himher  'him' or 'her'.
 * @property    {string}    HimHer  'Him' or 'Her'.
 * @property    {string}    hisher  'his' or 'her'.
 * @property    {string}    HisHer  'His' or 'Her'.
 * @property    {string}    hishers 'his' or 'hers'.
 * @property    {string}    HisHers 'His' or 'Hers'.
 */
/**
 * @summary Get an object containing a name along with sex and pronouns.
 *
 * @description Returns an object which has a name chosen at random. Sex can be controlled by the optional parameter.
 * Pronouns for use in sentences will also be returned as properties of the object. Throws a TypeError if a string sex is provided and is
 * not a string. Throws a RangeError if sex is not one of the accepted arguments.
 *
 * @since 0.1.0
 * @memberof module:MCMisc
 *
 * @param   {('m' | 'male' | 'f' | 'female')} [sex]    Option for chosing whether the name belongs to a male or female.
 * @returns {Name}                                   Name and sex properties.
 * @throws  {TypeError}                              Optional parameter sex should be a string if provided.
 * @throws  {RangeError}                             Optional parameter sex should be one of the accepted values if provided.
 *
 * @example
 * // get a name (any sex)
 * const name1 = MCMisc.getName();
 * console.log(name1.name); // Output: 'Bob' (name will differ)
 * // get a male name (shorthand)
 * const male1 = MCMisc.getName('m');
 * // get a male name
 * const male2 = MCMisc.getName('male');
 * // get a female name (shorthand)
 * const female = MCMisc.getName('f');
 * console.log(female.sex); // Output: 'female'
 * console.log(female.HeShe); // Output: 'She'
 * console.log(female.name); // Output: 'Clara' (name will differ)
 */
function getName(sex) {
  if (typeof sex !== "undefined") {
    if (typeof sex !== "string") {
      throw new TypeError(`expected string but got ${typeof sex}`);
    } else if (sex === "m" || sex === "male") {
      return {
        name: chooseElement(MALE_NAMES),
        sex: "male",
        heshe: "he",
        HeShe: "He",
        himher: "him",
        HimHer: "Him",
        hisher: "his",
        HisHer: "His",
        hishers: "his",
        HisHers: "His",
      };
    } else if (sex === "f" || sex === "female") {
      return {
        name: chooseElement(FEMALE_NAMES),
        sex: "female",
        heshe: "she",
        HeShe: "She",
        himher: "her",
        HimHer: "Her",
        hisher: "her",
        HisHer: "Her",
        hishers: "hers",
        HisHers: "Hers",
      };
    } else {
      throw new RangeError(
        `expected 'm', 'male', 'f' or 'female' but got ${sex} instead`
      );
    }
  } else {
    const mOrF = randomInt(0, 1);
    if (mOrF === 0) {
      return {
        name: chooseElement(MALE_NAMES),
        sex: "male",
        heshe: "he",
        HeShe: "He",
        himher: "him",
        HimHer: "Him",
        hisher: "his",
        HisHer: "His",
        hishers: "his",
        HisHers: "His",
      };
    }
    return {
      name: chooseElement(FEMALE_NAMES),
      sex: "female",
      heshe: "she",
      HeShe: "She",
      himher: "her",
      HimHer: "Her",
      hisher: "her",
      HisHer: "Her",
      hishers: "hers",
      HisHers: "Hers",
    };
  }
}

export { getName };
