/**
 * Solves the ineqality lower < functions < upper.
 * This uses numerical meths, and starts at the root input.
 * So for something like -0.5<sin(x)<0.5, if the root is 0, will return [-pi/6, pi/6]
 * If the root is 3, it would now return [5pi/6,7pi/6]
 * If this is slow, pick a root closer to the region you're looking at
 * @memberof module:MCMaths
 * @author James Pickersgill
 */

class Inequalities {
  /**
   * Finds a<x<b s.t. lower < func(x) < upper
   * @param {object} func The function to use, must have an .evaluate() defined
   * @param {number} lower a
   * @param {number} upper b
   * @param {number} root  The point to start the search for inequality from, i.e. the centre point of the inequality
   * @param {bool} negative Set to true if you're using a section of a function with a negative gradient
   * @param {number} accuracy Accuracy for the intital search, leave as 0.01, only make smaller if things break.
   */
  constructor(
    func,
    lower = -0.1,
    upper = 0.1,
    root = 0,
    negative = false,
    accuracy = 0.01
  ) {
    this.func = func;
    this.lower = lower;
    this.upper = upper;
    this.root = root;
    this.accuracy = accuracy;
    this.negative = negative;
  }

  /**
   * Prints the inequality as a string.
   * @returns {string}
   */
  toString() {
    return `${this.lower}\\le${this.func.toString()}\\le${this.upper}`;
  }

  /**
   * Finds a and b such that a<x<b, lower < function(x) < upper.
   * @returns {number[]} Array of a and b.
   */
  solve() {
    let rootL = this.root;
    let rootR = this.root;
    let { root } = this;

    let l = this.lower;
    let u = this.upper;
    if (this.negative === true) {
      u = this.lower;
      l = this.upper;
    }

    let step = 0;
    while (step <= 1000000000000) {
      step += 1;
      if (
        this.func.evaluate(rootL) < this.upper &&
        this.func.evaluate(rootL) > this.lower
      ) {
        root = rootL;
        break;
      }
      if (
        this.func.evaluate(rootR) < this.upper &&
        this.func.evaluate(rootR) > this.lower
      ) {
        root = rootR;
        break;
      }
      rootL -= this.accuracy;
      rootR += this.accuracy;
    }

    let a1 = root;
    let b1 = root;
    let a2 = root;
    let b2 = root;
    if (this.negative === false) {
      while (this.lower < this.func.evaluate(a1) && a1 > -10000) {
        a1 -= this.accuracy;
      }
      while (this.upper > this.func.evaluate(b2) && b2 < 10000) {
        b2 += this.accuracy;
      }
    } else {
      while (this.upper > this.func.evaluate(a1) && a1 > -10000) {
        a1 -= this.accuracy;
      }
      while (this.lower < this.func.evaluate(b2) && b2 < 10000) {
        b2 += this.accuracy;
      }
    }

    // Put machnie epsilon, if small anough should show multiples of pi?.
    const epsilon = 0.000000000000001;
    let breakCount = 0;
    while (a2 - a1 > epsilon && breakCount < 50000) {
      const mid = (a2 + a1) / 2;
      if ((this.func.evaluate(a1) - l) * (this.func.evaluate(mid) - l) > 0) {
        a1 = mid;
      } else {
        a2 = mid;
      }
      breakCount += 1;
    }
    while (b2 - b1 > epsilon && breakCount < 50000) {
      const mid = (b2 + b1) / 2;
      if ((this.func.evaluate(b1) - u) * (this.func.evaluate(mid) - u) > 0) {
        b1 = mid;
      } else {
        b2 = mid;
      }
      breakCount += 1;
    }
    if (breakCount > 49999) {
      return ["Errored Out", "Errored Out"];
    }
    return [(a1 + a2) / 2, (b1 + b2) / 2];
  }

  /**
   * Returns a string in the form a<x<b
   * @returns {string}
   */
  solveString() {
    return `$${this.solve()[0]}\\le x \\le${this.solve()[1]}$`;
  }
}
export { Inequalities };
