import _ from "lodash";

function titleCase(str) {
  let theTitle = _.startCase(str);
  theTitle = theTitle.replace("And", "and");
  theTitle = theTitle.replace("By", "by");
  theTitle = theTitle.replace("Of", "of");
  theTitle = theTitle.replace("Newtons", "Newton's");
  theTitle = theTitle.replace("A 2 ", "");
  theTitle = theTitle.replace("3 D", "3D");
  theTitle = theTitle.replace("Counter Example", "Counterexample");
  return theTitle;
}

export default titleCase;
