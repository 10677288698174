import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import _ from "lodash";
import styles from "./styles/Accordion.module.css";
import Checklist from "./Checklist";

const Accordion = ({
  title,
  listItems,
  parent,
  topics,
  togglerMajor,
  togglerMinor,
  indexable,
  disabled,
}) => {
  // State for whether content should be displayed
  const [isOpen, setIsOpen] = useState(false);
  // State for whether title is checked
  const [isChecked, setIsChecked] = useState(false);
  const [tabbed, setTabbed] = useState(false);

  useEffect(() => {
    let isAll = true;
    if (!Object.values(topics[parent][title]).every((val) => val === true)) {
      isAll = false;
    }
    if (isAll) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [topics, parent, title]);

  // Function to check the title
  const performCheck = (e) => {
    // Stop toggle of displayed content
    e.stopPropagation();
    togglerMajor(title, !isChecked);
  };

  const toglleMinor = (minor) => {
    togglerMinor(title, minor);
  };

  const keyPressHandler = (e) => {
    if (e.key === "Tab") {
      setTabbed(true);
    }
    if (e.key === "Enter" || e.key === " ") {
      setIsOpen(!isOpen);
    }
  };

  const display = (str) => {
    let theTitle = _.startCase(str);
    theTitle = theTitle.replace("And", "and");
    theTitle = theTitle.replace("By", "by");
    theTitle = theTitle.replace("Of", "of");
    theTitle = theTitle.replace("Newtons", "Newton's");
    theTitle = theTitle.replace("A 2 ", "");
    theTitle = theTitle.replace("3 D", "3D");
    theTitle = theTitle.replace("Counter Example", "Counterexample");
    theTitle = theTitle.replace(
      "Ratio Proportion and Rates of Change",
      "Ratio, Proportion and Rates of Change"
    );
    theTitle = theTitle.replace(
      "Notation Vocabulary and Manipulation",
      "Notation, Vocabulary and Manipulation"
    );
    return theTitle;
  };

  return (
    <div className={styles.wrapper}>
      <div
        role="button"
        tabIndex={indexable ? 0 : -1}
        className={`${styles.title} ${isOpen && styles.open} ${
          tabbed ? "" : styles.noTab
        }`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onKeyPress={keyPressHandler}
      >
        <FontAwesomeIcon
          className={`fa-fw ${styles.check}`}
          icon={isChecked ? faCheckSquare : faSquare}
          onClick={disabled ? null : performCheck}
        />
        {display(title)}
        <div className={`${styles.icon} ${isOpen && styles.rotate}`}>
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
      <div className={`${styles.item} ${!isOpen && styles.collapsed}`}>
        <div className={styles.content}>
          <Checklist
            items={listItems}
            togglerMinor={toglleMinor}
            indexable={isOpen}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  listItems: PropTypes.objectOf(PropTypes.bool).isRequired,
  togglerMajor: PropTypes.func.isRequired,
  togglerMinor: PropTypes.func.isRequired,
  topics: PropTypes.objectOf(PropTypes.object).isRequired,
  parent: PropTypes.string.isRequired,
  indexable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

Accordion.defaultProps = {
  disabled: false,
};

export default Accordion;
