/**
 * Defines an error for when an argument passed to a function is
 * invalid despite being the correct type.
 * @memberof module:MCError
 * @author Declan Clark <dec@dclark.dev>
 * @example
 * throw new MCError.ArgumentError('expected max to be greater or equal to min');
 */
class ArgumentError extends Error {
  /**
   * Initialises an ArgumentError.
   * @param {string} message  The error message.
   */
  constructor(message) {
    super(message);
    this.name = "ArgumentError";
  }
}

export { ArgumentError };
