import React from "react";
import JXGBoard from "jsxgraph-react-js";
import PropTypes from "prop-types";
import MathString from "./MathString";
import styles from "./styles/Graph.module.css";
import * as MCMaths from "../system/modules/MCMaths";

const Graph = ({ graphObjContent }) => {
  const { axis, funcs, steps } = graphObjContent;
  let xTop;
  let yLeft;

  const xTicks = [];
  const yTicks = [];
  const xMin = axis[0];
  const yMax = axis[1];
  const xMax = axis[2];
  const yMin = axis[3];
  const xStep = steps[0];
  const yStep = steps[1];
  const highestXTick = Math.floor(xMax / xStep);
  const lowestXTick = Math.floor(xMin / xStep);
  const overl = [];
  if (xMin < 0) {
    yLeft = (-xMin * 100) / (-xMin + xMax) - 4.5;
  }

  if (yMin < 0) {
    xTop = 100 + (yMin * 100) / (-yMin + yMax) + 4.5;
  }
  for (let index = lowestXTick; index <= highestXTick; index += 1) {
    if (index * xStep !== xMin && index * xStep !== xMax) {
      xTicks.push(index * xStep);
    }
  }
  const highestYTick = Math.floor(yMax / yStep);
  const lowestYTick = Math.floor(yMin / yStep);
  for (let index = lowestYTick; index <= highestYTick; index += 1) {
    if (index * yStep !== yMin && index * yStep !== yMax) {
      yTicks.push(index * yStep);
    }
  }
  for (let index = 0; index <= xTicks.length - 1; index += 1) {
    if (
      xTicks[index] !== 0 &&
      xTicks[index] !== xMax &&
      xTicks[index] !== xMin
    ) {
      const str = MCMaths.cleaner(`$${xTicks[index]}$`);
      let left;
      if (xTicks[index] < 0) {
        left = (94.8 * (xTicks[index] - xMin)) / (xMax - xMin) + 1;
      } else {
        left = (98.45 * (xTicks[index] - xMin)) / (xMax - xMin) + 1;
      }

      left -= ((xTicks.length - index) / xTicks.length) * 0.4;

      const xAlignmentAdjustment = 0;

      overl.push({
        top: xTop,
        left: left - xAlignmentAdjustment,
        string: str,
      });
    }
  }

  for (let index = 0; index <= yTicks.length - 1; index += 1) {
    if (
      yTicks[index] !== 0 &&
      yTicks[index] !== yMin &&
      yTicks[index] !== yMax
    ) {
      const str = MCMaths.cleaner(`$${yTicks[index]}$`);
      let top = 100 - (100 * (yTicks[index] - yMin)) / (yMax - yMin);
      top -= ((yTicks.length - index) / yTicks.length) * 0.4;

      let yAlignmentAdjustment = 0;
      if (yTicks[index] < 0) {
        yAlignmentAdjustment = 1;
      }
      if (yTicks[index] < -9) {
        yAlignmentAdjustment = 2;
      }
      if (yTicks[index] > 9 && yTicks[index] < 100) {
        yAlignmentAdjustment = 0.5;
      }
      overl.push({ top, left: yLeft - yAlignmentAdjustment, string: str });
    }
  }
  const cols = ["#3e70ae", "#c91212", "#000000", "#389c53"];
  const logicJS = (brd) => {
    brd.suspendUpdate();
    // overlays = [{top:50,left:50},{top:75,left:75}]
    overl.push({ top: 50, left: 50 });
    // Create y axis:
    const yaxis = brd.create("axis", [
      [0, 0],
      [0, 1],
    ]);
    brd.create("ticks", [yaxis, yTicks], {
      strokeColor: "#909090",
      majorHeight: 15,
      drawLabels: false,
      tickEndings: [1, 0],
    });
    yaxis.removeTicks(yaxis.defaultTicks);

    // Create x axis:
    const xaxis = brd.create("axis", [
      [0, 0],
      [1, 0],
    ]);
    brd.create("ticks", [xaxis, xTicks], {
      strokeColor: "#909090",
      majorHeight: 15,
      drawLabels: false,
      tickEndings: [0, 1],
    });
    xaxis.removeTicks(xaxis.defaultTicks);

    for (let index = 0; index < funcs.length; index += 1) {
      const { content } = funcs[index];
      switch (funcs[index].type) {
        case "functiongraph": {
          brd.create(
            "functiongraph",
            [
              // eslint-disable-next-line no-new-func
              new Function("x", `return ${content.func}`),
              content.lInterval,
              content.rInterval,
            ],
            {
              strokeColor: cols[index % cols.length],
              strokeWidth: 2,
              highlight: false,
            }
          );
          break;
        }
        case "polar": {
          brd.create(
            "curve",
            [
              // eslint-disable-next-line no-new-func
              new Function("x", `return ${content.func}`),
              [content.offsetX, content.offsetY],
              content.lInterval,
              content.rInterval,
            ],
            {
              curveType: "polar",
              strokeColor: cols[index % cols.length],
              strokeWidth: 2,
              highlight: false,
            }
          );
          break;
        }
        case "parametric": {
          brd.create(
            "curve",
            [
              // eslint-disable-next-line no-new-func
              new Function("t", `return ${content.x}`),
              // eslint-disable-next-line no-new-func
              new Function("t", `return ${content.y}`),
              content.lInterval,
              content.rInterval,
            ],
            {
              strokeColor: cols[index % cols.length],
              strokeWidth: 2,
              highlight: false,
            }
          );
          break;
        }
        case "circle": {
          const p = brd.create(
            "point",
            [
              function x() {
                return content.centerx;
              },
              function y() {
                return content.centery;
              },
            ],
            {
              visible: false,
            }
          );
          brd.create("circle", [p, content.radius], {
            strokeColor: cols[index % cols.length],
            strokeWidth: 2,
            highlight: false,
          });
          break;
        }
        default:
          break;
      }
    }
    brd.unsuspendUpdate();
  };

  return (
    <div className={`${styles.board}`}>
      <JXGBoard
        logic={logicJS}
        boardAttributes={{
          axis: false,
          boundingbox: axis,
          showCopyright: false,
          showNavigation: false,
          pan: {
            enabled: false,
          },
          zoom: {
            wheel: false,
          },
        }}
        style={{
          borderRadius: "25px",
          border: "2px solid black",
          height: "18em",
          width: "18em",
        }}
      />
      {/* ************** A single overlay here *********** */}

      {overl.map((obj, ind) => {
        return (
          <div
            key={ind}
            className={`${styles.overlay}`}
            style={{
              top: `${obj.top}%`,
              left: `${obj.left}%`,
              fontSize: "0.6em",
            }}
          >
            <MathString str={`${obj.string}`} />
          </div>
        );
      })}
      <div
        className={`${styles.overlay}`}
        style={{ height: "18em", width: "18em", top: "50%", left: "50%" }}
      />
      {/* ************************************************ */}
    </div>
  );
};

Graph.propTypes = {
  graphObjContent: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Graph;
