import { ArgumentError } from "../MCError";
/**
 * A class that is used to handle the inputs to an ExamQuestion.
 * @memberof module:MCQuestion
 * @author Declan Clark <dec@dclark.dev>
 * @since 0.1.0
 * @example
 * const group1 = new MCQuestion.InputGroup(2);
 */
class InputGroup {
  /**
   * Creates a new input group.
   *
   * @param {number} inputsPerLine  The number of input boxes to show on a single line (desktop/tablet only).
   */
  constructor(inputsPerLine) {
    this.inputs = [];
    this.perLine = inputsPerLine;
  }

  /**
   * @summary Gets the inputs for this group.
   *
   * @since 0.1.0
   * @returns {Object[]} The inputs for this group.
   */
  getInputs() {
    return this.inputs;
  }

  /**
   * @summary Gets the number of boxes to show per line.
   *
   * @since 0.1.0
   * @returns {number} Boxes per line.
   */
  getPerLine() {
    return this.perLine;
  }

  /**
   * @summary Adds an input to the input group.
   *
   * @description Adds an input box to the group of inputs where inputting expectedAnswer will
   *              mark the question as correct and wrong otherwise. The input label adds
   *              a label before or after the input box depending on the position specified.
   *              This allows for boxes that start with 'A =' for example or that end in units
   *              like '...cm'. Errors are thrown when expectedAnswer is not passed as
   *              an argument, is not a string or appears to contain LaTeX. Errors are also
   *              thrown when inputLabel and labelPosition are of the wrong type or not accepted values.
   *
   * @since 0.1.0
   *
   * @param   {number | number[]}     expectedAnswer              An answer for the input box, an array of values indicates
   *                                                              that all values must be given as an answer - if an array is supplied,
   *                                                              if tolerance is non-zero then bounds must not overlap.
   * @param   {number}                tolerance                   Answer plus/minus this will be accepted as correct.
   * @param   {string}                [inputLabel]                The label on the input box.
   * @param   {('before' | 'after')}  [labelPosition='before']    The position of the label.
   * @throws  {ArgumentError}                                     Required arguments not supplied, contains LaTeX.
   * @throws  {TypeError}                                         Arguments are not correct type.
   * @throws  {RangeError}                                        Argument is not an accepted value.
   * @example
   * // adding an input box which will display like so: [      ] cm
   * group1.addInput(0.5, 0, 'cm', 'after');
   */
  addInput(expectedAnswer, tolerance, inputLabel, labelPosition) {
    if (
      typeof expectedAnswer === "undefined" ||
      typeof tolerance === "undefined"
    ) {
      throw new ArgumentError(`argument is required`);
    }
    if (typeof expectedAnswer !== "number" && !Array.isArray(expectedAnswer)) {
      throw new TypeError(
        `expected a number or array but got ${typeof expectedAnswer} instead`
      );
    }
    if (Array.isArray(expectedAnswer)) {
      expectedAnswer.forEach((expected) => {
        if (typeof expected !== "number") {
          throw new TypeError(
            `expected an array of numbers but array has element of type ${typeof expected}`
          );
        }
      });
    }
    if (typeof tolerance !== "number") {
      throw new TypeError(
        `expected a string but got ${typeof tolerance} instead`
      );
    }
    if (
      typeof inputLabel === "undefined" &&
      typeof labelPosition === "undefined"
    ) {
      if (Array.isArray(expectedAnswer)) {
        expectedAnswer.forEach(() => {
          this.inputs.push({
            type: "input",
            content: {
              answer: expectedAnswer,
              tolerance,
            },
          });
        });
      } else {
        this.inputs.push({
          type: "input",
          content: {
            answer: expectedAnswer,
            tolerance,
          },
        });
      }
    } else if (
      typeof inputLabel !== "undefined" &&
      typeof labelPosition === "undefined"
    ) {
      if (typeof inputLabel !== "string") {
        throw new TypeError(
          `expected a string but got ${typeof inputLabel} instead`
        );
      }
      if (Array.isArray(expectedAnswer)) {
        expectedAnswer.forEach(() => {
          this.inputs.push({
            type: "input",
            content: {
              answer: expectedAnswer,
              tolerance,
              label: inputLabel,
              labelPos: "before",
            },
          });
        });
      } else {
        this.inputs.push({
          type: "input",
          content: {
            answer: expectedAnswer,
            tolerance,
            label: inputLabel,
            labelPos: "before",
          },
        });
      }
    } else if (labelPosition !== "before" && labelPosition !== "after") {
      throw new RangeError(
        `expected 'before' or 'after' but got ${labelPosition} instead`
      );
    } else if (Array.isArray(expectedAnswer)) {
      expectedAnswer.forEach(() => {
        this.inputs.push({
          type: "input",
          content: {
            answer: expectedAnswer,
            tolerance,
            label: inputLabel,
            labelPos: labelPosition,
          },
        });
      });
    } else {
      this.inputs.push({
        type: "input",
        content: {
          answer: expectedAnswer,
          tolerance,
          label: inputLabel,
          labelPos: labelPosition,
        },
      });
    }
  }
}

export { InputGroup };
