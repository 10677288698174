import React from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";

const GeneralModal = ({ show, onHide, title, text }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
    </Modal>
  );
};

GeneralModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.func.isRequired,
};

export default GeneralModal;
