import { cleaner } from "./cleaner";
import { Graph } from "../MCQuestion";
import { ArgumentError } from "../MCError";
/**
 * First Principals.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * const trig = new MCMaths.Trig(2, "sin");
 * const FP  = new MCMaths.FirstPrincipals(trig);
 */

class FirstPrincipals {
  /**
   * Creates a n
   *
   * @param {function} func The function for x.
   * @param {number} coordinate the coordinate to derive at. Leave as 'none' for function derivatives.
   *
   * @returns {object} Returns first principals object
   */
  constructor(func, coordinate = "none") {
    this.func = func;
    this.coordinate = coordinate;
  }

  /**
   * Returns the derivative of the function
   *
   * @returns {number | string} Depedning if coordanate if defined.
   */
  derivative() {
    if (this.coordinate === "none") {
      return this.func.derivative().toString();
    }
    return this.func.derivative().evaluate(this.coordinate);
  }

  derivativeWorking() {
    const output = [
      "HEADING Derivative by First Principals:",
      `$\\displaystyle \\frac{d}{dx}\\left(${this.func.toString()}\\right) = \\lim_{h\\to 0} ${this.toString()} $`,
      `$=${cleaner(`${this.derivative()}`)}$`,
    ];
    return output;
  }

  /**
   * Prints the product of functions as a string.
   * @returns {string}
   */
  toString() {
    if (this.coordinate === "none") {
      return `\\frac{\\left(${this.func
        .toString()
        .replace(
          /x/g,
          "(x+h)"
        )}\\right) - \\left(${this.func.toString()}\\right)}{h}`;
    }

    return `\\frac{\\left(${this.func
      .toString()
      .replace(
        /x/g,
        `(${this.coordinate}+h)`
      )}\\right) - \\left(${this.func
      .toString()
      .replace(/x/g, `(${this.coordinate})`)}\\right)}{h}`;
  }

  /**
   * Returns graph
   *
   * @param {Number} [Range = 5] The range of the axis to plon on.
   * @param {Number} [h = 1] The value of h to use when plotting
   *
   * @returns {Graph}
   */
  graph(range = 5, h = 1) {
    if (this.coordinate === "none") {
      throw new ArgumentError(`Coordinate needs to be defined.`);
    }
    const stepSize = Math.ceil(range / 10);
    const graph = new Graph(range, -range, range, -range, stepSize, stepSize);

    const j = this.func;
    function f1(x) {
      return j.evaluate(x);
    }
    const f = f1;
    const x1 = this.coordinate;
    const g = function g1(x) {
      return ((f1(x1 + h) - f1(x1)) / h) * (x - x1) + f1(x1);
    };

    graph.plot(f, -range, range);
    graph.plot(g, -range, range);
    return graph;
  }
}

export { FirstPrincipals };
