import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";

const MathString = ({ str, fontSize }) => {
  const delimiter = "$";
  const [strs, setStrs] = useState([]);
  const [fsize, setFsize] = useState(1);
  // const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  // const mobileView = () => {
  //   const inp = [...str];
  //   const arr = [];
  //   let temp = "";
  //   let nextOpBreak = false;
  //   for (let i = 0; i < inp.length; i += 1) {
  //     if (temp.length > 10 && !nextOpBreak) {
  //       nextOpBreak = true;
  //     }
  //     if (nextOpBreak) {
  //       if (inp[i] === delimiter) {
  //         if (temp.charAt(0) === delimiter) {
  //           temp += delimiter;
  //           arr.push(temp);
  //           temp = "";
  //           nextOpBreak = false;
  //         } else {
  //           arr.push(temp);
  //           temp = delimiter;
  //         }
  //       } else if (inp[i] === "+" || inp[i] === "-") {
  //         temp += inp[i];
  //         temp += delimiter;
  //         arr.push(temp);
  //         temp = `${delimiter}`;
  //         nextOpBreak = false;
  //       } else if (inp[i] === "\\") {
  //         if (
  //           typeof inp[i + 1] !== "undefined" &&
  //           typeof inp[i + 2] !== "undefined" &&
  //           typeof inp[i + 3] !== "undefined"
  //         ) {
  //           if (
  //             inp[i + 1] === "t" &&
  //             inp[i + 2] === "i" &&
  //             inp[i + 3] === "m"
  //           ) {
  //             if (
  //               typeof inp[i + 4] !== "undefined" &&
  //               typeof inp[i + 5] !== "undefined" &&
  //               inp[i + 4] === "e" &&
  //               inp[i + 5] === "s"
  //             ) {
  //               temp += "\\times";
  //               temp += delimiter;
  //               arr.push(temp);
  //               temp = `${delimiter}`;
  //               i += 6;
  //             }
  //           } else if (
  //             inp[i + 1] === "d" &&
  //             inp[i + 2] === "i" &&
  //             inp[i + 3] === "v"
  //           ) {
  //             temp += "\\div";
  //             temp += delimiter;
  //             arr.push(temp);
  //             temp = `${delimiter}`;
  //             i += 4;
  //           } else {
  //             temp += inp[i];
  //           }
  //         } else {
  //           temp += inp[i];
  //         }
  //       } else {
  //         temp += inp[i];
  //       }
  //     } else if (inp[i] === delimiter) {
  //       if (temp.charAt(0) === delimiter) {
  //         temp += delimiter;
  //         arr.push(temp);
  //         temp = "";
  //         nextOpBreak = false;
  //       } else {
  //         arr.push(temp);
  //         temp = delimiter;
  //       }
  //     } else {
  //       temp += inp[i];
  //     }
  //   }
  //   arr.push(temp);
  //   setStrs(arr);
  // };

  // const largerView = () => {
  //   const inp = [...str];
  //   const arr = [];
  //   let temp = "";
  //   let iterationsToSkip = 0;
  //   for (let i = 0; i < inp.length; i += 1) {
  //     if (iterationsToSkip === 0) {
  //       if (inp[i] === delimiter) {
  //         if (typeof inp[i + 1] !== "undefined" && inp[i + 1] === delimiter) {
  //           temp += " ";
  //           iterationsToSkip = 1;
  //         } else if (
  //           typeof inp[i + 1] !== "undefined" &&
  //           typeof inp[i + 2] !== "undefined" &&
  //           inp[i + 1] === " " &&
  //           inp[i + 2] === delimiter
  //         ) {
  //           temp += " ";
  //           iterationsToSkip = 2;
  //         } else if (temp.charAt(0) === delimiter) {
  //           temp += delimiter;
  //           arr.push(temp);
  //           temp = "";
  //         } else {
  //           arr.push(temp);
  //           temp = delimiter;
  //         }
  //       } else {
  //         temp += inp[i];
  //       }
  //     } else {
  //       iterationsToSkip -= 1;
  //     }
  //   }
  //   arr.push(temp);
  //   setStrs(arr);
  // };

  useEffect(() => {
    if (fontSize) {
      setFsize(fontSize);
    }
  }, [fontSize]);

  useEffect(() => {
    const inp = [...str];
    const arr = [];
    let temp = "";
    let iterationsToSkip = 0;
    for (let i = 0; i < inp.length; i += 1) {
      if (iterationsToSkip === 0) {
        if (inp[i] === delimiter) {
          if (typeof inp[i + 1] !== "undefined" && inp[i + 1] === delimiter) {
            temp += " ";
            iterationsToSkip = 1;
          } else if (
            typeof inp[i + 1] !== "undefined" &&
            typeof inp[i + 2] !== "undefined" &&
            inp[i + 1] === " " &&
            inp[i + 2] === delimiter
          ) {
            temp += " ";
            iterationsToSkip = 2;
          } else if (temp.charAt(0) === delimiter) {
            temp += delimiter;
            arr.push(temp);
            temp = "";
          } else {
            arr.push(temp);
            temp = delimiter;
          }
        } else {
          temp += inp[i];
        }
      } else {
        iterationsToSkip -= 1;
      }
    }
    arr.push(temp);
    setStrs(arr);
  }, [str]);

  // const resized = () => {
  //   const shouldRerender =
  //     (currentWidth < 760 && window.innerWidth > 760) ||
  //     (currentWidth > 760 && window.innerWidth < 760);
  //   if (shouldRerender) {
  //     setCurrentWidth(window.innerWidth);
  //     if (window.innerWidth > 760) {
  //       largerView();
  //     } else {
  //       largerView();
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", resized);

  //   return () => window.removeEventListener("resize", resized);
  // });

  return (
    <>
      {strs.map((s, i) => {
        if (s.charAt(0) === delimiter && s.charAt(s.length - 1) === delimiter) {
          return (
            <span style={{ fontSize: `${fsize}em` }} key={i}>
              <InlineMath key={i} math={s.substring(1, s.length - 1)} />
            </span>
          );
        }
        return <span key={i}>{s}</span>;
      })}
    </>
  );
};

MathString.propTypes = {
  str: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
};

MathString.defaultProps = {
  fontSize: 1,
};

export default MathString;
