import { ProductRule } from "./ProductRule";

/**
 * Quotient Rule.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Input two functions, will return the derivative of their quotient.
 * // I.e. for $\frac{2e^{3x}}{\\sin(x)}$, you would use two functions, $2e^{3x}$ and $\\sin(x)$.
 * let p1 = new Exponential(2,'e',3)
 * let s1 = new Trig(1,'sin',1,1)
 * let cr = new QuotientRule(p1,s1)
 */

class QuotientRule {
  constructor(func1, func2) {
    this.func1 = func1;
    this.func2 = func2;
  }

  /**
   * Prints the Quotient of functions as a string.
   * @returns {string}
   */
  toString() {
    return `\\frac{${this.func1.toString()}}{${this.func2.toString()}}`;
  }

  /**
   * Returns the derivative of the Quotient of functions as a string.
   * @returns {string}
   * @example
   * let p1 = new Exponential(2,'e',3)
   * let s1 = new Trig(1,'sin',1,1,'h')
   * let cr = new ChainRule(p1,s1)
   * console.log('Chain Derivative: $'+cr.derivative()+'$')
   */
  derivative() {
    return `\\frac{${new ProductRule(
      this.func1.derivative(),
      this.func2
    ).toString()}-${new ProductRule(
      this.func1,
      this.func2.derivative()
    ).toString()}}{\\left(${this.func2.toString()}\\right)^2}`;
  }

  /**
   * Returns the working for the rule
   *
   * @returns {string[]}
   */
  derivativeWorking() {
    const output = [
      `HEADING Using the quotient rule:`,
      `$f(x)=${this.func1.toString()}$`,
      `$f'(x)=${this.func1.derivative().toString()}$`,
      `$g(x)=${this.func2.toString()}$`,
      `$g'(x)=${this.func2.derivative().toString()}$`,
      `$\\displaystyle \\left(\\frac{f(x)}{g(x)}\\right)'=\\frac{f'(x)g(x)-f(x)g'(x)}{\\big(g(x)^2\\big)}$`,
      `$\\displaystyle =${this.derivative()}$`,
    ];
    return output;
  }

  /**
   * Evaluates the derivative
   *
   * @returns {number}
   */
  evaluate(x) {
    return (
      (this.func1.derivative().evaluate(x) * this.func2.evaluate(x) -
        this.func1.evaluate(x) * this.func2.derivative().evaluate(x)) /
      this.func2.evaluate(x) ** 2
    );
  }
}

export { QuotientRule };
