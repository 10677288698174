import React from "react";
import PropTypes from "prop-types";

const ProgressBar = ({ now }) => {
  return (
    <div className="progress" style={{ height: "8px" }}>
      <div
        style={{ width: `${now * 100}%`, backgroundColor: "#81bae3" }}
        className="progress-bar"
        role="progressbar"
        aria-valuenow={now}
        aria-valuemin="0"
        aria-valuemax="1"
        aria-label="MCM Score"
      />
    </div>
  );
};

ProgressBar.propTypes = {
  now: PropTypes.number.isRequired,
};

export default ProgressBar;
