import { ArgumentError, IndexError } from "../MCError";
import { Graph, Image } from ".";
/**
 * A class that allows the correct packaging of answers in MCQs.
 * @memberof module:MCQuestion
 * @author Declan Clark <dec@dclark.dev>
 * @since 0.1.0
 * @example
 * const ans1 = new MCQuestion.MultipleChoiceAnswer();
 */
class MultipleChoiceAnswer {
  /**
   * Creates a new multiple choice answer.
   */
  constructor() {
    this.sequence = [];
  }

  /**
   * @summary Returns the sequence of elements to include in the answer.
   *
   * @since 0.1.0
   * @returns {Object} Answer elements.
   */
  getSequence() {
    return this.sequence;
  }

  /**
   * @summary Adds a paragraph to the answer.
   *
   * @description Creates a paragraph object and adds it to the answer.
   *              Font size can be changed with the optional fontSize parameter.
   *
   * @since 0.1.0
   *
   * @param   {string}                    paragraph    The string for the paragraph being added.
   * @param   {number}                    [fontSize]   The size of the font when displaying the paragraph.
   * @throws  {ArgumentError}                          Paragraph string is required.
   * @throws  {TypeError}                              Parameters were the wrong type.
   *
   * @example
   * const ans1 = new MCQuestion.MultipleChoiceAnswer();
   * ans1.addParagraph('Some text here...', 1);
   */
  addParagraph(paragraph, fontSize) {
    if (typeof paragraph === "undefined") {
      throw new ArgumentError(`paragraph is required`);
    }
    if (typeof paragraph !== "string") {
      throw new TypeError(
        `expected a string but got ${typeof paragraph} instead`
      );
    }
    if (typeof fontSize !== "undefined") {
      if (typeof fontSize !== "number") {
        throw new TypeError(
          `expected a number but got ${typeof fontSize} instead`
        );
      }
      if (paragraph.includes("HEADING")) {
        const par = paragraph.replace("HEADING", "").trim();
        this.sequence.push({
          type: "paragraph",
          content: { p: par, size: fontSize, heading: true },
        });
      } else {
        this.sequence.push({
          type: "paragraph",
          content: { p: paragraph, size: fontSize },
        });
      }
    } else if (paragraph.includes("HEADING")) {
      const par = paragraph.replace("HEADING", "").trim();
      this.sequence.push({
        type: "paragraph",
        content: { p: par, heading: true },
      });
    } else {
      this.sequence.push({
        type: "paragraph",
        content: { p: paragraph },
      });
    }
  }

  /**
   * @summary Adds multiple paragraphs to the answer.
   *
   * @description Takes an array of strings and adds each as a new paragraph. All paragraphs
   *              will have the given font size.
   *
   * @since 0.1.0
   *
   * @param {string[]}                    paragraphs  The paragraphs to be added, packed in an array.
   * @param {number}                      [fontSize]  The font size (in em) to be applied to paragraphs.
   * @throws  {ArgumentError}                          Paragraph strings are required.
   * @throws  {TypeError}                              Parameters were the wrong type.
   * @throws  {IndexError}                             Array was empty.
   *
   * @example
   * const ans1 = new MCQuestion.MultipleChoiceAnswer();
   * const someArrayOfText = ['hello', 'world'];
   * ans1.addMultipleParagraphs(someArrayOfText, 2);
   */
  addMultipleParagraphs(paragraphs, fontSize) {
    if (paragraphs.length === 0) {
      throw new IndexError("expected non-empty array of strings");
    }
    for (let i = 0; i < paragraphs.length; i += 1) {
      this.addParagraph(paragraphs[i], fontSize);
    }
  }

  /**
   * @summary Adds a heading to the answer.
   *
   * @description Adds a paragraph to the answer which is then styled as a heading.
   *
   * @since 0.1.0
   *
   * @param {string}                  heading     The content of the heading.
   * @param {number}                  [fontSize]  Font size of the heading (in em).
   * @throws  {ArgumentError}                          Heading string is required.
   * @throws  {TypeError}                              Parameters were the wrong type.
   *
   * @example
   * const question = new MCQuestion.ExamQuestion();
   * question.addHeading('Something to be styled as a heading.', 2);
   */
  addHeading(heading, fontSize) {
    const p = `HEADING ${heading}`;
    this.addParagraph(p, fontSize);
  }

  /**
   * @summary Adds an image to the answer.
   *
   * @description Takes an image object and adds it and its overlays to the answer.
   *
   * @since 0.1.0
   *
   * @param   {Image}                     image   The image to add.
   * @throws  {ArgumentError}                     Required arguments were not supplied.
   * @throws  {TypeError}                         Arguments were not of the correct type.
   * @example
   * // adding the image
   * ans1.addImage(myImage);
   */
  addImage(image) {
    if (typeof image === "undefined") {
      throw new ArgumentError(`must supply required arguments`);
    }
    if (!(image instanceof Image)) {
      throw new TypeError(
        `expected an Image object but got ${typeof image} instead`
      );
    }
    this.sequence.push({
      type: "image",
      content: {
        src: image.getSrc(),
        measure: image.getMeasure(),
        size: image.getSize(),
        overlays: image.getOverlays(),
      },
    });
  }

  /**
   * @summary Adds a graph to the answer.
   *
   * @description Takes a graph object and adds it to the answer.
   *
   * @since 0.1.0
   *
   * @param   {Graph}                     graph   The graph to add.
   * @throws  {ArgumentError}                     Required arguments were not supplied.
   * @throws  {TypeError}                         Arguments were not of the correct type.
   * @example
   * // adding the graph
   * ans1.addGraph(myGraph);
   */
  addGraph(graph) {
    if (typeof graph === "undefined") {
      throw new ArgumentError(`must supply required arguments`);
    }
    if (!(graph instanceof Graph)) {
      throw new TypeError(
        `expected a Graph object but got ${typeof graph} instead`
      );
    }
    this.sequence.push({
      type: "graph",
      content: {
        axis: graph.getAxisValues(),
        funcs: graph.getFunctions(),
        steps: graph.getSteps(),
      },
    });
  }
}

export { MultipleChoiceAnswer };
