import React from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import RoundedBtn from "./RoundedBtn";

const ContentCoveredSaveModal = ({ show, onHide, onAction }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Save Changes
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You have unsaved changes! Do you want to save them now?
      </Modal.Body>
      <Modal.Footer>
        <RoundedBtn text="Exit" onClick={onHide} />
        <RoundedBtn
          text="Save Changes"
          onClick={onAction}
          variant="success"
          width={8}
        />
      </Modal.Footer>
    </Modal>
  );
};

ContentCoveredSaveModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default ContentCoveredSaveModal;
