import { NumberSetError } from "../MCError";
import { Fraction } from "./Fraction";
import { cleaner } from "./cleaner";
import { Polynomial } from "./Polynomial";
/**
 * Class Logarithm Objects.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Creates $3ln(x)$
 * const l1 = new log(3)
 * // Creates $log_2(3x+4)$
 * const l1 = new log(1,2,3,'x',4)
 */

class Logarithm {
  /**
   * Creates a log in the form a*log_b(cx+d) where:
   * a - coefficient
   * b - base
   * c - variable coefficient
   * x - variable
   * d - offset
   *
   * @param {number} [coefficient = 1]            The coefficient of the log object.
   * @param {number | string} [base = 'e']        The base of the log, use 'e' for the natural logarithm.
   * @param {number} [variableCoefficient = 1]    The coefficient of the variable of the log object.
   * @param {string} [variable = 'x']             The variable of the log object.
   * @param {string} [offset = 0]                 The value of any constant added to the variable.
   * @param {bool} [deriv = false]                If frue, is 1/x object, used for derivative.evaluate()
   *
   * @returns {Logarithm}  A new log object.
   * @throws {TypeError}   The argument should be number/strings as specified.
   */
  constructor(
    coefficient = 1,
    base = "e",
    variableCoefficient = 1,
    variable = "x",
    offset = 0,
    deriv = false
  ) {
    if (typeof coefficient !== "number") {
      throw new NumberSetError(`Expected a number, got ${coefficient} instead`);
    }
    if (typeof base !== "number" && base !== "e") {
      throw new NumberSetError(`Expected a number, got ${base} instead`);
    }
    if (typeof variableCoefficient !== "number") {
      throw new NumberSetError(
        `Expected a number, got ${variableCoefficient} instead`
      );
    }
    if (typeof variable !== "string") {
      throw new NumberSetError(`Expected a string, got ${variable} instead`);
    }
    if (typeof offset !== "number") {
      throw new NumberSetError(`Expected a number, got ${offset} instead`);
    }
    this.coefficient = coefficient;
    this.base = base;
    this.variableCoefficient = variableCoefficient;
    this.variable = variable;
    this.offset = offset;
    this.deriv = deriv;
  }

  /**
   * Outputs the log object to a string
   *
   * @returns {string} The log object as a string.
   *
   * @example
   * //Print $log_2(3x+4)$
   * let l1 = new Logarithm(1,2,3,'x',4)
   * console.log(l1.toString())
   */
  toString() {
    if (this.deriv === true) {
      return `\\frac{1}{${this.variable}}`;
    }
    let out = "";
    if (this.coefficient !== 1) {
      if (this.coefficient === -1) {
        out += "-";
      } else {
        out += new Fraction(this.coefficient).toString();
      }
    }
    if (this.base !== "e") {
      out += `log_{${this.base}}`;
    } else {
      out += "\\ln";
    }
    out += "\\left(";
    if (this.variableCoefficient !== 1) {
      if (this.variableCoefficient === -1) {
        out += "-";
      } else {
        out += new Fraction(this.variableCoefficient).toString();
      }
    }
    out += this.variable;
    if (this.offset < 0) {
      out += new Fraction(this.offset).toString();
    }
    if (this.offset > 0) {
      out += `+${new Fraction(this.offset).toString()}`;
    }
    out += "\\right)";

    return out;
  }

  /**
   * Evaluates the log object at a given value.
   *
   * @param {number} The value to evalue the log at.
   * @returns {number} The value of the log object at the given value.
   * @throws {TypeError}   The value should be a number.
   *
   * @example
   * //Evaluate $log_2(3x+4)$ at $x=3$
   * let l1 = new Logarithm(1,2,3,'x',4)
   * console.log(l1.evaluate(3))
   */
  evaluate(value) {
    if (typeof value !== "number") {
      throw new NumberSetError(`Expected a number, got ${value} instead`);
    }
    if (this.deriv === true) {
      return 1 / value;
    }
    if (this.base === "e") {
      return (
        this.coefficient *
        Math.log(this.variableCoefficient * value + this.offset)
      );
    }
    return (
      (this.coefficient *
        Math.log(this.variableCoefficient * value + this.offset)) /
      Math.log(this.base)
    );
  }

  /**
   * Returns the derivative of the log.
   *
   * @returns {string} The derivative of the log as a string. (Note - should be updated in future versions to add compatibility with partial fractions).
   * @example
   * //Print the derivative of $log_2(3x+4)$
   * let l1 = new Logarithm(1,2,3,'x',4)
   * console.log(l1.derivative())
   */
  derivative() {
    if (this.base === "e") {
      if (this.offset === 0) {
        if (this.coefficient !== 1) {
          return `\\frac{${this.coefficient}}{${this.variable}}`;
        }
        return new Logarithm(1, "e", 1, "x", 0, true);
      }
      if (this.offset > 0) {
        return `\\frac{${this.coefficient * this.variableCoefficient}}{${
          this.variableCoefficient
        }${this.variable}+${this.offset}}`;
      }
      if (this.offset < 0) {
        return `\\frac{${this.coefficient * this.variableCoefficient}}{${
          this.variableCoefficient
        }${this.variable}${this.offset}}`;
      }
    } else {
      if (this.offset === 0) {
        return `\\frac{${this.coefficient}}{${this.variable}ln\\left(${this.base}\\right)`;
      }
      if (this.offset > 0) {
        return `\\frac{${
          this.coefficient * this.variableCoefficient
        }}{ln\\left(${this.base}\\right)\\left(${this.variableCoefficient}${
          this.variable
        }+${this.offset}\\right)}`;
      }
      if (this.offset < 0) {
        return `\\frac{${
          this.coefficient * this.variableCoefficient
        }}{ln\\left(${this.base}\\right)\\left(${this.variableCoefficient}${
          this.variable
        }${this.offset}\\right)}`;
      }
    }
    return "";
  }

  /**
   * Solves the equations value = this log obejct.
   *
   * @param {number} value the value to solve for.
   *
   * @returns {number} The value the satisfys this equation.
   */
  solve(value) {
    let { base } = this;
    if (base === "e") {
      base = Math.E;
    }
    return (
      (base ** (value / this.coefficient) - this.offset) /
      this.variableCoefficient
    );
  }

  /**
   * Shows working for solving value = this log obejct.
   *
   * @param {number} value the value to solve for.
   *
   * @returns {string[]} The lines of working.
   */
  solveWorking(value) {
    const out = [];
    out.push(cleaner(`$${this.toString()}=${value}$`));
    if (this.coefficient !== 0) {
      out.push(
        cleaner(
          `$${new Logarithm(
            1,
            this.base,
            this.variableCoefficient,
            this.variable,
            this.offset
          ).toString()}=${value / this.coefficient}$`
        )
      );
    }
    out.push(
      cleaner(
        `$${new Polynomial(
          [this.variableCoefficient, this.offset],
          this.variable
        ).toString()}=${this.base}^{${value / this.coefficient}}$`
      )
    );
    out.push(cleaner(`$\\therefore ${this.variable}=${this.solve(value)}$`));
    return out;
  }

  functionString() {
    let tempBase = this.base;
    if (tempBase === "e") {
      tempBase = Math.E;
    }
    if (this.deriv === false) {
      return `${this.coefficient}*Math.log(${this.variableCoefficient}*x+${this.offset})/Math.log(${tempBase})`;
    }

    return `1/x`;
  }
}

export { Logarithm };
