import { cleaner } from "./cleaner";
import { randomInt } from "../MCRandom";
import { Graph } from "../MCQuestion";
import { ArgumentError } from "../MCError";

/**
 * Class creating Stats objects.
 * @memberof module:MCMaths
 * @author James Pickersgill
 */

class Stats {
  /**
   * Creates an stats object.
   *
   * @param {number[]} dataX The data to use for means/medians/etc.
   * @param {number[]} dataY The data to use for regression calculations.
   *
   * @returns {Stats} A new stats object
   */
  constructor(dataX, dataY = []) {
    this.dataX = dataX;
    this.dataY = dataY;
  }

  /**
   * Outputs the x Data as a string.
   *
   * @returns {string} The polynomial wrote as a string.
   */
  toString() {
    let out = this.dataX[0];
    for (let i = 1; i < this.dataX.length; i += 1) {
      out += `,${this.dataX[i]}`;
    }
    return cleaner(out);
  }

  /**
   * Outputs the y Data as a string.
   *
   * @returns {string} The polynomial wrote as a string.
   */
  toStringY() {
    let out = this.dataY[0];
    for (let i = 1; i < this.dataY.length; i += 1) {
      out += `,${this.dataY[i]}`;
    }
    return cleaner(out);
  }

  /**
   * Returns the sum of the x data
   *
   * @returns {number}
   */
  sx() {
    return this.dataX.reduce((a, b) => a + b, 0);
  }

  /**
   * Returns the sum of the y data
   *
   * @returns {number}
   */
  sy() {
    return this.dataY.reduce((a, b) => a + b, 0);
  }

  /**
   * Returns the sum of the square of the x data
   *
   * @returns {number}
   */
  sx2() {
    return this.dataX.reduce((a, b) => a + b ** 2, 0);
  }

  /**
   * Returns the sum of the square of the y data
   *
   * @returns {number}
   */
  sy2() {
    return this.dataY.reduce((a, b) => a + b ** 2, 0);
  }

  /**
   * Returns the sum of the xy
   *
   * @returns {number}
   */
  sxy() {
    let sum = 0;
    for (let i = 0; i < this.nx(); i += 1) {
      sum += this.dataX[i] * this.dataY[i];
    }
    return sum;
  }

  /**
   * Returns the length of the x data
   *
   * @returns {number}
   */
  nx() {
    return this.dataX.length;
  }

  /**
   * Returns the length of the y data
   *
   * @returns {number}
   */
  ny() {
    return this.dataY.length;
  }

  /**
   * Returns the mean of the x data
   *
   * @returns {number}
   */
  mean() {
    return this.sx() / this.nx();
  }

  /**
   * Returns the mean of the y data
   *
   * @returns {number}
   */
  meanY() {
    return this.sy() / this.ny();
  }

  /**
   * Returns the working of the mean of the x data
   *
   * @returns {number[]}
   */
  meanWorking() {
    return [
      cleaner(
        `$\\displaystyle \\overline{X} = \\frac{${this.sx()}}{${this.nx()}}$`
      ),
      cleaner(`$=${this.mean()}$`),
    ];
  }

  /**
   * Returns a stats object with the x data sorted. Ignores Y-data.
   *
   * @returns {number}
   */
  sort() {
    return new Stats(
      this.dataX.slice().sort(function s(a, b) {
        return a - b;
      })
    );
  }

  /**
   * Returns the median of the x data
   *
   * @returns {number}
   */
  median() {
    if (this.dataX.length % 2 === 1) {
      return this.sort().dataX[(this.dataX.length + 1) / 2 - 1];
    }
    return (
      (this.sort().dataX[this.dataX.length / 2] +
        this.sort().dataX[this.dataX.length / 2 - 1]) /
      2
    );
  }

  /**
   * Creates a random sample of X-data.
   *
   * @param {number} [n=10]      Number of data points to generate.
   * @param {number} [lower=0]   Lower bound of data to be generated.
   * @param {number} [upper=10]  Upper bound of data to be generated.
   * @param {number} [divisor=1] Divides the data by this. Can be used if decimal data is wanted.
   *
   * @returns {Stats}
   */
  static randomSample(n = 10, lower = 0, upper = 10, divisor = 1) {
    const dx = [];
    for (let i = 0; i < n; i += 1) {
      dx.push(randomInt(lower, upper) / divisor);
    }
    return new Stats(dx);
  }

  /**
   * Returns the lower quartile of the x data
   *
   * @returns {number}
   */

  q1() {
    return new Stats(
      this.dataX.sort().slice(0, Math.floor(this.nx() / 2))
    ).median();
  }

  /**
   * Returns the upper quartile of the x data
   *
   * @returns {number}
   */

  q3() {
    return new Stats(
      this.dataX.sort().slice(Math.ceil(this.nx() / 2)),
      this.nx()
    ).median();
  }

  /**
   * Returns the inter quartile range of the x data
   *
   * @returns {number}
   */

  iqr() {
    return this.q3() - this.q1();
  }

  /**
   * Returns the mode of the x data
   *
   * @returns {number}
   */

  mode() {
    const m = (arr) => {
      if (
        arr.filter((x, index) => arr.indexOf(x) === index).length === arr.length
      )
        return arr;
      return m(
        arr
          .sort((x, index) => x - index)
          .map((x, index) => (arr.indexOf(x) !== index ? x : null))
          .filter((x) => x !== null)
      );
    };
    return m(this.dataX);
  }

  /**
   * Returns the variance of the x data
   *
   * @returns {number}
   */
  variance() {
    return this.sx2() / this.nx() - this.mean() ** 2;
  }

  /**
   * Returns the lower Standard deviation of the x data
   *
   * @returns {number}
   */
  standardDeviation() {
    return Math.sqrt(this.variance());
  }

  /**
   * Find $b$ in the regression formula $a+bx$.
   *
   * @returns {number}
   */
  b() {
    return (
      (this.nx() * this.sxy() - this.sx() * this.sy()) /
      (this.nx() * this.sx2() - this.sx() ** 2)
    );
  }

  /**
   * Find $a$ in the regression formula $a+bx$.
   *
   * @returns {number}
   */
  a() {
    return (this.sy() - this.b() * this.sx()) / this.nx();
  }

  /**
   * Returns the Sum of Squared Deviations, sum ($x-x$ bar)$^2$
   *
   * @returns {number}
   */
  sumSD() {
    let sum = 0;
    for (let i = 0; i < this.nx(); i += 1) {
      sum += (this.dataX[i] - this.mean()) ** 2;
    }
    return sum;
  }

  /* boxPlot(){
  	const stepSizes = [0.01,0.02,0.05,0.1,0.2,0.5,1,2,5,10,20,50,100]
  	const minX = Math.min(...this.dataX)
  	const maxX = Math.max(...this.dataX)
  	const diff = maxX-minX
  	let xStep = stepSizes[0]
  	let i = 0
  	while(6*xStep < diff && i !== stepSizes.length-1){
  		i += 1
  		xStep = stepSizes[i]
  	}
  	const minY = 0
  	const maxY = 10
  	
  } */

  /**
   * Creates a histogram with specified number of equal width class.
   *
   * @param {number} [n=5]			  Numbers of bins.
   *
   * @returns {Graph}
   */
  histogram(n = 5) {
    const minX = Math.min(...this.dataX);
    const maxX = Math.max(...this.dataX);
    const step = (maxX - minX) / n;
    const xs = [];
    const ys = [];

    for (let i = 0; i < n; i += 1) {
      xs.push(Math.round(1000 * (minX + step * i)) / 1000);
      ys.push(0);
    }
    for (let i = this.dataX.length - 1; i >= 0; i -= 1) {
      const k = Math.floor((n - 1) * ((this.dataX[i] - minX) / (maxX - minX)));
      ys[k] += 1;
    }

    const stepSizes = [
      0.01,
      0.02,
      0.05,
      0.1,
      0.2,
      0.5,
      1,
      2,
      5,
      10,
      20,
      50,
      100,
    ];
    let xStep = stepSizes[0];
    let yStep = stepSizes[0];
    let i = 0;
    while (6 * xStep < maxX - minX && i !== stepSizes.length - 1) {
      i += 1;
      xStep = stepSizes[i];
    }

    let j = 0;
    while (6 * yStep < Math.max(...ys) && j !== stepSizes.length - 1) {
      j += 1;
      yStep = stepSizes[j];
    }

    xs.push(xs[xs.length - 1] + step);
    ys.push(ys[ys.length - 1]);

    const gx0 = Math.ceil(maxX / xStep) * xStep;
    const gx1 = Math.min(Math.floor(minX / xStep) * xStep, 0);

    const gy0 = Math.ceil(Math.max(...ys) / yStep) * yStep;
    const gy1 = Math.floor(-Math.max(...ys) / (8 * yStep)) * yStep;

    const grp = new Graph(gx0, gx1, gy0, gy1, xStep, yStep);
    for (let i2 = 0; i2 < n; i2 += 1) {
      grp.addParametric(
        `${xs[i2]} + 0*t`,
        `t * ${Math.max(ys[i2], ys[i2 - 1])}`,
        0,
        1
      );
      grp.addParametric(`${xs[i2]} + ${step} * t`, `${ys[i2]}+ 0*t`, 0, 1);
    }

    grp.addParametric(`${maxX} + 0*t`, `t * ${ys[n]}`, 0, 1);
    grp.addParametric(`${minX} + 0*t`, `t * ${ys[0]}`, 0, 1);

    return grp;
  }

  /**
   * Creates a histogram with specified classes.
   *
   * @param {number[]} widths    	  Array of class boundaries.
   * @param {string} [round='down']   Either 'up' or 'down', which way to count values on class boundaries.
   *
   * @returns {Graph}
   */
  histogramWidths(widths, round = "down") {
    const xs = widths;
    const ys = new Array(xs.length).fill(0);

    for (let i = this.dataX.length - 1; i >= 0; i -= 1) {
      let k = 1;
      if (this.dataX[i] >= xs[0] && round === "up") {
        while (xs[k] <= this.dataX[i] && k < xs.length) {
          k += 1;
        }
        if (k < xs.length) {
          ys[k - 1] += 1 / (xs[k] - xs[k - 1]);
        }
      }
      if (this.dataX[i] >= xs[0] && round === "down") {
        while (xs[k] < this.dataX[i] && k < xs.length) {
          k += 1;
        }
        if (k < xs.length) {
          ys[k - 1] += 1 / (xs[k] - xs[k - 1]);
        }
      }
    }

    const minX = Math.min(...xs);
    const maxX = Math.max(...xs);

    const stepSizes = [
      0.01,
      0.02,
      0.05,
      0.1,
      0.2,
      0.5,
      1,
      2,
      5,
      10,
      20,
      50,
      100,
    ];
    let xStep = stepSizes[0];
    let yStep = stepSizes[0];
    let i = 0;
    while (6 * xStep < maxX - minX && i !== stepSizes.length - 1) {
      i += 1;
      xStep = stepSizes[i];
    }

    let j = 0;
    while (6 * yStep < Math.max(...ys) && j !== stepSizes.length - 1) {
      j += 1;
      yStep = stepSizes[j];
    }

    const gx0 = Math.ceil(maxX / xStep) * xStep;
    const gx1 = Math.min(Math.floor(minX / xStep) * xStep, 0);

    const gy0 = Math.ceil(Math.max(...ys) / yStep) * yStep;
    const gy1 = Math.floor(-Math.max(...ys) / (8 * yStep)) * yStep;

    const grp = new Graph(gx0, gx1, gy0, gy1, xStep, yStep);

    grp.addParametric(`${minX} + 0*t`, `t * ${ys[0]}`, 0, 1);
    grp.addParametric(`${minX} + ${xs[1] - xs[0]}*t`, `t *0 + ${ys[0]}`, 0, 1);

    for (let i2 = 1; i2 < xs.length; i2 += 1) {
      const h = Math.max(ys[i2], ys[i2 - 1]);
      grp.addParametric(
        `${xs[i2]} + ${xs[i2 + 1] - xs[i2]}*t`,
        `t *0 + ${ys[i2]}`,
        0,
        1
      );
      grp.addParametric(`${xs[i2]} + 0*t`, `t * ${h}`, 0, 1);
    }

    grp.addParametric(`${maxX} + 0*t`, `t * ${ys[xs.length]}`, 0, 1);
    // grp.addParametric(`${xs[xs.length-1]} + ${(xs[xs.length]-xs[xs.length-1])}*t`, `t *0 + ${ys[xs.length]}`, 0, 1);

    return grp;
  }

  scatter(regLine = false) {
    const minX = Math.min(...this.dataX, 0);
    const maxX = Math.max(...this.dataX, 0);
    const minY = Math.min(...this.dataY, 0);
    const maxY = Math.max(...this.dataY, 0);

    const stepSizes = [
      0.01,
      0.02,
      0.05,
      0.1,
      0.2,
      0.5,
      1,
      2,
      5,
      10,
      20,
      50,
      100,
    ];
    let xStep = stepSizes[0];
    let yStep = stepSizes[0];
    let i = 0;
    while (6 * xStep < maxX - minX && i !== stepSizes.length - 1) {
      i += 1;
      xStep = stepSizes[i];
    }

    let j = 0;
    while (6 * yStep < maxY - minY && j !== stepSizes.length - 1) {
      j += 1;
      yStep = stepSizes[j];
    }

    const gx0 = Math.ceil(maxX / xStep) * xStep;
    const gx1 = Math.min(Math.floor(minX / xStep) * xStep, 0);

    const gy0 = Math.ceil(maxY / yStep) * yStep;
    const gy1 = Math.min(Math.floor(minY / yStep) * yStep, 0);

    const grp = new Graph(gx0, gx1, gy0, gy1, xStep, yStep);

    for (let k = 0; k < this.dataX.length; k += 1) {
      grp.addCircle(this.dataX[k], this.dataY[k], 0.05);
    }

    if (regLine === true) {
      const temp = this;
      grp.plot(`${temp.a()} + ${temp.b()} * x`, minX, maxX);
    }

    return grp;
  }

  /**
   * Returns the critical value for a PMCC correlation hypothesis test, based on the table from a common exam board.
   *
   * @param {number} level  The level of significance.
   * @param {number} n   	The sample size.
   *
   * @returns {number}
   */
  static pmcc(level, n) {
    if (Math.round(n) !== n) {
      throw new ArgumentError(`Expected an integer, got ${n} instead`);
    }
    if (n < 4) {
      throw new ArgumentError(`$n$ must be greater than 3. $n=${n}$.`);
    }
    if (
      n > 30 &&
      n !== 40 &&
      n !== 50 &&
      n !== 60 &&
      n !== 70 &&
      n !== 80 &&
      n !== 90 &&
      n !== 100
    ) {
      throw new ArgumentError(`$n$ Over 30 must be multiple of 10. $n=${n}$.`);
    }
    if (n > 100) {
      throw new ArgumentError(`$n$ must be 100 or less. $n=${n}$.`);
    }
    if (
      level !== 0.1 &&
      level !== 0.05 &&
      level !== 0.025 &&
      level !== 0.01 &&
      level !== 0.005
    ) {
      throw new ArgumentError(
        `Level must be $\\in [0.1,0.05,0.025,0.01,0.005]$. level $=${level}$.`
      );
    }

    const table = [
      [0.8, 0.9, 0.95, 0.98, 0.99],
      [0.687, 0.8054, 0.8783, 0.9343, 0.9587],
      [0.6084, 0.7293, 0.8114, 0.8822, 0.9172],
      [0.5509, 0.6694, 0.7545, 0.8329, 0.8745],
      [0.5067, 0.6215, 0.7067, 0.7887, 0.8343],
      [0.4716, 0.5822, 0.6664, 0.7498, 0.7977],
      [0.4428, 0.5494, 0.6319, 0.7155, 0.7646],
      [0.4187, 0.5214, 0.6021, 0.6851, 0.7348],
      [0.3981, 0.4973, 0.576, 0.6581, 0.7079],
      [0.3802, 0.4762, 0.5529, 0.6339, 0.6835],
      [0.3646, 0.4575, 0.5324, 0.612, 0.6614],
      [0.3507, 0.4409, 0.514, 0.5923, 0.6411],
      [0.3383, 0.4259, 0.4973, 0.5742, 0.6226],
      [0.3271, 0.4124, 0.4821, 0.5577, 0.6055],
      [0.317, 0.4, 0.4683, 0.5425, 0.5897],
      [0.3077, 0.3887, 0.4555, 0.5285, 0.5751],
      [0.2992, 0.3783, 0.4438, 0.5155, 0.5614],
      [0.2914, 0.3687, 0.4329, 0.5034, 0.5487],
      [0.2841, 0.3598, 0.4227, 0.4921, 0.5368],
      [0.2774, 0.3515, 0.4133, 0.4815, 0.5256],
      [0.2711, 0.3438, 0.4044, 0.4716, 0.5151],
      [0.2653, 0.3365, 0.3961, 0.4622, 0.5052],
      [0.2598, 0.3297, 0.3882, 0.4534, 0.4958],
      [0.2546, 0.3233, 0.3809, 0.4451, 0.4869],
      [0.2497, 0.3172, 0.3739, 0.4372, 0.4785],
      [0.2451, 0.3115, 0.3673, 0.4297, 0.4705],
      [0.2407, 0.3061, 0.361, 0.4226, 0.4629],
      [0.207, 0.2638, 0.312, 0.3665, 0.4026],
      [0.1843, 0.2353, 0.2787, 0.3281, 0.361],
      [0.1678, 0.2144, 0.2542, 0.2997, 0.3301],
      [0.155, 0.1982, 0.2352, 0.2776, 0.306],
      [0.1448, 0.1852, 0.2199, 0.2597, 0.2864],
      [0.1364, 0.1745, 0.2072, 0.2449, 0.2702],
      [0.1292, 0.1654, 0.1966, 0.2324, 0.2565],
    ];

    let j = 999;
    if (level === 0.1) {
      j = 0;
    }
    if (level === 0.05) {
      j = 1;
    }
    if (level === 0.025) {
      j = 2;
    }
    if (level === 0.01) {
      j = 3;
    }
    if (level === 0.005) {
      j = 4;
    }

    let i = n;
    if (i < 31) {
      i -= 4;
    }
    if (i === 40) {
      i = 27;
    }
    if (i === 50) {
      i = 28;
    }
    if (i === 60) {
      i = 29;
    }
    if (i === 70) {
      i = 30;
    }
    if (i === 80) {
      i = 31;
    }
    if (i === 90) {
      i = 32;
    }
    if (i === 100) {
      i = 33;
    }

    return table[i][j];
  }
}

export { Stats };
