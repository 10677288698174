import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Tabs, Tab } from "react-bootstrap";
import PropTypes from "prop-types";
import RoundedBtn from "./RoundedBtn";
import PlainInput from "./PlainInput";
import Alert from "./Alert";
import Checkbox from "./Checkbox";

const AddRelationModal = ({
  show,
  onHide,
  onAction,
  email,
  emailChanged,
  danger,
  entity,
}) => {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirm: "",
    admin: false,
  });
  const [formErrors, setFormErrors] = useState({});
  const [tab, setTab] = useState("exists");
  const setField = (field, value) => {
    setFormValues({ ...formValues, [field]: value });
  };
  const validateEmail = (e) => {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(String(e).toLowerCase());
  };
  const validateForm = () => {
    let errs = {};
    const lower = /[a-z]/;
    const upper = /[A-Z]/;
    const number = /[0-9]/;
    if (formValues.firstName === "") {
      errs = { ...errs, firstName: "This field is required." };
    }
    if (formValues.lastName === "") {
      errs = { ...errs, lastName: "This field is required." };
    }
    if (!validateEmail(formValues.email)) {
      errs = {
        ...errs,
        email: "Please supply a valid email address.",
      };
    }
    if (!lower.test(formValues.password)) {
      errs = {
        ...errs,
        password: "Password should have at least one lowercase letter.",
      };
    }
    if (!upper.test(formValues.password)) {
      errs = {
        ...errs,
        password: "Password should have at least one uppercase letter.",
      };
    }
    if (!number.test(formValues.password)) {
      errs = {
        ...errs,
        password: "Password should have at least one number.",
      };
    }
    if (formValues.password.length < 8) {
      errs = {
        ...errs,
        password: "Password should contain at least 8 characters.",
      };
    }
    if (formValues.password !== formValues.confirm) {
      errs = { ...errs, confirm: "Passwords do not match." };
    }
    setFormErrors(errs);
    if (Object.keys(errs).length === 0) {
      return true;
    }
    return false;
  };

  const staffAdminView = () => {
    return (
      <Tabs
        defaultActiveKey="exists"
        id="staff-admin-staff-modal-tabs"
        onSelect={(k) => {
          setTab(k);
        }}
      >
        <Tab eventKey="exists" title="Existing Account">
          <div className="my-3">
            <PlainInput
              width={-1}
              label="Email"
              value={email}
              handleChange={emailChanged}
              onEnterPressed={onAction}
            />
            <b>Please check this email carefully.</b>
            <p>
              By adding this user you are giving them access to the data of all
              pupils in this school.
            </p>
          </div>
        </Tab>
        <Tab eventKey="new" title="Create Account">
          <form className="my-3">
            <div className="row">
              <div className="col-md-6">
                <PlainInput
                  label="First Name"
                  value={formValues.firstName}
                  handleChange={(v) => setField("firstName", v)}
                  width={-1}
                />
                {formErrors.firstName && (
                  <span style={{ color: "red" }}>{formErrors.firstName}</span>
                )}
              </div>
              <div className="col-md-6">
                <PlainInput
                  label="Surname"
                  value={formValues.lastName}
                  handleChange={(v) => setField("lastName", v)}
                  width={-1}
                />
                {formErrors.lastName && (
                  <span style={{ color: "red" }}>{formErrors.lastName}</span>
                )}
              </div>
              <div className="col-md-6">
                <PlainInput
                  label="Email"
                  value={formValues.email}
                  handleChange={(v) => setField("email", v)}
                  width={-1}
                />
                {formErrors.email && (
                  <span style={{ color: "red" }}>{formErrors.email}</span>
                )}
              </div>
              <div className="col-md-6">
                <PlainInput
                  label="Password"
                  value={formValues.password}
                  handleChange={(v) => setField("password", v)}
                  width={-1}
                />
                {formErrors.password && (
                  <span style={{ color: "red" }}>{formErrors.password}</span>
                )}
              </div>
              <div className="col-md-6">
                <PlainInput
                  label="Confirm Password"
                  value={formValues.confirm}
                  handleChange={(v) => setField("confirm", v)}
                  width={-1}
                />
                {formErrors.confirm && (
                  <span style={{ color: "red" }}>{formErrors.confirm}</span>
                )}
              </div>
              <div className="col-md-6 my-4">
                <Checkbox
                  handleToggle={(b) => {
                    setField("admin", b);
                  }}
                >
                  <span>Assign administrator privileges</span>
                </Checkbox>
              </div>
            </div>
          </form>
        </Tab>
      </Tabs>
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {entity === "sadmin" && "Add Staff Member"}
          {entity === "pupil" && "Add Pupil"}
          {entity === "teacher" && "Add Teacher"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {danger !== "" && <Alert variant="danger">{danger}</Alert>}
        {entity === "sadmin" ? (
          <div>{staffAdminView()}</div>
        ) : (
          <div>
            <PlainInput
              width={-1}
              label="Email"
              value={email}
              handleChange={emailChanged}
              onEnterPressed={onAction}
            />
            <p>
              {`Please use the email associated with your 
          ${
            entity === "pupil" ? "student" : "teacher"
          }'s Mr. Carter Maths account.`}
            </p>
            <b>
              {`By adding a 
          ${
            entity === "pupil"
              ? "student to your account you are giving us permission to display your first initial and surname to the student."
              : "teacher to your account you are consenting to share your full name and your review data."
          } 
          `}
            </b>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <RoundedBtn text="Close" onClick={onHide} />
        <RoundedBtn
          text={entity === "pupil" ? "Add Pupil" : "Add Teacher"}
          onClick={() => {
            setFormErrors({});
            if (tab === "exists") {
              onAction();
            } else if (tab === "new" && validateForm()) {
              onAction(formValues);
            }
          }}
          variant="dark"
          width={8}
        />
      </Modal.Footer>
    </Modal>
  );
};

AddRelationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  emailChanged: PropTypes.func.isRequired,
  danger: PropTypes.string,
  entity: PropTypes.string.isRequired,
};

AddRelationModal.defaultProps = {
  danger: "",
};

export default AddRelationModal;
