import { NumberSetError } from "../MCError";
import { Fraction } from "./Fraction";
/**
 * Recurrence Formulas.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * ADD EXAMPLE - have to use variable 'x'
 */

class Recurrence {
  /**
   * Creates a Recurrence in the form $U_{n+1}$ = func($U_{n}$)
   *
   * @param {object} func - the function to recur.
   * @param {number} start - the value of the first term.
   * @returns {Recurrence}
   */
  constructor(func, start) {
    this.func = func;
    this.start = start;
  }

  /**
   * Returns the formula as a string.
   *
   * @returns {string}
   */
  toString() {
    return `u_{n+1} = ${this.func.toString().replace(/x/g, "u_n")}`;
  }

  /**
   * Returns term n in the sequence.
   *
   * @param  {number} 	n The number of the term to calculate.
   * @throws {TypeError}  The argument should be a number.
   * @returns {number} 	Term n.
   *
   * @example
   * //Finds the 3rd term in the sequence $u_{n+1} = u_n^2 + 1$
   * const p1 = new MCMaths.Polynomial([1, 0, 1]);
   * const rr = new MCMaths.Recurrence(p1,1);
   * console.log(rr.term(3))
   */
  term(n) {
    if (typeof n !== "number") {
      throw new NumberSetError(`Expected a number, got ${n} instead`);
    }
    let output = this.start;
    for (let i = 1; i < n; i += 1) {
      output = this.func.evaluate(output);
    }
    return output;
  }

  /**
   * Returns multiple terms in the sequence as a string.
   *
   * @param  {number} 	start The number of the first term to calculate.
   * @param  {number} 	end The number of the last term to calculate.
   * @throws {TypeError}  The arguments should be numbers.
   * @returns {string} 	The terms as a comma seperated string.
   *
   * @example
   * //Finds the first 4 terms in the sequence $u_{n+1} = u_n^2 + 1$
   * const p1 = new MCMaths.Polynomial([1, 0, 1]);
   * const rr = new MCMaths.Recurrence(p1,1);
   * console.log(rr.terms(1,4))
   */
  terms(start, end) {
    if (typeof start !== "number") {
      throw new NumberSetError(`Expected a number, got ${start} instead`);
    }
    if (typeof end !== "number") {
      throw new NumberSetError(`Expected a number, got ${end} instead`);
    }
    let out = "";
    for (let i = start; i <= end; i += 1) {
      out += `${new Fraction(this.term(i)).toString()}, `;
    }
    return out.substring(0, out.length - 2);
  }

  /**
   * Sums the first n terms of the sequence.
   *
   * @param  {number} 	n The number of the term to be summerd.
   * @throws {TypeError}  The argument should be a number.
   * @returns {number} 	The sum of the first n terms.
   *
   * @example
   * //Finds the sum of the first 4 terms in the sequence $u_{n+1} = u_n^2 + 1$
   * const p1 = new MCMaths.Polynomial([1, 0, 1]);
   * const rr = new MCMaths.Recurrence(p1,1);
   * console.log(rr.sum(4))
   */
  sum(n) {
    if (typeof n !== "number") {
      throw new NumberSetError(`Expected a number, got ${n} instead`);
    }
    let output = 0;
    for (let i = 1; i <= n; i += 1) {
      output += this.term(i);
    }
    return output;
  }

  /**
   * Finds the sum of terms in the range.
   *
   * @param  {number}     start The term to start the sum on.
   * @param  {number}     end The number of the last term to calculate.
   * @throws {TypeError}  The arguments should be numbers.
   * @returns {number}    The result of the sum.
   *
   * @example
   * //Finds the sum of terms 4 to 6 in the sequence $u_{n+1} = u_n^2 + 1$
   * const p1 = new MCMaths.Polynomial([1, 0, 1]);
   * const rr = new MCMaths.Recurrence(p1,1);
   * console.log(rr.sumBetween(4,6))
   */
  sumBetween(start, end) {
    if (typeof start !== "number") {
      throw new NumberSetError(`Expected a number, got ${start} instead`);
    }
    if (typeof end !== "number") {
      throw new NumberSetError(`Expected a number, got ${end} instead`);
    }
    return this.sum(end) - this.sum(start - 1);
  }
}

export { Recurrence };
