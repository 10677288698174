/**
 * Defines an error for when a number parameter is of the wrong sub-type.
 * @memberof module:MCError
 * @author Declan Clark <dec@dclark.dev>
 * @example
 * throw new MCError.IndexError('expected a non-empty array');
 */
class IndexError extends Error {
  /**
   * Initialises an IndexError.
   * @param {string} message  The error message.
   */
  constructor(message) {
    super(message);
    this.name = "IndexError";
  }
}

export { IndexError };
