import { cleaner } from "./cleaner";
/**
 * Calulus, does working for integrations and differentiation.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Calulus for the derivative of x^2+1 at 0, or the integral over [0,5]
 * let p1 = new Polynomial([1,0,1])
 * let c1 = new Calculus(p1,0,5)
 */

class Calculus {
  /**
   * @param {object} func The function to do calculus to.
   * @param {number} [a='none'] The value to evaluate the derivative at, or the lower bound of the integral.
   * @param {number} [b='none'] The upper bound of the integral, leave as 'none' for derivatives.
   *
   * @returns {Calculus} A new calculus object.
   */
  constructor(func, a = "none", b = "none") {
    this.func = func;
    this.a = a;
    this.b = b;
  }

  /**
   * Returns an array of working for finding the derivative.
   *
   * @returns {string[]}
   */
  derivativeWorking() {
    const output = [];
    if (this.a === "none") {
      output.push(`HEADING Derivative of $${this.func.toString()}$:`);
    } else {
      output.push(
        `HEADING Derivative of $${this.func.toString()}$ at $${
          this.func.variable
        }=${cleaner(`${this.a}`)}$:`
      );
    }
    output.push(
      `$\\frac{d}{d${this.func.variable}}\\left(${
        this.func
      }\\right) = ${this.func.derivative().toString()} $`
    );
    if (this.a !== "none") {
      output.push(
        `Substituing $${cleaner(`${this.func.variable}=${this.a}`)}$:`
      );
      output.push(
        `$${cleaner(
          this.func
            .derivative()
            .toString()
            .replace(this.func.variable, `(${this.a})`)
        )}=${this.func.derivative().evaluate(this.a)} $`
      );
    }
    return output;
  }

  /**
   * Returns an array of working for finding the integral.
   *
   * @returns {string[]}
   */
  integralWorking() {
    const output = [];
    if (this.a === "none" && this.b === "none") {
      output.push(`HEADING Integral of $${this.func.toString()}$:`);
      output.push(
        `$\\int ${this.func.toString()}d${
          this.func.variable
        } = ${this.func.integral().toString()}+c$`
      );
    } else {
      output.push(
        `HEADING Integral of $${this.func.toString()}$ from $${
          this.func.variable
        }=${cleaner(`${this.a}`)}$ to $${this.func.variable}=${cleaner(
          `${this.b}`
        )}$:`
      );
      output.push(
        `$\\int ${cleaner(
          `_{${this.a}}^{${this.b}}`
        )} ${this.func.toString()}d${
          this.func.variable
        } = \\left[${this.func.integral().toString()} \\right]${cleaner(
          `_{${this.a}}^{${this.b}}`
        )}$`
      );
      const funcVar = new RegExp(this.func.variable, "g");
      output.push(
        `$=${cleaner(
          `\\left(${this.func
            .integral()
            .toString()
            .replace(
              funcVar,
              `(${this.b})`
            )}\\right)-\\left(${this.func
            .integral()
            .toString()
            .replace(funcVar, `(${this.a})`)}\\right) `
        )}$`
      );
      output.push(cleaner(`$= ${this.evaluateIntegral()}$`));
    }

    return output;
  }

  /**
   * Returns the evaluated integral as a number.
   *
   * @returns {number}
   */
  evaluateIntegral() {
    if (this.a === "none" && this.b === "none") {
      return "No Bounds";
    }
    return (
      this.func.integral().evaluate(this.b) -
      this.func.integral().evaluate(this.a)
    );
  }

  /**
   * Returns the evaluated derivative as a number.
   *
   * @returns {number}
   */
  evaluateDerivative() {
    if (this.a === "none") {
      return "No Bounds";
    }
    return this.func.derivative().evaluate(this.a);
  }
}
export { Calculus };
