import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const ProtectedRoute = ({ children, isAuthenticated, exact, path }) => {
  return (
    <Route exact={exact} path={path}>
      {isAuthenticated ? children : <Redirect to="/login" />}
    </Route>
  );
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isAuthenticated: PropTypes.bool,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
};

ProtectedRoute.defaultProps = {
  isAuthenticated: false,
  exact: false,
};

export default ProtectedRoute;
