/**
 * @constant {string[]} femaleNames An array of female first names.
 * @memberof module:MCMisc
 * @private
 */
const FEMALE_NAMES = [
  "Emily",
  "Hannah",
  "Madison",
  "Ashley",
  "Sarah",
  "Samantha",
  "Jessica",
  "Elizabeth",
  "Lauren",
  "Alyssa",
  "Abigail",
  "Olivia",
  "Emma",
  "Megan",
  "Grace",
  "Victoria",
  "Rachel",
  "Anna",
  "Jennifer",
  "Jasmine",
  "Julia",
  "Kaitlyn",
  "Nicole",
  "Amanda",
  "Katherine",
  "Natalie",
  "Hailey",
  "Alexandra",
  "Chloe",
  "Rebecca",
  "Stephanie",
  "Maria",
  "Sophia",
  "Isabella",
  "Amber",
  "Mary",
  "Brooke",
  "Michelle",
  "Andrea",
  "Madeline",
  "Kimberly",
  "Courtney",
  "Erin",
  "Vanessa",
  "Caroline",
  "Faith",
  "Paige",
  "Melissa",
  "Kaylee",
  "Mariah",
  "Caitlin",
  "Marissa",
  "Breanna",
  "Angela",
  "Catherine",
  "Zoe",
  "Briana",
  "Jada",
  "Laura",
  "Claire",
  "Alexa",
  "Alexandria",
  "Sabrina",
  "Mia",
  "Isabel",
  "Molly",
  "Leah",
  "Katie",
  "Lindsey",
  "Kylie",
  "Amy",
  "Diana",
  "Cassidy",
  "Ariana",
  "Margaret",
  "Kelly",
  "Miranda",
  "Maya",
  "Melanie",
  "Jade",
  "Caitlyn",
  "Alicia",
  "Lily",
  "Heather",
  "Madelyn",
  "Arianna",
  "Lillian",
  "Kiara",
  "Meghan",
  "Chelsea",
  "Daisy",
  "Isabelle",
  "Bethany",
  "Shannon",
  "Michaela",
  "Karen",
  "Kaitlin",
  "Karina",
  "Sophie",
  "Sofia",
  "Carly",
  "Ashlyn",
  "Ava",
  "Naomi",
  "Holly",
  "Julie",
  "Kendall",
  "Jasmin",
  "Selena",
  "Claudia",
  "Abby",
  "Amelia",
  "Serena",
  "Aaliyah",
  "Nina",
  "Kate",
  "Nancy",
  "Elena",
  "Katrina",
  "Sadie",
  "Elise",
  "Ruby",
  "Ella",
  "Natasha",
  "Ciara",
  "Josephine",
  "Eva",
  "Lisa",
  "Rose",
  "Lucy",
  "Denise",
  "Ellie",
  "Jenny",
  "Eleanor",
  "Jessie",
  "Carla",
  "Francesca",
  "Tia",
  "Layla",
  "Zoey",
  "Susan",
  "Eve",
];

/**
 * @constant {string[]} maleNames An array of male first names.
 * @memberof module:MCMisc
 * @private
 */
const MALE_NAMES = [
  "Jacob",
  "Michael",
  "Matthew",
  "Joshua",
  "Christopher",
  "Nicholas",
  "Andrew",
  "Joseph",
  "Daniel",
  "Tyler",
  "William",
  "Brandon",
  "Ryan",
  "John",
  "Zachary",
  "David",
  "Anthony",
  "James",
  "Justin",
  "Alexander",
  "Jonathan",
  "Christian",
  "Austin",
  "Dylan",
  "Ethan",
  "Benjamin",
  "Noah",
  "Samuel",
  "Robert",
  "Nathan",
  "Cameron",
  "Kevin",
  "Thomas",
  "Jordan",
  "Kyle",
  "Caleb",
  "Jason",
  "Aaron",
  "Eric",
  "Brian",
  "Adam",
  "Jack",
  "Luis",
  "Connor",
  "Charles",
  "Isaac",
  "Steven",
  "Evan",
  "Jared",
  "Sean",
  "Timothy",
  "Luke",
  "Cody",
  "Nathaniel",
  "Alex",
  "Seth",
  "Mason",
  "Richard",
  "Carlos",
  "Patrick",
  "Bryan",
  "Ian",
  "Trevor",
  "Mark",
  "Sebastian",
  "Antonio",
  "Lucas",
  "Jeremy",
  "Gavin",
  "Julian",
  "Dalton",
  "Bryce",
  "Tanner",
  "Kenneth",
  "Stephen",
  "Jake",
  "Victor",
  "Spencer",
  "Marcus",
  "Paul",
  "Edward",
  "Joel",
  "Shane",
  "Colin",
  "Dominic",
  "Derek",
  "Oscar",
  "Eduardo",
  "Peter",
  "Henry",
  "George",
  "Bradley",
  "Shawn",
  "Taylor",
  "Nicolas",
  "Gregory",
  "Liam",
  "Owen",
  "Omar",
  "Corey",
  "Fernando",
  "Clayton",
  "Johnathan",
  "Scott",
  "Alan",
  "Max",
  "Mario",
  "Martin",
  "Sergio",
  "Phillip",
  "Frank",
  "Pedro",
  "Andy",
  "Abraham",
  "Calvin",
  "Donald",
  "Jonathon",
  "Rafael",
  "Johnny",
  "Dennis",
  "Simon",
  "Curtis",
  "Jimmy",
  "Gary",
  "Louis",
  "Larry",
  "Tony",
  "Albert",
  "Damon",
  "Myles",
  "Ricky",
  "Darren",
  "Carl",
  "Oliver",
  "Tommy",
  "Joe",
  "Arthur",
  "Lawrence",
  "Jay",
];

export { FEMALE_NAMES, MALE_NAMES };
