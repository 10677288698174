const site = process.env.REACT_APP_SITE;
const siteID = site === "gcse" ? 0 : 1;

export const getSiteText = () => {
  return siteID === 0 ? "GCSE" : "A Level";
};

export const getSiteID = () => {
  return siteID;
};
