import * as MCMaths from "./MCMaths";
import * as MCQuestion from "./MCQuestion";
import * as MCRandom from "./MCRandom";

function test() {
  const i = new MCMaths.Inequalities(
    new MCMaths.Trig(1, "sin", 1, 3, "x"),
    -0.5,
    0.5,
    1,
    true
  );

  const p1 = new MCMaths.Polynomial([1, -10, 35, -50, 24]);
  const c1 = new MCMaths.Calculus(p1, 1, 2);

  const question = new MCQuestion.ExamQuestion();
  question.addMultipleParagraphs("question", [
    `$${i.toString()}$`,
    MCMaths.cleaner(`${i.solveString()}`),
  ]);

  question.addMultipleParagraphs("question", [
    MCMaths.cleaner(
      `$45^\\circ=${new MCMaths.Angle(45, "deg").toRadians().toString()}$`
    ),
    MCMaths.cleaner(`$${Math.PI} + ${Math.sqrt(2)}  +- \\pi + 11\\pi$`),
  ]);

  question.addMultipleParagraphs("question", [
    `$${p1.toString()}$`,
    `$${p1.realRoots()}$`,
  ]);

  question.addGraph("question", p1.graph());
  const p2 = new MCMaths.Intersections([1, -7, 0], [4, 1, -1, -3.5, 1]);
  question.addGraph("question", p2.graph());
  const p3 = new MCMaths.Intersections([1, -7, 0], [4, 1, -1, 1, 1]);
  question.addGraph("question", p3.graph());

  const circ1 = new MCMaths.Circles(1, -2, 2);
  question.addMultipleParagraphs("question", [
    `$${circ1.toString()}$`,
    `$${circ1.expandedString()}$`,
  ]);

  question.addGraph("question", circ1.graph());

  const param1 = new MCMaths.Polynomial([1, 0, 1]);
  const param2 = new MCMaths.Trig(2, "sin");
  const param = new MCMaths.Parametric(param1, param2, -Math.PI, Math.PI);

  question.addMultipleParagraphs("question", [
    `$${param.toString()}$`,
    `$${param.derivativeX()}$`,
    `$${param.derivativeY()}$`,
    `$${param.derivative()}$`,
  ]);
  // question.addGraph("question", param.graph());
  question.addMultipleParagraphs("question", param.derivativeWorking());

  const modFunc = new MCMaths.Polynomial([1, 2, -1]);
  const mod1 = new MCMaths.Modulus(modFunc);
  const mod2 = new MCMaths.Modulus(modFunc, true);

  question.addMultipleParagraphs("question", [
    `$${mod1.toString()}$`,
    `$${mod2.toString()}$`,
  ]);
  // question.addGraph("question", mod1.graph());
  // question.addGraph("question", mod2.graph());

  const FP = new MCMaths.FirstPrincipals(new MCMaths.Polynomial([1, 0, -4]), 0);
  question.addMultipleParagraphs("question", [`$${FP.toString()}$`]);

  // question.addGraph("question", FP.graph());

  question.addMultipleParagraphs("question", FP.derivativeWorking());

  const vec1 = new MCMaths.Vector(1, 2);
  const vec2 = new MCMaths.Vector(3, 2);
  question.addMultipleParagraphs("question", [
    `$${vec1.toString()}$`,
    `$${vec1.dotWorking(vec2)}$`,
    `$${MCMaths.cleaner(`${vec1.magnitudeWorking()}`)}$`,
  ]);
  // question.addGraph("question", vec1.addGraph(vec2));

  const prod1 = new MCMaths.Polynomial([1, 0, -1]);
  const prod2 = new MCMaths.Trig();
  const prod = new MCMaths.QuotientRule(prod1, prod2);
  question.addMultipleParagraphs("question", prod.derivativeWorking());

  const numMeth1 = new MCMaths.Logarithm(1, "e", 5);
  const numMeth = new MCMaths.NumericalMethods(numMeth1, 1);
  // question.addGraph("question", numMeth.iterateGraph(3, 4, true));

  const numMeth12 = new MCMaths.Polynomial([1, 0, -1]);
  const numMeth2 = new MCMaths.NumericalMethods(numMeth12, 2);
  // question.addGraph("question", numMeth2.newtonRaphsonGraph(2, 2, true));

  question.addMultipleParagraphs("question", numMeth2.newtonRaphsonWorking(4));
  question.addMultipleParagraphs("question", numMeth.iterateWorking(4));

  question.addMultipleParagraphs("question", prod1.normalWorking(3));

  const pTest1 = new MCMaths.Polynomial([1, 0, -12, 16], "x");
  const pTest2 = new MCMaths.Polynomial([1 / 5, 0, -12 / 5, 16 / 5], "x");

  question.addMultipleParagraphs("question", [
    `$${pTest1.toString()}$`,
    `$${pTest2.toString()}$`,
  ]);

  question.addGraph("question", pTest1.graph());
  question.addGraph("question", pTest2.graph());

  question.addMultipleParagraphs("question", [
    `$${pTest1.toString()}$`,
    `$${pTest2.toString()}$`,
  ]);

  const expTest = new MCMaths.Exponential(5);
  // question.addGraph("question", expTest.graph());

  const circTest = new MCMaths.Circles(5, 5, 7);
  question.addGraph("question", circTest.graph(3));

  const customFunc = {
    evaluate(x) {
      return (5 * Math.sin(x)) / x;
    },
    toString() {
      return "\\frac{5\\sin(x)}{x}";
    },
  };

  const cfTest = new MCMaths.Modulus(customFunc);

  // question.addGraph("question", cfTest.graph());

  const newTestName = new MCMaths.NumericalMethods(
    new MCMaths.Polynomial([1, 0, 1, 0]),
    0.1
  );
  question.addMultipleParagraphs("question", newTestName.iterateWorking(3));

  const data = [1, 2, 3, 4, 5, 7];
  const dataY = [1, 3, 5, 6, 8, 9];
  // const stat = MCMaths.Stats.randomSample(7, 1, 5);
  const stat = new MCMaths.Stats(data, dataY);
  question.addMultipleParagraphs("question", [
    `$${stat.sort().toString()}$`,
    `$${stat.toString()}$`,
    `nx = $${stat.nx()}$`,
    `Mean = $${stat.mean()}$`,
    `Median = $${stat.median()}$`,
    `Q1 = $${stat.q1()}$`,
    `Q3 = $${stat.q3()}$`,
    `Mode = $${stat.mode()}$`,
    `sx = $${stat.sx()}$`,
    `sx2 = $${stat.sx2()}$`,
    `sxy = $${stat.sxy()}$`,
    `sum SD = $${stat.sumSD()}$`,
    `a = ${stat.a()}`,
    `b = ${stat.b()}`,
    `variance = $${stat.variance()}$`,
    `standard deviation = $${stat.standardDeviation()}$`,
    `\\int\\limits_{lower}^{upper}  = $\\int\\limits_{lower}^{upper}$`,
    `\\displaystyle \\int_{lower}^{upper} = $\\displaystyle \\int_{lower}^{upper}$`,
  ]);

  question.addGraph("question", stat.scatter(true));

  const norm = new MCMaths.Normal(8, 3);
  const nsamp = norm.samples(200);
  const nstat = new MCMaths.Stats(nsamp);

  question.addMultipleParagraphs("question", [
    `$${norm.toString()}$`,
    `cdf($-1$)$=${norm.cdf(-1)}$`,
    `$${nsamp}$`,
    `Mean = $${nstat.mean()}$`,
    `Sd = $${nstat.standardDeviation()}$`,
    `91.2% = $${new MCMaths.Normal().inverseNormal(0.912)}$`,
  ]);
  question.addGraph(`question`, norm.graph(-1, 3));

  question.addGraph(`question`, nstat.histogram(15));
  question.addGraph(`question`, nstat.histogram(5));

  question.addGraph(`question`, nstat.histogramWidths([4, 5, 6, 8, 11]));

  const nstat2 = new MCMaths.Stats([
    1,
    1,
    1,
    1,
    1,
    2,
    2,
    2,
    2,
    3,
    3,
    3,
    3,
    4,
    4,
    5,
    5,
    6,
    6,
    7,
    9,
    11,
    12,
  ]);
  question.addGraph(
    `question`,
    nstat2.histogramWidths([1, 2, 5, 9, 11], "down")
  );
  question.addGraph(`question`, nstat2.histogramWidths([1, 2, 5, 9, 11], "up"));

  const bindis = new MCMaths.BinomialDistribution(5, 0.94);
  question.addMultipleParagraphs("question", [
    `${bindis.pdf(0)} `,
    `${bindis.pdf(1)} `,
    `${bindis.pdf(2)} `,
    `${bindis.pdf(3)} `,
    `${bindis.pdf(4)} `,
    `${bindis.pdf(5)} `,
    `${bindis.cdf(5)} `,
  ]);

  question.addParagraph("question", `${MCMaths.Stats.pmcc(0.005, 100)} Pmcc`);

  return question.makeQuestion();
}

export default test;
