import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles/ClassList.module.css";
import ClassModal from "./ClassModal";

const ClassList = ({ classes, onDelete }) => {
  const [deleting, setDeleting] = useState([false]);
  const [editing, setEditing] = useState([false]);

  const deleteRel = () => {
    onDelete(deleting[1]);
    setDeleting([false]);
  };

  return (
    <div>
      <ClassModal
        show={deleting[0] || editing[0]}
        onHide={() => {
          setDeleting([false]);
          setEditing([false]);
        }}
        className={
          // eslint-disable-next-line no-nested-ternary
          deleting[1] ? deleting[1].name : editing[1] ? editing[1].name : ""
        }
        onAction={deleteRel}
        editing={editing[0]}
        c={editing[1] ? editing[1] : {}}
      />
      <ul className="list-group">
        {classes.map((c) => {
          return (
            <li className="list-group-item" key={c.classId}>
              <button
                type="button"
                className="btn btn-link float-left"
                onClick={() => setEditing([true, c])}
              >
                {c.name}
              </button>
              <div className={`${styles.delete} mt-1`}>
                <FontAwesomeIcon
                  icon={faTimes}
                  color="#d9534f"
                  onClick={() => setDeleting([true, c])}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

ClassList.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ClassList;
