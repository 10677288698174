import { ArgumentError, IndexError, QuestionError } from "../MCError";
import { SkillsPart } from "./SkillsPart";
import { Graph, Image } from ".";
/**
 * A class that supports the development of skills questions.
 * @memberof module:MCQuestion
 * @author Declan Clark <dec@dclark.dev>
 * @since 0.1.0
 * @example
 * const question = new MCQuestion.SkillsQuestion(2);
 */
class SkillsQuestion {
  /**
   * Creates a new skills question.
   *
   * @param {number} [inputsPerLine=1]  The number of inputs to show visually on a single row.
   */
  constructor(inputsPerLine = 1) {
    this.mainSequence = [];
    this.parts = [];
    this.perLine = inputsPerLine;
  }

  /**
   * @summary Adds a paragraph to the skills question.
   *
   * @description Creates a paragraph object and adds it to the question.
   *              Font size can be changed with the optional fontSize parameter.
   *
   * @since 0.1.0
   *
   * @param   {string}                    paragraph    The string for the paragraph being added.
   * @param   {number}                    [fontSize]   The size of the font when displaying the paragraph.
   * @throws  {ArgumentError}                          Paragraph string and section are required.
   * @throws  {TypeError}                              Parameters were the wrong type.
   *
   * @example
   * const question = new MCQuestion.SkillsQuestion();
   * question.addParagraph('Simplify:', 2);
   */
  addParagraph(paragraph, fontSize) {
    if (typeof paragraph === "undefined") {
      throw new ArgumentError(`paragraph is required`);
    }
    if (typeof paragraph !== "string") {
      throw new TypeError(
        `expected a string but got ${typeof paragraph} instead`
      );
    }
    if (typeof fontSize !== "undefined") {
      if (typeof fontSize !== "number") {
        throw new TypeError(
          `expected a number but got ${typeof fontSize} instead`
        );
      }
      if (paragraph.includes("HEADING")) {
        const par = paragraph.replace("HEADING", "").trim();
        this.mainSequence.push({
          type: "paragraph",
          content: { p: par, size: fontSize, heading: true },
        });
      } else {
        this.mainSequence.push({
          type: "paragraph",
          content: { p: paragraph, size: fontSize },
        });
      }
    } else if (paragraph.includes("HEADING")) {
      const par = paragraph.replace("HEADING", "").trim();
      this.mainSequence.push({
        type: "paragraph",
        content: { p: par, heading: true },
      });
    } else {
      this.mainSequence.push({
        type: "paragraph",
        content: { p: paragraph },
      });
    }
  }

  /**
   * @summary Adds multiple paragraphs to the skills question.
   *
   * @description Takes an array of strings and adds each as a new paragraph. All paragraphs
   *              will have the given font size.
   *
   * @since 0.1.0
   *
   * @param {string[]}                    paragraphs  The paragraphs to be added, packed in an array.
   * @param {number}                      [fontSize]  The font size (in em) to be applied to paragraphs.
   * @throws  {ArgumentError}                          Paragraph strings and section are required.
   * @throws  {TypeError}                              Parameters were the wrong type.
   *
   * @example
   * const question = new MCQuestion.SkillsQuestion();
   * const someArrayOfText = ['hello', 'world'];
   * question.addMultipleParagraphs(someArrayOfText, 2);
   */
  addMultipleParagraphs(paragraphs, fontSize) {
    if (paragraphs.length === 0) {
      throw new IndexError("expected non-empty array of strings");
    }
    for (let i = 0; i < paragraphs.length; i += 1) {
      this.addParagraph(paragraphs[i], fontSize);
    }
  }

  /**
   * @summary Adds a heading to the skills question.
   *
   * @description Adds a paragraph to the question which is then styled as a heading.
   *
   * @since 0.1.0
   *
   * @param {string}                  heading     The content of the heading.
   * @param {number}                  [fontSize]  Font size of the heading (in em).
   * @throws  {ArgumentError}                          Heading string and section are required.
   * @throws  {TypeError}                              Parameters were the wrong type.
   *
   * @example
   * const question = new MCQuestion.SkillsQuestion();
   * question.addHeading('Simplify:', 2);
   */
  addHeading(heading, fontSize) {
    const p = `HEADING ${heading}`;
    this.addParagraph(p, fontSize);
  }

  /**
   * @summary Adds an image to the skills question.
   *
   * @description Takes an image object and adds it and its overlays to the question.
   *
   * @since 0.1.0
   *
   * @param   {Image}                     image   The image to add.
   * @throws  {ArgumentError}                     Required arguments were not supplied.
   * @throws  {TypeError}                         Arguments were not of the correct type.
   * @example
   * // adding the image to the solution sequence
   * question.addImage(myImage);
   */
  addImage(image) {
    if (typeof image === "undefined") {
      throw new ArgumentError(`must supply required argument`);
    }
    if (!(image instanceof Image)) {
      throw new TypeError(
        `expected an Image object but got ${typeof image} instead`
      );
    }
    this.mainSequence.push({
      type: "image",
      content: {
        src: image.getSrc(),
        measure: image.getMeasure(),
        size: image.getSize(),
        overlays: image.getOverlays(),
      },
    });
  }

  /**
   * @summary Adds a graph to the skills question.
   *
   * @description Takes a graph object and adds it to the question.
   *
   * @since 0.1.0
   *
   * @param   {Graph}                     graph   The graph to add.
   * @throws  {ArgumentError}                     Required arguments were not supplied.
   * @throws  {TypeError}                         Arguments were not of the correct type.
   * @example
   * // adding the graph
   * question.addGraph(myGraph);
   */
  addGraph(graph) {
    if (typeof graph === "undefined") {
      throw new ArgumentError(`must supply required argument`);
    }
    if (!(graph instanceof Graph)) {
      throw new TypeError(
        `expected a Graph object but got ${typeof graph} instead`
      );
    }
    this.mainSequence.push({
      type: "graph",
      content: {
        axis: graph.getAxisValues(),
        funcs: graph.getFunctions(),
        steps: graph.getSteps(),
      },
    });
  }

  /**
   * @summary Adds a part to the skills question.
   *
   * @description Provide a SkillsPart object and a new part will be added to the question.
   *
   * @since 0.1.0
   *
   * @param {SkillsPart} part   The part to add.
   */
  addPart(part) {
    if (!(part instanceof SkillsPart)) {
      throw new TypeError(`expected a SkillsPart but got ${typeof part}`);
    }
    const partLetter = String.fromCharCode(97 + this.parts.length);
    const questionArr = part.getQuestion();
    if (questionArr[0].type === "paragraph") {
      questionArr[0].content.p = `${partLetter}) ${questionArr[0].content.p}`;
    } else {
      questionArr.unshift({
        type: "paragraph",
        content: { p: `${partLetter})` },
      });
    }
    const solArr = part.getSolution();
    if (solArr[0].type === "paragraph") {
      solArr[0].content.p = `${partLetter}) ${solArr[0].content.p}`;
    } else {
      solArr.unshift({
        type: "paragraph",
        content: { p: `${partLetter})` },
      });
    }
    const inpArr = part.getAnswer();
    for (let i = 0; i < inpArr.length; i += 1) {
      if (typeof inpArr[i].content.label === "undefined") {
        inpArr[i].content.label = `${partLetter})`;
      }
    }
    this.parts.push({
      question: questionArr,
      solution: solArr,
      answer: inpArr,
    });
  }

  /**
   * @summary Use this function when returning the result of your question.
   *
   * @description Packages all question attributes into an object ready to be consumed by the application.
   *
   * @since 0.1.0
   *
   * @throws {QuestionError}  The question is not suitable (see error for why).
   * @returns {Object} The packaged question.
   */
  makeQuestion() {
    this.mainSequence.forEach((elem) => {
      if (elem.type === "paragraph") {
        if (/\$[0-9]{8,}\$/.test(elem.content.p)) {
          throw new QuestionError(`${elem.content.p} (integer >= 8 digits)`);
        }
      }
    });
    this.parts.forEach((part) => {
      part.question.forEach((elem) => {
        if (elem.type === "paragraph") {
          if (/\$[0-9]{8,}\$/.test(elem.content.p)) {
            throw new QuestionError(`${elem.content.p} (integer >= 8 digits)`);
          }
        }
      });
      part.solution.forEach((elem) => {
        if (elem.type === "paragraph") {
          if (/\$[0-9]{8,}\$/.test(elem.content.p)) {
            throw new QuestionError(`${elem.content.p} (integer >= 8 digits)`);
          }
        }
      });
      part.answer.forEach((inp) => {
        if (Array.isArray(inp.content.answer)) {
          const { tolerance } = inp.content;
          if (tolerance !== 0) {
            for (let i = 0; i < inp.content.answer.length; i += 1) {
              const expectingExact = inp.content.answer[i];
              const upperbound = expectingExact + tolerance;
              const lowerbound = expectingExact - tolerance;
              for (let j = 0; j < inp.content.answer.length; j += 1) {
                if (i !== j) {
                  if (
                    upperbound >= inp.content.answer[j] &&
                    lowerbound <= inp.content.answer[j]
                  ) {
                    throw new QuestionError(
                      "values that have their tolerances overlapping are not permitted"
                    );
                  }
                }
              }
            }
          }
        }
      });
    });
    return {
      type: "skill",
      content: {
        main: this.mainSequence,
        parts: this.parts,
        perLine: this.perLine,
      },
    };
  }
}

export { SkillsQuestion };
