import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSchool,
  faGraduationCap,
  faChalkboardTeacher,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import YoutubeModal from "../YoutubeModal";
import GeneralModal from "../GeneralModal";
import { getSiteText } from "../../system/utils/getSite";

const LandingPage = () => {
  const [isTeacherOpen, setTeacherIsOpen] = useState(false);
  const [isPupilOpen, setPupilIsOpen] = useState(false);

  const openModal = (entity) => {
    if (entity === "PUPIL") {
      setPupilIsOpen(true);
    } else {
      setTeacherIsOpen(true);
    }
  };
  const onHide = (entity) => {
    if (entity === "PUPIL") {
      setPupilIsOpen(false);
    } else {
      setTeacherIsOpen(false);
    }
  };

  const [isSchoolOpen, setSchoolIsOpen] = useState(false);
  const openSchoolModal = () => {
    setSchoolIsOpen(true);
  };
  const onSchoolHide = () => {
    setSchoolIsOpen(false);
  };

  const site = getSiteText();

  const text = () => {
    return (
      <div>
        <p>
          {`Mr Carter ${site} Maths is available as our comprehensive learning
          platform for all your staff and students to use. It has been created
          to enable you to easily manage all of your students logins from a user
          friendly interface.`}
        </p>
        <p>
          To sign up your school or college, e-mail our admin team at
          Contact@MrCarterMaths.com who will be happy to get your account up and
          running.
        </p>
      </div>
    );
  };

  return (
    <div>
      <YoutubeModal
        videoId="xL7nSDtiUNg"
        onHide={onHide}
        isOpen={isPupilOpen}
        entity="PUPIL"
      />
      <YoutubeModal
        videoId="5dPMTmup6g4"
        onHide={onHide}
        isOpen={isTeacherOpen}
        entity=""
      />
      <GeneralModal
        show={isSchoolOpen}
        onHide={onSchoolHide}
        title="School Sign Up"
        text={text()}
      />

      <section
        className="jumbotron text-center jumbotron-fluid"
        style={{ backgroundColor: "#000033", color: "white" }}
      >
        <div className="container">
          <h1 className="jumbotron-heading">Where practice happens</h1>
          <p className="lead ">
            {`An extensive collection of ${site} Mathematics practice questions. A
            smart system that will use your performance to direct your future
            practice.`}
          </p>
          <p>
            <Link to="/register" className="btn btn-secondary">
              Sign up now
            </Link>
          </p>
        </div>
      </section>

      <div className="album">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="card mb-4 box-shadow">
                <div className="card-body text-center">
                  <FontAwesomeIcon
                    icon={faGraduationCap}
                    size="4x"
                    color="#74c5ed"
                  />
                  <h2 style={{ color: "#74c5ed" }}>Pupils</h2>
                  <p className="card-text">
                    Use our work smart feature to direct your practice to where
                    you need it most. All questions have fully worked solutions
                    to see where you went wrong.
                  </p>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        openModal("PUPIL");
                      }}
                    >
                      Pupil Video
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4 box-shadow">
                <div className="card-body text-center">
                  <FontAwesomeIcon
                    icon={faChalkboardTeacher}
                    size="4x"
                    color="#74c5ed"
                  />
                  <h2 style={{ color: "#74c5ed" }}>Teachers</h2>
                  <p className="card-text">
                    We support the work you do in class with a comprehensive
                    homework platform. Let our systems direct your pupils&apos;
                    practice to their areas of weakness.
                  </p>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        openModal("");
                      }}
                    >
                      Teacher Video
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-4 box-shadow">
                <div className="card-body text-center">
                  <FontAwesomeIcon icon={faSchool} size="4x" color="#74c5ed" />
                  <h2 style={{ color: "#74c5ed" }}>Schools</h2>
                  <p className="card-text">
                    Get in touch today to sign up as a school or college. Once
                    we set you up, you can manage all your pupils and teachers
                    from our easy to use platform.
                  </p>

                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => {
                        openSchoolModal();
                      }}
                    >
                      Find out more
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="alert alert-primary text-center" role="alert">
            <span>Looking for our teaching resources for KS3/4/5, visit </span>
            <a
              href="https://www.MrCarterMaths.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.MrCarterMaths.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
