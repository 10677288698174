import React, { useEffect, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { useHistory } from "react-router-dom";
import RoundedBtn from "./RoundedBtn";
import ClassList from "./ClassList";
import Alert from "./Alert";
import ClassModal from "./ClassModal";
import checkToken from "../system/auth/checkToken";
import { getSiteID } from "../system/utils/getSite";

const Classes = ({ classes, pupils }) => {
  const site = getSiteID();
  const [loading, setLoading] = useState(true);
  const [classList, setClassList] = useState([]);
  const [alert, setAlert] = useState({});
  const [className, setClassName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [allPupils, setAllPupils] = useState([]);
  const [pupilsToAdd, setPupilsToAdd] = useState([]);
  const [classLevel, setClassLevel] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (pupils) {
      const allowedEmails = [];
      for (let i = 0; i < pupils.length; i += 1) {
        const pupil = pupils[i];
        if (!pupil.createdBy) {
          allowedEmails.push(pupil.email);
        }
      }
      setAllPupils(allowedEmails);
    }
    if (classes) {
      setClassList([...classes]);
    }
    if (classes && pupils) {
      setLoading(false);
    }
  }, [classes]);

  const addToPupils = (email) => {
    if (!allPupils.includes(email)) {
      setAlert({
        variant: "danger",
        modal: true,
        msg: "Cannot add this pupil.",
      });
      return false;
    }
    if (pupilsToAdd.includes(email)) {
      setAlert({
        variant: "danger",
        modal: true,
        msg: "Already added.",
      });
      return false;
    }
    const addedPupils = [...pupilsToAdd];
    let pupil = null;
    for (let i = 0; i < pupils.length; i += 1) {
      const thisPupil = pupils[i];
      if (thisPupil.email === email) {
        pupil = thisPupil;
      }
    }
    if (pupil === null) {
      setAlert({
        variant: "danger",
        modal: true,
        msg: "Cannot add this pupil.",
      });
      return false;
    }
    addedPupils.unshift(pupil);
    setPupilsToAdd(addedPupils);
    return true;
  };

  const startLoad = () => {
    return setTimeout(() => {
      setLoading(true);
    }, 500);
  };

  const stopLoad = (timer) => {
    clearTimeout(timer);
    setLoading(false);
  };

  const addClass = async () => {
    setAlert({});
    setShowModal(false);
    const start = startLoad();
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    const ids = [];
    for (let i = 0; i < pupilsToAdd.length; i += 1) {
      ids.push(pupilsToAdd[i].pupilId);
    }
    try {
      const body =
        site === 0
          ? { className, pupils: ids, classLevel }
          : { className, pupils: ids };
      const res = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/classes`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
        }
      );
      setClassList([res.data, ...classList]);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setAlert({
          variant: "danger",
          msg: JSON.stringify(e.response.data.error),
        });
      } else {
        setAlert({
          variant: "danger",
          msg: "A problem occurred. Please try again.",
        });
      }
      stopLoad(start);
      return false;
    }
    setAlert({
      variant: "success",
      msg: `Successfully added class '${className}'!`,
    });
    setClassName("");
    stopLoad(start);
    return true;
  };

  const onDelete = async (c) => {
    const start = startLoad();
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    try {
      await axios.delete(`${process.env.REACT_APP_DOMAIN}/classes`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
        data: {
          classId: c.classId,
        },
      });
      const data = [...classList];
      let ind = -1;
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].classId === c.classId) {
          ind = i;
        }
      }
      data.splice(ind, 1);
      setAlert({
        variant: "success",
        msg: `Successfully removed class '${c.name}'.`,
      });
      setClassList(data);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setAlert({
          variant: "danger",
          msg: JSON.stringify(e.response.data.error),
        });
      } else {
        setAlert({
          variant: "danger",
          msg: "A problem occurred. Please try again.",
        });
      }
    }
    stopLoad(start);
    return true;
  };

  return (
    <div className="card text-center">
      <h4 className="card-header">Classes</h4>
      <div className="card-body">
        <ClassModal
          onHide={() => setShowModal(false)}
          onAction={addClass}
          show={showModal}
          adding
          changeClassName={(val) => setClassName(val)}
          className={className}
          danger={alert.modal ? alert.msg : ""}
          pupils={allPupils}
          addPupil={(email) => addToPupils(email)}
          pupilList={pupilsToAdd}
          isGCSE={site === 0}
          handleClassLevel={(newClass) => setClassLevel(newClass)}
          classLevel={classLevel}
        />
        {loading ? (
          <div className="my-5">
            <ClipLoader loading color="#74c5ed" size="3em" />
          </div>
        ) : (
          <div>
            <div className={`${site === 0 ? "d-flex" : "text-center"}`}>
              <RoundedBtn
                className={`${site === 0 ? "mr-auto" : ""}`}
                text="Add Class"
                width={10}
                icon={{ icon: faPlus, position: "left" }}
                onClick={() => setShowModal(true)}
              />
              {site === 0 && (
                <RoundedBtn
                  variant="dark"
                  text="Set Content Covered"
                  width={16}
                  onClick={() => {
                    history.push("/content-covered", { data: { classList } });
                  }}
                />
              )}
            </div>

            {!alert.modal && alert.msg && (
              <div className="mt-2">
                <Alert variant={alert.variant}>{alert.msg}</Alert>
              </div>
            )}
            <div className="mt-3">
              <ClassList classes={classList} onDelete={onDelete} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Classes.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.object).isRequired,
  pupils: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Classes;
