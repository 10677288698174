/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import _ from "lodash";
import NavigationPrompt from "react-router-navigation-prompt";
import { useHistory, useLocation } from "react-router-dom";
import jwt from "jsonwebtoken";
import PropTypes from "prop-types";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";
import Alert from "../Alert";
import ParentAccordion from "../ParentAccordion";
import alevelTopics from "../../system/topics/alevelTopics";
import gcseTopics from "../../system/topics/gcseTopics";
import RoundedBtn from "../RoundedBtn";
// eslint-disable-next-line import/no-cycle
import { AppContext } from "../../App";
import ContentCoveredSaveModal from "../ContentCoveredSaveModal";
import checkToken from "../../system/auth/checkToken";
import { getSiteID } from "../../system/utils/getSite";
import Dropdown from "../Dropdown";

const Topics = ({ showClassControls = false }) => {
  const location = useLocation();

  const siteID = getSiteID();
  const { role } = jwt.decode(localStorage.getItem("mcmToken"));
  const IS_GCSE_SCHOOL_PUPIL = role === "FOUND_PUPIL" || role === "HIGH_PUPIL";
  const [allTopics, setAllTopics] = useState({});

  const { pupilData, setPupilData } = useContext(AppContext);
  const [changes, setChanges] = useState(false);
  const [topics, setTopics] = useState({});
  const [topicsBefore, setTopicsBefore] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const [classToUpdate, setClassToUpdate] = useState({
    name: "Filter by Class",
    id: 0,
    level: 0,
  });
  const [classOptions, setClassOptions] = useState([]);
  // const [classLevel, setClassLevel] = useState(0);

  const startLoad = () => {
    return setTimeout(() => {
      setLoading(true);
    }, 10);
  };

  const stopLoad = (timer) => {
    clearTimeout(timer);
    setLoading(false);
  };

  useEffect(() => {
    let aTopics = {};
    const start = startLoad();
    if (classToUpdate.level === 1) {
      aTopics = _.merge(gcseTopics.Foundation, gcseTopics.Crossover);
    } else if (classToUpdate.level === 2) {
      aTopics = _.merge(gcseTopics.Higher, gcseTopics.Crossover);
    }
    setAllTopics(allTopics);
    if (classToUpdate.id !== 0) {
      axios
        .get(
          `${process.env.REACT_APP_DOMAIN}/content${
            showClassControls ? "/class" : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
            },
            params: {
              class: classToUpdate.id,
            },
          }
        )
        .then((res) => {
          const resData = res.data;
          const tops = JSON.parse(JSON.stringify(aTopics));
          resData.forEach((topic) => {
            const { level, major, minor } = topic;
            if (
              typeof tops[level] !== "undefined" &&
              typeof tops[level][major] !== "undefined" &&
              typeof tops[level][major][minor] !== "undefined"
            ) {
              tops[level][major][minor] = true;
            }
          });
          setTopicsBefore(JSON.parse(JSON.stringify(tops)));
          setTopics(JSON.parse(JSON.stringify(tops)));
          setLoading(false);
        })
        .catch(() => {
          setAlert({
            variant: "danger",
            msg: "A problem occurred! Please try again later!",
          });
        });
    }

    stopLoad(start);
  }, [classToUpdate]);

  useEffect(() => {
    if (!showClassControls) {
      const PUPIL_GCSE_LEVEL = role === "HIGH_PUPIL" ? 1 : 0;
      if (siteID === 0) {
        if (PUPIL_GCSE_LEVEL === 0) {
          setAllTopics(_.merge(gcseTopics.Foundation, gcseTopics.Crossover));
        } else {
          setAllTopics(_.merge(gcseTopics.Higher, gcseTopics.Crossover));
        }
      } else {
        setAllTopics(alevelTopics);
      }
    }
    if (showClassControls) {
      const list = location.state.data.classList;

      const classes = [];
      for (let i = 0; i < list.length; i += 1) {
        const clss = list[i];
        classes.push({
          id: clss.classId,
          title: clss.name,
          value: clss.classId,
          level: clss.classLevel,
        });
      }
      setClassOptions(classes);
    }
  }, []);

  const history = useHistory();

  const getChanges = () => {
    const diff = [];
    Object.keys(topics).forEach((level) => {
      Object.keys(topics[level]).forEach((major) => {
        Object.keys(topics[level][major]).forEach((minor) => {
          if (
            topicsBefore[level][major][minor] !== topics[level][major][minor]
          ) {
            if (topics[level][major][minor]) {
              diff.push({
                level,
                major,
                minor,
                add: true,
              });
            } else {
              diff.push({
                level,
                major,
                minor,
                remove: true,
              });
            }
          }
        });
      });
    });
    return diff;
  };

  useEffect(() => {
    if (!showClassControls) {
      const start = startLoad();
      if (pupilData.content !== null) {
        const resData = pupilData.content;
        const tops = JSON.parse(JSON.stringify(allTopics));
        resData.forEach((topic) => {
          const { level, major, minor } = topic;
          if (
            typeof tops[level] !== "undefined" &&
            typeof tops[level][major] !== "undefined" &&
            typeof tops[level][major][minor] !== "undefined"
          ) {
            tops[level][major][minor] = true;
          }
        });
        setTopicsBefore(JSON.parse(JSON.stringify(tops)));
        setTopics(JSON.parse(JSON.stringify(tops)));
        setLoading(false);
      } else if (checkToken()) {
        const body = showClassControls ? { classId: classToUpdate.id } : {};
        axios
          .get(
            `${process.env.REACT_APP_DOMAIN}/content${
              showClassControls ? "/class" : ""
            }`,
            body,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
              },
            }
          )
          .then((res) => {
            const resData = res.data;
            const tops = JSON.parse(JSON.stringify(allTopics));
            resData.forEach((topic) => {
              const { level, major, minor } = topic;
              if (
                typeof tops[level] !== "undefined" &&
                typeof tops[level][major] !== "undefined" &&
                typeof tops[level][major][minor] !== "undefined"
              ) {
                tops[level][major][minor] = true;
              }
            });
            setTopicsBefore(JSON.parse(JSON.stringify(tops)));
            setTopics(JSON.parse(JSON.stringify(tops)));
            setLoading(false);
          })
          .catch(() => {
            setAlert({
              variant: "danger",
              msg: "A problem occurred! Please try again later!",
            });
          });
      } else {
        localStorage.removeItem("mcmToken");
        history.push("/login");
      }
      stopLoad(start);
    }
  }, [pupilData.content, allTopics]);

  useEffect(() => {
    setChanges(!_.isEqual(topics, topicsBefore));
  }, [topics, topicsBefore]);

  const postChanges = async () => {
    setAlert({});
    const tops = topics;
    const start = startLoad();
    const diffs = getChanges();
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      setChanges(false);
      history.push("/login");
      return false;
    }
    try {
      if (showClassControls) {
        await axios.post(
          `${process.env.REACT_APP_DOMAIN}/content/class`,
          { topics: diffs, classId: classToUpdate.id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
            },
          }
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_DOMAIN}/content`,
          { topics: diffs },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
            },
          }
        );
      }
      setAlert({ variant: "success", msg: "Saved!" });
      setTopicsBefore(JSON.parse(JSON.stringify(tops)));
      const newContentCovered = [];
      Object.keys(topics).forEach((level) => {
        Object.keys(topics[level]).forEach((major) => {
          Object.keys(topics[level][major]).forEach((minor) => {
            if (topics[level][major][minor]) {
              newContentCovered.push({ level, major, minor });
            }
          });
        });
      });
      setPupilData({ answers: pupilData.answers, content: newContentCovered });
    } catch (e) {
      setAlert({
        variant: "danger",
        msg: "A problem occurred. Try reloading the page.",
      });
    }
    stopLoad(start);
    return true;
  };

  const toggleLevel = (level, levelVal) => {
    const newTopics = { ...topics };
    const majors = Object.keys(newTopics[level]);
    majors.forEach((maj) => {
      const minors = Object.keys(newTopics[level][maj]);
      minors.forEach((min) => {
        newTopics[level][maj][min] = levelVal;
      });
    });
    setTopics(newTopics);
  };

  const toggleMajor = (level, major, majorVal) => {
    const newTopics = { ...topics };
    const minors = Object.keys(newTopics[level][major]);
    minors.forEach((minor) => {
      newTopics[level][major][minor] = majorVal;
    });
    setTopics(newTopics);
  };

  const toggleMinor = (level, major, minor) => {
    const newTopics = { ...topics };
    newTopics[level][major][minor] = !topics[level][major][minor];
    setTopics(newTopics);
  };

  return (
    <div className="container my-4">
      <NavigationPrompt when={changes} afterCancel={postChanges}>
        {({ onConfirm, onCancel }) => (
          <ContentCoveredSaveModal
            show
            onHide={onConfirm}
            onAction={onCancel}
          />
        )}
      </NavigationPrompt>
      <div style={{ paddingBottom: "7em" }}>
        <div className="text-center">
          <h4>Content Covered</h4>
        </div>
        {alert.msg && <Alert variant={alert.variant}>{alert.msg}</Alert>}
        {showClassControls && (
          <div className="text-center d-flex mt-3">
            <Dropdown
              width={-1}
              icon={faUserFriends}
              title={classToUpdate.id === 0 ? "Class" : classToUpdate.name}
              options={classOptions}
              handleChange={(id) => {
                for (let i = 0; i < classOptions.length; i += 1) {
                  if (classOptions[i].id === id) {
                    setClassToUpdate({
                      level: classOptions[i].level,
                      id,
                      name: classOptions[i].title,
                    });
                    break;
                  }
                }
              }}
              centered
            />
          </div>
        )}
        {loading ? (
          <div className="text-center my-5">
            <ClipLoader loading color="#74c5ed" size="3em" />
          </div>
        ) : !showClassControls ||
          (showClassControls && classToUpdate.id !== 0) ? (
          // eslint-disable-next-line react/jsx-indent
          <div>
            {IS_GCSE_SCHOOL_PUPIL && (
              <div>
                <Alert variant="info">
                  <span>Your content covered list is </span>
                  <b>read only</b>
                  <span>
                    {" "}
                    because your account is linked with your school. If you
                    think there is a mistake on your content covered list,
                    please speak to your teacher who is responsible for setting
                    the content covered list for your class.
                  </span>
                </Alert>
              </div>
            )}

            <div className="my-4">
              {Object.keys(topics).map((topic) => {
                return (
                  <ParentAccordion
                    title={topic}
                    topics={topics}
                    togglerLevel={toggleLevel}
                    togglerMajor={toggleMajor}
                    togglerMinor={toggleMinor}
                    startOpen={topic === "AS"}
                    disabled={IS_GCSE_SCHOOL_PUPIL}
                  />
                );
              })}
            </div>
            {
              // If user has made changes then they have the option to save them
              changes && (
                <div className="text-center">
                  <RoundedBtn
                    text="Save Changes"
                    variant="light"
                    width={12.5}
                    onClick={postChanges}
                  />
                </div>
              )
            }
          </div>
        ) : (
          <div className="text-center my-5 font-italic">
            Please choose a class whose content you would like to update.
          </div>
        )}
      </div>
    </div>
  );
};

Topics.propTypes = {
  showClassControls: PropTypes.bool,
};

Topics.defaultProps = {
  showClassControls: false,
};

export default Topics;
