// ------ ORDER ------

export const topicsOrdered = [
  `ProofByDeduction`,
  `ProofByExhaustion`,
  `DisproofByCounterExample`,
  `LawsOfIndices`,
  `Surds`,
  `Polynomials`,
  `QuadraticFunctions`,
  `SimultaneousEquations`,
  `Inequalities`,
  `Graphs`,
  `Transformations`,
  `StraightLines`,
  `Circles`,
  `BinomialExpansion`,
  `RulesOfTrigonometry`,
  `TrigonometricIdentities`,
  `TrigonometricEquations`,
  `ExponentialFunctions`,
  `ExponentialDifferentiation`,
  `Logarithms`,
  `LogarithmLaws`,
  `ExponentialEquations`,
  `ExponentialGrowthAndDecay`,
  `BasicDifferentiation`,
  `AppliedDifferentiation`,
  `FirstPrinciples`,
  `PolynomialIntegration`,
  `VectorSkills`,
  `VectorProblems`,
  `Sampling`,
  `Averages`,
  `Coding`,
  `Outliers`,
  `BoxPlots`,
  `Histograms`,
  `Correlation`,
  `LinearRegression`,
  `Probabilities`,
  `VennDiagrams`,
  `MutuallyExclusive`,
  `TreeDiagrams`,
  `Probability`,
  `BinomialDistribution`,
  `Cumulative`,
  `HypothesisTesting`,
  `SUVATGraphs`,
  `SUVAT`,
  `ForceVectors`,
  `NewtonsSecondLaw`,
  `ConnectedParticles`,
  `VariableAcceleration`,
  `ProofByContradiction`,
  `AlgebraicDivision`,
  `ModulusFunction`,
  `CompositeAndInverseFunctions`,
  `PartialFractions`,
  `ParametricEquations`,
  `A2Transformations`,
  `Recurrence`,
  `ArithmeticSequences`,
  `GeometricSequences`,
  `SmallAngleApproximation`,
  `ExactValues`,
  `DoubleAngleFormulae`,
  `Proofs`,
  `TrigonometryProblems`,
  `FunctionDerivatives`,
  `TurningPoints`,
  `MaximaAndMinima`,
  `ProductRule`,
  `QuotientRule`,
  `ChainRule`,
  `A2FirstPrinciples`,
  `ParametricResults`,
  `FunctionIntegration`,
  `IntegrationBySubstitution`,
  `IntegrationByParts`,
  `IntegrationOfPartialFractions`,
  `DifferentialEquationIntegration`,
  `IterativeMethods`,
  `NewtonRaphsonMethod`,
  `NumericalIntegration`,
  `Vectors3D`,
  `ExponentialModels`,
  `MeasuringCorrelation`,
  `CorrelationHypothesisTesting`,
  `SetNotation`,
  `ConditionalProbability`,
  `ProbabilityFormulae`,
  `A2TreeDiagrams`,
  `Probabilities`,
  `InverseNormal`,
  `BinomialApproximation`,
  `NormalHypothesisTesting`,
  `MomentsSkills`,
  `MomentsProblems`,
  `ResolvingForces`,
  `ProjectileSkills`,
  `ProjectileProblems`,
  `Statics`,
  `A2ConnectedParticles`,
  `FurtherVectors`,
  `A2VariableAcceleration`,
  `VectorCalculus`,
];

// ---- As ----

// Proof
export const ProofByDeduction = [];
export const ProofByExhaustion = [];
export const DisproofByCounterExample = [];

// Algebra And Functions
export const LawsOfIndices = [];
export const Surds = [];
export const QuadraticFunctions = ["Polynomials"];
export const SimultaneousEquations = ["QuadraticFunctions"];
export const Inequalities = ["Polynomials"];
export const Polynomials = [];
export const Graphs = ["Polynomials", "QuadraticFunctions"];
export const Transformations = ["Polynomials", "QuadraticFunctions", "Graphs"];

// Coordinate Geometry
export const StraightLines = ["Polynomials", "Graphs"];
export const Circles = ["Polynomials", "Graphs"];

// Sequences and Series
export const A2BinomialExpansion = ["Polynomials"];

// Trigonometry
export const RulesOfTrigonometry = [];
export const TrigonometricIdentities = ["RulesOfTrigonometry"];
export const TrigonometricEquations = [
  "RulesOfTrigonometry",
  "TrigonometricIdentities",
];

// Exponentials and Logarithms
export const ExponentialFunctions = [];
export const ExponentialDifferentiation = ["ExponentialFunctions"];
export const Logarithms = [];
export const LogarithmLaws = ["Logarithms"];
export const ExponentialEquations = ["ExponentialFunctions"];
export const ExponentialGrowthAndDecay = [
  "ExponentialFunctions",
  "ExponentialEquations",
];

// Differentiation
export const BasicDifferentiation = [];
export const AppliedDifferentiation = ["BasicDifferentiation"];
export const FirstPrinciples = ["BasicDifferentiation"];

// Integration
export const PolynomialIntegration = ["Polynomials"];

// Vectors
export const VectorSkills = [];
export const VectorProblems = ["VectorSkills"];

// Data
export const Sampling = [];
export const Data = [Sampling];

// Measures
export const Averages = [];
export const Coding = ["Averages"];

// Representations
export const Outliers = ["Averages"];
export const BoxPlots = ["Averages"];
export const Histograms = ["BoxPlots"];

// Correlation
export const Correlation = ["Averages"];
export const LinearRegression = ["Correlation"];

// Probability
export const Probabilities = [];
export const VennDiagrams = ["Probabilities"];
export const MutuallyExclusive = ["Probabilities"];
export const TreeDiagrams = ["Probabilities"];

// Distributions
export const Probability = ["Probabilities"];
export const BinomialDistribution = ["BinomialExpansion"];
export const Cumulative = ["Probabilities", "Probability"];

// Hypothesis
export const HypothesisTesting = ["Probabilities", "BinomialDistribution"];

// ConstantAcceleration
export const SUVATGraphs = ["Graphs"];
export const SUVAT = [];

// Forces
export const ForceVectors = ["VectorSkills"];
export const NewtonsSecondLaw = [];
export const ConnectedParticles = ["NewtonsSecondLaw"];

// VariableAcceleration
export const VariableAcceleration = [
  "AppliedDifferentiation",
  "PolynomialIntegration",
];

// ---- A2 ----

// Proof
export const ProofByContradiction = [
  "ProofByDeduction",
  "ProofByExhaustion",
  "DisproofByCounterExample",
];

// Algebra and Functions
export const AlgebraicDivision = ["Polynomials"];
export const ModulusFunction = [
  "Polynomials",
  "Graphs",
  "ExponentialFunctions",
];
export const CompositeAndInverseFunctions = [
  "Polynomials",
  "Graphs",
  "ExponentialFunctions",
];
export const PartialFractions = ["Polynomials", "AlgebraicDivision"];

export const ParametricEquations = ["Polynomials"];
export const A2Transformations = ["Transformations"];

// SequencesAndSeries
export const BinomialExpansion = [];
export const Recurrence = [];
export const ArithmeticSequences = [];
export const GeometricSequences = [];

// Trigonometry
export const SmallAngleApproximation = [];
export const ExactValues = ["TrigonometricIdentities"];
export const DoubleAngleFormulae = ["TrigonometricIdentities"];
export const TrigonometryProofs = ["TrigonometricEquations"];
export const TrigonometryProblems = ["TrigonometricEquations"];
export const Proofs = ["TrigonometricIdentities"];

// Differentiation
export const MaximaAndMinima = ["BasicDifferentiation"];
export const ProductRule = ["BasicDifferentiation"];
export const QuotientRule = ["BasicDifferentiation", "ProductRule"];
export const ChainRule = [
  "BasicDifferentiation",
  "ProductRule",
  "QuotientRule",
];
export const A2FirstPrinciples = ["FirstPrinciples"];
export const ParametricResults = ["ParametricEquations"];
export const FunctionDerivatives = ["BasicDifferentiation"];
export const TurningPoints = ["AppliedDifferentiation"];

// Integration
export const FunctionIntegration = ["PolynomialIntegration"];
export const IntegrationBySubstitution = ["FunctionIntegration"];
export const IntegrationByParts = ["FunctionIntegration"];
export const IntegrationOfPartialFractions = [
  "FunctionIntegration",
  "PartialFractions",
];
export const DifferentialEquationIntegration = ["FunctionIntegration"];

// Numerical Methods
export const IterativeMethods = ["Recurrence"];
export const NewtonRaphsonMethod = ["IterativeMethods"];
export const NumericalIntegration = [
  "IterativeMethods",
  "FunctionIntegration",
  "PolynomialIntegration",
];

// Vectors
export const Vectors3D = ["VectorSkills"];

// Regression
export const ExponentialModels = ["Correlation", "LinearRegression"];
export const MeasuringCorrelation = ["Correlation", "LinearRegression"];
export const CorrelationHypothesisTesting = ["Correlation", "LinearRegression"];

// Probability
export const SetNotation = ["Probabilities", "VennDiagrams"];
export const ConditionalProbability = ["MutuallyExclusive", "SetNotation"];
export const ProbabilityFormulae = ["Probabilities"];
export const A2TreeDiagrams = ["TreeDiagrams"];

// NormalDistribution
export const NormalProbabilities = [];
export const InverseNormal = ["Probabilities"];
export const BinomialApproximation = ["BinomialDistribution", "Probabilities"];
export const NormalHypothesisTesting = ["HypothesisTesting"];

// Moments
export const MomentsSkills = ["NewtonsSecondLaw"];
export const MomentsProblems = ["MomentsSkills"];

// Forces
export const ResolvingForces = [];

// Projectiles
export const ProjectileSkills = ["SUVAT"];
export const ProjectileProblems = ["ProjectileSkills"];

// AppliedForces
export const Statics = ["NewtonsSecondLaw"];
export const A2ConnectedParticles = ["ConnectedParticles"];

// FurtherKinematics
export const FurtherVectors = ["VectorSkills", "VectorProblems"];
export const A2VariableAcceleration = ["VariableAcceleration"];
export const VectorCalculus = ["BasicDifferentiation", "PolynomialIntegration"];
