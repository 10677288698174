import React, { useState } from "react";
import * as jwt from "jsonwebtoken";
import PropTypes from "prop-types";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./styles/TeacherList.module.css";
import AcceptModal from "./AcceptModal";
import DeleteRelationModal from "./DeleteRelationModal";

const TeacherList = ({ data, onRespondToInvite, onDelete, anon }) => {
  const uid = jwt.decode(localStorage.getItem("mcmToken")).userId;
  const [isTabbing, setTabbing] = useState(false);
  const [accepting, setAccepting] = useState([false]);
  const [deleting, setDeleting] = useState([false]);

  const keyPressed = (e /* staffId */) => {
    if (e.key === "Tab") {
      setTabbing(true);
    }
    if (e.key === "Enter" || e.key === " ") {
      // do thing
    }
  };

  const accept = () => {
    onRespondToInvite(true, accepting[1].staffId);
    setAccepting([false]);
  };

  const deleteRel = () => {
    onDelete(deleting[1]);
    setDeleting([false]);
  };

  const displayTeacher = (relation) => {
    if (anon) return "**********";
    if (
      !relation.confirmed &&
      (typeof relation.createdBy === "undefined" || relation.createdBy === uid)
    ) {
      return relation.email;
    }
    return `${relation.fname.toUpperCase()[0]} ${relation.lname}`;
  };

  return (
    <div>
      <AcceptModal
        show={accepting[0]}
        onHide={() => setAccepting([false])}
        onAction={accept}
        personName={
          accepting[0] ? `${accepting[1].fname} ${accepting[1].lname}` : ""
        }
        invitationFrom="staff"
      />
      <DeleteRelationModal
        show={deleting[0]}
        onHide={() => setDeleting([false])}
        onAction={deleteRel}
        personName={
          deleting[0] ? `${deleting[1].fname} ${deleting[1].lname}` : ""
        }
        entity="teacher"
      />
      <ul className="list-group">
        {data.map((relation) => {
          return (
            <li className="list-group-item" key={relation.staffId}>
              {displayTeacher(relation)}
              {!relation.confirmed ? (
                <div style={{ display: "inline" }}>
                  {typeof relation.createdBy === "undefined" ||
                  uid === relation.createdBy ? (
                    <span
                      className="badge badge-primary mt-1 float-right"
                      style={{ backgroundColor: "#74c5ed" }}
                    >
                      PENDING
                    </span>
                  ) : (
                    <div className="float-right">
                      <span
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          onRespondToInvite(false, relation.staffId);
                        }}
                        className={`badge badge-danger mr-1 ${
                          styles.response
                        } ${!isTabbing && styles.noTab}`}
                        onKeyPress={(e) => keyPressed(e, relation.staffId)}
                      >
                        REJECT
                      </span>
                      <span
                        role="button"
                        tabIndex={0}
                        onClick={() => setAccepting([true, relation])}
                        className={`badge badge-success ml-1 ${
                          styles.response
                        } ${!isTabbing && styles.noTab}`}
                        onKeyPress={(e) => keyPressed(e, relation.staffId)}
                      >
                        ACCEPT
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <div className={`${styles.delete}`}>
                  <FontAwesomeIcon
                    icon={faTimes}
                    color="#d9534f"
                    onClick={() => setDeleting([true, relation])}
                  />
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

TeacherList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onRespondToInvite: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  anon: PropTypes.bool,
};

TeacherList.defaultProps = {
  anon: false,
};

export default TeacherList;
