import { Fraction } from "./Fraction";
import { Polynomial } from "./Polynomial";
import { ArgumentError, IndexError, NumberSetError } from "../MCError";
/**
 * Class Handling Partial Fractions.
 * @memberof module:MCMaths
 * @author Darren Carter
 * @example
 * // Creates the Partial Fraction $3x+1/(x+1)(x+2)$.
 * p1 = new PartialFraction([3,1],[1,1],[1,2]).
 * // Creates the Partial Fraction $x^2-2x+3/(x+1)^2(x+2)$.
 * p2 = new PartialFraction([1,-2,3],[1,1],[1,1],[1,2]).
 */

class PartialFraction {
  /**
   * Creates a Partial Fraction, limited to 3 linear factors on denominator two of which can be the same.
   * @param {number[]} numerator First parameter is array of coefficients of numerator.
   * @param {number[]} first  Array of coefficients for first linear factor
   * @param {number[]} second Array of coefficients for second linear factor
   * @param {number[]} [third] Array of coefficients for third linear factor
   */
  constructor(numerator, first, second, third) {
    if (!Array.isArray(numerator)) {
      throw new TypeError(
        `Numerator not given as array of coefficients of numerator`
      );
    }
    if (!Array.isArray(first)) {
      throw new TypeError(`First not given as array`);
    }
    if (!Array.isArray(second)) {
      throw new TypeError(`Second not given as array`);
    }
    if (typeof third !== "undefined" && !Array.isArray(third)) {
      throw new TypeError(`Third not given as array`);
    }
    if (first.length !== 2) {
      throw new IndexError(`First needs to be length 2, as a linear factor.`);
    }
    if (second.length !== 2) {
      throw new IndexError(`Second needs to be length 2, as a linear factor.`);
    }
    if (typeof third !== "undefined" && third.length !== 2) {
      throw new IndexError(`Third needs to be length 2, as a linear factor.`);
    }
    this.numerator = new Polynomial(numerator);

    if (
      this.numerator.evaluate(-first[1] / first[0]) === 0 ||
      this.numerator.evaluate(-second[1] / second[0]) === 0 ||
      (typeof third !== "undefined" &&
        this.numerator.evaluate(-third[1] / third[0]) === 0)
    ) {
      throw new ArgumentError("Contains common factor");
    }

    this.numeratorOrder = numerator.length - 1;
    this.first = new Polynomial(first);
    this.second = new Polynomial(second);
    this.denominatorOrder = 2;
    if (typeof third !== "undefined") {
      this.third = new Polynomial(third);
      this.denominatorOrder = 3;
    }
  }

  /**
   * @summary expresses the LHS before split into partial fractions.
   *
   * @returns {string}
   *
   * @example
   * // Creates the string $\\frac{x^2-2x+3}{(x+1)^2(x+2)}$.
   * p1 = new PartialFraction([1,-2,3],[1,1],[1,3],[1,2])
   * console.log(p1.lhsToString())
   */
  lhsToString() {
    let string = "";
    if (
      this.denominatorOrder === 2 &&
      this.first.toString() !== this.second.toString()
    ) {
      string = `\\frac{${this.numerator.toString()}}{(${this.first.toString()})(${this.second.toString()})}`;
    } else if (this.denominatorOrder === 2) {
      string = `\\frac{${this.numerator.toString()}}{(${this.first.toString()})^2}`;
    } else if (this.first.toString() === this.second.toString()) {
      string = `\\frac{${this.numerator.toString()}}{(${this.first.toString()})^2(${this.third.toString()})}`;
    } else if (this.first.toString() === this.third.toString()) {
      string = `\\frac{${this.numerator.toString()}}{(${this.first.toString()})^2(${this.second.toString()})}`;
    } else if (this.second.toString() === this.third.toString()) {
      string = `\\frac{${this.numerator.toString()}}{(${this.second.toString()})^2(${this.first.toString()})}`;
    } else {
      string = `\\frac{${this.numerator.toString()}}{(${this.first.toString()})(${this.second.toString()})(${this.third.toString()})}`;
    }

    string = `$${string.replace(/\$/g, "")}$`;
    return string;
  }

  /**
   * @summary expresses the RHS before split into partial fractions.
   *
   * @returns {string}
   *
   * @example
   * // Creates the string $\\frac{A}{(x+1)^2} + \\frac{B}{(x+1)} + \\frac{C}{(x+2)}$.
   * p1 = new PartialFraction([1,-2,3],[1,1],[1,3],[1,2])
   * console.log(rhsToString())
   */
  rhsToString() {
    let string = "";
    if (this.denominatorOrder === 2 && this.numeratorOrder === 2) {
      string = ` A  +\\frac{B}{(${this.first.toString()})}+\\frac{C}{(${this.second.toString()})}`;
    } else if (
      this.denominatorOrder === 2 &&
      this.first.toString() !== this.second.toString()
    ) {
      string = `\\frac{A}{${this.first.toString()}}+\\frac{B}{${this.second.toString()}}`;
    } else if (this.denominatorOrder === 2) {
      string = `\\frac{A}{(${this.first.toString()})^2}+\\frac{B}{(${this.first.toString()})}`;
    } else if (this.first.toString() === this.second.toString()) {
      string =
        `\\frac{A}{(${this.first.toString()})^2}+\\frac{B}{(${this.first.toString()})}` +
        `+\\frac{C}{(${this.third.toString()})}`;
    } else if (this.first.toString() === this.third.toString()) {
      string =
        `\\frac{A}{(${this.first.toString()})^2}+\\frac{B}{(${this.first.toString()})}` +
        `+\\frac{C}{(${this.second.toString()})}`;
    } else if (this.second.toString() === this.third.toString()) {
      string =
        `\\frac{A}{(${this.second.toString()})^2}+\\frac{B}{(${this.second.toString()})}` +
        `+\\frac{C}{(${this.first.toString()})}`;
    } else {
      string =
        `\\frac{A}{(${this.first.toString()})}+\\frac{B}{(${this.second.toString()})}` +
        `+\\frac{C}{(${this.third.toString()})}`;
    }

    string = `$${string.replace(/\$/g, "")}$`;
    return string;
  }

  /**
   * @summary expresses LSH = RHS as a string, often used as the question.
   *
   * @returns {string[]}
   *
   * @example
   * // Creates array of strings, each element lin of the working for $\\frac{x^2-2x+3}{(x+1)^2(x+2)} \\equiv \\frac{A}{(x+1)^2} + \\frac{B}{(x+1)} + \\frac{C}{(x+2)}$.
   * p1 = new PartialFraction([1,-2,3],[1,1],[1,3],[1,2])
   * console.log(rhsToString())
   */
  toString() {
    const string = `$${this.lhsToString().replace(
      /\$/g,
      ""
    )}\\equiv${this.rhsToString().replace(/\$/g, "")}$`;
    return string;
  }

  /**
   * @summary Provides full working for putting into Partial Fractions.
   *
   * @returns {string}
   *
   * @example
   * // Show working of $\\frac{x^2-2x+3}{(x+1)^2(x+2)}$ to a Partial Fraction.
   * p1 = new PartialFraction([1,-2,3],[1,1],[1,3],[1,2])
   * console.log(p1.lhsToString())
   */
  working() {
    let working = [];
    if (this.denominatorOrder === 2 && this.numeratorOrder === 2) {
      working = this.quadraticOverQuadratic().workingArray;
    } else if (
      this.denominatorOrder === 2 &&
      this.first.toString() !== this.second.toString()
    ) {
      working = this.twoDistinctFactors().workingArray;
    } else if (this.denominatorOrder === 2) {
      working = this.twoRepeatedFactors().workingArray;
    } else if (this.first.toString() === this.second.toString()) {
      working = this.threeFactorsOneRepeated(this.first, this.third)
        .workingArray;
    } else if (this.first.toString() === this.third.toString()) {
      working = this.threeFactorsOneRepeated(this.first, this.second)
        .workingArray;
    } else if (this.second.toString() === this.third.toString()) {
      working = this.threeFactorsOneRepeated(this.second, this.first)
        .workingArray;
    } else {
      working = this.threeDistinctFactors().workingArray;
    }

    // Tidy negative coefficients
    working = working.map((x) =>
      x.replace("$-1A", "$-A").replace("$-1B", "$-B").replace("$-1C", "$-C")
    );
    // remove redundent lines
    working = working.filter((elements) => elements.indexOf("$1A") <= -1);
    working = working.filter((elements) => elements.indexOf("$1B") <= -1);
    working = working.filter((elements) => elements.indexOf("$1C") <= -1);

    return working;
  }

  /**
   * @summary Returns A, B and C for given Partial Fraction.
   *
   * @returns {Object}
   * @returns {Object.A}
   * @returns {Object.B}
   * @returns {Object.C}
   *
   * @example
   * pf = new PartialFraction([1,-2,3],[1,1],[1,3],[1,2])
   * let unknowns = pf.unknowns()
   * console.log(unknowns.A)
   * console.log(unknowns.B)
   * console.log(unknowns.C)
   */
  unknowns() {
    let unknowns = "";
    if (this.denominatorOrder === 2 && this.numeratorOrder === 2) {
      unknowns = this.quadraticOverQuadratic();
    } else if (
      this.denominatorOrder === 2 &&
      this.first.toString() !== this.second.toString()
    ) {
      unknowns = this.twoDistinctFactors();
      return {
        A: unknowns.A.toFloat(),
        B: unknowns.B.toFloat(),
      };
    } else if (this.denominatorOrder === 2) {
      unknowns = this.twoRepeatedFactors();
      return {
        A: unknowns.A.toFloat(),
        B: unknowns.B.toFloat(),
      };
    } else if (this.first.toString() === this.second.toString()) {
      unknowns = this.threeFactorsOneRepeated(this.first, this.third);
    } else if (this.first.toString() === this.third.toString()) {
      unknowns = this.threeFactorsOneRepeated(this.first, this.second);
    } else if (this.second.toString() === this.third.toString()) {
      unknowns = this.threeFactorsOneRepeated(this.second, this.first);
    } else {
      unknowns = this.threeDistinctFactors();
    }

    return {
      A: unknowns.A.toFloat(),
      B: unknowns.B.toFloat(),
      C: unknowns.C.toFloat(),
    };
  }

  // Working for denominator of 2 distinct factors
  twoDistinctFactors() {
    const workingArray = [this.toString()];
    // values of x for substitution
    const x1 = new Fraction(
      -this.first.coefficients[1] / this.first.coefficients[0]
    );
    const x2 = new Fraction(
      -this.second.coefficients[1] / this.second.coefficients[0]
    );
    const A = new Fraction(
      this.numerator.evaluate(x1.toFloat()) / this.second.evaluate(x1.toFloat())
    );
    const B = new Fraction(
      this.numerator.evaluate(x2.toFloat()) / this.first.evaluate(x2.toFloat())
    );
    const ACoefficient = new Fraction(this.second.evaluate(x1.toFloat()));
    const BCoefficient = new Fraction(this.first.evaluate(x2.toFloat()));
    const numeratorx1 = new Fraction(this.numerator.evaluate(x1.toFloat()));
    const numeratorx2 = new Fraction(this.numerator.evaluate(x2.toFloat()));
    let ACoefficientSign = "";
    let BCoefficientSign = "+";
    if (A.toFloat() < 0) {
      ACoefficientSign = "-";
    }
    if (B.toFloat() < 0) {
      BCoefficientSign = "-";
    }

    workingArray.push(
      `$${`${this.numerator.toString()}=A(${this.second.toString()})+B(${this.first.toString()}`.replace(
        /\$/g,
        ""
      )})$`
    );
    workingArray.push(`Let $x=${x1}$`);
    workingArray.push(
      `$${`${this.numerator
        .toString()
        .replace(/x/g, `(${x1})`)}=A(${ACoefficient})+B(0)`.replace(
        /\$/g,
        ""
      )}$`
    );
    workingArray.push(
      `$${`${ACoefficient}A = ${numeratorx1}`.replace(/\$/g, "")}$`
    );
    workingArray.push(`$A=${A}$`);
    workingArray.push(`Let $x=${x2}$`);
    workingArray.push(
      `$${`${this.numerator
        .toString()
        .replace(/x/g, `(${x2})`)}=A(0)+B(${BCoefficient})`.replace(
        /\$/g,
        ""
      )}$`
    );
    workingArray.push(
      `$${`${BCoefficient}B=${numeratorx2}`.replace(/\$/g, "")}$`
    );
    workingArray.push(`$B=${B}$`);
    workingArray.push(
      `$\\therefore ${this.lhsToString().replace(
        /\$/g,
        ""
      )}=${ACoefficientSign}\\frac{${Math.abs(
        A.numerator
      )}}{${`${A.denominator}`.replace(
        "{1(",
        ""
      )}(${this.first
        .toString()
        .replace(/\$/g, "")})}${BCoefficientSign}\\frac{${Math.abs(
        B.numerator
      )}}{${`${B.denominator}`.replace(
        "{1(",
        ""
      )}(${this.second.toString().replace(/\$/g, "")})}$`
    );
    return {
      workingArray,
      A,
      B,
    };
  }

  // Working for denominator of 3 distinct factors
  threeDistinctFactors() {
    const workingArray = [this.toString()];
    // values of x for substitution
    const x1 = new Fraction(
      -this.first.coefficients[1] / this.first.coefficients[0]
    );
    const x2 = new Fraction(
      -this.second.coefficients[1] / this.second.coefficients[0]
    );
    const x3 = new Fraction(
      -this.third.coefficients[1] / this.third.coefficients[0]
    );

    const A = new Fraction(
      this.numerator.evaluate(x1.toFloat()) /
        (this.second.evaluate(x1.toFloat()) * this.third.evaluate(x1.toFloat()))
    );
    const B = new Fraction(
      this.numerator.evaluate(x2.toFloat()) /
        (this.first.evaluate(x2.toFloat()) * this.third.evaluate(x2.toFloat()))
    );
    const C = new Fraction(
      this.numerator.evaluate(x3.toFloat()) /
        (this.first.evaluate(x3.toFloat()) * this.second.evaluate(x3.toFloat()))
    );

    // Coefficient on step before find A,B,C
    const ACoefficient = new Fraction(
      this.second.evaluate(x1.toFloat()) * this.third.evaluate(x1.toFloat())
    );
    const BCoefficient = new Fraction(
      this.first.evaluate(x2.toFloat()) * this.third.evaluate(x2.toFloat())
    );
    const CCoefficient = new Fraction(
      this.first.evaluate(x3.toFloat()) * this.second.evaluate(x3.toFloat())
    );

    // values in each bracket on righthand side after substitution A()() +B()() +C()()
    const ABracket1 = new Fraction(this.second.evaluate(x1.toFloat()));
    const ABracket2 = new Fraction(this.third.evaluate(x1.toFloat()));
    const BBracket1 = new Fraction(this.first.evaluate(x2.toFloat()));
    const BBracket2 = new Fraction(this.third.evaluate(x2.toFloat()));
    const CBracket1 = new Fraction(this.first.evaluate(x3.toFloat()));
    const CBracket2 = new Fraction(this.second.evaluate(x3.toFloat()));

    // Evaluate numerator for different "roots" of denominator
    const numeratorx1 = new Fraction(this.numerator.evaluate(x1.toFloat()));
    const numeratorx2 = new Fraction(this.numerator.evaluate(x2.toFloat()));
    const numeratorx3 = new Fraction(this.numerator.evaluate(x3.toFloat()));

    let ACoefficientSign = "";
    let BCoefficientSign = "+";
    let CCoefficientSign = "+";
    if (A.toFloat() < 0) {
      ACoefficientSign = "-";
    }
    if (B.toFloat() < 0) {
      BCoefficientSign = "-";
    }
    if (C.toFloat() < 0) {
      CCoefficientSign = "-";
    }

    // multiply by factors
    workingArray.push(
      `$${`${this.numerator.toString()}=A(${this.second.toString()})(${this.third.toString()})+B(${this.first.toString()})(${this.third.toString()})+C(${this.first.toString()})(${this.second.toString()})`.replace(
        /\$/g,
        ""
      )}$`
    );

    // Find A
    workingArray.push(`Let $x=${x1}$`);
    workingArray.push(
      `$${`${this.numerator
        .toString()
        .replace(
          /x/g,
          `(${x1})`
        )}=A(${ABracket1})(${ABracket2})+B(0)+C(0)`.replace(/\$/g, "")}$`
    );
    workingArray.push(
      `$${`${ACoefficient}A = ${numeratorx1}`.replace(/\$/g, "")}$`
    );
    workingArray.push(`$A=${A}$`);

    // Find B
    workingArray.push(`Let $x=${x2}$`);
    workingArray.push(
      `$${`${this.numerator
        .toString()
        .replace(
          /x/g,
          `(${x2})`
        )}=A(0)+B(${BBracket1})(${BBracket2})+C(0)`.replace(/\$/g, "")}$`
    );
    workingArray.push(
      `$${`${BCoefficient}B = ${numeratorx2}`.replace(/\$/g, "")}$`
    );
    workingArray.push(`$B=${B}$`);

    // Find C
    workingArray.push(`Let $x=${x3}$`);
    workingArray.push(
      `$${`${this.numerator
        .toString()
        .replace(
          /x/g,
          `(${x3})`
        )}=A(0)+B(0)+C(${CBracket1})(${CBracket2})`.replace(/\$/g, "")}$`
    );
    workingArray.push(
      `$${`${CCoefficient}C = ${numeratorx3}`.replace(/\$/g, "")}$`
    );
    workingArray.push(`$C=${C}$`);

    workingArray.push(
      `$\\therefore ${this.lhsToString().replace(
        /\$/g,
        ""
      )}=${ACoefficientSign}\\frac{${Math.abs(
        A.numerator
      )}}{${`${A.denominator}`.replace(
        "{1(",
        "{("
      )}(${this.first
        .toString()
        .replace(/\$/g, "")})}${BCoefficientSign}\\frac{${Math.abs(
        B.numerator
      )}}{${`${B.denominator}`.replace(
        "{1(",
        "{("
      )}(${this.second
        .toString()
        .replace(/\$/g, "")})}${CCoefficientSign}\\frac{${Math.abs(
        C.numerator
      )}}{${`${C.denominator}`.replace(
        "{1(",
        "{("
      )}(${this.first.toString().replace(/\$/g, "")})}$`
    );
    return {
      workingArray,
      A,
      B,
      C,
    };
  }

  // working for
  threeFactorsOneRepeated(repeatedFactor, otherFactor) {
    const workingArray = [this.toString()];
    // values of x for substitution
    const x1 = new Fraction(
      -repeatedFactor.coefficients[1] / repeatedFactor.coefficients[0]
    );
    const x2 = new Fraction(
      -otherFactor.coefficients[1] / otherFactor.coefficients[0]
    );

    // third value is arbitary, cannot be x1 or x2
    let x3 = 0;
    if (x3 === x1.toFloat() || x3 === x2.toFloat()) {
      x3 = 1;
    }
    if (x3 === x1.toFloat() || x3 === x2.toFloat()) {
      x3 = 2;
    }

    // evaluate each factor with x3
    const repeatedFactorx3 = new Fraction(repeatedFactor.evaluate(x3));
    const otherFactorx3 = new Fraction(otherFactor.evaluate(x3));

    const A = new Fraction(
      this.numerator.evaluate(x1.toFloat()) / otherFactor.evaluate(x1.toFloat())
    );
    const C = new Fraction(
      this.numerator.evaluate(x2.toFloat()) /
        (repeatedFactor.evaluate(x2.toFloat()) *
          repeatedFactor.evaluate(x2.toFloat()))
    );

    // value in each of B's brackets after x3 substitution
    // const BBracket1 = new Fraction(repeatedFactor.evaluate(x3));
    // const BBracket2 = new Fraction(otherFactor.evaluate(x3));

    // Coefficient on step before find A,B,C
    const ACoefficient = new Fraction(otherFactor.evaluate(x1.toFloat()));
    const CCoefficient = new Fraction(
      repeatedFactor.evaluate(x2.toFloat()) *
        repeatedFactor.evaluate(x2.toFloat())
    );

    // Evaluate numerator for different "roots" of denominator
    const numeratorx1 = new Fraction(this.numerator.evaluate(x1.toFloat()));
    const numeratorx2 = new Fraction(this.numerator.evaluate(x2.toFloat()));
    // const numeratorx3 = new Fraction(this.numerator.evaluate(x3));

    // Rhs when finding B before division
    const BRHS = new Fraction(
      this.numerator.evaluate(x3) -
        A * otherFactorx3.toFloat() -
        C * repeatedFactorx3.toFloat() * repeatedFactorx3.toFloat()
    );

    let B = new Fraction(BRHS.toFloat());
    B = B.divide(repeatedFactorx3.toFloat() * otherFactorx3.toFloat());

    // Signs
    let ACoefficientSign = "";
    let BCoefficientSign = "+";
    let CCoefficientSign = "+";
    if (A.toFloat() < 0) {
      ACoefficientSign = "-";
    }
    if (B.toFloat() < 0) {
      BCoefficientSign = "-";
    }
    if (C.toFloat() < 0) {
      CCoefficientSign = "-";
    }
    // multiply by factors
    workingArray.push(
      `$${`${this.numerator.toString()}=A(${otherFactor.toString()})+B(${repeatedFactor.toString()})(${otherFactor.toString()})+C(${repeatedFactor.toString()})^2`.replace(
        /\$/g,
        ""
      )}$`
    );

    // Find A
    workingArray.push(`Let $x=${x1}$`);
    workingArray.push(
      `$${`${this.numerator
        .toString()
        .replace(/x/g, `(${x1})`)}=A(${ACoefficient})+B(0)+C(0)`.replace(
        /\$/g,
        ""
      )}$`
    );
    workingArray.push(
      `$${`${ACoefficient}A = ${numeratorx1}`.replace(/\$/g, "")}$`
    );
    workingArray.push(`$A=${A}$`);

    // sub x2 to eliminate A and B
    workingArray.push(`Let $x=${x2}$`);
    workingArray.push(
      `$${`${this.numerator
        .toString()
        .replace(/x/g, `(${x2})`)}=A(0)+B(0)+C(${repeatedFactor.evaluate(
        x2.toFloat()
      )})^2`.replace(/\$/g, "")}$`
    );
    workingArray.push(
      `$${`${CCoefficient}C = ${numeratorx2}`.replace(/\$/g, "")}$`
    );
    workingArray.push(`$C=${C}$`);

    // sub x3, A, C to find B
    workingArray.push(`Let $x=${x3}$, with $A =${A}$ and $C=${C}$`);
    workingArray.push(
      `$${`${this.numerator
        .toString()
        .replace(
          /x/g,
          `(${x3})`
        )}=${A}(${otherFactorx3})+B(${repeatedFactorx3})(${otherFactorx3})${CCoefficientSign}${new Fraction(
        Math.abs(C.toFloat())
      )}(${repeatedFactorx3})^2`.replace(/\$/g, "")}$`
    );
    workingArray.push(
      `$${`${repeatedFactorx3 * otherFactorx3}B=${BRHS}`.replace(/\$/g, "")}$`
    );
    workingArray.push(`$B=${B}$`);

    workingArray.push(
      `$\\therefore ${`${this.lhsToString()}=${ACoefficientSign}\\frac{${Math.abs(
        A.numerator
      )}}{${
        A.denominator
      }(${repeatedFactor.toString()})^2}${BCoefficientSign}\\frac{${Math.abs(
        B.numerator
      )}}{${
        B.denominator
      }(${repeatedFactor.toString()})}${CCoefficientSign}\\frac{${Math.abs(
        C.numerator
      )}}{${C.denominator}(${otherFactor.toString()})}`
        .replace(/\$/g, "")
        .replace("{1(", "{(")
        .replace("{1(", "{(")
        .replace("{1(", "{(")}$`
    );
    return {
      workingArray,
      A,
      B,
      C,
    };
  }

  // working for
  twoRepeatedFactors() {
    const workingArray = [this.toString()];
    const x1 = new Fraction(
      -this.first.coefficients[1] / this.first.coefficients[0]
    );
    const A = new Fraction(this.numerator.evaluate(x1.toFloat()));
    // third value is arbitary, cannot be x1 or x2
    let x2 = 0;
    if (x1.toFloat() === x2) {
      x2 = 1;
    }

    // Coefficient on step before finding B
    const BCoefficient = new Fraction(this.first.evaluate(x2));
    // Right hand side when Bcoefficient(B) is subject
    const RHSB = new Fraction(this.numerator.evaluate(x2) - A);
    const B = RHSB.divide(BCoefficient);

    // Signs
    let ACoefficientSign = "";
    let BCoefficientSign = "+";
    if (A.toFloat() < 0) {
      ACoefficientSign = "-";
    }
    if (B < 0) {
      BCoefficientSign = "-";
    }

    // multiply by factors
    workingArray.push(
      `$${`${this.numerator.toString()}=A+B(${this.first.toString()})`.replace(
        /\$/g,
        ""
      )}$`
    );

    // set x to "root" of repeated root
    workingArray.push(`Let $x=${x1}$`);
    workingArray.push(
      `$${`${this.numerator
        .toString()
        .replace(/x/g, `(${x1})`)}=A+B(0)`.replace(/\$/g, "")}$`
    );
    workingArray.push(`$A=${A}$`);

    // set x to second value and use known value of A
    workingArray.push(`Let $x=${x2}$`);
    workingArray.push(
      `$${`${this.numerator
        .toString()
        .replace(/x/g, `(${x2})`)}=${A}+B(${BCoefficient})`.replace(
        /\$/g,
        ""
      )}$`
    );
    workingArray.push(`$${`${BCoefficient}B = ${RHSB}`.replace(/\$/g, "")}$`);
    workingArray.push(`$B=${B}$`);

    workingArray.push(
      `$\\therefore ${`${this.lhsToString()}=${ACoefficientSign}\\frac{${Math.abs(
        A.numerator
      )}}{${
        A.denominator
      }(${this.first.toString()})^2}${BCoefficientSign}\\frac{${Math.abs(
        B.numerator
      )}}{${B.denominator}(${this.first.toString()})}`
        .replace(/\$/g, "")
        .replace("{1(", "{(")
        .replace("{1(", "{(")
        .replace("{1(", "{(")}$`
    );
    return {
      workingArray,
      A,
      B,
    };
  }

  // Order 2 over order 2 leading to A + B/() + C/()
  quadraticOverQuadratic() {
    const workingArray = [this.toString()];
    // values of x for substitution
    const x1 = new Fraction(
      -this.first.coefficients[1] / this.first.coefficients[0]
    );
    const x2 = new Fraction(
      -this.second.coefficients[1] / this.second.coefficients[0]
    );

    // third value is arbitary, cannot be x1 or x2
    let x3 = 0;
    if (x3 === x1.toFloat() || x3 === x2.toFloat()) {
      x3 = 1;
    }
    if (x3 === x1.toFloat() || x3 === x2.toFloat()) {
      x3 = 2;
    }

    // evaluate each factor with x3
    const firstFactorx3 = new Fraction(this.first.evaluate(x3));
    const secondFactorx3 = new Fraction(this.second.evaluate(x3));

    // value in each of B's brackets after x3 substitution
    // const BBracket1 = new Fraction(this.first.evaluate(x3));
    // const BBracket2 = new Fraction(this.second.evaluate(x3));

    // Coefficient on step before find A,B,C
    const ACoefficient = new Fraction(
      this.first.evaluate(x3) * this.second.evaluate(x3)
    );
    const BCoefficient = new Fraction(this.second.evaluate(x1.toFloat()));
    const CCoefficient = new Fraction(this.first.evaluate(x2.toFloat()));

    // Evaluate numerator for different "roots" of denominator
    const numeratorx1 = new Fraction(this.numerator.evaluate(x1.toFloat()));
    const numeratorx2 = new Fraction(this.numerator.evaluate(x2.toFloat()));
    // const numeratorx3 = new Fraction(this.numerator.evaluate(x3));

    // Rhs when finding B before division
    const B = numeratorx1.divide(BCoefficient);
    const C = numeratorx2.divide(CCoefficient);

    // Right hand side when Acoefficient(A) is subject
    const RHSA = new Fraction(
      this.numerator.evaluate(x3) -
        B.toFloat() * this.second.evaluate(x3) -
        C.toFloat() * this.first.evaluate(x3)
    );
    const A = RHSA.divide(ACoefficient);

    // Signs
    // let ACoefficientSign = "";
    let BCoefficientSign = "+";
    let CCoefficientSign = "+";
    // if (A.toFloat() < 0) {
    //   ACoefficientSign = "-";
    // }
    if (B < 0) {
      BCoefficientSign = "-";
    }
    if (C < 0) {
      CCoefficientSign = "-";
    }

    // multiply by factors
    workingArray.push(
      `$${`${this.numerator.toString()}=A(${this.first.toString()})(${this.second.toString()})+B(${this.second.toString()})+C(${this.first.toString()})`.replace(
        /\$/g,
        ""
      )}$`
    );

    // Find B
    workingArray.push(`Let $x=${x1}$`);
    workingArray.push(
      `$${`${this.numerator
        .toString()
        .replace(/x/g, `(${x1})`)}=A(0)+B(${BCoefficient})+C(0)`.replace(
        /\$/g,
        ""
      )}$`
    );
    workingArray.push(
      `$${`${BCoefficient}B = ${numeratorx1}`.replace(/\$/g, "")}$`
    );
    workingArray.push(`$B=${B}$`);

    // Find C
    workingArray.push(`Let $x=${x2}$`);
    workingArray.push(
      `$${`${this.numerator
        .toString()
        .replace(/x/g, `(${x2})`)}=A(0)+B(0)+C(${CCoefficient})`.replace(
        /\$/g,
        ""
      )}$`
    );
    workingArray.push(
      `$${`${CCoefficient}C = ${numeratorx2}`.replace(/\$/g, "")}$`
    );
    workingArray.push(`$C=${C}$`);

    // sub x3, A, C to find B
    workingArray.push(`Let $x=${x3}$, with $B =${B}$ and $C=${C}$`);
    workingArray.push(
      `$${`${this.numerator
        .toString()
        .replace(
          /x/g,
          `(${x3})`
        )}=A(${firstFactorx3})(${secondFactorx3})${BCoefficientSign}${Math.abs(
        B
      )}(${secondFactorx3})${CCoefficientSign}${Math.abs(
        C
      )}(${firstFactorx3})`.replace(/\$/g, "")}$`
    );
    workingArray.push(`$${`${ACoefficient}A = ${RHSA}`.replace(/\$/g, "")}$`);
    workingArray.push(`$A=${A}$`);

    workingArray.push(
      `$\\therefore ${`${this.lhsToString()}=${A}${BCoefficientSign}\\frac{${Math.abs(
        B.numerator
      )}}{${
        B.denominator
      }(${this.first.toString()})}${CCoefficientSign}\\frac{${Math.abs(
        C.numerator
      )}}{${C.denominator}(${this.second.toString()})}`
        .replace(/\$/g, "")
        .replace("{1(", "{(")
        .replace("{1(", "{(")
        .replace("{1(", "{(")}$`
    );
    return {
      workingArray,
      A,
      B,
      C,
    };
  }

  /**
   * Evaluates the partial Fraction.
   *
   * @param {number} coordinate Coordinate to evaluate at.
   * @returns {number} The value of the instance evaluated at the input value.
   * @throws {NumberSetError} The coordinate should be a number.
   *
   */
  evaluate(coordinate) {
    if (typeof coordinate !== "number") {
      throw new NumberSetError(`Expected a number, got ${coordinate} instead`);
    }
    if (this.denominatorOrder === 2) {
      return (
        this.numerator.evaluate(coordinate) /
        (this.first.evaluate(coordinate) * this.second.evaluate(coordinate))
      );
    }
    if (this.denominatorOrder === 3) {
      return (
        this.numerator.evaluate(coordinate) /
        (this.first.evaluate(coordinate) *
          this.second.evaluate(coordinate) *
          this.third.evaluate(coordinate))
      );
    }
    return "Error - Contact James, Partial Fractions Line 875";
  }
}

export { PartialFraction };
