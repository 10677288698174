import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "../styles/Skills.module.css";
import MathString from "../MathString";
import ImageCanvas from "../ImageCanvas";
import Graph from "../Graph";
import { cleaner } from "../../system/modules/MCMaths/cleaner";

const Skills = ({
  question,
  check,
  checked,
  correct,
  setSolution,
  solution,
  disabled,
  oldInputs,
}) => {
  // required for exam questions
  const [eqInputs, setEQInputs] = useState([]);
  const [singleInput, setSingleInput] = useState(true);
  const colSize = 12 / question.perLine;

  // sets enough inputs for the exam question
  useEffect(() => {
    if (oldInputs) {
      setEQInputs(oldInputs);
    } else {
      const inps = [];
      for (let i = 0; i < question.parts.length; i += 1) {
        for (let j = 0; j < question.parts[i].answer.length; j += 1) {
          inps.push(question.parts[i].answer[j]);
        }
      }
      if (inps.length === 1) {
        setSingleInput(true);
      } else {
        setSingleInput(false);
      }
      setEQInputs(inps);
    }
  }, [question, oldInputs]);

  const toDeci = (fraction) => {
    const fract = fraction.toString();
    let result;
    let wholeNum = 0;
    let frac;
    let deci = 0;
    if (fract.search("/") >= 0) {
      if (fract.search(" ") >= 0) {
        wholeNum = fract.split(" ");
        [, frac] = wholeNum;
        wholeNum = parseInt(wholeNum, 10);
      } else {
        frac = fract;
      }
      if (fract.search("/") >= 0) {
        frac = frac.split("/");
        deci = parseInt(frac[0], 10) / parseInt(frac[1], 10);
      }
      result = wholeNum + deci;
    } else {
      result = fract;
    }
    return result;
  };

  const checkAnswers = () => {
    let correctness = 0;
    for (let i = 0; i < eqInputs.length; i += 1) {
      let isCorrect = 1;
      const givenAns = eqInputs[i].givenAnswer;
      if (givenAns === "" || typeof givenAns === "undefined") {
        isCorrect = 0;
      }
      if (Array.isArray(eqInputs[i].content.answer)) {
        const { tolerance } = eqInputs[i].content;
        if (tolerance === 0) {
          const ans = toDeci(givenAns);
          if (Number.isNaN(ans)) {
            isCorrect = 0;
          } else {
            let there = -1;
            for (let a = 0; a < eqInputs[i].content.answer.length; a += 1) {
              if (
                cleaner(eqInputs[i].content.answer[a].toString()) ===
                cleaner(ans.toString())
              ) {
                there = a;
              }
            }
            if (there === -1) {
              isCorrect = 0;
            } else {
              eqInputs[i].content.answer.splice(there, 1);
            }
          }
        } else {
          const ans = toDeci(givenAns);
          if (Number.isNaN(ans)) {
            isCorrect = 0;
          } else {
            const boundedArray = [];
            for (let a = 0; a < eqInputs[i].content.answer.length; a += 1) {
              const possible = eqInputs[i].content.answer[a];
              const upperbound = Number(possible) + Number(tolerance);
              const lowerbound = Number(possible) - Number(tolerance);
              boundedArray.push({ upperbound, lowerbound });
            }
            const size = boundedArray.length;
            for (let a = 0; a < boundedArray.length; a += 1) {
              const { upperbound, lowerbound } = boundedArray[a];
              if (ans >= lowerbound && ans <= upperbound) {
                eqInputs[i].content.answer.splice(a, 1);
                boundedArray.splice(a, 1);
              }
            }
            if (boundedArray.length === size) {
              isCorrect = 0;
            }
          }
        }
      } else if (eqInputs[i].content.tolerance === 0) {
        if (
          !(
            cleaner(eqInputs[i].content.answer.toString()) ===
            cleaner(toDeci(givenAns).toString())
          )
        ) {
          isCorrect = 0;
        }
      } else {
        const ans = toDeci(givenAns);
        if (Number.isNaN(ans)) {
          isCorrect = 0;
        } else {
          const lowerbound =
            Number(eqInputs[i].content.answer) -
            Number(eqInputs[i].content.tolerance);
          const upperbound =
            Number(eqInputs[i].content.answer) +
            Number(eqInputs[i].content.tolerance);
          if (ans < lowerbound || ans > upperbound) {
            isCorrect = 0;
          }
        }
      }
      correctness += isCorrect;
    }
    correctness /= eqInputs.length;
    check(correctness, eqInputs);
  };

  const handleChange = (e) => {
    const arr = [...eqInputs];
    arr[e.target.dataset.id].givenAnswer = e.target.value;
    setEQInputs(arr);
  };

  const eventPressed = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) {
      checkAnswers();
    }
  };

  return (
    <>
      <div className={`${styles.padout}`}>
        {question.main.map((elem, index) => {
          if (elem.type === "paragraph") {
            return (
              <div
                key={index}
                className={`${styles.text}  ${
                  typeof elem.content.heading !== "undefined" &&
                  elem.content.heading &&
                  styles.heading
                }`}
              >
                <MathString str={elem.content.p} fontSize={elem.content.size} />
              </div>
            );
          }
          if (elem.type === "image") {
            if (elem.content.measure === "height") {
              return (
                <div className="text-center" key={index}>
                  <ImageCanvas
                    img={elem.content.src}
                    height={elem.content.size}
                    overlays={elem.content.overlays}
                  />
                </div>
              );
            }
            return (
              <div className="text-center" key={index}>
                <ImageCanvas
                  img={elem.content.src}
                  width={elem.content.size}
                  overlays={elem.content.overlays}
                />
              </div>
            );
          }
          if (elem.type === "graph") {
            return (
              <div className="text-center" key={index}>
                <Graph graphObjContent={elem.content} />
              </div>
            );
          }
          return <div />;
        })}
        {question.parts.map((elem, index) => {
          return elem.question.map((q, i) => {
            if (q.type === "paragraph") {
              return (
                <div
                  key={index * 100 + i * 10}
                  className={`${styles.text}  ${
                    typeof q.content.heading !== "undefined" &&
                    q.content.heading &&
                    styles.heading
                  }`}
                >
                  <MathString str={q.content.p} fontSize={q.content.size} />
                </div>
              );
            }
            if (q.type === "image") {
              if (q.content.measure === "height") {
                return (
                  <div className="text-center" key={index * 100 + i * 10}>
                    <ImageCanvas
                      img={q.content.src}
                      height={q.content.size}
                      overlays={q.content.overlays}
                    />
                  </div>
                );
              }
              return (
                <div className="text-center" key={index * 100 + i * 10}>
                  <ImageCanvas
                    img={q.content.src}
                    width={q.content.size}
                    overlays={q.content.overlays}
                  />
                </div>
              );
            }
            if (q.type === "graph") {
              return (
                <div className="text-center" key={index}>
                  <Graph graphObjContent={q.content} />
                </div>
              );
            }
            return <div />;
          });
        })}
      </div>
      <hr />
      {!checked && (
        <>
          {disabled && <b>Answer given:</b>}
          <div className={`${styles.padout} ${styles.inputArea} row`}>
            {eqInputs.map((inp, index) => {
              const ansId = `ans-${index}`;
              const count = index;
              return (
                <div className={`col-sm-${colSize}`} key={index}>
                  <div className={`input-group ${styles.input}`}>
                    {typeof inp.content.labelPos === "undefined" && (
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          <MathString str={inp.content.label} fontSize={0.9} />
                        </span>
                      </div>
                    )}
                    {inp.content.labelPos === "before" && (
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          <MathString str={inp.content.label} fontSize={0.9} />
                        </span>
                      </div>
                    )}
                    <input
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      onChange={handleChange}
                      onKeyPress={eventPressed}
                      value={
                        oldInputs ? inp.givenAnswer : inp.content.givenAnswer
                      }
                      name={ansId}
                      id={ansId}
                      data-id={count}
                      aria-label={inp.content.label}
                      aria-describedby="basic-addon1"
                      disabled={disabled}
                    />
                    {inp.labelPos === "after" && (
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon1">
                          <MathString str={inp.content.label} fontSize={0.9} />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {!disabled ? (
            <div className="text-center">
              <button
                type="button"
                className="btn btn-success"
                onClick={checkAnswers}
              >
                {!singleInput && "Check Answers"}
                {singleInput && "Check Answer"}
              </button>
            </div>
          ) : (
            <hr />
          )}
        </>
      )}
      {checked && correct < 1 && correct >= 0.65 && (
        <div className="alert alert-warning" role="alert">
          <b>Almost there! </b>
          Check out the solution below.
        </div>
      )}
      {checked && correct < 0.65 && correct > 0 && (
        <div className="alert alert-warning" role="alert">
          <b>Partially correct! </b>
          Check out the solution below.
        </div>
      )}
      {checked && correct <= 0 && (
        <div className="alert alert-danger" role="alert">
          <b>Incorrect! </b>
          Check out the solution below.
        </div>
      )}
      {checked && correct >= 1 && (
        <div className="alert alert-success" role="alert">
          <b>Correct! </b>
          <div style={{ display: "inline" }}>Click </div>
          <button
            type="button"
            className={`alert-link ${styles.alertBtn}`}
            onClick={setSolution}
          >
            here
          </button>
          <div style={{ display: "inline" }}> to see our solution.</div>
        </div>
      )}
      {disabled && <b>Worked solution:</b>}
      {solution && (
        <div className={`${styles.padoutSol}`}>
          {question.parts.map((elem, index) => {
            return elem.solution.map((q, i) => {
              if (q.type === "paragraph") {
                return (
                  <div
                    key={index * 100 + i * 10}
                    className={`${styles.text} ${
                      !disabled && styles.solText
                    }  ${
                      typeof q.content.heading !== "undefined" &&
                      q.content.heading &&
                      styles.heading
                    }`}
                  >
                    <MathString str={q.content.p} fontSize={q.content.size} />
                  </div>
                );
              }
              if (q.type === "image") {
                if (q.content.measure === "height") {
                  return (
                    <div className="text-center" key={index}>
                      <ImageCanvas
                        img={q.content.src}
                        height={q.content.size}
                        overlays={q.content.overlays}
                      />
                    </div>
                  );
                }
                return (
                  <div className="text-center" key={index}>
                    <ImageCanvas
                      img={q.content.src}
                      width={q.content.size}
                      overlays={q.content.overlays}
                    />
                  </div>
                );
              }
              if (q.type === "graph") {
                return (
                  <div className="text-center" key={index}>
                    <Graph graphObjContent={q.content} />
                  </div>
                );
              }
              return <div />;
            });
          })}
        </div>
      )}
    </>
  );
};

Skills.propTypes = {
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  check: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  correct: PropTypes.bool.isRequired,
  setSolution: PropTypes.func.isRequired,
  solution: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  oldInputs: PropTypes.arrayOf(PropTypes.any),
};

Skills.defaultProps = {
  disabled: false,
  oldInputs: undefined,
};

export default Skills;
