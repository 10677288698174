import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ExamQuestion from "../question_types/ExamQuestion";
import MathString from "../MathString";
import styles from "../styles/QuestionDisplay.module.css";
import MultipleChoiceQuestion from "../question_types/MultipleChoiceQuestion";
import MultipleChoiceAnswers from "../question_types/MultipleChoiceAnswers";
import Skills from "../question_types/Skills";
import { cleaner } from "../../system/modules/MCMaths/cleaner";
import Checkbox from "../Checkbox";
import Alert from "../Alert";

const DevQuestionDisplay = ({ question }) => {
  // required for exam questions
  const [eqInputs, setEQInputs] = useState([]);
  // does this question only have a single input box
  const [singleInput, setSingleInput] = useState(true);
  // was the question checked yet
  const [checked, setChecked] = useState(false);
  // was the answer correct
  const [correct, setCorrect] = useState(0);
  // show solution
  const [solution, setSolution] = useState(true);
  const [confirmedSelf, setSelf] = useState(true);
  const [alert, setAlert] = useState({});

  // sets enough inputs for the exam question
  useEffect(() => {
    if (question.type === "exam") {
      const inps = [];
      for (let i = 0; i < question.content.inputGroups.length; i += 1) {
        const { perLine } = question.content.inputGroups[i];
        let toFill = [];
        for (
          let j = 0;
          j < question.content.inputGroups[i].inputs.length;
          j += 1
        ) {
          toFill.push({
            answer: "",
            labelPos:
              question.content.inputGroups[i].inputs[j].content.labelPos,
            label: question.content.inputGroups[i].inputs[j].content.label,
            expecting: question.content.inputGroups[i].inputs[j].content.answer,
            tolerance:
              question.content.inputGroups[i].inputs[j].content.tolerance,
          });
          if (toFill.length === perLine) {
            inps.push([...toFill]);
            toFill = [];
          }
        }
        if (toFill.length !== 0) {
          inps.push([...toFill]);
          toFill = [];
        }
      }
      if (inps.length === 1 && inps[0].length === 1) {
        setSingleInput(true);
      } else {
        setSingleInput(false);
      }
      setEQInputs(inps);
      if (question.self) {
        setSelf(false);
      }
    }
  }, [question]);

  // handles changes for dynamic inputs in Exam Questions
  const handleChange = (e) => {
    const arr = [...eqInputs];
    const row = Math.floor(e.target.dataset.id / 100);
    const col = e.target.dataset.id % 100;

    arr[row][col].answer = e.target.value;
    setEQInputs(arr);
  };

  const toDeci = (fraction) => {
    const fract = fraction.toString();
    let result;
    let wholeNum = 0;
    let frac;
    let deci = 0;
    if (fract.search("/") >= 0) {
      if (fract.search(" ") >= 0) {
        wholeNum = fract.split(" ");
        [, frac] = wholeNum;
        wholeNum = parseInt(wholeNum, 10);
      } else {
        frac = fract;
      }
      if (fract.search("/") >= 0) {
        frac = frac.split("/");
        deci = parseInt(frac[0], 10) / parseInt(frac[1], 10);
      }
      result = wholeNum + deci;
    } else {
      result = fract;
    }
    return result;
  };

  const checkForEmptyInputs = () => {
    for (let i = 0; i < eqInputs.length; i += 1) {
      for (let j = 0; j < eqInputs[i].length; j += 1) {
        if (
          typeof eqInputs[i][j].answer === "undefined" ||
          eqInputs[i][j].answer.replace(/\s+/g, "") === ""
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const checkExamAnswers = async () => {
    if (question.self && !confirmedSelf) {
      setAlert({
        variant: "danger",
        msg: "Please complete all parts before checking.",
      });
      return false;
    }
    if (checkForEmptyInputs()) {
      setAlert({
        variant: "danger",
        msg: "Please complete all parts before checking.",
      });
      return false;
    }
    setAlert({});
    let correctness = 0;
    for (let i = 0; i < eqInputs.length; i += 1) {
      let isCorrect = 1;
      for (let j = 0; j < eqInputs[i].length; j += 1) {
        eqInputs[i][j].correct = true;
        if (Array.isArray(eqInputs[i][j].expecting)) {
          if (eqInputs[i][j].tolerance === 0) {
            const ans = toDeci(eqInputs[i][j].answer);
            if (Number.isNaN(Number(ans))) {
              isCorrect = 0;
              eqInputs[i][j].correct = false;
            } else {
              let there = -1;
              for (let a = 0; a < eqInputs[i][j].expecting.length; a += 1) {
                if (
                  cleaner(eqInputs[i][j].expecting[a].toString()) ===
                  cleaner(ans.toString())
                ) {
                  there = a;
                }
              }
              if (there === -1) {
                isCorrect = 0;
                eqInputs[i][j].correct = false;
              } else {
                eqInputs[i][j].expecting.splice(there, 1);
              }
            }
          } else {
            const ans = toDeci(eqInputs[i][j].answer);
            if (Number.isNaN(Number(ans))) {
              isCorrect = 0;
              eqInputs[i][j].correct = false;
            } else {
              const boundedArray = [];
              for (let a = 0; a < eqInputs[i][j].expecting.length; a += 1) {
                const possible = eqInputs[i][j].expecting[a];
                const { tolerance } = eqInputs[i][j];
                const upperbound = Number(possible) + Number(tolerance);
                const lowerbound = Number(possible) - Number(tolerance);
                boundedArray.push({ upperbound, lowerbound });
              }
              const size = boundedArray.length;
              for (let a = 0; a < boundedArray.length; a += 1) {
                const { upperbound, lowerbound } = boundedArray[a];
                if (ans >= lowerbound && ans <= upperbound) {
                  eqInputs[i][j].expecting.splice(a, 1);
                  boundedArray.splice(a, 1);
                }
              }
              if (boundedArray.length === size) {
                isCorrect = 0;
                eqInputs[i][j].correct = false;
              }
            }
          }
        } else if (eqInputs[i][j].tolerance === 0) {
          if (
            !(
              cleaner(eqInputs[i][j].expecting.toString()) ===
              cleaner(toDeci(eqInputs[i][j].answer).toString())
            )
          ) {
            isCorrect = 0;
            eqInputs[i][j].correct = false;
          }
        } else {
          const ans = toDeci(eqInputs[i][j].answer);
          if (Number.isNaN(Number(ans))) {
            isCorrect = 0;
            eqInputs[i][j].correct = false;
          } else {
            const lowerbound =
              Number(eqInputs[i][j].expecting) -
              Number(eqInputs[i][j].tolerance);
            const upperbound =
              Number(eqInputs[i][j].expecting) +
              Number(eqInputs[i][j].tolerance);
            if (ans < lowerbound || ans > upperbound) {
              isCorrect = 0;
              eqInputs[i][j].correct = false;
            }
          }
        }
      }
      correctness += isCorrect;
    }
    correctness /= eqInputs.length;
    console.log(eqInputs);
    setCorrect(correctness);
    if (correctness < 1) {
      setSolution(true);
    }
    setChecked(true);
    return correctness;
  };

  const checkMCQAnswers = (right) => {
    setChecked(true);
    setCorrect(right);
  };

  const checkSkillsAnswers = (right) => {
    setChecked(true);
    setCorrect(right);
    if (!right) {
      setSolution(true);
    }
  };

  const setSol = () => {
    setSolution(true);
  };

  const eventPressed = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) {
      checkExamAnswers();
    }
  };

  const selfAssesment = () => {
    checkExamAnswers();
    setSolution(true);
    setChecked(true);
  };

  switch (question.type) {
    case "exam": {
      return (
        <>
          {alert.msg && (
            <div className="mt-4">
              <Alert variant={alert.variant}>{alert.msg}</Alert>
            </div>
          )}
          <div className="px-2">
            <h6>You are currently viewing this question in developer mode.</h6>
          </div>
          <hr />
          <div className={`${styles.padout}`}>
            <ExamQuestion type="question" data={question.content.question} />
          </div>

          <hr />
          {solution && (
            <div className="px-2">
              <ExamQuestion type="solution" data={question.content.solution} />
            </div>
          )}
          <hr />
          {!checked && (
            <>
              <div className={`${styles.padout} ${styles.inputArea} row`}>
                {eqInputs.map((arr, index) => {
                  const colSize = 12 / arr.length;
                  return arr.map((ans, i) => {
                    const ansId = `ans-${index}-${i}`;
                    const count = index * 100 + i;
                    return (
                      <div className={`col-sm-${colSize}`} key={index + i}>
                        <div className={`input-group ${styles.input}`}>
                          {ans.labelPos === "before" && (
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <MathString str={ans.label} fontSize={0.9} />
                              </span>
                            </div>
                          )}
                          <input
                            autoComplete="off"
                            type="text"
                            onChange={handleChange}
                            onKeyPress={eventPressed}
                            className="form-control"
                            value={ans.answer}
                            name={ansId}
                            id={ansId}
                            data-id={count}
                            aria-label={ans.label}
                            aria-describedby="basic-addon1"
                          />
                          {ans.labelPos === "after" && (
                            <div className="input-group-append">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                <MathString str={ans.label} fontSize={0.9} />
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  });
                })}
              </div>
              {question.self && (
                <div className="text-center mb-3">
                  <Checkbox handleToggle={() => setSelf((s) => !s)}>
                    I have answered all parts that require self-marking
                  </Checkbox>
                </div>
              )}
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={!question.self ? checkExamAnswers : selfAssesment}
                >
                  {!singleInput && "Check Answers"}
                  {singleInput && "Check Answer"}
                </button>
              </div>
            </>
          )}
          {checked && correct < 1 && !question.self && (
            <div className="alert alert-danger" role="alert">
              <b>Incorrect! </b>
              Check out the solution below.
            </div>
          )}
          {checked && correct < 1 && question.self && (
            <div className="alert alert-danger" role="alert">
              <b>Incorrect! </b>
            </div>
          )}
          {checked && correct >= 1 && question.self && (
            <div className="alert alert-success" role="alert">
              <b>Correct! </b>
            </div>
          )}
          {checked && correct >= 1 && !question.self && (
            <div className="alert alert-success" role="alert">
              <b>Correct! </b>
              <div style={{ display: "inline" }}>Click </div>
              <button
                type="button"
                className={`alert-link ${styles.alertBtn}`}
                onClick={() => setSolution(true)}
              >
                here
              </button>
              <div style={{ display: "inline" }}> to see our solution.</div>
            </div>
          )}
          {checked && solution && question.self && (
            <div className={`${styles.padNoInp} my-4`}>
              <div className={`${styles.option} text-center`}>
                Did you answer the self-marking parts correctly?
                <div>
                  <button
                    type="button"
                    className={`alert-link ${styles.yesBtn} mx-4`}
                    onClick={checkExamAnswers}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className={`alert-link ${styles.noBtn} mx-4`}
                    onClick={() => {
                      setCorrect(0);
                      setChecked(true);
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
    case "mcq": {
      return (
        <>
          <div className="px-2">
            <h6>You are currently viewing this question in developer mode.</h6>
          </div>
          <hr />
          <div className={`${styles.padout}`}>
            <MultipleChoiceQuestion data={question.content.question} />
            <MultipleChoiceAnswers
              data={question.content.answers}
              disabled={checked}
              checkFunction={checkMCQAnswers}
            />
          </div>
          {checked && correct < 1 && (
            <div className="alert alert-danger my-4 mx-2" role="alert">
              <b>Incorrect! </b>
            </div>
          )}
          {checked && correct >= 1 && (
            <div className="alert alert-success my-4 mx-2" role="alert">
              <b>Correct! </b>
            </div>
          )}
        </>
      );
    }
    case "skill": {
      return (
        <>
          <div className="px-2">
            <h6>You are currently viewing this question in developer mode.</h6>
          </div>
          <hr />
          <Skills
            question={question.content}
            check={checkSkillsAnswers}
            checked={checked}
            correct={correct}
            solution={solution}
            setSolution={setSol}
          />
        </>
      );
    }
    default:
      return <p>There was an error with this question.</p>;
  }
};

DevQuestionDisplay.propTypes = {
  question: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DevQuestionDisplay;
