import { NumberSetError } from "../MCError";
import { Fraction } from ".";
/**
 * Arithmetic Sequence Object.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Creates a new arithmetic sequence with first term $5$ and difference $2$
 * const a1 = new ArithmeticSequence(5,2)
 */

class ArithmeticSequence {
  /**
   * Creates a Geometric Sequence $a\\cdot r^{n-1}$.
   *
   * @param {number} 				a The first term.
   * @param {number} 				r The common ratio.
   * @returns {GeometricSequence} A new geometric sequence.
   * @throws {TypeError}   		The argument should be numbers.
   */
  constructor(a, d) {
    if (typeof a !== "number") {
      throw new NumberSetError(`Expected a number, got ${a} instead`);
    }
    if (typeof d !== "number") {
      throw new NumberSetError(`Expected a number, got ${d} instead`);
    }
    this.a = a;
    this.d = d;
  }

  /**
   * Returns term n in the sequence.
   *
   * @param  {number} 	n The number of the term to calculate.
   * @throws {TypeError}  The argument should be a number.
   * @returns {number} 	Term n.
   *
   * @example
   * //Finds the 3rd term in the sequence $4+2(n-1)$
   * let a1 = new ArithmeticSequence(4,2)
   * console.log(a1.term(3))
   */
  term(n) {
    if (typeof n !== "number") {
      throw new NumberSetError(`Expected a number, got ${n} instead`);
    }
    return this.a + (n - 1) * this.d;
  }

  /**
   * Returns multiple terms in the sequence as a string.
   *
   * @param  {number} 	start The number of the first term to calculate.
   * @param  {number} 	end The number of the last term to calculate.
   * @throws {TypeError}  The arguments should be numbers.
   * @returns {string} 	The terms as a comma seperated string.
   *
   * @example
   * //Finds the Fisrt 4 terms in the sequence $4+2(n-1)$
   * let a1 = new ArithmeticSequence(4,2)
   * console.log(a1.terms(1,4))
   */
  terms(start, end) {
    if (typeof start !== "number") {
      throw new NumberSetError(`Expected a number, got ${start} instead`);
    }
    if (typeof end !== "number") {
      throw new NumberSetError(`Expected a number, got ${end} instead`);
    }
    let out = "";
    for (let i = start; i <= end; i += 1) {
      out += `${new Fraction(this.term(i)).toString()}, `;
    }
    return out.substring(0, out.length - 2);
  }

  /**
   * Sums the first n terms of the sequence.
   *
   * @param  {number} 	n The number of the term to be summerd.
   * @throws {TypeError}  The argument should be a number.
   * @returns {number} 	The sum of the first n terms.
   *
   * @example
   * //Finds the sum of the first four terms in the sequence $4+2(n-1)$
   * let g1 = new GeometricSequence(4,2)
   * console.log(g1.sum(4))
   */
  sum(n) {
    if (typeof n !== "number") {
      throw new NumberSetError(`Expected a number, got ${n} instead`);
    }
    return 0.5 * n * (2 * this.a + (n - 1) * this.d);
  }

  /**
   * Shows Wroking for the sum of the first n terms of the sequence.
   *
   * @param  {number}     n The number of the term to be summerd.
   * @throws {TypeError}  The argument should be a number.
   * @returns {number}    The sum of the first n terms.
   *
   * @example
   * //Finds the sum of the first four terms in the sequence $4 + 2(n-1)$
   * let a1 = new ArithmeticSequence(4,2)
   * console.log(g1.sumWorking(4))
   */
  sumWorking(n) {
    if (typeof n !== "number") {
      throw new NumberSetError(`Expected a number, got ${n} instead`);
    }
    return [
      `HEADING Sum of first $${n}$ terms.`,
      "$S_n=\\frac{1}{2}n\\left[2a+(n-1)d\\right]$",
      `$S_{${n}} = \\frac{(${n})}{2}\\left[2\\left(${new Fraction(
        this.a
      ).toString()}\\right)+(${n}-1)\\left(${new Fraction(
        this.d
      ).toString()}\\right)\\right]$`,
      `$S_{${n}} = ${new Fraction(this.sum(n)).toString()}$`,
    ];
  }

  /**
   * Creats a string for the nth term.
   *
   * @returns {string} The nth term formula.
   *
   * @example
   * //Print the nth term formula of the the sequence $4+ 0.5(n-1)$
   * let a1 = new ArithmeticSequence(4,0.5)
   * console.log(a1.nthTerm())
   */
  nthTerm() {
    if (this.d > 0) {
      return `${new Fraction(this.a).toString()}+${new Fraction(
        this.d
      ).toString()}(n-1)`;
    }
    return `${new Fraction(this.a).toString()}${new Fraction(
      this.d
    ).toString()}(n-1)`;
  }

  /**
   * Finds the sum of terms in the range.
   *
   * @param  {number}     start The term to start the sum on.
   * @param  {number}     end The number of the last term to calculate.
   * @throws {TypeError}  The arguments should be numbers.
   * @returns {number}    The result of the sum.
   *
   * @example
   * //Finds the sum of terms 4 to 6 in the sequence $4\\cdot 2^{n-1}$
   * let a1 = new ArithmeticSequence(4,2)
   * console.log(a1.sumBetween(4,6))
   */
  sumBetween(start, end) {
    if (typeof start !== "number") {
      throw new NumberSetError(`Expected a number, got ${start} instead`);
    }
    if (typeof end !== "number") {
      throw new NumberSetError(`Expected a number, got ${end} instead`);
    }
    return this.sum(end) - this.sum(start - 1);
  }

  /**
   * Shows working for the sum of terms in the range.
   *
   * @param  {number}     start The term to start the sum on.
   * @param  {number}     end The number of the last term to calculate.
   * @throws {TypeError}  The arguments should be numbers.
   * @returns {string[]}  Lines of working.
   *
   * @example
   * //Shows the working for the sum of terms 4 to 6 in the sequence $4\\cdot 2^{n-1}$
   * let a1 = new ArithmeticSequence(4,2)
   * console.log(a1.sumBetweenWorking(4,6))
   */
  sumBetweenWorking(start, end) {
    if (typeof start !== "number") {
      throw new NumberSetError(`Expected a number, got ${start} instead`);
    }
    if (typeof end !== "number") {
      throw new NumberSetError(`Expected a number, got ${end} instead`);
    }
    let out = [];
    out.push(
      `HEADING Sum of terms between $${start}$ and $${end}$.`,
      `$\\displaystyle\\sum_{n=${start}}^{${end}}U_n=S_{${end}}-S_{${start}-1}=S_{${end}}-S_{${
        start - 1
      }}$`
    );
    out = out.concat(this.sumWorking(end));
    out = out.concat(this.sumWorking(start - 1));
    out.push(
      `$\\therefore\\displaystyle\\sum_{n=${start}}^{${end}}U_n=${new Fraction(
        this.sum(end)
      ).toString()}-${new Fraction(this.sum(start - 1)).toString()}$`,
      `$=${this.sumBetween(start, end)}$`
    );
    return out;
  }
}

export { ArithmeticSequence };
