import React from "react";
import Test from "../../system/modules/testJP";
import ModuleDisplay from "../developmentComps/ModuleDisplay";

const ModuleTest = () => {
  const t = Test();

  return (
    <div className="container my-5">
      <ModuleDisplay question={t} />
    </div>
  );
};

export default ModuleTest;
