import React, { useState } from "react";
import PropTypes from "prop-types";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteRelationModal from "./DeleteRelationModal";
import styles from "./styles/StaffList.module.css";

const StaffList = ({ data, onDelete }) => {
  const [deleting, setDeleting] = useState([false]);

  const deleteRel = () => {
    onDelete(deleting[1]);
    setDeleting([false]);
  };

  const display = (member) => {
    return typeof member.fname === "undefined"
      ? member.email
      : `${member.fname} ${member.lname}`;
  };

  return (
    <div>
      <DeleteRelationModal
        show={deleting[0]}
        onHide={() => setDeleting([false])}
        onAction={deleteRel}
        personName={
          deleting[0] ? `${deleting[1].fname} ${deleting[1].lname}` : ""
        }
        entity="sadmin"
      />
      <ul className="list-group">
        {data.map((member) => {
          return (
            <li className="list-group-item" key={member.userId}>
              <span>{display(member)}</span>
              <div className={styles.delete}>
                <FontAwesomeIcon
                  icon={faTimes}
                  color="#d9534f"
                  onClick={() => setDeleting([true, member])}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

StaffList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default StaffList;
