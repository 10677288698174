import React, { useState, useEffect } from "react";
import { faArrowAltCircleUp } from "@fortawesome/free-regular-svg-icons";
import { faBullseye, faHistory } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import ClipLoader from "react-spinners/ClipLoader";
import NumberStat from "./NumberStat";
import IconText from "./IconText";
import Dropdown from "./Dropdown";

const Summary = ({
  summaryPeriod,
  handleSummaryPeriodChange,
  data,
  loading,
  staffView,
}) => {
  const statCol = data.skillsImproved !== 0 ? 4 : 6;
  const targetCol =
    data.improved &&
    data.targets &&
    (data.improved.length === 0 || data.targets.length === 0)
      ? 12
      : 6;
  const [ddTitle, setDDTitle] = useState("");
  const options = [
    { id: 0, title: "Last 7 days", value: 7 },
    { id: 1, title: "Last 14 days", value: 14 },
    { id: 2, title: "Last 30 days", value: 30 },
    { id: 3, title: "Last 90 days", value: 90 },
    { id: 4, title: "All-time", value: -1 },
  ];

  useEffect(() => {
    let dropdownTitle = "";
    if (summaryPeriod !== -1) {
      dropdownTitle = `Last ${summaryPeriod} days`;
    } else {
      dropdownTitle = "All-time";
    }
    setDDTitle(dropdownTitle);
  }, [summaryPeriod]);

  return (
    <div className="card text-center">
      <h5 className="card-header ">Summary</h5>
      <div className="card-body">
        {loading ? (
          <div className="my-5">
            <ClipLoader loading color="#74c5ed" size="3em" />
          </div>
        ) : (
          <>
            <Dropdown
              icon={faHistory}
              title={ddTitle}
              options={options}
              handleChange={handleSummaryPeriodChange}
              centered
            />
            <div className="row">
              <div className={`col-md-${statCol} my-4`}>
                <NumberStat number={data.total} label="questions answered" />
              </div>
              <div className={`col-md-${statCol} my-4`}>
                <NumberStat
                  number={
                    data.total === 0
                      ? 0
                      : Math.round((data.correct / data.total) * 100)
                  }
                  label="questions answered correctly"
                  percentage
                />
              </div>
              {data.skillsImproved !== 0 && (
                <div className="col-md-4 my-4">
                  <NumberStat
                    number={data.skillsImproved}
                    label={
                      data.skillsImproved === 1
                        ? "skill improved"
                        : "skills improved"
                    }
                  />
                </div>
              )}
            </div>

            <div>
              {typeof data.improved !== "undefined" &&
                data.improved.length !== 0 &&
                typeof data.targets !== "undefined" &&
                data.targets.length !== 0 && <hr />}
              <div className="row">
                {typeof data.improved !== "undefined" &&
                  data.improved.length !== 0 && (
                    <div className={`col-md-${targetCol} my-3`}>
                      <IconText
                        icon={faArrowAltCircleUp}
                        text={
                          staffView
                            ? "Your pupils' most recent improvements:"
                            : "Your most recent improvements:"
                        }
                        sizeVariant={5}
                        color="#65ad0c"
                      />
                      {data.improved &&
                        data.improved.map((imp) => {
                          return (
                            <div
                              style={{
                                fontWeight: 425,
                                marginBottom: "0.25em",
                              }}
                            >
                              {imp}
                            </div>
                          );
                        })}
                    </div>
                  )}
                {typeof data.targets !== "undefined" &&
                  data.targets.length !== 0 && (
                    <div className={`col-md-${targetCol} my-3`}>
                      <IconText
                        icon={faBullseye}
                        text={
                          staffView
                            ? "Your pupils' targeted areas for development:"
                            : "Your targeted areas for development:"
                        }
                        sizeVariant={5}
                        color="#4f8fc4"
                      />
                      {data.targets &&
                        data.targets.map((target) => {
                          return (
                            <div
                              style={{
                                fontWeight: 425,
                                marginBottom: "0.25em",
                              }}
                            >
                              {target}
                            </div>
                          );
                        })}
                    </div>
                  )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Summary.propTypes = {
  summaryPeriod: PropTypes.number.isRequired,
  handleSummaryPeriodChange: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool,
  staffView: PropTypes.bool,
};

Summary.defaultProps = {
  loading: false,
  staffView: false,
};

export default Summary;
