const qs = require("./index.js");

// Example name:
// AS_Proof_ProofByDeduction_Exam_Q1
const names = Object.keys(qs);
let questions = {};
names.forEach((name) => {
  const info = name.split("_");
  if (info.length === 5) {
    if (!(info[0] in questions)) {
      questions = { ...questions, [info[0]]: {} };
    }
    if (!(info[1] in questions[info[0]])) {
      questions[info[0]] = { ...questions[info[0]], [info[1]]: {} };
    }
    if (!(info[2] in questions[info[0]][info[1]])) {
      questions[info[0]][info[1]] = {
        ...questions[info[0]][info[1]],
        [info[2]]: {},
      };
    }
    if (!(info[3] in questions[info[0]][info[1]][info[2]])) {
      questions[info[0]][info[1]][info[2]] = {
        ...questions[info[0]][info[1]][info[2]],
        [info[3]]: [],
      };
    }
    questions[info[0]][info[1]][info[2]][info[3]].push(info[4]);
  }
  if (info.length === 7) {
    if (!(info[1] in questions)) {
      questions = { ...questions, [info[1]]: {} };
    }
    if (!(info[2] in questions[info[1]])) {
      questions[info[1]] = { ...questions[info[1]], [info[2]]: {} };
    }
    if (!(info[3] in questions[info[1]][info[2]])) {
      questions[info[1]][info[2]] = {
        ...questions[info[1]][info[2]],
        [info[3]]: {},
      };
    }
    if (!(info[4] in questions[info[1]][info[2]][info[3]])) {
      questions[info[1]][info[2]][info[3]] = {
        ...questions[info[1]][info[2]][info[3]],
        [info[4]]: {},
      };
    }
    if (!(info[5] in questions[info[1]][info[2]][info[3]][info[4]])) {
      questions[info[1]][info[2]][info[3]][info[4]] = {
        ...questions[info[1]][info[2]][info[3]][info[4]],
        [info[5]]: [],
      };
    }
    questions[info[1]][info[2]][info[3]][info[4]][info[5]].push(info[6]);
  }
});

export default questions;
