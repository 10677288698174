import React from "react";
import PropTypes from "prop-types";
import Pupils from "../Pupils";
import Staff from "../Staff";
import Classes from "../Classes";
import RoundedBtn from "../RoundedBtn";

const StaffMenu = ({ admin, data, setView }) => {
  return (
    <div className="container my-4">
      <div className="alert alert-primary" role="alert">
        <h3 className="text-center">Getting Started</h3>
        <h4>Introduction</h4>
        <p>
          Our site is not about setting pupils tasks, instead we let them work
          on all the content they have covered, reviewing their progress as they
          go. Our Work Smart feature directs them to the places they need to
          work on while interleaving topics they haven&apos;t seen in a while.
        </p>
        <p>
          As a teacher you can select your pupils below to see the questions
          they have tried recently with their responses.
        </p>
        {!admin && (
          <div>
            <h4>Adding pupils</h4>
            <p>
              To be able to link a pupil to your account they must first have an
              account on the site. You can then either add them as a pupil or
              ask them to go to account and add you as a teacher.
            </p>
            <p>
              If you would like to add a whole class, we recommend that you ask
              each of your pupils to visit the site, create an account and add
              you as a teacher. You then just have to accept your pupils below.
            </p>
            <p>
              If you are adding lots of pupils it might be easier to drop us an
              email on Contact@MrCarterMaths.com and get set up as a school.
            </p>
          </div>
        )}
      </div>
      <div>
        <RoundedBtn
          className="mr-auto"
          text="Try Pupil Experience"
          width={12}
          onClick={() => setView(0)}
        />
      </div>

      <div>
        <div className="mt-3">
          <Pupils
            pupilList={data.pupils}
            answers={data.answers}
            classList={data.pupilClasses}
          />
        </div>
        {admin && (
          <div>
            <div className="mt-3">
              <Staff staff={data.staff} />
            </div>
            <div className="mt-3">
              <Classes classes={data.classes} pupils={data.pupils} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

StaffMenu.propTypes = {
  admin: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  setView: PropTypes.func.isRequired,
};

StaffMenu.defaultProps = {
  admin: false,
};

export default StaffMenu;
