import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles/SelectionBox.module.css";

const SelectionBox = ({ title, handleSelection, selected }) => {
  const [tabbed, setTabbed] = useState(false);

  const keyPressHandler = (e) => {
    if (e.key === "Tab") {
      setTabbed(true);
    }
    if (e.key === "Enter" || e.key === " ") {
      handleSelection();
    }
  };

  return (
    <div
      className={`${styles.option} 
      ${selected ? styles.chosen : ""} ${tabbed ? "" : styles.noTabs} `}
      onClick={(e) => {
        e.preventDefault();
        handleSelection();
      }}
      role="button"
      tabIndex={0}
      onKeyPress={keyPressHandler}
    >
      {title}
    </div>
  );
};

SelectionBox.propTypes = {
  title: PropTypes.string.isRequired,
  handleSelection: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

SelectionBox.defaultProps = {
  selected: false,
};

export default SelectionBox;
