import React, { useState } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";
import PlainInput from "../PlainInput";
import RoundedBtn from "../RoundedBtn";
import Alert from "../Alert";
import checkToken from "../../system/auth/checkToken";

const ChangePassword = () => {
  const [formValues, setFormValues] = useState({
    old: "",
    new: "",
    newConfirmed: "",
  });
  const [alert, setAlert] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const setError = (msg) => {
    setAlert({ variant: "danger", message: msg });
  };

  const validate = () => {
    const lower = /[a-z]/;
    const upper = /[A-Z]/;
    const number = /[0-9]/;
    if (
      formValues.old.length === 0 ||
      formValues.new.length === 0 ||
      formValues.newConfirmed.length === 0
    ) {
      setError("Please complete all fields.");
      return false;
    }
    if (formValues.new !== formValues.newConfirmed) {
      setError("New passwords do not match.");
      return false;
    }
    if (!lower.test(formValues.new)) {
      setError("New password should contain at least one lowercase letter");
      return false;
    }
    if (!upper.test(formValues.new)) {
      setError("New password should contain at least one uppercase letter");
      return false;
    }
    if (!number.test(formValues.new)) {
      setError("New password should contain at least one number");
      return false;
    }
    if (formValues.new.length < 8) {
      setError("New password should have 8 or more characters");
      return false;
    }
    if (formValues.new === formValues.old) {
      setError("Current and new passwords cannot be the same.");
      return false;
    }
    return true;
  };

  const startLoad = () => {
    return setTimeout(() => {
      setLoading(true);
    }, 500);
  };

  const stopLoad = (timer) => {
    clearTimeout(timer);
    setLoading(false);
  };

  const changePassword = async () => {
    const start = startLoad();
    setAlert({});
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    if (!validate()) {
      stopLoad(start);
      return false;
    }
    try {
      await axios.patch(
        `${process.env.REACT_APP_DOMAIN}/users/resetPassword`,
        {
          oldPassword: formValues.old,
          newPassword: formValues.new,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
        }
      );
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setError(e.response.data.error);
      } else {
        setError("A problem occurred. Please try again.");
      }
      stopLoad(start);
      return false;
    }
    setFormValues({
      old: "",
      new: "",
      newConfirmed: "",
    });
    stopLoad(start);
    setAlert({ variant: "success", message: "Password changed." });
    return true;
  };

  return (
    <div className="container">
      <div className="my-4">
        <div className="text-center">
          <h4>Change Password</h4>
          {alert.variant && alert.message && (
            <div className="mt-4">
              <Alert variant={alert.variant}>{alert.message}</Alert>
            </div>
          )}
          {loading ? (
            <div className="my-5">
              <ClipLoader loading color="#74c5ed" size="3em" />
            </div>
          ) : (
            <div>
              <div style={{ display: "inline-block", width: "20em" }}>
                <div className="my-3">
                  <PlainInput
                    value={formValues.old}
                    autoFocus
                    obscure
                    label="Current Password"
                    width={20}
                    handleChange={(inp) => {
                      setFormValues({ ...formValues, old: inp });
                    }}
                    onEnterPressed={changePassword}
                  />
                </div>
                <div className="my-3">
                  <PlainInput
                    value={formValues.new}
                    obscure
                    label="New Password"
                    width={20}
                    handleChange={(inp) => {
                      setFormValues({ ...formValues, new: inp });
                    }}
                    onEnterPressed={changePassword}
                  />
                </div>
                <div className="my-3">
                  <PlainInput
                    value={formValues.newConfirmed}
                    obscure
                    label="Confirm Password"
                    width={20}
                    handleChange={(inp) => {
                      setFormValues({ ...formValues, newConfirmed: inp });
                    }}
                    onEnterPressed={changePassword}
                  />
                </div>
              </div>
              <div className="my-3">
                <RoundedBtn
                  text="Submit"
                  variant="dark"
                  width={10}
                  onClick={changePassword}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
