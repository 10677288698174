import { NumberSetError } from "../MCError";
import { cleaner } from "./cleaner";
import { Graph } from "../MCQuestion";

/**
 * Class Logarithm Objects.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Creates $3ln(x)$
 * const l1 = new log(3)
 * // Creates $log_2(3x+4)$
 * const l1 = new log(1,2,3,'x',4)
 */

class Circles {
  /**
   * Creates the circle in the form (x-a)^2+(y-b)^2=r^2
   * @param {number} [a=0] The x centre of the circle.
   * @param {number} [b=0] The y centre of the circle.
   * @param {number} [r=1] Radius of the circle.
   *
   * @throws {NUmberSetError} r must be positive.
   *
   * @returns {Circle}  A new log object
   */
  constructor(a = 0, b = 0, r = 1) {
    if (r <= 0) {
      throw new NumberSetError(`Expected a positive number, got ${r} instead`);
    }
    this.a = a;
    this.b = b;
    this.r = r;
  }

  /**
   * Outputs the circle object to a string
   *
   * @returns {string}
   *
   * @example
   * //Print $(x-1)^2+y^2=4$
   * let c1 = new circle(1,0,2)
   * console.log(c1.toString())
   */
  toString() {
    let output = ``;

    if (this.a === 0) {
      output += "x^2";
    } else {
      output += `\\left(x-${this.a}\\right)^2`;
    }

    if (this.b === 0) {
      output += "+y^2";
    } else {
      output += `+\\left(y-${this.b}\\right)^2`;
    }

    output += `=${this.r * this.r}`;

    return cleaner(output);
  }

  /**
   * Outputs the circle object to a string in the form x^2+ix+y^2+jy+k=0
   *
   * @returns {string}
   *
   */

  expandedString() {
    let output = ``;
    if (this.a === 0) {
      output += "x^2";
    } else {
      output += `x^2-${2 * this.a}x`;
    }
    if (this.b === 0) {
      output += "+y^2";
    } else {
      output += `+y^2-${2 * this.b}y`;
    }
    output += `+${-this.r * this.r + this.a * this.a + this.b * this.b} = 0`;
    return cleaner(output);
  }

  /**
   *  Returns a graph of the circle
   *
   * @returns {graph}
   * @param {number} stepsize The incriment size on the axis.
   *
   * @example
   * const c1 = new MCMaths.Circles(1,-2,2);
   * question.addGraph("question",  c1.graph());
   */
  graph(stepsize = 1) {
    const graphSize = Math.max(
      Math.abs(this.a) + this.r,
      Math.abs(this.b) + this.r
    );

    const gLower = -Math.floor(graphSize / stepsize) * stepsize;
    const gUpper = Math.ceil(graphSize / stepsize) * stepsize;

    const graph = new Graph(gUpper, gLower, gUpper, gLower, stepsize, stepsize);
    graph.addCircle(this.a, this.b, this.r);
    return graph;
  }
}

export { Circles };
