import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import PlainInput from "../PlainInput";
import Alert from "../Alert";
import RoundedBtn from "../RoundedBtn";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const { token } = useParams();

  const validateEmail = (e) => {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(String(e).toLowerCase());
  };

  const startLoad = () => {
    return setTimeout(() => {
      setLoading(true);
    }, 500);
  };

  const stopLoad = (timer) => {
    clearTimeout(timer);
    setLoading(false);
  };

  const validate = () => {
    const lower = /[a-z]/;
    const upper = /[A-Z]/;
    const number = /[0-9]/;
    if (!validateEmail(email)) {
      setAlert({
        variant: "danger",
        msg: "Please enter a valid email address.",
      });
      return false;
    }
    if (!lower.test(password)) {
      setAlert({
        variant: "danger",
        msg: "Password should contain a lowercase letter.",
      });
      return false;
    }
    if (!upper.test(password)) {
      setAlert({
        variant: "danger",
        msg: "Password should contain an uppercase letter.",
      });
      return false;
    }
    if (!number.test(password)) {
      setAlert({
        variant: "danger",
        msg: "Password should contain a number.",
      });
      return false;
    }
    if (password.length < 8) {
      setAlert({
        variant: "danger",
        msg: "Password should contain at least 8 characters.",
      });
      return false;
    }
    if (password !== confirmation) {
      setAlert({
        variant: "danger",
        msg: "Passwords do not match.",
      });
      return false;
    }
    return true;
  };

  const requestReset = async () => {
    setAlert({});
    const start = startLoad();
    if (!validate()) {
      stopLoad(start);
      return false;
    }
    try {
      await axios.post(`${process.env.REACT_APP_DOMAIN}/users/resetForgotten`, {
        email,
        password,
        token,
      });
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) {
          setAlert({
            msg: JSON.stringify(e.response.data.error),
            variant: "danger",
          });
        } else {
          setAlert({
            msg: "A problem occurred! Try again later.",
            variant: "danger",
          });
        }
        stopLoad(start);
        return false;
      }
    }
    setEmail("");
    setPassword("");
    setConfirmation("");
    stopLoad(start);
    setAlert({
      variant: "success",
      msg: "Password changed.",
    });
    return true;
  };

  return (
    <div className="container text-center my-5">
      <h4>Reset Password</h4>
      {alert.msg && <Alert variant={alert.variant}>{alert.msg}</Alert>}
      {loading ? (
        <div className="my-5">
          <ClipLoader loading color="#74c5ed" size="3em" />
        </div>
      ) : (
        <div>
          <div
            className="my-3"
            style={{ display: "inline-block", width: "20em" }}
          >
            <div className="my-3">
              <PlainInput
                value={email}
                handleChange={(v) => setEmail(v)}
                label="Email"
                width={-1}
              />
            </div>
            <div className="my-3">
              <PlainInput
                value={password}
                handleChange={(v) => setPassword(v)}
                label="New Password"
                obscure
                width={-1}
              />
            </div>
            <div className="my-3">
              <PlainInput
                value={confirmation}
                handleChange={(v) => setConfirmation(v)}
                label="Confirm Password"
                obscure
                width={-1}
              />
            </div>
          </div>
          <div>
            <RoundedBtn
              text="Change Password"
              onClick={requestReset}
              width={20}
              variant="dark"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PasswordReset;
