import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MathString from "../MathString";
import ImageCanvas from "../ImageCanvas";
import { shuffle } from "../../system/modules/MCRandom";
import styles from "../styles/MultipleChoiceAnswers.module.css";
import Graph from "../Graph";

const MultipleChoiceAnswers = ({ data, disabled, checkFunction }) => {
  const [answers, setAnswers] = useState(shuffle(data.ans));
  const [tabbed, setTabbed] = useState(false);
  useEffect(() => {
    setAnswers(shuffle(data.ans));
  }, [data]);

  const col = 12 / data.pLine;
  const alignment = data.align;

  const toggleAnswer = (ind) => {
    if (disabled) {
      return;
    }
    const ans = answers;
    ans[ind] = { ...ans[ind], chosen: !ans[ind].chosen };
    setAnswers([...ans]);
  };

  const handleKeyPress = (e, i) => {
    if (e.key === "Tab") {
      setTabbed(true);
    }
    if (e.key === "Enter" || e.key === " ") {
      toggleAnswer(i);
    }
  };

  const checkAnswers = () => {
    let isCorrect = 1;
    answers.forEach((answer) => {
      if (
        (answer.correct && !answer.chosen) ||
        (answer.chosen && !answer.correct)
      ) {
        isCorrect = 0;
      }
    });
    checkFunction(isCorrect, data);
  };

  return (
    <>
      <div className="row">
        {answers.map((ans, i) => {
          return (
            <div key={i} className={`col-md-${col}`}>
              <div
                className={`${styles.option} ${
                  alignment === "center" ? "text-center" : ""
                } ${ans.chosen ? styles.chosen : ""} ${
                  tabbed ? "" : styles.noTabs
                } ${disabled ? styles.disabled : ""} ${
                  disabled && ans.correct ? styles.correctAns : ""
                } ${
                  disabled && !ans.correct && ans.chosen
                    ? styles.incorrectlyChosen
                    : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  toggleAnswer(i);
                }}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => handleKeyPress(e, i)}
              >
                {ans.sequence.map((elem, ind) => {
                  if (elem.type === "paragraph") {
                    return (
                      <div
                        key={i + ind}
                        className={`${styles.text} ${
                          typeof elem.content.heading !== "undefined" &&
                          elem.content.heading &&
                          styles.heading
                        }`}
                      >
                        <MathString
                          str={elem.content.p}
                          fontSize={elem.content.size}
                        />
                      </div>
                    );
                  }
                  if (elem.type === "image") {
                    if (elem.content.measure === "height") {
                      return (
                        <div className="text-center" key={i + ind}>
                          <ImageCanvas
                            img={elem.content.src}
                            height={elem.content.size}
                            overlays={elem.content.overlays}
                          />
                        </div>
                      );
                    }
                    return (
                      <div className="text-center" key={i + ind}>
                        <ImageCanvas
                          img={elem.content.src}
                          width={elem.content.size}
                          overlays={elem.content.overlays}
                        />
                      </div>
                    );
                  }
                  if (elem.type === "graph") {
                    return (
                      <div className="text-center" key={i + ind}>
                        <Graph graphObjContent={elem.content} />
                      </div>
                    );
                  }
                  return <div key={i + ind} />;
                })}
              </div>
            </div>
          );
        })}
      </div>
      {!disabled && (
        <div className="text-center my-4">
          <button
            type="button"
            className="btn btn-success"
            onClick={checkAnswers}
          >
            Check Answer
          </button>
        </div>
      )}
    </>
  );
};

MultipleChoiceAnswers.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool,
  checkFunction: PropTypes.func.isRequired,
};

MultipleChoiceAnswers.defaultProps = {
  disabled: false,
};

export default MultipleChoiceAnswers;
