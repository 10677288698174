/**
 * A class that allows developers to easily add graphs to a question.
 * @memberof module:MCQuestion
 * @author Declan Clark <dec@dclark.dev>
 * @since 0.1.0
 * @example
 * const myGraph = new MCQuestion.Graph();
 */
class Graph {
  /**
   * Creates a new graphing instance.
   *
   * @param {number} maxX The greatest X axis value.
   * @param {number} minX The smallest X axis value.
   * @param {number} maxY The greatest Y axis value.
   * @param {number} minY The smallest Y axis value.
   * @param {number} xStep  The step on the x axis.
   * @param {number} yStep  The step on the y axis.
   */
  constructor(maxX, minX, maxY, minY, xStep, yStep) {
    this.boundingBox = [minX - xStep, maxY + yStep, maxX + xStep, minY - yStep];
    this.functions = [];
    this.overlays = [];
    this.xStep = xStep;
    this.yStep = yStep;
  }

  /**
   * @summary Gets the bounding box values of the graph.
   *
   * @since 0.1.0
   *
   * @returns {number[]} Bounding box values.
   */
  getAxisValues() {
    return this.boundingBox;
  }

  /**
   * @summary Gets the functions to plot.
   *
   * @since 0.1.0
   *
   * @returns {object[]} Functions to plot.
   */
  getFunctions() {
    return this.functions;
  }

  /**
   * @summary Gets the steps for the graph axis.
   *
   * @since 0.1.0
   *
   * @returns {number[]} Overlays.
   */
  getSteps() {
    return [this.xStep, this.yStep];
  }

  /**
   * @summary Add a non-specific function to the graph.
   *
   * @description Provide a function of x and the left and right intervals to display
   *              the function on the graph starting with x = left interval and ending
   *              at x = right interval.
   *
   * @since 0.1.0
   *
   * @param   {string}  fx            A function of x.
   * @param   {number}    lInterval   The left interval bound (the start of the plot).
   * @param   {number}    rInterval   The right interval bound (the end of the plot).
   * @throws  {TypeError}             Requires a function of x and both interval bounds as numbers.
   */
  plot(fx, lInterval, rInterval) {
    if (typeof fx !== "string") {
      throw new TypeError(
        `expected a function of x given as a string e.g. '2 * x + 5', got ${fx} instead.`
      );
    }
    if (typeof lInterval !== "number") {
      throw new TypeError(
        `expected numerical left interval but got ${typeof lInterval} instead`
      );
    }
    if (typeof rInterval !== "number") {
      throw new TypeError(
        `expected a numerical right interval but got ${typeof rInterval} instead`
      );
    }
    this.functions.push({
      type: "functiongraph",
      content: { func: fx, lInterval, rInterval },
    });
  }

  /**
   * @summary Adds a polar to the graph.
   *
   * @description Provide a function of theta as well as the intervals for theta to draw a polar on the graph.
   *
   * @since 0.1.0
   *
   * @param   {string}  rTheta      A function of theta.
   * @param   {number}    lInterval   The smallest value of theta to start plotting with.
   * @param   {number}    rInterval   The largest value of theta to end plotting with.
   * @param   {number}    [offsetX=0] The x offset from the origin.
   * @param   {number}    [offsetY=0] The y offset from the origin.
   * @throws  {TypeError}             Any passed parameter should be of the correct type.
   */
  addPolar(rTheta, lInterval, rInterval, offsetX = 0, offsetY = 0) {
    if (typeof rTheta !== "string") {
      throw new TypeError(
        "expected a function of x as a string e.g. '2 * x + 5'"
      );
    }
    if (typeof lInterval !== "number") {
      throw new TypeError(
        `expected a numeric left interval but got ${typeof lInterval} instead`
      );
    }
    if (typeof rInterval !== "number") {
      throw new TypeError(
        `expected a numeric right interval but got ${typeof rInterval} instead`
      );
    }
    if (typeof offsetX !== "number") {
      throw new TypeError(
        `expected a numerical x offset but got ${typeof offsetX} instead`
      );
    }
    if (typeof offsetY !== "number") {
      throw new TypeError(
        `expected a numerical y offset but got ${typeof offsetY} instead`
      );
    }
    this.functions.push({
      type: "polar",
      content: { func: rTheta, offsetX, offsetY, lInterval, rInterval },
    });
  }

  /**
   * @summary Plot parametric equations.
   *
   * @description Provide a function of t for both x and y as well as the intervals for which to plot values between.
   *
   * @param   {string}  x         Function of t.
   * @param   {string}  y         Function of t.
   * @param   {number}    lInterval Left interval.
   * @param   {number}    rInterval Right interval.
   * @throws  {TypeError}           Required parameters should be of the correct type.
   */
  addParametric(x, y, lInterval, rInterval) {
    if (typeof x !== "string" || typeof y !== "string") {
      throw new TypeError(
        "expected a function of t as a string e.g. ' 2 * t + 5'"
      );
    }
    if (typeof lInterval !== "number") {
      throw new TypeError(
        `expected a numerical left interval but got ${typeof lInterval} instead`
      );
    }
    if (typeof rInterval !== "number") {
      throw new TypeError(
        `expected a numerical right interval but got ${typeof rInterval} instead`
      );
    }
    this.functions.push({
      type: "parametric",
      content: { x, y, lInterval, rInterval },
    });
  }

  /**
   * @summary Add a circle to the graph.
   *
   * @description Give a center and radius to plot a circle on the graph instance.
   *
   * @since 0.1.0
   *
   * @param   {number}  centerx    X coordinate of center of circle.
   * @param   {number}  centery    Y coordinate of center of circle.
   * @param   {number}  radius     The radius of the circle.
   */
  addCircle(centerx, centery, radius) {
    if (typeof centerx !== "number") {
      throw new TypeError(
        `expected a numerical center but got ${typeof centerx} instead`
      );
    }
    if (typeof centery !== "number") {
      throw new TypeError(
        `expected a numerical center coordinate but got ${typeof centery} instead`
      );
    }
    if (typeof radius !== "number") {
      throw new TypeError(
        `expected a numerical radius but got ${typeof radius} instead`
      );
    }
    this.functions.push({
      type: "circle",
      content: {
        centerx,
        centery,
        radius,
      },
    });
  }
}

export { Graph };
