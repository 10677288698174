import React from "react";
import ModalVideo from "react-modal-video";
import PropTypes from "prop-types";

const YoutubeModal = ({ videoId, isOpen, onHide, entity }) => {
  return (
    <div>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => {
          onHide(entity);
        }}
      />
    </div>
  );
};

YoutubeModal.propTypes = {
  videoId: PropTypes.string.isRequired,
  entity: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default YoutubeModal;
