import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useHistory, Redirect, Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import jwt from "jsonwebtoken";
import PlainInput from "../PlainInput";
import RoundedBtn from "../RoundedBtn";
import { getSiteID } from "../../system/utils/getSite";

const SignIn = ({ handleAuthentication, isAuthenticated }) => {
  const site = getSiteID();
  const history = useHistory();
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const startLoad = () => {
    return setTimeout(() => {
      setLoading(true);
    }, 500);
  };

  const stopLoad = (timer) => {
    clearTimeout(timer);
    setLoading(false);
  };

  const logUserIn = async () => {
    const start = startLoad();
    if (!validateEmail(formValues.email.trim())) {
      setError("Please enter a valid email address.");
      stopLoad(start);
      return false;
    }
    if (formValues.password.length === 0) {
      setError("Please enter your password.");
      stopLoad(start);
      return false;
    }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/auth/login`,
        {
          email: formValues.email.trim(),
          password: formValues.password,
        }
      );
      const { role } = jwt.decode(res.data.token);
      if ((role === "FOUND_PUPIL" || role === "HIGH_PUPIL") && site !== 0) {
        window.location.assign("https://gcse.mrcartermaths.com");
        return true;
      }
      if (role === "PUPIL" && site !== 1) {
        window.location.assign("https://alevel.mrcartermaths.com");
        return true;
      }
      localStorage.setItem("mcmToken", res.data.token);

      stopLoad(start);
      handleAuthentication();
      history.push("/");
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) {
          setError(e.response.data.error);
        }
      } else {
        setError("A problem occurred. Please try again.");
      }
      stopLoad(start);
      return false;
    }
    return true;
  };

  return (
    <div className="container">
      <div className="my-5 text-center">
        {isAuthenticated ? (
          <Redirect to="/menu" />
        ) : (
          <div>
            <h4>Sign In</h4>
            {loading ? (
              <div className="my-5">
                <ClipLoader loading color="#74c5ed" size="3em" />
              </div>
            ) : (
              <div>
                <div style={{ display: "inline-block", width: "20em" }}>
                  {error !== "" && (
                    <span style={{ color: "red" }}>{error}</span>
                  )}
                  <div className="my-3">
                    <PlainInput
                      value={formValues.email}
                      autoFocus
                      label="Email"
                      width={20}
                      handleChange={(inp) => {
                        setFormValues({ ...formValues, email: inp });
                      }}
                      onEnterPressed={logUserIn}
                    />
                  </div>
                  <div className="my-3">
                    <PlainInput
                      value={formValues.password}
                      obscure
                      label="Password"
                      width={20}
                      handleChange={(inp) => {
                        setFormValues({ ...formValues, password: inp });
                      }}
                      onEnterPressed={logUserIn}
                    />
                  </div>
                  <div className="my-3">
                    <Link to="/forgot-password">Forgot Password?</Link>
                  </div>
                </div>
                <div className="my-4">
                  <RoundedBtn
                    text="Submit"
                    variant="dark"
                    width={10}
                    onClick={logUserIn}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

SignIn.propTypes = {
  isAuthenticated: PropTypes.bool,
  handleAuthentication: PropTypes.func.isRequired,
};

SignIn.defaultProps = {
  isAuthenticated: false,
};

export default SignIn;
