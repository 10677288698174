import React from "react";
import PropTypes from "prop-types";
import MathString from "../MathString";
import ImageCanvas from "../ImageCanvas";
import styles from "../styles/ExamQuestion.module.css";
import Graph from "../Graph";

const ExamQuestion = ({ data, type }) => {
  return (
    <>
      {data.map((elem, index) => {
        if (elem.type === "paragraph") {
          return (
            <div
              key={index}
              className={`${styles.text} ${
                type === "solution" && styles.solText
              } ${
                typeof elem.content.heading !== "undefined" &&
                elem.content.heading &&
                styles.heading
              }`}
            >
              <MathString str={elem.content.p} fontSize={elem.content.size} />
            </div>
          );
        }
        if (elem.type === "image") {
          if (elem.content.measure === "height") {
            return (
              <div className="text-center" key={index}>
                <ImageCanvas
                  img={elem.content.src}
                  height={elem.content.size}
                  overlays={elem.content.overlays}
                />
              </div>
            );
          }
          return (
            <div className="text-center" key={index}>
              <ImageCanvas
                img={elem.content.src}
                width={elem.content.size}
                overlays={elem.content.overlays}
              />
            </div>
          );
        }
        if (elem.type === "graph") {
          return (
            <div className="text-center" key={index}>
              <Graph graphObjContent={elem.content} />
            </div>
          );
        }
        if (elem.type === "table") {
          let { table } = elem.content;
          const headings = table[0];
          table = table.slice(1);
          return (
            <div key={index}>
              <table className={styles.table}>
                <tr>
                  {headings.map((heading) => {
                    return (
                      <th className={styles.tableHeader}>
                        <MathString str={heading} />
                      </th>
                    );
                  })}
                </tr>
                {table.map((row) => {
                  return (
                    <tr>
                      {row.map((attribute) => {
                        return (
                          <td className={styles.tableData}>
                            <MathString str={attribute} />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </table>
            </div>
          );
        }
        return <div />;
      })}
    </>
  );
};

ExamQuestion.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
};

export default ExamQuestion;
