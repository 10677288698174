import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DevQuestionDisplay from "../developmentComps/DevQuestionDisplay";

const GCSEDevelopment = () => {
  const { questionID } = useParams();
  const [question, setQuestion] = useState({});

  useEffect(() => {
    const parts = questionID.split("_");
    import(
      `../../system/questions/questions/${parts[0]}/${parts[1]}/${parts[2]}/${parts[3]}/${parts[4]}/${parts[5]}/${questionID}`
    ).then((q) => {
      setQuestion(q[questionID]());
    });
  }, [questionID]);

  return (
    <div className="container my-5">
      <DevQuestionDisplay question={question} />
    </div>
  );
};

export default GCSEDevelopment;
