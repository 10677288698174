import React from "react";
import PropTypes from "prop-types";

const Alert = ({ variant, children }) => {
  return (
    <div className={`alert alert-${variant}`} role="alert">
      {children}
    </div>
  );
};

Alert.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ]),
  children: PropTypes.node.isRequired,
};

Alert.defaultProps = {
  variant: "primary",
};

export default Alert;
