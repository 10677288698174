import React, { useState, useEffect } from "react";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation, useHistory } from "react-router-dom";
// eslint-disable-next-line import/no-cycle
import jwt from "jsonwebtoken";
import QuestionDisplay from "../QuestionDisplay";
import * as Questions from "../../system/questions";
import { QuestionSelector } from "../../system/modules/MCSelector";
import Alert from "../Alert";
import checkToken from "../../system/auth/checkToken";
import { getSiteID } from "../../system/utils/getSite";

const WorkSmart = () => {
  const location = useLocation();
  const [question, setQuestion] = useState({});
  const [repeat, setRepeat] = useState(false);
  const [loading, setLoading] = useState(
    !(location.state && location.state.content)
  );
  const [contentCovered, setContentCovered] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [error, setError] = useState("");
  const history = useHistory();
  const PUPIL_GCSE_LEVEL =
    jwt.decode(localStorage.getItem("mcmToken")).role === "HIGH_PUPIL" ? 1 : 0;

  const site = getSiteID();

  let level = "ALEVEL";
  if (site === 0) {
    if (PUPIL_GCSE_LEVEL === 1) level = "HIGH";
    else level = "FOUND";
  }

  const setQuestionSafely = (content, answered) => {
    let chosenQuestion = QuestionSelector(level, content, answered);
    let errored = true;
    const questionObject = {};
    while (errored) {
      try {
        questionObject.question = Questions[chosenQuestion]();
        errored = false;
      } catch (e) {
        chosenQuestion = QuestionSelector(level, content, answered);
        // chosenQuestion =
        // "GCSE_Higher_Number_Sac_FactorsMultiplesAndPrimes_Exam_Q1";
      }
    }
    questionObject.name = chosenQuestion;
    setQuestion(questionObject);
  };

  useEffect(() => {
    const getContentCovered = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_DOMAIN}/content`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
        });
        return res.data;
      } catch (e) {
        setError("A problem occurred! Please check your connection!");
      }
      return false;
    };
    const getQuestionsAnswered = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_DOMAIN}/answers`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
        });
        return res.data;
      } catch (e) {
        setError("A problem occurred! Please check your connection!");
      }
      return false;
    };
    if (location.state && location.state.content) {
      const { content } = location.state;
      const answered = location.state.answers;
      const names = [];
      for (let i = 0; i < content.length; i += 1) {
        names.push(
          `${content[i].level}_${content[i].major}_${content[i].minor}`
        );
      }
      setContentCovered(names);
      setAnswers(answered);
      setQuestionSafely(names, answered);
    } else if (checkToken()) {
      getContentCovered().then((content) => {
        if (content) {
          if (content.length !== 0) {
            getQuestionsAnswered().then((answered) => {
              const names = [];
              for (let i = 0; i < content.length; i += 1) {
                names.push(
                  `${content[i].level}_${content[i].major}_${content[i].minor}`
                );
              }
              setContentCovered(names);
              setAnswers(answered);
              setQuestionSafely(names, answered);
              setLoading(false);
            });
          } else {
            setError(
              "Please fill in your content covered list and then come back!"
            );
          }
        }
      });
    } else {
      localStorage.removeItem("mcmToken");
      history.push("/login");
    }
  }, [location.state]);

  const startLoad = () => {
    return setTimeout(() => {
      setLoading(true);
    }, 500);
  };

  const stopLoad = (timer) => {
    clearTimeout(timer);
    setLoading(false);
  };

  const setNext = (lastAnswer) => {
    const start = startLoad();
    const answered = [...answers];
    answered.unshift(lastAnswer);
    setAnswers(answered);
    setRepeat(false);
    setQuestionSafely(contentCovered, answered);
    stopLoad(start);
  };

  const tryAgain = (lastAnswer) => {
    const answered = [...answers];
    answered.unshift(lastAnswer);
    setAnswers(answered);
    setRepeat(true);
    const questionObject = {};
    questionObject.question = Questions[question.name]();
    questionObject.name = question.name;
    setQuestion(questionObject);
  };

  return (
    <div className="container">
      <div className="my-4">
        <div className="text-center">
          <h4>Work Smart</h4>
        </div>
        {error === "" ? (
          <div>
            {loading ? (
              <div className="my-5 text-center">
                <ClipLoader loading color="#74c5ed" size="3em" />
              </div>
            ) : (
              <div>
                {Object.keys(question).length !== 0 && (
                  <QuestionDisplay
                    question={question.question}
                    questionName={question.name}
                    next={setNext}
                    repeat={repeat}
                    tryAgain={tryAgain}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <Alert variant="danger">{error}</Alert>
        )}
      </div>
    </div>
  );
};

export default WorkSmart;
