import { NumberSetError } from "../MCError";
import { Fraction } from "./Fraction";
/**
 * Class creating Angle objects. Not used much, probably not very useful.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Create an angle of 45 degrees
 * const a1 = new Angle(45)
 * // Creates an angle of 0.5pi radians
 * const a2 = new Angle(0.5,'pi-rad')
 */

class Angle {
  /**
   * Creates an angle.
   *
   * @param {number} angle The angle of the angle object to create.
   * @param {string}[unit='deg'] unit The unit of the angle, either 'deg' (default), 'rad', or 'pi-rad'.
   *
   * @throws {TypeError}  The argument should be in the correct form.
   *
   * @returns {Angle} A new angle object
   */
  constructor(angle, unit = "deg") {
    if (typeof angle !== "number") {
      throw new NumberSetError(`Expected a number, got ${angle} instead`);
    }
    if (unit !== "deg" && unit !== "rad" && unit !== "pi-rad") {
      throw new NumberSetError(`Expected a unit, got ${unit} instead`);
    }
    this.angle = angle;
    this.unit = unit;
  }

  /**
   * Converts this angle to be in degrees.
   *
   * @example
   * //converts pi/2 radians to degree
   * a1 = new Angle(Math.PI/2, 'rad')
   * console.log(a1.convertToDegrees())
   */
  toDegrees() {
    let a = 0;
    if (this.unit === "rad") {
      a = (this.angle * 180) / Math.PI;
    }
    if (this.unit === "pi-rad") {
      a = this.angle * 180;
    }
    return new Angle(a, "deg");
  }

  /**
   * Converts this angle to be in radians.
   * @example
   * //converts 90 degrees to radians
   * a1 = new Angle(90)
   * console.log(a1.convertToRadians())
   */
  toRadians() {
    let a = 0;
    if (this.unit === "deg") {
      a = (this.angle * Math.PI) / 180;
    }
    if (this.unit === "pi-rad") {
      a = this.angle * Math.PI;
    }
    return new Angle(a, "rad");
  }

  /**
   * Converts this angle to be in Pi radians.
   *
   * @example
   * //converts 90 degrees to pi radians
   * a1 = new Angle(90)
   * console.log(a1.convertToPiRadians())
   */
  toPiRadians() {
    let a = 0;
    if (this.unit === "deg") {
      a = this.angle / 180;
    }
    if (this.unit === "rad") {
      a = this.angle / Math.PI;
    }
    return new Angle(a, "pi-rad");
  }

  /**
   * Writes this angle to a string
   *
   * @returns{string} The angle plus unit
   *
   * @example
   * //writes 90 degrees
   * a1 = new Angle(90)
   * console.log(a1.toString())
   */
  toString() {
    if (this.unit === "deg") {
      return `${this.angle}^{\\circ}`;
    }
    if (this.unit === "rad") {
      return this.angle;
    }
    if (this.unit === "pi-rad") {
      let f = new Fraction(this.angle).toString();
      if (f.includes("{1}{")) {
        f = f.replace("{1}{", "{\\pi}{");
      } else {
        f = f.replace("}{", "\\pi}{");
      }
      return f;
    }
    return "";
  }

  /**
   * Writes the angle as a float for further calculations
   *
   * @returns{number} The angle
   *
   * @example
   * //creturns 90
   * a1 = new Angle(90)
   * console.log(a1.toFloat())
   */
  toFloat() {
    return this.angle;
  }
}

export { Angle };
