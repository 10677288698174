import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import styles from "./styles/AlertDropdown.module.css";

const AlertDropdown = ({ text, children, variant }) => {
  const [isOpen, setOpen] = useState(false);
  const [isTabbing, setTabbing] = useState(false);

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  const handleKeyPressToggle = (e) => {
    if (e.key === "Tab") {
      setTabbing(true);
    }
    if (e.key === "Enter" || e.key === " ") {
      setOpen(!isOpen);
    }
  };

  return (
    <>
      <div className={`alert alert-${variant}`}>
        <div
          style={{ cursor: "pointer" }}
          role="button"
          onClick={() => toggleOpen()}
          tabIndex={0}
          className={`${!isTabbing ? styles.noTabs : ""}`}
          onKeyDown={handleKeyPressToggle}
        >
          {text}
          <div style={{ float: "right" }}>
            <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} />
          </div>
        </div>
        {isOpen && <div className="mt-2">{children}</div>}
      </div>
    </>
  );
};

AlertDropdown.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
};

AlertDropdown.defaultProps = {
  variant: "success",
};

export default AlertDropdown;
