import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const IconText = ({ icon, text, color, sizeVariant }) => {
  const CustomHeading = `h${sizeVariant}`;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <CustomHeading>
        <FontAwesomeIcon
          className="fa-fw"
          icon={icon}
          style={{ marginRight: "0.2em", color }}
        />
        {text}
      </CustomHeading>
    </div>
  );
};

IconText.propTypes = {
  icon: PropTypes.objectOf(PropTypes.any).isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  sizeVariant: PropTypes.number,
};

IconText.defaultProps = {
  color: "black",
  sizeVariant: 1,
};

export default IconText;
