import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import Summary from "../Summary";
// eslint-disable-next-line import/no-cycle
import HistoryCard from "../HistoryCard";
import TopicBreakdown from "../TopicBreakdown";
import Alert from "../Alert";
import { CalculateScores } from "../../system/modules/MCSelector";
import checkToken from "../../system/auth/checkToken";
import titleCase from "../../system/utils/titleCase";

const History = () => {
  const location = useLocation();
  const [summaryPeriod, setSummaryPeriod] = useState(7);
  const [data, setData] = useState([]);
  const [topicScores, setTopicScores] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (location.state && location.state.id) {
      let resData = [];
      axios
        .get(`${process.env.REACT_APP_DOMAIN}/answers/pupil`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
          params: { pupilId: location.state.id },
        })
        .then((res) => {
          resData = res.data;
          setData(resData);
          const scores = CalculateScores(resData);
          const keys = Object.keys(scores);
          for (let i = 0; i < keys.length; i += 1) {
            const minor = keys[i];
            let answeredForThisMinor = false;
            for (let j = 0; j < resData.length; j += 1) {
              const question = resData[j];
              if (question.minor === minor) {
                answeredForThisMinor = true;
                break;
              }
            }
            if (!answeredForThisMinor) {
              delete scores[minor];
            }
          }
          setTopicScores(scores);
          setLoading(false);
        })
        .catch(() => {
          setAlert("A problem occurred. Please try again later.");
        });
    } else if (checkToken()) {
      axios
        .get(`${process.env.REACT_APP_DOMAIN}/answers`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
        })
        .then((res) => {
          const resData = res.data;
          setData(resData);
          const scores = CalculateScores(resData);
          const keys = Object.keys(scores);
          for (let i = 0; i < keys.length; i += 1) {
            const minor = keys[i];
            let answeredForThisMinor = false;
            for (let j = 0; j < resData.length; j += 1) {
              const question = resData[j];
              if (question.minor === minor) {
                answeredForThisMinor = true;
                break;
              }
            }
            if (!answeredForThisMinor) {
              delete scores[minor];
            }
          }
          setTopicScores(scores);
          setLoading(false);
        })
        .catch(() => {
          setAlert("A problem occurred. Please try again later.");
        });
    } else {
      localStorage.removeItem("mcmToken");
      history.push("/login");
    }
  }, [location.state]);

  const targetGenerator = (toSlice) => {
    let improvements = [];
    let targets = [];
    const all = [...data];
    const minus5 = [...data].splice(0, toSlice);
    const scoresForAll = CalculateScores(all);
    const scoresLimited = CalculateScores(minus5);
    const topics = Object.keys(scoresForAll);
    const higher = [];
    const lower = [];
    const allScores = [];
    for (let i = 0; i < topics.length; i += 1) {
      const topic = topics[i];
      if (scoresForAll[topic] > scoresLimited[topic]) {
        higher.push([topic, scoresForAll[topic] - scoresLimited[topic]]);
      } else if (scoresForAll[topic] < scoresLimited[topic]) {
        lower.push([topic, scoresLimited[topic] - scoresForAll[topic]]);
      }
      allScores.push([topic, scoresForAll[topics]]);
    }
    higher.sort((a, b) => {
      if (a[1] > b[1]) {
        return -1;
      }
      if (a[1] < b[1]) {
        return 1;
      }
      return 0;
    });
    lower.sort((a, b) => {
      if (a[1] > b[1]) {
        return 1;
      }
      if (a[1] < b[1]) {
        return -1;
      }
      return 0;
    });
    improvements = higher.slice(0, 3);
    targets = lower.slice(0, 3);
    if (targets.length === 0) {
      allScores.sort((a, b) => {
        if (a[1] > b[1]) {
          return 1;
        }
        if (a[1] < b[1]) {
          return -1;
        }
        return 0;
      });
      targets = allScores.slice(0, 3);
    }
    return { improvements, targets };
  };

  const getImprovedSkills = (limitedQuestionSet) => {
    const all = [...data];
    const limited = [...limitedQuestionSet];
    const allScores = CalculateScores(all);
    const limitedScores = CalculateScores(all.slice(limited.length));
    const topics = Object.keys(allScores);
    const higher = [];
    for (let i = 0; i < topics.length; i += 1) {
      const topic = topics[i];
      if (limitedScores[topic] < allScores[topic]) {
        higher.push(topic);
      }
    }
    // go through higher and check for at least one correct question for the topic
    const toKeep = [];
    for (let i = 0; i < higher.length; i += 1) {
      const topic = higher[i];
      for (let j = 0; j < all.length; j += 1) {
        const question = all[j];
        if (question.minor === topic) {
          if (question.correct >= 1) {
            toKeep.push(topic);
          }
        }
      }
    }
    return [...new Set(toKeep)].length;
  };

  useEffect(() => {
    let total = 0;
    let correct = 0;
    let skillsImproved = 0;
    const relevantQs = [];
    if (summaryPeriod === -1) {
      data.forEach((entry) => {
        total += 1;
        if (entry.correct >= 1) {
          correct += 1;
        }
      });
    } else {
      const dateRange = new Date();
      dateRange.setDate(dateRange.getDate() - summaryPeriod);
      data.forEach((entry) => {
        const qDate = entry.dateAnswered.split(/[- :]/);
        const date = new Date(
          qDate[0],
          qDate[1] - 1,
          qDate[2],
          qDate[3],
          qDate[4],
          qDate[5]
        );
        if (date >= dateRange) {
          total += 1;
          relevantQs.push(entry);
          correct += entry.correct;
        }
      });
      skillsImproved = getImprovedSkills(relevantQs);
    }
    let imp = [];
    let tar = [];
    if (data.length >= 20) {
      const { improvements, targets } = targetGenerator(
        data.length >= 40 ? 20 : 5
      );
      for (let i = 0; i < improvements.length; i += 1) {
        imp.push(titleCase(improvements[i][0]));
      }
      for (let i = 0; i < targets.length; i += 1) {
        tar.push(titleCase(targets[i][0]));
      }
    }
    imp = [...new Set(imp)];
    tar = [...new Set(tar)];
    const summ = {
      total,
      correct,
      skillsImproved,
      improved: imp,
      targets: tar,
    };
    setSummaryData(summ);
  }, [data, summaryPeriod]);

  const handleSummaryPeriodChange = (newPeriod) => {
    setSummaryPeriod(newPeriod);
  };

  return (
    <div className="container">
      <div className="mt-4 mb-5">
        {alert !== "" && <Alert variant="danger">{alert}</Alert>}
        {location.state && location.state.name && (
          <h2 className="text-center">{location.state.name}</h2>
        )}
        <div className="my-3">
          <Summary
            summaryPeriod={summaryPeriod}
            handleSummaryPeriodChange={handleSummaryPeriodChange}
            data={summaryData}
            loading={loading}
          />
        </div>
        <div className="my-3">
          <HistoryCard questions={data} loading={loading} />
        </div>
        <div className="my-3">
          <TopicBreakdown topics={topicScores} loading={loading} />
        </div>
      </div>
    </div>
  );
};

export default History;
