import { NumberSetError } from "../MCError";
/**
 * Class Handling Fractions.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Creates the fraction $3/4$ from the decimal $0.75$
 * const f1 = new Fraction(0.75)
 */

class Fraction {
  /**
   * Creates a Fraction.
   *
   * @param {number} 		value The value to be converted into a fraction.
   * @returns {Fraction} 	A new Fraction equal to the input value.
   * @throws {TypeError}   The argument should be a number.
   */
  constructor(value) {
    if (typeof value !== "number") {
      throw new NumberSetError(`Expected a number, got ${value} instead`);
    }
    // algorithm from https://begriffs.com/pdf/dec2frac.pdf
    const x = Math.abs(value);
    let z = x;
    let d0 = 0;
    let dTemp = 0;
    let d1 = 1;
    let n = 1;

    // Sorts whole numbers out
    if (Math.round(x) === x) {
      this.numerator = value;
      this.denominator = 1;
      return;
    }
    // 1e-15 is the accuracy, this loop does the decimal to fraction conversion
    while (Math.abs(n / d1 - x) > 1e-15) {
      z = 1 / (z - Math.floor(z));
      dTemp = d1;
      d1 = d1 * Math.floor(z) + d0;
      d0 = dTemp;
      n = Math.round(x * d1);
    }

    this.numerator = n;

    // Sorts negative numbers
    if (value < 0) {
      this.numerator *= -1;
    }

    this.denominator = d1;
  }

  /**
   * @summary Adds to a fraction.
   *
   * @description Takes an input fraction or number, and adds it to the fraction.
   *
   * @param  {number | Fraction} 	input The value, either fraction or number, to add to the fraction.
   * @throws {TypeError}          	The argument should be either a fraction or number.
   * @returns {Fraction} 			A new Fraction equal to the sum.
   *
   * @example
   * //Adds 2 to 2/3
   * let f1 = new Fraction(2/3)
   * console.log(f1.add(2))
   * //Add 1/4 to 1/5
   * let f2 = new Fraction(1/5)
   * let f3 = new Fraction(1/4)
   * console.log(f2.add(f3))
   */
  add(input) {
    if (input instanceof Fraction) {
      return new Fraction(this.toFloat() + input.toFloat());
    }
    if (typeof input === "number") {
      return new Fraction(this.toFloat() + input);
    }
    throw new NumberSetError(
      `Expected a number or fraction, got ${input} instead`
    );
  }

  /**
   * @summary Subtracts from a fraction.
   *
   * @description Takes an input fraction or number, and subtracts it from the fraction.
   *
   * @param  {number | Fraction} 	input The value, either fraction or number, to subtract from the fraction.
   * @throws {TypeError}          	The argument should be either a fraction or number.
   * @returns {Fraction} 			A new Fraction equal to the difference.
   *
   * @example
   * //Subtracts 2 from 2/3
   * let f1 = new Fraction(2/3)
   * console.log(f1.subtract(2))
   * //Subtracts 1/5 from 1/4
   * let f2 = new Fraction(1/4)
   * let f3 = new Fraction(1/5)
   * console.log(f2.add(f3))
   */
  subtract(input) {
    if (input instanceof Fraction) {
      return new Fraction(this.toFloat() - input.toFloat());
    }
    if (typeof input === "number") {
      return new Fraction(this.toFloat() - input);
    }
    throw new NumberSetError(
      `Expected a number or fraction, got ${input} instead`
    );
  }

  /**
   * @summary Multiplys a fraction.
   *
   * @description Takes an input fraction or number, and multiplys the fraction by it.
   *
   * @param  {number | Fraction} 	input The value, either fraction or number, to multiply the fraction by.
   * @throws {TypeError}         	The argument should be either a fraction or number.
   * @returns {Fraction} 			A new Fraction equal to the product.
   *
   * @example
   * //Multiplys 2/3 by 2
   * const f1 = new Fraction(2/3)
   * console.log(f1.multiply(2))
   * //Multiplys 1/4 by 1/5
   * const f2 = new Fraction(1/4)
   * const f3 = new Fraction(1/5)
   * console.log(f2.multiply(f3))
   */
  multiply(input) {
    if (input instanceof Fraction) {
      return new Fraction(this.toFloat() * input.toFloat());
    }
    if (typeof input === "number") {
      return new Fraction(this.toFloat() * input);
    }
    throw new NumberSetError(
      `Expected a number or fraction, got ${input} instead`
    );
  }

  /**
   * @summary Divides a fraction.
   *
   * @description Takes an input fraction or number, and divides the fraction by it.
   *
   * @param  {number | Fraction} 	input The value, either fraction or number, to divide the fraction by.
   * @throws {TypeError}         	The argument should be either a fraction or number.
   * @returns {Fraction} 			A new Fraction equal to the quotient.
   *
   * @example
   * //Divides 2/3 by 2
   * const f1 = new Fraction(2/3)
   * console.log(f1.divide(2))
   * //Dividess 1/4 by 1/5
   * const f2 = new Fraction(1/4)
   * const f3 = new Fraction(1/5)
   * console.log(f2.divide(f3))
   */
  divide(input) {
    if (input instanceof Fraction) {
      return new Fraction(this.toFloat() / input.toFloat());
    }
    if (typeof input === "number") {
      return new Fraction(this.toFloat() / input);
    }
    throw new NumberSetError(
      `Expected a number or fraction, got ${input} instead`
    );
  }

  /**
   * @summary Returns the deciaml value of the fraction.
   *
   * @returns {number} The numerical value of the fraction.
   *
   * @example
   * const f1 = new Fraction(2/3)
   * console.log(f1.toFloat())
   */
  toFloat() {
    return this.numerator / this.denominator;
  }

  /**
   * @summary Returns the fraction in a ready to display format.
   *
   * @returns {string} A string of latex displaying the fraction.
   *
   * @example
   * const f1 = new Fraction(2/3)
   * console.log(f1.toString())
   */
  toString() {
    if (this.denominator === 1) {
      return this.numerator;
    }
    if (this.numerator >= 0) {
      return `\\frac{${this.numerator}}{${this.denominator}}`;
    }
    return `-\\frac{${-this.numerator}}{${this.denominator}}`;
  }

  /**
   * @summary Returns the fraction in a ready to display mixed number format.
   *
   * @returns {string} A string of latex displaying the fraction as a mixed number.
   *
   * @example
   * const f1 = new Fraction(2/3)
   * console.log(f1.toMixed())
   */
  toMixed() {
    const value = Math.abs(this.toFloat());
    const whole = Math.floor(value);
    const fraction = value % 1;
    let output = "";
    if (this.toFloat() < 0) {
      output += "-";
    }
    if (whole !== 0) {
      output += whole;
    }
    if (fraction !== 0) {
      output += new Fraction(fraction).toString();
    }
    if (value === 0) {
      output = "0";
    }
    return output;
  }
}

export { Fraction };
