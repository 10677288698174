import { NumberSetError, ArgumentError } from "../MCError";
import { cleaner } from "./cleaner";
import { Graph } from "../MCQuestion";
/**
 * Simultaneous Equations.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Creates a the intersection between 3x+2y=3 and x+y+x^2=-5
 * const Is = new Intersections([3,2,3],[1,1,1,-5])
 */

class Intersections {
  /**
   * Creates a Geometric Sequence $a\\cdot r^{n-1}$.
   *
   * @param {number[]} 		eq1 - $ax+by=c$, the linear equations
   * @param {number[]} 		eq2 - $ax+by+cx^2+dy^2=e$ the other equation, $c$ and $d$ are optional (must have $c\neq 0$ if $d\neq 0$).
   * @param {string}  		var1='x' The first variable
   * @param {string}  		var2='y' The second variable
   * @returns {Intersections} An Intersections object.
   * @throws {TypeError}      The variable arguments should be strings.
   */
  constructor(eq1, eq2, var1 = "x", var2 = "y") {
    if (typeof var1 !== "string") {
      throw new NumberSetError(`Expected a string, got ${var1} instead`);
    }
    if (typeof var2 !== "string") {
      throw new NumberSetError(`Expected a string, got ${var2} instead`);
    }
    if (eq2.length === 5) {
      if (eq2[3] === 0 && eq2[4] !== 0) {
        throw new NumberSetError(`Expected a 0, got ${eq2[3]} instead`);
      }
    }
    if (eq2.length !== 3 && eq2.length !== 4 && eq2.length !== 5) {
      throw new ArgumentError(
        `Expected an array of length 3,4 or 5, got ${eq2} instead`
      );
    }
    this.eq1 = eq1;
    this.eq2 = eq2;
    this.var1 = var1;
    this.var2 = var2;
  }

  /**
   * Returns a string of both of the equations
   *
   * @returns {string}
   */
  toString() {
    if (this.eq2.length === 3) {
      return `${cleaner(
        `${this.eq1[0] + this.var1}+${this.eq1[1]}${this.var2}=${this.eq1[2]}`
      )}\\hspace{1cm} ${cleaner(
        `${this.eq2[0] + this.var1}+${this.eq2[1]}${this.var2}=${this.eq2[2]}`
      )}`;
    }
    if (this.eq2.length === 4) {
      return `${cleaner(
        `${this.eq1[0] + this.var1}+${this.eq1[1]}${this.var2}=${this.eq1[2]}`
      )}\\hspace{1cm} ${cleaner(
        `${this.eq2[2] + this.var1}^2 +${this.eq2[0]}${this.var1}+${
          this.eq2[1]
        }${this.var2}=${this.eq2[3]}`
      )}`;
    }
    if (this.eq2.length === 5) {
      return `${cleaner(
        `${this.eq1[0] + this.var1}+${this.eq1[1]}${this.var2}=${this.eq1[2]}`
      )}\\hspace{1cm} ${cleaner(
        `${this.eq2[2] + this.var1}^2 +${this.eq2[3]}${this.var2}^2 +${
          this.eq2[0]
        }${this.var1}+${this.eq2[1]}${this.var2}=${this.eq2[4]}`
      )}`;
    }
    return "Not Yet Implimented";
  }

  /**
   * Returns a string array of both of the equations
   *
   * @returns {string[]}
   */
  toStringArray() {
    if (this.eq2.length === 3) {
      return [
        `$${cleaner(
          `${this.eq1[0] + this.var1}+${this.eq1[1]}${this.var2}=${this.eq1[2]}`
        )}$`,
        `$${cleaner(
          `${this.eq2[0] + this.var1}+${this.eq2[1]}${this.var2}=${this.eq2[2]}`
        )}$`,
      ];
    }
    if (this.eq2.length === 4) {
      return [
        `$${cleaner(
          `${this.eq1[0] + this.var1}+${this.eq1[1]}${this.var2}=${this.eq1[2]}`
        )}$`,
        `$${cleaner(
          `${this.eq2[2] + this.var1}^2 +${this.eq2[0]}${this.var1}+${
            this.eq2[1]
          }${this.var2}=${this.eq2[3]}`
        )}$`,
      ];
    }
    if (this.eq2.length === 5) {
      return [
        `$${cleaner(
          `${this.eq1[0] + this.var1}+${this.eq1[1]}${this.var2}=${this.eq1[2]}`
        )}$`,
        `$${cleaner(
          `${this.eq2[2] + this.var1}^2 +${this.eq2[3]}${this.var2}^2 +${
            this.eq2[0]
          }${this.var1}+${this.eq2[1]}${this.var2}=${this.eq2[4]}`
        )}$`,
      ];
    }
    return "Eq2 wrong length";
  }

  /**
   * Returns an array of solutions to the intersections, in the form [x1,y1,x2,y2] or [x,y] depending on number of solutions.
   *
   * @returns {string[]}
   */
  solutions() {
    if (this.eq2.length === 3) {
      const a = this.eq1[0];
      const b = this.eq1[1];
      const c = this.eq1[2];
      const d = this.eq2[0];
      const e = this.eq2[1];
      const f = this.eq2[2];
      if (a * e - b * d === 0 || a === 0) {
        return "No Solutions";
      }
      const y = (a * f - c * d) / (a * e - b * d);
      const x = (c - b * y) / a;
      return [x, y];
    }
    if (this.eq2.length === 4) {
      const a = this.eq2[2];
      const b = this.eq2[0] - (this.eq2[1] * this.eq1[0]) / this.eq1[1];
      const c = (this.eq2[1] * this.eq1[2]) / this.eq1[1] - this.eq2[3];
      const discriminat = b * b - 4 * a * c;
      if (discriminat < 0) {
        return "No Real Roots";
      }
      if (discriminat === 0) {
        const x = -b / (2 * a);
        const y = (this.eq1[2] - this.eq1[0] * x) / this.eq1[1];
        return [x, y];
      }
      const x1 = (-b - Math.sqrt(discriminat)) / (2 * a);
      const x2 = (-b + Math.sqrt(discriminat)) / (2 * a);
      const y1 = (this.eq1[2] - this.eq1[0] * x1) / this.eq1[1];
      const y2 = (this.eq1[2] - this.eq1[0] * x2) / this.eq1[1];
      return [x1, y1, x2, y2];
    }
    if (this.eq2.length === 5) {
      const a =
        this.eq2[2] + (this.eq2[3] * this.eq1[0] ** 2) / this.eq1[1] ** 2;
      const b =
        this.eq2[0] -
        (this.eq1[0] * this.eq2[1]) / this.eq1[1] -
        (2 * this.eq1[0] * this.eq1[2] * this.eq2[3]) / this.eq1[1] ** 2;
      const c =
        (this.eq2[1] * this.eq1[2]) / this.eq1[1] +
        (this.eq2[3] * this.eq1[2] ** 2) / this.eq1[1] ** 2 -
        this.eq2[4];
      const discriminat = b * b - 4 * a * c;
      if (discriminat < 0) {
        return "No Real Roots";
      }
      if (discriminat === 0) {
        const x = -b / (2 * a);
        const y = (this.eq1[2] - this.eq1[0] * x) / this.eq1[1];
        return [x, y];
      }
      const x1 = (-b - Math.sqrt(discriminat)) / (2 * a);
      const x2 = (-b + Math.sqrt(discriminat)) / (2 * a);
      const y1 = (this.eq1[2] - this.eq1[0] * x1) / this.eq1[1];
      const y2 = (this.eq1[2] - this.eq1[0] * x2) / this.eq1[1];
      return [x1, y1, x2, y2];
    }
    return "Eq2 wrong length";
  }

  /**
   * Returns working of the solutions.
   *
   * @returns {string[]}
   */
  solutionsWorking() {
    if (this.eq2.length === 3) {
      const output = [];
      output.push(
        `Subtracting ${cleaner(
          `$${this.eq2[0] / this.eq1[0]}$`
        )} times equation 1 from equation 2:`
      );
      const factor = this.eq2[1] - (this.eq2[0] / this.eq1[0]) * this.eq1[1];
      output.push(
        cleaner(`$${factor}${this.var2}=${factor * this.solutions()[1]}$`)
      );
      output.push(cleaner(`$${this.var2}=${this.solutions()[1]}$`));
      output.push("Substituting:");
      const replaceY = new RegExp(this.var2, "g");
      output.push(
        cleaner(
          this.toStringArray()[0].replace(
            replaceY,
            `\\left(${this.solutions()[1]}\\right)`
          )
        )
      );
      output.push(cleaner(`$${this.var1}=${this.solutions()[0]}$`));
      return output;
    }
    if (this.eq2.length === 4) {
      const output = [];
      const xcoef = -this.eq1[0] / this.eq1[1];
      const xoffset = this.eq1[2] / this.eq1[1];
      const eq1Rearranged = cleaner(`${xcoef + this.var1}+${xoffset}`);
      output.push(`Rearranging, we have $${this.var2}=${eq1Rearranged}$.`);
      output.push("Substituting,");
      output.push(
        cleaner(
          this.toStringArray()[1].replace(
            this.var2,
            `\\left(${eq1Rearranged}\\right)`
          )
        )
      );
      const a = this.eq2[2];
      const b = this.eq2[0] - (this.eq2[1] * this.eq1[0]) / this.eq1[1];
      const c = (this.eq2[1] * this.eq1[2]) / this.eq1[1] - this.eq2[3];
      output.push(
        `Rearranging, ${cleaner(
          `$${a}${this.var1}^2+${b}${this.var1}+${c}=0$`
        )}`
      );
      if (this.solutions() === "No Real Roots") {
        output.push("No Real Roots");
      } else {
        if (this.solutions().length === 2) {
          output.push(
            `Solving, ${cleaner(`$${this.var1}=${this.solutions()[0]}$.`)}`
          );
        }
        if (this.solutions().length === 4) {
          output.push(
            `Solving, ${cleaner(
              `$${this.var1}_1 =${this.solutions()[0]}, ${this.var1}_2 =${
                this.solutions()[2]
              }$.`
            )}`
          );
        }
      }
      output.push("Substituting,");
      if (this.solutions() === "No Real Roots") {
        output.push("No Real Roots");
      } else {
        if (this.solutions().length === 2) {
          output.push(
            cleaner(
              this.toStringArray()[0].replace(
                this.var1,
                `\\left(${this.solutions()[0]}\\right)`
              )
            )
          );
          output.push(cleaner(`$${this.var2}=${this.solutions()[1]}$`));
          output.push(
            `Solution is ${cleaner(
              `$\\left(${this.solutions()[0]},${this.solutions()[1]}\\right)$`
            )}`
          );
        }
        if (this.solutions().length === 4) {
          output.push(
            cleaner(
              this.toStringArray()[0].replace(
                this.var1,
                `\\left(${this.solutions()[0]}\\right)`
              )
            )
          );
          output.push(cleaner(`$${this.var2}_1 =${this.solutions()[1]}$`));
          output.push(
            cleaner(
              this.toStringArray()[0].replace(
                this.var1,
                `\\left(${this.solutions()[2]}\\right)`
              )
            )
          );
          output.push(cleaner(`$${this.var2}_2 =${this.solutions()[3]}$`));
          output.push(
            `Solutions are ${cleaner(
              `$\\left(${this.solutions()[0]},${
                this.solutions()[1]
              }\\right)$ and $\\left(${this.solutions()[2]},${
                this.solutions()[3]
              }\\right)$.`
            )}`
          );
        }
      }
      return output;
    }
    if (this.eq2.length === 5) {
      const output = [];
      const xcoef = -this.eq1[0] / this.eq1[1];
      const xoffset = this.eq1[2] / this.eq1[1];
      const eq1Rearranged = cleaner(`${xcoef + this.var1}+${xoffset}`);
      output.push(`Rearranging, $${this.var2}=${eq1Rearranged}$.`);
      output.push("Substituting,");
      const yregex = new RegExp(this.var2, "g");
      output.push(
        cleaner(
          this.toStringArray()[1].replace(
            yregex,
            `\\left(${eq1Rearranged}\\right)`
          )
        )
      );
      const a =
        this.eq2[2] + (this.eq2[3] * this.eq1[0] ** 2) / this.eq1[1] ** 2;
      const b =
        this.eq2[0] -
        (this.eq1[0] * this.eq2[1]) / this.eq1[1] -
        (2 * this.eq1[0] * this.eq1[2] * this.eq2[3]) / this.eq1[1] ** 2;
      const c =
        (this.eq2[1] * this.eq1[2]) / this.eq1[1] +
        (this.eq2[3] * this.eq1[2] ** 2) / this.eq1[1] ** 2 -
        this.eq2[4];
      output.push(
        `Rearranging, ${cleaner(
          `$${a}${this.var1}^2+${b}${this.var1}+${c}=0$`
        )}`
      );
      if (this.solutions() === "No Real Roots") {
        output.push("No Real Roots");
      } else {
        if (this.solutions().length === 2) {
          output.push(
            `Solving, ${cleaner(`$${this.var1}=${this.solutions()[0]}$.`)}`
          );
        }
        if (this.solutions().length === 4) {
          output.push(
            `Solving, ${cleaner(
              `$${this.var1}_1 =${this.solutions()[0]}, ${this.var1}_2 =${
                this.solutions()[2]
              }$.`
            )}`
          );
        }
      }
      output.push("Substituting,");
      if (this.solutions() === "No Real Roots") {
        output.push("No Real Roots");
      } else {
        if (this.solutions().length === 2) {
          output.push(
            cleaner(
              this.toStringArray()[0].replace(
                this.var1,
                `\\left(${this.solutions()[0]}\\right)`
              )
            )
          );
          output.push(cleaner(`$${this.var2}=${this.solutions()[1]}$`));
          output.push(
            `Solution is ${cleaner(
              `$\\left(${this.solutions()[0]},${this.solutions()[1]}\\right)$`
            )}`
          );
        }
        if (this.solutions().length === 4) {
          output.push(
            cleaner(
              this.toStringArray()[0].replace(
                this.var1,
                `\\left(${this.solutions()[0]}\\right)`
              )
            )
          );
          output.push(cleaner(`$${this.var2}_1 =${this.solutions()[1]}$`));
          output.push(
            cleaner(
              this.toStringArray()[0].replace(
                this.var1,
                `\\left(${this.solutions()[2]}\\right)`
              )
            )
          );
          output.push(cleaner(`$${this.var2}_2 =${this.solutions()[3]}$`));
          output.push(
            `Solutions are ${cleaner(
              `$\\left(${this.solutions()[0]},${
                this.solutions()[1]
              }\\right)$ and $\\left(${this.solutions()[2]},${
                this.solutions()[3]
              }\\right)$.`
            )}`
          );
        }
      }
      return output;
    }

    return "Eq2 wrong length";
  }

  /**
   * Sometimes the working for simultaneous Equations has ugly numbers, this test numbers are nice.
   *
   * @returns {bool} True is good, false is ugly numbers.
   */

  solutionsWorkingTest() {
    if (
      parseFloat(cleaner(`${this.solutionsWorking()[1]}`).match(/\d+/g)[1]) <
      100
    ) {
      return true;
    }
    return false;
  }

  /**
   * Returns The Graph For The Intersections
   *
   * @param {number} size The +-x, +-y size of the graph
   * @param {number} step The size of the steps of the grap
   *
   * @returns {graph}
   *
   * @example
   * const p2 = new MCMaths.Intersections([1, -7, 0],[4,1,-1,-3.5,1]);
   * question.addGraph("question", p2.graph());
   */
  graph(size = 5, step = 1) {
    const graph = new Graph(size, -size, size, -size, step, step);
    const func1 = this.eq1;

    graph.plot(`(${func1[2]} - ${func1[0]} * x) / ${func1[1]}`, -size, size);

    const a = this.eq2[0];
    const b = this.eq2[1];
    const c = this.eq2[2];
    const d = this.eq2[3];
    const e = this.eq2[4];

    graph.plot(
      `(-((${b} / 2) * ${d}) +Math.sqrt(${b} * ${b} - 4 * ${d} * (${a} * x + ${c} * x * x - ${e})) / (${2} * ${d}))`,
      -size,
      size
    );
    graph.plot(
      `(-((${b} / 2) * ${d}) -Math.sqrt(${b} * ${b} - 4 * ${d} * (${a} * x + ${c} * x * x - ${e})) / (${2} * ${d}))`,
      -size,
      size
    );

    return graph;
  }
}

export { Intersections };
