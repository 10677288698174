import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { useHistory } from "react-router-dom";
import ExamQuestion from "./question_types/ExamQuestion";
import MathString from "./MathString";
import styles from "./styles/QuestionDisplay.module.css";
import MultipleChoiceQuestion from "./question_types/MultipleChoiceQuestion";
import MultipleChoiceAnswers from "./question_types/MultipleChoiceAnswers";
import Skills from "./question_types/Skills";
import { cleaner } from "../system/modules/MCMaths/cleaner";
import Checkbox from "./Checkbox";
import Alert from "./Alert";
// eslint-disable-next-line import/no-cycle
import { AppContext } from "../App";
import checkToken from "../system/auth/checkToken";
import useWindowDimensions from "../system/hooks/useWindowDimensions";
import { getSiteID } from "../system/utils/getSite";

const QuestionDisplay = ({
  question,
  questionName,
  next,
  oldInputs,
  oldCorrectness,
  nextText,
  repeat,
  tryAgain,
}) => {
  const { width } = useWindowDimensions();
  const { pupilData, setPupilData } = useContext(AppContext);
  // required for exam questions
  const [eqInputs, setEQInputs] = useState(oldInputs || []);
  // does this question only have a single input box
  const [singleInput, setSingleInput] = useState(true);
  // was the question checked yet
  const [checked, setChecked] = useState(false);
  // was the answer correct
  const [correct, setCorrect] = useState(0);
  // show solution
  const [solution, setSolution] = useState(false);
  const [confirmedSelf, setSelf] = useState(true);
  const [alert, setAlert] = useState({});
  const [nonExamAnswers, setNonExamAnswers] = useState([]);
  const history = useHistory();

  // sets enough inputs for the exam question
  useEffect(() => {
    if (oldInputs) {
      setSolution(true);
    }
    if (question.type === "exam" && !oldInputs) {
      const inps = [];
      for (let i = 0; i < question.content.inputGroups.length; i += 1) {
        const { perLine } = question.content.inputGroups[i];
        let toFill = [];
        for (
          let j = 0;
          j < question.content.inputGroups[i].inputs.length;
          j += 1
        ) {
          toFill.push({
            answer: "",
            labelPos:
              question.content.inputGroups[i].inputs[j].content.labelPos,
            label: question.content.inputGroups[i].inputs[j].content.label,
            expecting: question.content.inputGroups[i].inputs[j].content.answer,
            tolerance:
              question.content.inputGroups[i].inputs[j].content.tolerance,
          });
          if (toFill.length === perLine) {
            inps.push([...toFill]);
            toFill = [];
          }
        }
        if (toFill.length !== 0) {
          inps.push([...toFill]);
          toFill = [];
        }
      }
      if (inps.length === 1 && inps[0].length === 1) {
        setSingleInput(true);
      } else {
        setSingleInput(false);
      }
      setEQInputs(inps);
      if (question.self) {
        setSelf(false);
      }
    }
  }, [question, oldInputs]);

  const postAnswer = async (
    level,
    major,
    minor,
    type,
    qnum,
    context,
    answers,
    isCorrect,
    repeated
  ) => {
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_DOMAIN}/answers`,
        {
          questionLevel: level,
          questionMajor: major,
          questionMinor: minor,
          questionType: type,
          questionNumber: qnum,
          questionContext: context,
          answers,
          correct: isCorrect,
          repeated,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
        }
      );
      const thisAnswer = {
        level,
        major,
        minor,
        type,
        qNumber: qnum,
        context: JSON.stringify(context),
        answers: JSON.stringify(answers),
        correct: isCorrect,
        repeated,
        dateAnswered: new Date().toISOString().slice(0, 19).replace("T", " "),
      };
      setPupilData({
        content: pupilData.content,
        answers: [thisAnswer, ...pupilData.answers],
      });
    } catch (e) {
      console.log(e.response);
      setPupilData({
        ...pupilData,
        error: `${level}_${major}_${minor}`,
      });
    }
    return true;
  };

  const getQuestionAttributes = () => {
    const site = getSiteID();
    let baseIdx = 0;
    if (site === 0) baseIdx = 2;
    const values = questionName.split("_");
    const level = values[baseIdx];
    const major = values[baseIdx + 1];
    const minor = values[baseIdx + 2];
    const number = parseInt(values[baseIdx + 4].slice(1), 10);
    return { level, major, minor, number };
  };

  // handles changes for dynamic inputs in Exam Questions
  const handleChange = (e) => {
    const arr = [...eqInputs];
    const row = Math.floor(e.target.dataset.id / 100);
    const col = e.target.dataset.id % 100;

    arr[row][col].answer = e.target.value;
    setEQInputs(arr);
  };

  const toDeci = (fraction) => {
    const fract = fraction.toString();
    let result;
    let wholeNum = 0;
    let frac;
    let deci = 0;
    if (fract.search("/") >= 0) {
      if (fract.search(" ") >= 0) {
        wholeNum = fract.split(" ");
        [, frac] = wholeNum;
        wholeNum = parseInt(wholeNum, 10);
      } else {
        frac = fract;
      }
      if (fract.search("/") >= 0) {
        frac = frac.split("/");
        deci = parseInt(frac[0], 10) / parseInt(frac[1], 10);
      }
      result = wholeNum + deci;
    } else {
      result = fract;
    }
    return result;
  };

  const checkForEmptyInputs = () => {
    for (let i = 0; i < eqInputs.length; i += 1) {
      for (let j = 0; j < eqInputs[i].length; j += 1) {
        if (
          typeof eqInputs[i][j].answer === "undefined" ||
          eqInputs[i][j].answer.replace(/\s+/g, "") === ""
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const checkExamAnswers = async () => {
    if (question.self && !confirmedSelf) {
      setAlert({
        variant: "danger",
        msg: "Please complete all parts before checking.",
      });
      return false;
    }
    if (checkForEmptyInputs()) {
      setAlert({
        variant: "danger",
        msg: "Please complete all parts before checking.",
      });
      return false;
    }
    setAlert({});
    let correctness = 0;
    for (let i = 0; i < eqInputs.length; i += 1) {
      let isCorrect = 1;
      for (let j = 0; j < eqInputs[i].length; j += 1) {
        eqInputs[i][j].correct = true;
        if (Array.isArray(eqInputs[i][j].expecting)) {
          if (eqInputs[i][j].tolerance === 0) {
            const ans = toDeci(eqInputs[i][j].answer);
            if (Number.isNaN(Number(ans))) {
              isCorrect = 0;
              eqInputs[i][j].correct = false;
            } else {
              let there = -1;
              for (let a = 0; a < eqInputs[i][j].expecting.length; a += 1) {
                if (
                  cleaner(eqInputs[i][j].expecting[a].toString()) ===
                  cleaner(ans.toString())
                ) {
                  there = a;
                }
              }
              if (there === -1) {
                isCorrect = 0;
                eqInputs[i][j].correct = false;
              } else {
                eqInputs[i][j].expecting.splice(there, 1);
              }
            }
          } else {
            const ans = toDeci(eqInputs[i][j].answer);
            if (Number.isNaN(Number(ans))) {
              isCorrect = 0;
              eqInputs[i][j].correct = false;
            } else {
              const boundedArray = [];
              for (let a = 0; a < eqInputs[i][j].expecting.length; a += 1) {
                const possible = eqInputs[i][j].expecting[a];
                const { tolerance } = eqInputs[i][j];
                const upperbound = Number(possible) + Number(tolerance);
                const lowerbound = Number(possible) - Number(tolerance);
                boundedArray.push({ upperbound, lowerbound });
              }
              const size = boundedArray.length;
              for (let a = 0; a < boundedArray.length; a += 1) {
                const { upperbound, lowerbound } = boundedArray[a];
                if (ans >= lowerbound && ans <= upperbound) {
                  eqInputs[i][j].expecting.splice(a, 1);
                  boundedArray.splice(a, 1);
                }
              }
              if (boundedArray.length === size) {
                isCorrect = 0;
                eqInputs[i][j].correct = false;
              }
            }
          }
        } else if (eqInputs[i][j].tolerance === 0) {
          if (
            !(
              cleaner(eqInputs[i][j].expecting.toString()) ===
              cleaner(toDeci(eqInputs[i][j].answer).toString())
            )
          ) {
            isCorrect = 0;
            eqInputs[i][j].correct = false;
          }
        } else {
          const ans = toDeci(eqInputs[i][j].answer);
          if (Number.isNaN(Number(ans))) {
            isCorrect = 0;
            eqInputs[i][j].correct = false;
          } else {
            const lowerbound =
              Number(eqInputs[i][j].expecting) -
              Number(eqInputs[i][j].tolerance);
            const upperbound =
              Number(eqInputs[i][j].expecting) +
              Number(eqInputs[i][j].tolerance);
            if (ans < lowerbound || ans > upperbound) {
              isCorrect = 0;
              eqInputs[i][j].correct = false;
            }
          }
        }
      }
      correctness += isCorrect;
    }
    correctness /= eqInputs.length;
    if (Number.isNaN(correctness)) correctness = 1;
    console.log(eqInputs);
    setCorrect(correctness);
    if (correctness < 1) {
      setSolution(true);
    }
    setChecked(true);
    const atts = getQuestionAttributes();
    postAnswer(
      atts.level,
      atts.major,
      atts.minor,
      "Exam",
      atts.number,
      question,
      eqInputs,
      correctness,
      repeat
    );
    return correctness;
  };

  const checkMCQAnswers = (right, answers) => {
    setNonExamAnswers(answers);
    setChecked(true);
    setCorrect(right);
    const atts = getQuestionAttributes();
    postAnswer(
      atts.level,
      atts.major,
      atts.minor,
      "MCQ",
      atts.number,
      question.content.question,
      answers,
      right,
      repeat
    );
  };

  const checkSkillsAnswers = (right, answers) => {
    setNonExamAnswers(answers);
    setChecked(true);
    setCorrect(right);
    if (!right) {
      setSolution(true);
    }
    const atts = getQuestionAttributes();
    postAnswer(
      atts.level,
      atts.major,
      atts.minor,
      "Skills",
      atts.number,
      question.content,
      answers,
      right,
      repeat
    );
  };

  const nextQ = () => {
    setChecked(false);
    setCorrect(0);
    setSolution(false);
    setAlert({});
    setSelf(true);
    setNonExamAnswers([]);
    const atts = getQuestionAttributes();
    let thisQuestion;
    if (question.type === "exam") {
      thisQuestion = {
        level: atts.level,
        major: atts.major,
        minor: atts.minor,
        type: "Exam",
        qNumber: atts.number,
        context: question,
        answers: eqInputs,
        correct,
        repeated: false,
      };
    } else {
      thisQuestion = {
        level: atts.level,
        major: atts.major,
        minor: atts.minor,
        type: question.type === "mcq" ? "MCQ" : "Skills",
        qNumber: atts.number,
        context: question.content.question,
        answers: nonExamAnswers,
        correct,
        repeated: false,
      };
    }
    next(thisQuestion);
  };

  const tryQAgain = () => {
    setChecked(false);
    setCorrect(0);
    setSolution(false);
    setAlert({});
    setSelf(true);
    setNonExamAnswers([]);
    const atts = getQuestionAttributes();
    let thisQuestion;
    if (question.type === "exam") {
      thisQuestion = {
        level: atts.level,
        major: atts.major,
        minor: atts.minor,
        type: "Exam",
        qNumber: atts.number,
        context: question,
        answers: eqInputs,
        correct,
        repeated: false,
      };
    } else {
      thisQuestion = {
        level: atts.level,
        major: atts.major,
        minor: atts.minor,
        type: question.type === "mcq" ? "MCQ" : "Skills",
        qNumber: atts.number,
        context: question.content.question,
        answers: nonExamAnswers,
        correct,
        repeated: false,
      };
    }
    tryAgain(thisQuestion);
  };

  const setSol = () => {
    setSolution(true);
  };

  const eventPressed = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) {
      checkExamAnswers();
    }
  };

  const selfAssesment = () => {
    if (!confirmedSelf) {
      setAlert({
        variant: "danger",
        msg: "Please complete all parts before checking.",
      });

      return false;
    }
    if (checkForEmptyInputs()) {
      setAlert({
        variant: "danger",
        msg: "Please complete all parts before checking.",
      });
      return false;
    }
    setSolution(true);
    return true;
  };

  const incorrectStyles = {
    background: `#f3d8da`,
    border: `1px solid #e3989d`,
  };

  const correctStyles = {
    background: `#d9ecdb`,
    border: `1px solid #9dd4a2`,
  };

  const applyInputStyling = (answer) => {
    if (
      !oldInputs ||
      typeof answer.correct === "undefined" ||
      oldCorrectness <= 0 ||
      oldCorrectness >= 1
    ) {
      return {};
    }
    if (answer.correct) {
      return correctStyles;
    }
    return incorrectStyles;
  };

  switch (question.type) {
    case "exam": {
      return (
        <>
          {alert.msg && (
            <div className="mt-4">
              <Alert variant={alert.variant}>{alert.msg}</Alert>
            </div>
          )}
          <div className={`${styles.padout}`} style={{ overflowX: "auto" }}>
            <ExamQuestion type="question" data={question.content.question} />
          </div>
          <>
            {eqInputs.length !== 0 && (
              <div>
                <hr />
                <div className={`${styles.padout}`}>
                  {oldInputs && <b>Answer given:</b>}
                </div>
              </div>
            )}

            <div
              className={`${eqInputs.length !== 0 && styles.padout} ${
                styles.inputArea
              } row`}
            >
              {eqInputs.map((arr, index) => {
                const colSize = 12 / arr.length;
                return arr.map((ans, i) => {
                  const ansId = `ans-${index + i}`;
                  const count = index * 100 + i;
                  return (
                    <div className={`col-sm-${colSize}`} key={index + i}>
                      <div className={`input-group ${styles.input}`}>
                        {ans.labelPos === "before" && (
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                              style={applyInputStyling(ans)}
                            >
                              <MathString str={ans.label} fontSize={0.9} />
                            </span>
                          </div>
                        )}
                        <input
                          autoComplete="off"
                          type="text"
                          onChange={handleChange}
                          onKeyPress={eventPressed}
                          className="form-control"
                          value={ans.answer}
                          name={ansId}
                          id={ansId}
                          data-id={count}
                          aria-label={ans.label}
                          aria-describedby="basic-addon1"
                          disabled={Boolean(oldInputs) || checked || solution}
                          style={applyInputStyling(ans)}
                        />
                        {ans.labelPos === "after" && (
                          <div className="input-group-append">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <MathString str={ans.label} fontSize={0.9} />
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                });
              })}
            </div>
            {question.self && !oldInputs && !solution && (
              <div className="text-center mb-3">
                <Checkbox handleToggle={() => setSelf((s) => !s)}>
                  I have answered all parts that require self-marking
                </Checkbox>
              </div>
            )}
            {!checked && !solution && !oldInputs && (
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={!question.self ? checkExamAnswers : selfAssesment}
                >
                  {!singleInput && "Check Answers"}
                  {singleInput && "Check Answer"}
                </button>
              </div>
            )}
          </>
          {checked && correct < 1 && correct >= 0.65 && !question.self && (
            <div className="alert alert-warning" role="alert">
              <b>Almost there! </b>
              Check out the solution below.
            </div>
          )}
          {checked && correct < 0.65 && correct > 0 && !question.self && (
            <div className="alert alert-warning" role="alert">
              <b>Partially correct! </b>
              Check out the solution below.
            </div>
          )}
          {checked && correct <= 0 && !question.self && (
            <div className="alert alert-danger" role="alert">
              <b>Incorrect! </b>
              Check out the solution below.
            </div>
          )}
          {checked && correct <= 0 && question.self && (
            <div className="alert alert-danger" role="alert">
              <b>Incorrect! </b>
            </div>
          )}
          {checked && correct >= 1 && question.self && (
            <div className="alert alert-success" role="alert">
              <b>Correct! </b>
            </div>
          )}
          {checked && correct >= 1 && !question.self && (
            <div className="alert alert-success" role="alert">
              <b>Correct! </b>
              <div style={{ display: "inline" }}>Click </div>
              <button
                type="button"
                className={`alert-link ${styles.alertBtn}`}
                onClick={() => setSolution(true)}
              >
                here
              </button>
              <div style={{ display: "inline" }}> to see our solution.</div>
            </div>
          )}
          {oldInputs && (
            <>
              <hr />
              <div className="px-2">
                <b>Worked solution:</b>
              </div>
            </>
          )}
          {solution && (
            <div className="px-2" style={{ overflowX: "auto" }}>
              <ExamQuestion
                type={`${oldInputs ? "question" : "solution"}`}
                data={question.content.solution}
              />
            </div>
          )}
          {!checked && !oldInputs && solution && question.self && (
            <div className={`${styles.padNoInp} my-4`}>
              <div className={`${styles.option} text-center`}>
                Did you answer the self-marking parts correctly?
                <div>
                  <button
                    type="button"
                    className={`alert-link ${styles.yesBtn} mx-4`}
                    onClick={checkExamAnswers}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className={`alert-link ${styles.noBtn} mx-4`}
                    onClick={() => {
                      setCorrect(0);
                      setChecked(true);
                      const atts = getQuestionAttributes();
                      postAnswer(
                        atts.level,
                        atts.major,
                        atts.minor,
                        "Exam",
                        atts.number,
                        question,
                        eqInputs,
                        false,
                        repeat
                      );
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}
          {checked && next && (
            <div className="text-center my-4">
              {correct < 1 ? (
                <div className="row">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className={`btn ${styles.next}`}
                      onClick={tryQAgain}
                      style={{
                        width: "10em",
                        float: width > 750 ? "right" : "",
                        marginBottom: "1em",
                      }}
                    >
                      Try Again
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      className={`btn ${styles.next}`}
                      onClick={nextQ}
                      style={{
                        width: "10em",
                        float: width > 750 ? "left" : "",
                      }}
                    >
                      {nextText}
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  type="button"
                  className={`btn ${styles.next}`}
                  onClick={nextQ}
                  style={{ width: "10em" }}
                >
                  {nextText}
                </button>
              )}
            </div>
          )}
        </>
      );
    }
    case "mcq": {
      return (
        <>
          <div className={`${styles.padout}`}>
            <MultipleChoiceQuestion data={question.content.question} />
            {Boolean(oldInputs) && (
              <div>
                <hr />
                <b>Solution:</b>
              </div>
            )}
            <MultipleChoiceAnswers
              data={question.content.answers}
              disabled={checked || Boolean(oldInputs)}
              checkFunction={checkMCQAnswers}
            />
          </div>
          {checked && correct > 0 && correct < 1 && (
            <div className="alert alert-danger my-4 mx-2" role="alert">
              <b>Partially correct! </b>
            </div>
          )}
          {checked && correct <= 0 && (
            <div className="alert alert-danger my-4 mx-2" role="alert">
              <b>Incorrect! </b>
            </div>
          )}
          {checked && correct >= 1 && (
            <div className="alert alert-success my-4 mx-2" role="alert">
              <b>Correct! </b>
            </div>
          )}
          {checked && next && (
            <div className="text-center my-4">
              {correct < 1 ? (
                <div className="row">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className={`btn ${styles.next}`}
                      onClick={tryQAgain}
                      style={{
                        width: "10em",
                        float: width > 750 ? "right" : "",
                        marginBottom: "1em",
                      }}
                    >
                      Try Again
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      className={`btn ${styles.next}`}
                      onClick={nextQ}
                      style={{
                        width: "10em",
                        float: width > 750 ? "left" : "",
                      }}
                    >
                      {nextText}
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  type="button"
                  className={`btn ${styles.next}`}
                  onClick={nextQ}
                  style={{ width: "10em" }}
                >
                  {nextText}
                </button>
              )}
            </div>
          )}
        </>
      );
    }
    case "skill": {
      return (
        <>
          <Skills
            question={question.content}
            check={checkSkillsAnswers}
            checked={checked}
            correct={correct}
            solution={solution}
            setSolution={setSol}
            disabled={Boolean(oldInputs)}
            oldInputs={oldInputs}
          />
          {checked && next && (
            <div className="text-center my-4">
              {correct < 1 ? (
                <div className="row">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className={`btn ${styles.next}`}
                      onClick={tryQAgain}
                      style={{
                        width: "10em",
                        float: width > 750 ? "right" : "",
                        marginBottom: "1em",
                      }}
                    >
                      Try Again
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button
                      type="button"
                      className={`btn ${styles.next}`}
                      onClick={nextQ}
                      style={{
                        width: "10em",
                        float: width > 750 ? "left" : "",
                      }}
                    >
                      {nextText}
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  type="button"
                  className={`btn ${styles.next}`}
                  onClick={nextQ}
                  style={{ width: "10em" }}
                >
                  {nextText}
                </button>
              )}
            </div>
          )}
        </>
      );
    }
    default:
      return <p>There was an error with this question.</p>;
  }
};

QuestionDisplay.propTypes = {
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  questionName: PropTypes.string,
  next: PropTypes.func,
  oldInputs: PropTypes.arrayOf(PropTypes.any),
  oldCorrectness: PropTypes.number,
  nextText: PropTypes.string,
  repeat: PropTypes.bool,
  tryAgain: PropTypes.func.isRequired,
};

QuestionDisplay.defaultProps = {
  next: undefined,
  oldInputs: undefined,
  oldCorrectness: undefined,
  questionName: undefined,
  nextText: "Next Question",
  repeat: false,
};

export default QuestionDisplay;
