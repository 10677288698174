import React from "react";
import PropTypes from "prop-types";
import styles from "./styles/ImageCanvas.module.css";
import MathString from "./MathString";

const ImageCanvas = ({ img, height, width, overlays }) => {
  return (
    <div className={styles.container}>
      <img
        src={require(`./../system/images/questions/${img}`)}
        style={{
          width: `${width}em`,
          height: `${height}em`,
        }}
        alt="Temp"
      />
      {overlays.length !== 0 &&
        overlays.map((obj, ind) => {
          if (
            "size" in obj.content &&
            typeof obj.content.size !== "undefined"
          ) {
            return (
              <div
                key={ind}
                className={styles.overlay}
                style={{
                  top: `${obj.content.y}%`,
                  left: `${obj.content.x}%`,
                  fontSize: `${obj.content.size}em`,
                }}
              >
                <MathString str={obj.content.item} />
              </div>
            );
          }
          return (
            <div
              key={ind}
              className={styles.overlay}
              style={{
                top: `${obj.content.y}%`,
                left: `${obj.content.x}%`,
              }}
            >
              <MathString str={obj.content.item} />
            </div>
          );
        })}
    </div>
  );
};

ImageCanvas.propTypes = {
  img: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  overlays: PropTypes.arrayOf(PropTypes.object),
};

ImageCanvas.defaultProps = {
  overlays: [],
};

export default ImageCanvas;
