import { Graph } from "../MCQuestion";
import { cleaner } from ".";
/**
 * Numerical Methods.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * For iterative methods, x=f(x)
 * For Newton Raphson, is roots of f(x)
 */

class NumericalMethods {
  /**
   * Creates a Recurrence in the form $U_{n+1}$ = func($U_{n}$)
   *
   * @param {object} func - the function do things with.
   * @param {number} start - the value to start at.
   * @returns {NumericalMethods}
   */
  constructor(func, start = 0) {
    this.func = func;
    this.start = start;
  }

  /**
   * Iterates x_n=f(x_n-1)
   *
   * @param {number} iterations How many times to itterate
   * @returns {number} The value of x_n after the itterations
   */
  iterate(iterations = 1) {
    let x = this.start;
    for (let i = 0; i < iterations; i += 1) {
      x = this.func.evaluate(x);
    }
    return x;
  }

  /**
   * Returns list of x_n
   *
   * @param {number} iterations How many times to itterate
   * @returns {string[]}
   */
  iterateWorking(iterations = 1) {
    const output = [`$x_1=${this.start}$`];
    for (let i = 1; i < iterations; i += 1) {
      output.push(`$x_{${i + 1}}=${this.iterate(i)}$`);
    }
    return output;
  }

  /**
   * Returns the graph for the iterations
   *
   * @param {number} iterations How many times to itterate
   * @param {number} range The range to plot
   * @param {bool} q1 Only plot the top right quadrant
   */
  iterateGraph(iterations = 1, range = 5, q1 = false) {
    const stepSize = Math.ceil(range / 10);
    let graph;
    if (q1 === true) {
      graph = new Graph(range, 0, range, 0, stepSize, stepSize);
    } else {
      graph = new Graph(range, -range, range, -range, stepSize, stepSize);
    }

    function f(x) {
      return x;
    }
    graph.plot(f, -range, range);

    const j = this.func;
    function g(x) {
      return j.evaluate(x);
    }
    graph.plot(g, -range, range);

    let x = this.start;
    let y = 0;
    let x3 = this.iterate(1);
    for (let i = 0; i < iterations; i += 1) {
      const x2 = x;
      const y2 = y;
      const x32 = x3;
      graph.addParametric(
        function l(t) {
          return x2 + 0 * t;
        },
        function k(t) {
          return y2 + t * (x32 - y2);
        },
        0,
        1
      );
      graph.addParametric(
        function l(t) {
          return x2 + (x32 - x2) * t;
        },
        function k(t) {
          return x32 + 0 * t;
        },
        0,
        1
      );
      // console.log('x='+x+', x3='+x3+', y='+y)
      y = this.func.evaluate(x);
      x = x3;
      x3 = this.iterate(i + 2);
    }

    return graph;
  }

  /**
   * Iterates x_n=x_{n-1}-f(x_{n-1})/f'(x_{n-1})
   *
   * @param {number} iterations How many times to itterate
   * @returns {number} The value of x_n after the itterations
   */
  newtonRaphson(iterations = 1) {
    if (iterations === 0) {
      return this.start;
    }
    let x = this.start;
    for (let i = 0; i < iterations; i += 1) {
      x -= this.func.evaluate(x) / this.func.derivative().evaluate(x);
    }
    return x;
  }

  /**
   * Returns list of x_n
   *
   * @param {number} iterations How many times to itterate
   * @returns {string[]}
   */
  newtonRaphsonWorking(iterations = 1) {
    const output = [`$x_0=${this.start}$`];
    for (let i = 1; i < iterations; i += 1) {
      const xm = this.newtonRaphson(i - 1);
      output.push(
        `$x_${i}=${xm}-\\frac{${this.func.evaluate(
          xm
        )}}{${this.func.derivative().evaluate(xm)}}$`
      );
      output.push(`$=${this.newtonRaphson(i)}$`);
    }
    return output;
  }

  /**
   * Returns the graph for the iterations
   *
   * @param {number} iterations How many times to itterate
   * @param {number} range The range to plot
   * @param {bool} q1 Only plot the top right quadrant
   */
  newtonRaphsonGraph(iterations = 1, range = 5, q1 = false) {
    const stepSize = Math.ceil(range / 10);
    let graph;
    if (q1 === true) {
      graph = new Graph(range, 0, range, 0, stepSize, stepSize);
    } else {
      graph = new Graph(range, -range, range, -range, stepSize, stepSize);
    }

    const j = this.func;
    function g(x) {
      return j.evaluate(x);
    }
    graph.plot(g, -range, range);

    let x = this.start;
    let y = this.func.evaluate(x);
    for (let i = 0; i < iterations; i += 1) {
      const x2 = x;
      const y2 = y;
      graph.addParametric(
        function l(t) {
          return x2 + 0 * t;
        },
        function k(t) {
          return t * y2;
        },
        0,
        1
      );
      graph.plot(
        function l(t) {
          return j.derivative().evaluate(x2) * (t - x2) + j.evaluate(x2);
        },
        -range,
        range
      );
      x = this.newtonRaphson(i + 1);
      y = this.func.evaluate(x);
    }

    return graph;
  }

  /**
   * Returns a string of the trapezium rule with values substituted.
   *
   * @param {number} a Lower bound of the integral
   * @param {number} b Higher bound of the integral
   * @param {number} n number of lines, or number of strips plus one.
   *
   * @returns {string}
   */
  trapeziumRuleString(a, b, n) {
    const h = (b - a) / (n - 1);
    let ys = `${Math.round(this.func.evaluate(a + h) * 1000) / 1000}`;
    for (let i = 2; i < n - 1; i += 1) {
      ys += `+${Math.round(this.func.evaluate(a + i * h) * 1000) / 1000}`;
    }
    return cleaner(
      `$\\displaystyle \\int_{${a}}^{${b}} ${this.func.toString()} dx \\approx ${
        Math.round((1000 * (b - a)) / (2 * (n - 1))) / 1000
      } \\left(${
        Math.round(this.func.evaluate(a) * 1000) / 1000
      } + 2\\left(${ys}\\right)+${
        Math.round(this.func.evaluate(b) * 1000) / 1000
      }\\right) $`,
      false,
      false
    );
  }

  /**
   * Returns the evaluated trapezium rule.
   *
   * @param {number} a Lower bound of the integral
   * @param {number} b Higher bound of the integral
   * @param {number} n number of lines, or number of strips plus one.
   * @param {bool} [approx = true] If true rounds the answer to 3dp.
   *
   * @returns {number}
   */
  trapeziumRuleEvaluate(a, b, n, approx = true) {
    const h = (b - a) / (n - 1);
    let ys = 0;
    for (let i = 1; i < n - 1; i += 1) {
      ys += this.func.evaluate(a + i * h);
    }
    if (approx === false) {
      return (
        ((b - a) / (2 * (n - 1))) *
        (this.func.evaluate(a) + this.func.evaluate(b) + 2 * ys)
      );
    }

    return (
      Math.round(
        ((b - a) / (2 * (n - 1))) *
          (this.func.evaluate(a) + this.func.evaluate(b) + 2 * ys) *
          1000
      ) / 1000
    );
  }
}

export { NumericalMethods };
