import { Fraction } from "./Fraction";
import { Polynomial } from "./Polynomial";
import { cleaner } from "./cleaner";
import { NumberSetError, ArgumentError } from "../MCError";

/**
 * Class Handling Binomial Expansion Questions.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Creates a new binomial expansion object for (1+x)^5
 * const b1 = new Binomial(1,'',1,'x',5)
 * // Creates a new binomial expansion object for (7x-\\sin(x))^-0.5
 * const b1 = new Binomial(7,'x',-1,'\\sin(x)',-0.5)
 */
class Binomial {
  /** Makes the binomial object of the form (ax+by)^n
   * a - coefficient1
   * x - variable1
   * b - coefficient2
   * y - variable2
   * n - power
   *
   * @param {number} [coefficient1=1] The coefficient of the first variable.
   * @param {string} [variable1='']   The first variable.
   * @param {number} [coefficient2=1] The coefficient of the second variable.
   * @param {string} [variable2='']   The second variable.
   * @param {number} [power=2]        The power of the expansion.
   *
   * @returns {Binomial} A new binomial object.
   *
   * @throws {ArgumentError}  Only Supports negative and fractional powers if the first term is a constant.
   * @throws {NumberSetError} Coefficients must be numbers.
   */
  constructor(
    coefficient1 = 1,
    variable1 = "",
    coefficient2 = 1,
    variable2 = "",
    power = 2
  ) {
    if (
      (variable1 !== "" && power < 1) ||
      (variable1 !== "" && Math.round(power) !== power)
    ) {
      throw new ArgumentError(
        `Expected the first variable to be a constant, got ${variable1} instead`
      );
    }
    if (typeof coefficient1 !== "number") {
      throw new NumberSetError(
        `Expected a number, got ${coefficient1} instead`
      );
    }
    if (typeof coefficient2 !== "number") {
      throw new NumberSetError(
        `Expected a number, got ${coefficient2} instead`
      );
    }
    this.coefficient1 = coefficient1;
    this.variable1 = variable1;
    this.coefficient2 = coefficient2;
    this.variable2 = variable2;
    this.power = power;
  }

  /**
   * Prints the binomial as a string, useful for writing questions.
   *
   * @returns {string} The binomial in a display format.
   */
  toString() {
    let a = new Fraction(this.coefficient1).toString();
    if (this.coefficient1 === 1 && this.variable1 !== "") {
      a = "";
    }
    if (this.coefficient1 === -1 && this.variable1 !== "") {
      a = "-";
    }
    let b = `+${new Fraction(this.coefficient2).toString()}`;
    if (this.coefficient2 < 0) {
      b = new Fraction(this.coefficient2).toString();
    }
    if (this.coefficient2 === 1 && this.variable2 !== "") {
      b = "+";
    }
    if (this.coefficient2 === -1 && this.variable2 !== "") {
      b = "-";
    }
    return `\\left(${a}${this.variable1}${b}${
      this.variable2
    }\\right)^{${new Fraction(this.power).toString()}}`;
  }

  /**
   * Returns the first coefficients of the expansion, up to a given point.
   * Used by other functions in this object, or to get specific coefficients of the expansion.
   *
   * @param {number} [range = 10] The amount of terms to calulate if using a negative or fractional power, for positive integers will return every coefficient.
   *
   * @returns {number[]} The coefficients of the expansion.
   *
   * @throws {NumberSetError} The range must be an integer.
   *
   * @example
   * //Returns every coefficient of the expansion of (1+x)^5
   * b1 = new Binomial(1,'',1,'x',5)
   * console.log(b1.expansionCoefficient())
   */
  expansionCoefficient(range = 10) {
    if (typeof range !== "number") {
      throw new NumberSetError(`Expected a number, got ${range} instead`);
    }
    if (range !== Math.round(range)) {
      throw new NumberSetError(`Expected an integer, got ${range} instead`);
    }
    // Uses polynomial powers for positive integer powers, neater than pascals triangle
    if (Math.round(this.power) === this.power && this.power > 1) {
      let p = new Polynomial([this.coefficient1, this.coefficient2]);
      p = p.pow(this.power);
      return p.coefficients;
    }
    // Non positve integer method.
    let c2 = this.coefficient2;
    let multi = 1;
    if (this.coefficient1 !== 1) {
      multi = this.coefficient1 ** this.power;
      c2 = this.coefficient2 / this.coefficient1;
    }
    const out = [1];
    for (let i = 1; i < range; i += 1) {
      out.push((out[i - 1] * (this.power - i + 1)) / i);
    }
    for (let i = 0; i < out.length; i += 1) {
      out[i] = out[i] * multi * c2 ** i;
    }
    return out;
  }

  /**
   * Returns an array of the terms of the expansion, both the coefficient and the variables raised to be correct powers.
   * Used by other functions in this object, or to get specific terms of the expansion.
   *
   * @param {string | number} [range='all'] The range of the terms to return, if the power is a positive integer this can be set to 'all' to return the full expansion.
   *
   * @returns {string[]} The full expansion as an array.
   *
   * @throws {ArgumentError} range='all' is only for postitive integer powers.
   * @example
   * //Returns every term of the expansion of (1+x)^5
   * b1 = new Binomial(1,'',1,'x',5)
   * console.log(b1.expansion())
   */
  expansion(range = "all") {
    let rnge;
    if (
      typeof range === "number" &&
      Math.round(this.power) === this.power &&
      range > this.power + 1 &&
      this.power > 0
    ) {
      rnge = this.power + 1;
    } else if (range === "all") {
      if (Math.round(this.power) === this.power && this.power > 1) {
        rnge = this.power + 1;
      } else {
        throw new ArgumentError(
          `Cannot use range='all' for a fraciton or negative power, the current power is ${this.power}`
        );
      }
    } else {
      rnge = range;
    }
    const coefficients = this.expansionCoefficient(rnge);
    const out = [];
    for (let i = 0; i < rnge; i += 1) {
      const v1 = Binomial.specialPower(this.variable1, this.power - i);
      const v2 = Binomial.specialPower(this.variable2, i);
      if (
        (coefficients[i] === 1 && v1 !== "") ||
        (coefficients[i] === 1 && v2 !== "")
      ) {
        out.push(`${v1}${v2}`);
      } else if (
        (coefficients[i] === -1 && v1 !== "") ||
        (coefficients[i] === -1 && v2 !== "")
      ) {
        out.push(`-${v1}${v2}`);
      } else {
        out.push(`${new Fraction(coefficients[i]).toString()}${v1}${v2}`);
      }
    }
    return out;
  }

  /**
   * Returns a string of the terms of the expansion concatenated together into a sum.
   *
   * @param {string | number} [range='all'] The range of the terms to return, if the power is a positive integer this can be set to 'all' to return the full expansion.
   *
   * @returns {string} The full expansion as string.
   *
   * @throws {ArgumentError} range='all' is only for postitive integer powers.
   * @example
   * //Returns the expanded sum of (1+x)^5
   * b1 = new Binomial(1,'',1,'x',5)
   * console.log(b1.expansionString())
   */
  expansionString(range = "all") {
    let rnge;
    if (
      typeof range === "number" &&
      Math.round(this.power) === this.power &&
      range > this.power + 1 &&
      this.power > 0
    ) {
      rnge = this.power + 1;
    } else if (range === "all") {
      if (Math.round(this.power) === this.power && this.power > 1) {
        rnge = this.power + 1;
      } else {
        throw new ArgumentError(
          `Cannot use range='all' for a fraciton or negative power, the current power is ${this.power}`
        );
      }
    } else {
      rnge = range;
    }
    const expansion = this.expansion(rnge);
    let out = "";
    for (let i = 0; i < expansion.length; i += 1) {
      if (expansion[i].charAt(0) !== "-" && i !== 0) {
        out += "+";
      }
      out += expansion[i];
    }
    if (
      (rnge !== "all" && typeof rnge !== "number") ||
      (typeof rnge === "number" && rnge < this.power + 1) ||
      this.power < 1 ||
      (typeof rnge === "number" && Math.round(this.power) !== this.power)
    ) {
      out += "+\\cdots";
    }
    return out;
  }

  /**
   * Shows working for the expansion. Picks the expansion formula to use based on if the power is a positive integer (or not).
   *
   * @param {string | number} [range='all'] The range of the terms to return, if the power is a positive integer this can be set to 'all' to return the full expansion.
   *
   * @returns {string[]} The full working as a string array.
   *
   * @throws {ArgumentError} range='all' is only for postitive integer powers.
   *
   * @example
   * //Returns the working of expansion of (1+x)^5
   * b1 = new Binomial(1,'',1,'x',5)
   * console.log(b1.expansionWorking())
   */
  expansionWorking(range = "all") {
    let rnge;
    if (
      typeof range === "number" &&
      Math.round(this.power) === this.power &&
      range > this.power + 1 &&
      this.power > 0
    ) {
      rnge = this.power + 1;
    } else if (range === "all") {
      if (Math.round(this.power) === this.power && this.power > 1) {
        rnge = this.power + 1;
      } else {
        throw new ArgumentError(
          `Cannot use range='all' for a fraciton or negative power, the current power is ${this.power}`
        );
      }
    } else {
      rnge = range;
    }
    // terms was not being used anywhere, commented out rather than removed for anyone that needs to fix errors
    // const terms = this.expansion(rnge);
    const out = [];
    // FIRST FORUMLA ---------------------------------------------------------
    if (Math.round(this.power) === this.power && this.power > 1) {
      out.push("HEADING Using the binomial expansion formula:");
      out.push(
        "$\\displaystyle (a+b)^n= a^n + {n \\choose 1}a^{n-1}b + {n \\choose 2}a^{n-2}b^2 + \\cdots + {n \\choose r}a^{n-r}b^r + \\cdots + b^n$."
      );
      const a = Binomial.specialPower(this.variable1, 1, this.coefficient1);
      const b = Binomial.specialPower(this.variable2, 1, this.coefficient2);
      const n = this.power;
      out.push(`Let $a=${a}$, $ b=${b},$ and $n=${this.power}$.`);
      let bigLine = `$${this.toString()}=$ `;
      for (let i = 0; i < rnge; i += 1) {
        if (i === 0) {
          bigLine += `$\\displaystyle \\left(${a}\\right)^${n}$`;
        } else if (i === this.power) {
          bigLine += `$\\displaystyle +\\left(${b}\\right)^${n}$`;
        } else {
          bigLine += `$\\displaystyle +{${n} \\choose ${i}}\\left(${a}\\right)^{${n}-${i}}\\left(${b}\\right)^{${i}}$`;
        }
      }
      if (rnge < this.power) {
        bigLine += "$+\\cdots$";
      }
      out.push(bigLine);
      out.push(`$\\therefore${this.toString()}=${this.expansionString(rnge)}$`);
      return out;
    } // SECOND FORMULA -------------------------------------------------------------------------------------------------------------------------

    out.push("HEADING Using the binomial expansion formula:");
    out.push(
      "$\\displaystyle (1+x)^n= 1 + nx + \\frac{n(n-1)}{1\\cdot 2}x^2 + \\cdots + \\frac{n(n-1)\\cdots(n-r+1)}{1\\cdot 2 \\cdots r}x^r + \\cdots$"
    );
    const n = this.power;
    const multi = this.coefficient1 ** n;
    const c2 = this.coefficient2 / this.coefficient1;
    const workingBinomial = new Binomial(1, "", c2, this.variable2, n);
    if (this.coefficient1 !== 1) {
      out.push(
        `$${this.toString()}=${new Fraction(
          multi
        ).toString()}${workingBinomial.toString()}$`
      );
    }
    let workingLine = `$${this.toString()}=`;
    if (multi !== 0) {
      workingLine += multi;
    }
    workingLine += `\\left[1+\\left(${n}\\right)\\left(${c2}${Binomial.specialPower(
      this.variable2,
      1
    )}\\right)`;
    for (let i = 2; i < rnge; i += 1) {
      workingLine += `+\\frac{\\left(${n}\\right)`;
      for (let j = 1; j < i; j += 1) {
        workingLine += `\\left(${n}-${j}\\right)`;
      }
      workingLine += "}{";
      for (let j = 1; j < i + 1; j += 1) {
        workingLine += j;
        if (j !== i) {
          workingLine += "\\cdot";
        }
      }
      workingLine += `}\\left(${c2}${this.variable2}\\right)^${i}`;
    }
    workingLine += "+\\cdots";
    if (multi !== 0) {
      workingLine += "\\right]";
    }
    workingLine += "$";
    workingLine = cleaner(workingLine);
    out.push(workingLine);
    out.push(`$\\therefore${this.toString()}=${this.expansionString(rnge)}$`);
    return out;
  }

  /**
   * Shows working for calculating individual term, only works for natural powers so far.
   *
   * @param {number} i The term to return working for.
   *
   * @returns {string[]} The working of the term.
   *
   * @throws {NumberSetError} i must be a number
   * @throws {ArgumentError} This only works for natural powers.
   *
   * @example
   * //Returns the working of the third term of (1+x)^5
   * b1 = new Binomial(1,'',1,'x',5)
   * console.log(b1.termWorking(3))
   */
  termWorking(i) {
    if (typeof i !== "number") {
      throw new NumberSetError(`Expected a number, got ${i} instead`);
    }
    if (!(Math.round(this.power) === this.power) || !(this.power > 1)) {
      throw new ArgumentError(
        `Cannot use termWorking for a fraciton or negative power, the current power is ${this.power}`
      );
    }
    const a = Binomial.specialPower(this.variable1, 1, this.coefficient1);
    const b = Binomial.specialPower(this.variable2, 1, this.coefficient2);
    const terms = this.expansion(i + 1);
    const n = this.power;
    const out = [];
    out.push(`HEADING Term ${i + 1}`);
    if (i === 0) {
      out.push(`$a^n=\\left(${a}\\right)^${n}=${terms[i]}$`);
    } else if (i === this.power) {
      out.push(`$b^n=\\left(${b}\\right)^${n}=${terms[i]}$`);
    } else {
      out.push(
        `$r=${i}$, so $\\displaystyle {n \\choose r}a^{n-r}b^r =  {${n} \\choose ${i}}\\left(${a}\\right)^{${n}-${i}}\\left(${b}\\right)^{${i}}$.`
      );
      out.push(`$\\displaystyle = ${terms[i]}$`);
    }
    return out;
  }

  /**
   * Used for binomial specific cleanning, mostly replacing \\sin(x)^2 with \\sin^2(x) etc. DO NOT USE OUTSIDE OF THIS!
   *
   * @param {string} variable     The variable to be raised to a power
   * @param {number} power        The power to raise the variable to.
   * @param {number} coefficient  Used for some one clenaing (Depriciated, use cleaner instead.)
   * @returns {string}            Cleaned String.
   */
  static specialPower(variable, power, coefficient = "") {
    if (power === 1 && coefficient !== "") {
      if (variable === "") {
        return coefficient;
      }
      if (coefficient === 1) {
        return variable;
      }
      if (coefficient === -1) {
        return `-${variable}`;
      }
      return new Fraction(coefficient).toString() + variable;
    }

    if (power === 0 || variable === "") {
      return "";
    }
    if (power === 1) {
      return variable;
    }
    if (variable.includes("sin(")) {
      return variable.replace("sin(", `sin^{${power}}(`);
    }
    if (variable.includes("cos(")) {
      return variable.replace("cos(", `cos^{${power}}(`);
    }
    if (variable.includes("tan(")) {
      return variable.replace("tan(", `tan^{${power}}(`);
    }
    if (variable.includes("e^{")) {
      return variable.replace("e^{", `e^{${power}`);
    }
    if (variable.includes("x^2")) {
      return variable.replace("x^2", `x^{${power * 2}}`);
    }
    if (variable.includes("x^{2}")) {
      return variable.replace("x^{2}", `x^{${power * 2}}`);
    }
    return `${variable}^{${power}}`;
  }
}

export { Binomial };
