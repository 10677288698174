import { ArgumentError } from "../MCError";

/**
 * A class that allows developers to easily add overlays to an image.
 * @memberof module:MCQuestion
 * @author Declan Clark <dec@dclark.dev>
 * @since 0.1.0
 * @example
 * const myImage = new MCQuestion.Image('Dec/example_image.svg');
 */
class Image {
  /**
   * Creates a new image, given an image path.
   *
   * @param   {string}                imagePath    Path to the image relative to 'src/images/questions'.
   * @param   {('height' | 'width')}  measure      Whether to scale image by height or width.
   * @param   {number}                size         Size of image (in em).
   * @throws  {ArgumentError}                      All arguments are required.
   * @throws  {TypeError}                          Arguments are of the incorrect type.
   * @throws  {RangeError}                         Measure should be one of the accepted options.
   */
  constructor(imagePath, measure, size) {
    if (typeof imagePath === "undefined") {
      throw new ArgumentError(`image path is required`);
    }
    if (typeof imagePath !== "string") {
      throw new TypeError(
        `expected a string but got ${typeof imagePath} instead`
      );
    }
    if (typeof measure === "undefined") {
      throw new ArgumentError(`measure is required`);
    }
    if (typeof measure !== "string") {
      throw new TypeError(
        `expected a string but got ${typeof measure} instead`
      );
    }
    if (measure !== "height" && measure !== "width") {
      throw new RangeError(
        `expected 'height' or 'width' but got ${measure} instead`
      );
    }
    if (typeof size === "undefined") {
      throw new ArgumentError(`image size is required`);
    }
    if (typeof size !== "number") {
      throw new TypeError(`expected number but got ${typeof size} instead`);
    }
    this.src = imagePath;
    this.measure = measure;
    this.size = size;
    this.overlays = [];
  }

  /**
   * @summary Returns the path of the image.
   *
   * @since 0.1.0
   *
   * @returns {string}    The image path.
   */
  getSrc() {
    return this.src;
  }

  /**
   * @summary Returns the array of overlays for the image.
   *
   * @since 0.1.0
   *
   * @returns {Object[]}   Array of overlay objects.
   */
  getOverlays() {
    return this.overlays;
  }

  /**
   * @summary Returns the size of the image.
   *
   * @since 0.1.0
   *
   * @returns {number}   Size of the image (em).
   */
  getSize() {
    return this.size;
  }

  /**
   * @summary Returns the measure that the image is scaled by.
   *
   * @since 0.1.0
   *
   * @returns {('height' | 'width')}   Measure that the image is scaled by.
   */
  getMeasure() {
    return this.measure;
  }

  /**
   * @summary Adds an overlay to the list of image overlays.
   *
   * @description Adds an overlay to the image which will display the given string
   *              on top of the image. Will display the string at x percent from the left of the
   *              image and y percent from the top of the image.
   *
   * @since 0.1.0
   *
   * @param   {string}        overlayString  The string to appear on the image.
   * @param   {number}        xPercent       X location of the image (%).
   * @param   {number}        yPercent       Y location of the image (%).
   * @param   {number}        [fontSize]     Font size of the overlay.
   * @throws  {ArgumentError}                Required arguments were not supplied.
   * @throws  {TypeError}                    Arguments were not of the correct type.
   * @example
   * // add an overlay in the middle of the image
   * myImage.addOverlay('$x$', 50, 50);
   */
  addOverlay(overlayString, xPercent, yPercent, fontSize) {
    if (
      typeof overlayString === "undefined" ||
      typeof xPercent === "undefined" ||
      typeof yPercent === "undefined"
    ) {
      throw new ArgumentError(`failed to supply required arguments`);
    }
    if (typeof overlayString !== "string") {
      throw new TypeError(
        `expected a string but got ${typeof overlayString} instead`
      );
    }
    if (typeof xPercent !== "number") {
      throw new TypeError(
        `expected a number but got ${typeof xPercent} instead`
      );
    }
    if (typeof yPercent !== "number") {
      throw new TypeError(
        `expected a number but got ${typeof yPercent} instead`
      );
    }
    if (typeof fontSize !== "undefined") {
      if (typeof fontSize !== "number") {
        throw new TypeError(
          `expected a number but got ${typeof fontSize} instead`
        );
      }
      this.overlays.push({
        type: "overlay",
        content: {
          item: overlayString,
          x: xPercent,
          y: yPercent,
          size: fontSize,
        },
      });
    } else {
      this.overlays.push({
        type: "overlay",
        content: {
          item: overlayString,
          x: xPercent,
          y: yPercent,
        },
      });
    }
  }
}

export { Image };
