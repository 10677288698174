import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import * as jwt from "jsonwebtoken";
import RoundedBtn from "../RoundedBtn";
import Alert from "../Alert";

const Account = ({ handleAuthentication, shareRequests, role }) => {
  const userName = localStorage.getItem("mcmToken")
    ? jwt.decode(localStorage.getItem("mcmToken")).userName
    : "";
  const history = useHistory();
  const removeToken = () => {
    localStorage.removeItem("mcmToken");
    handleAuthentication();
    return true;
  };

  return (
    <div className="container">
      <div className="my-4 text-center">
        <div className="my-3">
          {alert.msg && <Alert variant={alert.variant}>{alert.msg}</Alert>}
        </div>
        <h4>{userName}</h4>
        <div>
          {role === "PUPIL" && (
            <div className="mt-5 mb-3">
              <RoundedBtn
                text="Your Teachers"
                width={20}
                onClick={() => {
                  history.push("/teachers");
                }}
                badge={
                  shareRequests !== 0
                    ? { text: shareRequests, bg: "#74c5ed", color: "#fff" }
                    : undefined
                }
              />
            </div>
          )}
          <div className="my-3">
            <RoundedBtn
              text="Change Password"
              width={20}
              onClick={() => {
                history.push("/change-password");
              }}
            />
          </div>
          <div className="my-3">
            <RoundedBtn
              text="Sign Out"
              width={20}
              onClick={() => {
                removeToken();
                history.push("/");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Account.propTypes = {
  handleAuthentication: PropTypes.func.isRequired,
  shareRequests: PropTypes.number,
  role: PropTypes.string.isRequired,
};

Account.defaultProps = {
  shareRequests: 0,
};

export default Account;
