import React from "react";
import PropTypes from "prop-types";
import ImageCanvas from "../ImageCanvas";
import MathString from "../MathString";
import styles from "../styles/MultipleChoiceQuestion.module.css";
import Graph from "../Graph";

const MultipleChoiceQuestion = ({ data }) => {
  return (
    <>
      {data.map((elem, index) => {
        if (elem.type === "paragraph") {
          return (
            <div
              key={index}
              className={`${styles.text} ${
                typeof elem.content.heading !== "undefined" &&
                elem.content.heading &&
                styles.heading
              }`}
            >
              <MathString str={elem.content.p} fontSize={elem.content.size} />
            </div>
          );
        }
        if (elem.type === "image") {
          if (elem.content.measure === "height") {
            return (
              <div className="text-center" key={index}>
                <ImageCanvas
                  img={elem.content.src}
                  height={elem.content.size}
                  overlays={elem.content.overlays}
                />
              </div>
            );
          }
          return (
            <div className="text-center" key={index}>
              <ImageCanvas
                img={elem.content.src}
                width={elem.content.size}
                overlays={elem.content.overlays}
              />
            </div>
          );
        }
        if (elem.type === "graph") {
          return (
            <div className="text-center" key={index}>
              <Graph graphObjContent={elem.content} />
            </div>
          );
        }
        return <div />;
      })}
    </>
  );
};

MultipleChoiceQuestion.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default MultipleChoiceQuestion;
