import React from "react";
import PropTypes from "prop-types";
import ProgressBar from "./ProgressBar";
import styles from "./styles/TopicProgress.module.css";

const TopicProgress = ({ topic, score }) => {
  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.text}`}>{topic}</div>
      <div className={`${styles.progress}`}>
        <ProgressBar now={score} />
      </div>
    </div>
  );
};

TopicProgress.propTypes = {
  topic: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
};

export default TopicProgress;
