import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import PropTypes from "prop-types";
import styles from "./styles/Checkbox.module.css";

const Checkbox = ({ children, handleToggle }) => {
  const [checked, setChecked] = useState(false);
  const [tabbed, setTabbed] = useState(false);

  const keyPressHandler = (e) => {
    if (e.key === "Tab") {
      setTabbed(true);
    }
    if (e.key === "Enter" || e.key === " ") {
      setChecked(!checked);
    }
  };

  return (
    <span
      role="button"
      tabIndex={0}
      onClick={() => {
        handleToggle(!checked);
        setChecked(!checked);
      }}
      className={`${styles.checkableItem} ${tabbed ? "" : styles.noTab}`}
      onKeyPress={keyPressHandler}
    >
      <FontAwesomeIcon
        className={`fa-fw ${styles.checkbox}`}
        // tick the box if item in object has true value
        icon={checked ? faCheckSquare : faSquare}
      />
      {children}
    </span>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node,
  handleToggle: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  children: undefined,
};

export default Checkbox;
