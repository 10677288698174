import React, { useState } from "react";
import axios from "axios";
import { useHistory, Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import ClipLoader from "react-spinners/ClipLoader";
import * as jwt from "jsonwebtoken";
import PlainInput from "../PlainInput";
import SelectionBox from "../SelectionBox";
import Checkbox from "../Checkbox";
import RoundedBtn from "../RoundedBtn";
import Alert from "../Alert";
import { getSiteID } from "../../system/utils/getSite";

const Register = ({ handleAuthentication, isAuthenticated, adminFunction }) => {
  const site = getSiteID();
  const history = useHistory();
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
    tsAndCs: false,
    optedIn: false,
  });
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    const errs = [];
    const lower = /[a-z]/;
    const upper = /[A-Z]/;
    const number = /[0-9]/;

    if (formValues.firstName === "") {
      errs.push({ field: "firstName", message: "Please enter a valid name" });
    }
    if (formValues.lastName === "") {
      errs.push({ field: "lastName", message: "Please enter a valid name" });
    }
    if (!validateEmail(formValues.email)) {
      errs.push({
        field: "email",
        message: "Please enter a valid email address",
      });
    }
    if (!lower.test(formValues.password)) {
      errs.push({
        field: "password",
        message: "Password should contain at least one lowercase letter",
      });
    }
    if (!upper.test(formValues.password)) {
      errs.push({
        field: "password",
        message: "Password should contain at least one uppercase letter",
      });
    }
    if (!number.test(formValues.password)) {
      errs.push({
        field: "password",
        message: "Password should contain at least one number",
      });
    }
    if (formValues.password.length < 8) {
      errs.push({
        field: "password",
        message: "Password should have 8 or more characters",
      });
    }
    if (formValues.password !== formValues.confirmPassword) {
      errs.push({
        field: "confirmation",
        message: "Password does not match",
      });
    }
    if (
      formValues.role === "" ||
      formValues.role === "UNKNOWN_PUPIL" ||
      formValues.role === "GCSE_PUPIL"
    ) {
      errs.push({
        field: "role",
        message: "Please choose an option",
      });
    }
    if (!formValues.tsAndCs) {
      errs.push({
        field: "tsAndCs",
        message: "Must accept terms and conditions to register for an account",
      });
    }
    setErrors(errs);
    if (errs.length === 0) {
      return true;
    }
    return false;
  };

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const startLoad = () => {
    return setTimeout(() => {
      setLoading(true);
    }, 500);
  };

  const stopLoad = (timer) => {
    clearTimeout(timer);
    setLoading(false);
  };

  const registerUser = async () => {
    const start = startLoad();
    if (!validateForm()) {
      stopLoad(start);
      return false;
    }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/auth/register`,
        {
          firstName: toTitleCase(formValues.firstName),
          lastName: toTitleCase(formValues.lastName),
          email: formValues.email,
          password: formValues.password,
          role: formValues.role,
          optedIn: formValues.optedIn,
        }
      );
      if (handleAuthentication === null) {
        stopLoad();
        const { userId } = jwt.decode(res.data.token);
        const user = { userId };
        user.fname = formValues.firstName;
        user.lname = formValues.lastName;
        user.roleName = formValues.role;
        user.email = formValues.email;
        user.createdAt = new Date()
          .toISOString()
          .slice(0, 19)
          .replace("T", " ");
        adminFunction(user);
      } else {
        localStorage.setItem("mcmToken", res.data.token);
        stopLoad(start);
        handleAuthentication();
        history.push("/");
      }
    } catch (e) {
      if (e.response) {
        if (e.response.status === 400) {
          setErrors([{ field: "form", message: e.response.data.error }]);
        }
      } else {
        setErrors([
          { field: "form", message: "A problem occurred. Please try again." },
        ]);
      }
      stopLoad(start);
      return false;
    }
    return true;
  };

  const displayFieldErrors = (field) => {
    return (
      <>
        {errors.map((error) => {
          if (error.field === field) {
            return (
              <div key={`${error.field}:${error.message}`}>
                <span style={{ color: "red" }}>{error.message}</span>
              </div>
            );
          }
          return (
            <React.Fragment
              key={`noMatch:${error.field}-${field}:${error.message}`}
            />
          );
        })}
      </>
    );
  };

  return (
    <div className="container">
      <div className="my-5 text-center">
        {isAuthenticated ? (
          <Redirect to="/menu" />
        ) : (
          <div>
            <Alert variant="primary">
              To sign up multiple users from one school or college, please email
              Contact@MrCarterMaths.com
            </Alert>
            <h4>Register</h4>
            {loading ? (
              <div className="my-5">
                <ClipLoader loading size="3em" color="#74c5ed" />
              </div>
            ) : (
              <div>
                <div style={{ display: "inline-block", width: "20em" }}>
                  {displayFieldErrors("form")}
                  <div className="my-3">
                    <PlainInput
                      value={formValues.firstName}
                      autoFocus
                      label="First Name"
                      width={20}
                      handleChange={(inp) => {
                        setFormValues({ ...formValues, firstName: inp });
                      }}
                      onEnterPressed={() => registerUser()}
                    />
                    {displayFieldErrors("firstName")}
                  </div>
                  <div className="my-3">
                    <PlainInput
                      value={formValues.lastName}
                      label="Surname"
                      width={20}
                      handleChange={(inp) => {
                        setFormValues({ ...formValues, lastName: inp });
                      }}
                      onEnterPressed={() => registerUser()}
                    />
                    {displayFieldErrors("lastName")}
                  </div>
                  <div className="my-3">
                    <PlainInput
                      value={formValues.email}
                      label="Email"
                      width={20}
                      handleChange={(inp) => {
                        setFormValues({ ...formValues, email: inp });
                      }}
                      onEnterPressed={() => registerUser()}
                    />
                    {displayFieldErrors("email")}
                  </div>
                  <div className="my-3">
                    <PlainInput
                      value={formValues.password}
                      label="Password"
                      width={20}
                      obscure
                      handleChange={(inp) => {
                        setFormValues({ ...formValues, password: inp });
                      }}
                      onEnterPressed={() => registerUser()}
                    />
                    {displayFieldErrors("password")}
                  </div>
                  <div className="my-3">
                    <PlainInput
                      value={formValues.confirmPassword}
                      label="Confirm Password"
                      width={20}
                      obscure
                      handleChange={(inp) => {
                        setFormValues({ ...formValues, confirmPassword: inp });
                      }}
                      onEnterPressed={() => registerUser()}
                    />
                    {displayFieldErrors("confirmation")}
                  </div>
                  <div style={{ textAlign: "left" }}>I am a:</div>
                  <div className="row">
                    <div className="col-md-6">
                      <SelectionBox
                        title="Student"
                        selected={
                          formValues.role !== "STAFF" && formValues.role !== ""
                        }
                        handleSelection={() => {
                          setFormValues({
                            ...formValues,
                            role: site === 0 ? "GCSE_PUPIL" : "PUPIL",
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-6">
                      <SelectionBox
                        title="Teacher"
                        selected={formValues.role === "STAFF"}
                        handleSelection={() => {
                          setFormValues({ ...formValues, role: "STAFF" });
                        }}
                      />
                    </div>
                  </div>
                  {(formValues.role === "GCSE_PUPIL" ||
                    formValues.role === "FOUND_PUPIL" ||
                    formValues.role === "HIGH_PUPIL") && (
                    <>
                      <div className="mt-3" style={{ textAlign: "left" }}>
                        Which level are you working at?
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <SelectionBox
                            title="Foundation"
                            selected={formValues.role === "FOUND_PUPIL"}
                            handleSelection={() => {
                              setFormValues({
                                ...formValues,
                                role: "FOUND_PUPIL",
                              });
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <SelectionBox
                            title="Higher"
                            selected={formValues.role === "HIGH_PUPIL"}
                            handleSelection={() => {
                              setFormValues({
                                ...formValues,
                                role: "HIGH_PUPIL",
                              });
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {displayFieldErrors("role")}
                </div>

                <div className="mt-4">
                  <div>
                    <Checkbox
                      handleToggle={(bool) => {
                        setFormValues({ ...formValues, tsAndCs: bool });
                      }}
                    >
                      <span>
                        I have read and agree to the
                        <span> </span>
                      </span>
                    </Checkbox>
                    <Link target="_blank" to="/terms">
                      Terms and Conditions
                    </Link>
                  </div>
                  <div>{displayFieldErrors("tsAndCs")}</div>
                </div>
                <div className="my-2">
                  <Checkbox
                    handleToggle={(bool) => {
                      setFormValues({ ...formValues, optedIn: bool });
                    }}
                  >
                    <span>I would like to receive marketing emails</span>
                  </Checkbox>
                </div>
                <div className="my-4">
                  <RoundedBtn
                    text="Submit"
                    variant="dark"
                    width={10}
                    onClick={() => {
                      registerUser();
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Register.propTypes = {
  isAuthenticated: PropTypes.bool,
  handleAuthentication: PropTypes.func.isRequired,
  adminFunction: PropTypes.func,
};

Register.defaultProps = {
  isAuthenticated: false,
  adminFunction: undefined,
};

export default Register;
