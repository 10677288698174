import React, { useState, useEffect } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import PropTypes from "prop-types";
import * as jwt from "jsonwebtoken";
import { useHistory } from "react-router-dom";
import TeacherList from "../TeacherList";
import RoundedBtn from "../RoundedBtn";
import AddRelationModal from "../AddRelationModal";
import Alert from "../Alert";
import checkToken from "../../system/auth/checkToken";

const Teachers = ({ removeNotifs }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({});
  const [email, setEmail] = useState("");
  const [teachers, setTeachers] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const getTeachers = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/relations`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
            },
            cancelToken: source.token,
          }
        );
        setTeachers(res.data);
        setLoading(false);
      } catch (e) {
        if (!axios.isCancel(e)) {
          if (e.response && e.response.status === 400) {
            setAlert({ variant: "danger", msg: e.response.data.error });
          } else {
            setAlert({
              variant: "danger",
              msg: "A problem occurred. Please try again.",
            });
          }
        }
        setLoading(false);
      }
    };
    if (checkToken()) {
      getTeachers();
    } else {
      localStorage.removeItem("mcmToken");
      history.push("/login");
    }
    return () => {
      source.cancel("Navigated away.");
    };
  }, []);

  const validateEmail = (e) => {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(String(e).toLowerCase());
  };

  const startLoad = () => {
    return setTimeout(() => {
      setLoading(true);
    }, 500);
  };

  const stopLoad = (timer) => {
    clearTimeout(timer);
    setLoading(false);
  };

  const addTeacher = async () => {
    setAlert({});
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    if (!validateEmail(email)) {
      setAlert({
        variant: "danger",
        modal: true,
        msg: "Please enter a valid email address.",
      });
      return false;
    }
    setShowModal(false);
    const start = startLoad();
    try {
      await axios.post(
        `${process.env.REACT_APP_DOMAIN}/relations`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
        }
      );
      setTeachers([{ confirmed: false, email }, ...teachers]);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setAlert({ variant: "danger", msg: e.response.data.error });
      } else {
        setAlert({
          variant: "danger",
          msg: "A problem occurred. Please try again.",
        });
      }
      stopLoad(start);
      return false;
    }
    setAlert({ variant: "success", msg: "Invitation sent!" });
    setEmail("");
    stopLoad(start);
    return true;
  };

  const respond = async (response, staffId) => {
    const start = startLoad();
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    try {
      await axios.patch(
        `${process.env.REACT_APP_DOMAIN}/relations`,
        { accept: response, otherId: staffId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
        }
      );
      const data = [...teachers];
      let waitingForResponse = 0;
      for (let i = 0; i < data.length; i += 1) {
        const relation = data[i];
        if (relation.staffId === staffId) {
          const msg = response
            ? `You have added ${relation.fname} ${relation.lname} to your list of teachers.`
            : `You have rejected ${relation.fname} ${relation.lname} from viewing your data.`;
          if (response) {
            relation.confirmed = true;
          } else {
            data.splice(i, 1);
          }
          setAlert({
            variant: "success",
            msg,
          });
        }
      }
      for (let i = 0; i < data.length; i += 1) {
        const relation = data[i];
        if (
          !relation.confirmed &&
          relation.createdBy !==
            jwt.decode(localStorage.getItem("mcmToken")).userId
        ) {
          waitingForResponse += 1;
        }
      }
      removeNotifs(waitingForResponse);
      setTeachers(data);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setAlert({ variant: "danger", msg: e.response.data.error });
      } else {
        setAlert({
          variant: "danger",
          msg: "A problem occurred. Please try again.",
        });
      }
    }
    stopLoad(start);
    return true;
  };

  const onDelete = async (staff) => {
    const start = startLoad();
    if (!staff.createdBy) {
      setAlert({
        variant: "danger",
        msg: "Cannot delete a teacher who is linked via the school.",
      });
      stopLoad(start);
      return false;
    }
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    try {
      await axios.delete(`${process.env.REACT_APP_DOMAIN}/relations`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
        data: {
          otherId: staff.staffId,
        },
      });
      const data = [...teachers];
      let ind = -1;
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].staffId === staff.staffId) {
          ind = i;
        }
      }
      data.splice(ind, 1);
      setAlert({
        variant: "success",
        msg: `Successfully removed ${staff.fname} ${staff.lname}.`,
      });
      setTeachers(data);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setAlert({
          variant: "danger",
          msg: JSON.stringify(e.response.data.error),
        });
      } else {
        setAlert({
          variant: "danger",
          msg: "A problem occurred. Please try again.",
        });
      }
    }
    stopLoad(start);
    return true;
  };

  return (
    <div className="container">
      <AddRelationModal
        danger={alert.modal ? alert.msg : ""}
        show={showModal}
        onHide={() => setShowModal(false)}
        onAction={() => addTeacher()}
        email={email}
        emailChanged={setEmail}
        entity="teacher"
      />
      <div className="my-4">
        <div>
          <div className="text-center">
            <h4>Your Teachers</h4>
            {loading ? (
              <div className="my-5">
                <ClipLoader loading color="#74c5ed" size="3em" />
              </div>
            ) : (
              <div>
                <div className="mt-3">
                  <RoundedBtn
                    text="Add Teacher"
                    width={10}
                    icon={{ icon: faPlus, position: "left" }}
                    onClick={() => setShowModal(true)}
                  />
                  {(typeof alert.modal === "undefined" || !alert.modal) &&
                    typeof alert.msg !== "undefined" && (
                      <div className="mt-3">
                        <Alert variant={alert.variant}>{alert.msg}</Alert>
                      </div>
                    )}
                </div>
                <div className="text-left mt-4">
                  <TeacherList
                    data={teachers}
                    onRespondToInvite={respond}
                    onDelete={onDelete}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Teachers.propTypes = {
  removeNotifs: PropTypes.func.isRequired,
};

export default Teachers;
