import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NumberStat = ({ icon, number, label, percentage }) => {
  return (
    <>
      {icon ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              alignContent: "center",
            }}
          >
            <h1>
              <FontAwesomeIcon
                icon={icon}
                style={{
                  marginRight: "0.3em",
                  marginBottom: "3px",
                  color: "#65ad0c",
                  fontSize: "0.75em",
                }}
              />
            </h1>
            {percentage ? <h1>{`${number}%`}</h1> : <h1>{number}</h1>}
          </div>

          <h6>{label}</h6>
        </>
      ) : (
        <>
          {percentage ? <h1>{`${number}%`}</h1> : <h1>{number}</h1>}
          <h6>{label}</h6>
        </>
      )}
    </>
  );
};

NumberStat.propTypes = {
  icon: PropTypes.objectOf(PropTypes.any),
  number: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  percentage: PropTypes.bool,
};

NumberStat.defaultProps = {
  icon: undefined,
  percentage: false,
};

export default NumberStat;
