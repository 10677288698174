import * as jwt from "jsonwebtoken";

export default function checkToken() {
  if (localStorage.getItem("mcmToken") === null) {
    return false;
  }
  const { exp } = jwt.decode(localStorage.getItem("mcmToken"));
  if (Date.now() >= exp * 1000) {
    return false;
  }
  return true;
}
