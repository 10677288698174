import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import jwt from "jsonwebtoken";
import TopicSelection from "../TopicSelection";
import styles from "../styles/YourChoice.module.css";
import { QuestionSelector } from "../../system/modules/MCSelector";
import * as Questions from "../../system/questions";
import Alert from "../Alert";
// eslint-disable-next-line import/no-cycle
import QuestionDisplay from "../QuestionDisplay";
import checkToken from "../../system/auth/checkToken";
import { getSiteID } from "../../system/utils/getSite";

const YourChoice = () => {
  const location = useLocation();
  const history = useHistory();
  const [showControls, setControls] = useState(true);
  const [overflow, setOverflow] = useState(true);
  const [tabbing, setTabbing] = useState(false);
  const [error, setError] = useState("");
  const [contentCovered, setContentCovered] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [question, setQuestion] = useState({});
  const [loading, setLoading] = useState(
    !(location.state && location.state.content)
  );
  const [chosenTopic, setChosenTopic] = useState("");
  const firstUpdate = useRef(true);
  const [repeat, setRepeat] = useState(false);
  const PUPIL_GCSE_LEVEL =
    jwt.decode(localStorage.getItem("mcmToken")).role === "HIGH_PUPIL" ? 1 : 0;

  const site = getSiteID();

  let level = "ALEVEL";
  if (site === 0) {
    if (PUPIL_GCSE_LEVEL === "HIGH_PUPIL") level = "HIGH";
    else level = "FOUND";
  }

  const loadQuestion = (content, ans, topic) => {
    setQuestion({});
    setError("");
    let chosenQuestion = QuestionSelector(level, content, ans, topic);
    if (chosenQuestion === null) {
      setError(
        "There was a problem selecting a question from this topic. Please try again. If the issue persists, please inform us at contact@mrcartermaths.com."
      );
      return;
    }
    let errored = true;
    const questionObject = {};
    let count = 0;
    while (errored) {
      try {
        questionObject.question = Questions[chosenQuestion]();

        errored = false;
      } catch (e) {
        count += 1;
        if (count > 250) {
          setError("A problem occurred.");
          errored = false;
        } else {
          chosenQuestion = QuestionSelector(level, content, ans, topic);
        }
      }
    }
    questionObject.name = chosenQuestion;
    if (process.env.REACT_APP_DOMAIN.indexOf("qa") !== -1) {
      // eslint-disable-next-line no-console
      console.log(questionObject.name);
    }
    setQuestion(questionObject);
  };

  useEffect(() => {
    const getContentCovered = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_DOMAIN}/content`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
        });
        return res.data;
      } catch (e) {
        setError("A problem occurred! Please check your connection!");
      }
      return false;
    };
    const getQuestionsAnswered = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_DOMAIN}/answers`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
        });
        return res.data;
      } catch (e) {
        setError("A problem occurred! Please check your connection!");
      }
      return false;
    };
    if (location.state && location.state.content) {
      const { content } = location.state;
      setContentCovered(content);
      setLoading(false);
    } else if (checkToken()) {
      getContentCovered().then((content) => {
        if (content) {
          if (content.length !== 0) {
            getQuestionsAnswered().then((answered) => {
              setContentCovered(content);
              setAnswers(answered);
              setLoading(false);
            });
          } else {
            setError(
              "Please fill in your content covered list and then come back!"
            );
          }
        }
      });
    } else {
      localStorage.removeItem("mcmToken");
      history.push("/login");
    }
  }, [location.state]);

  useEffect(() => {
    if (chosenTopic !== "") {
      loadQuestion(contentCovered, answers, chosenTopic);
    }
  }, [chosenTopic, contentCovered]);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setTimeout(() => {
      setOverflow((o) => !o);
    }, 100);
  }, [showControls]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      setControls(!showControls);
    }
    if (e.key === "Tab") {
      setTabbing(true);
    }
  };

  const startLoad = () => {
    return setTimeout(() => {
      setLoading(true);
    }, 500);
  };

  const stopLoad = (timer) => {
    clearTimeout(timer);
    setLoading(false);
  };

  const setNext = (lastAnswer) => {
    const start = startLoad();
    const answered = [...answers];
    answered.unshift(lastAnswer);
    setAnswers(answered);
    loadQuestion(contentCovered, answered, chosenTopic);
    stopLoad(start);
  };

  const tryAgain = (lastAnswer) => {
    const answered = [...answers];
    answered.unshift(lastAnswer);
    setAnswers(answered);
    setRepeat(true);
    const questionObject = {};
    questionObject.question = Questions[question.name]();
    questionObject.name = question.name;
    setQuestion(questionObject);
  };

  return (
    <div className="container">
      <div className="my-4">
        <div className="text-center">
          <div
            role="button"
            tabIndex={0}
            onClick={() => setControls(!showControls)}
            className={`${styles.showControls} ${!tabbing && styles.noTab}`}
            onKeyPress={handleKeyPress}
          >
            <h4 className={`${styles.heading}`}>Your Choice</h4>
            <div
              className={`${styles.icon} ${!showControls && styles.rotate} `}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          </div>
        </div>
        {error !== "" && <Alert variant="danger">{error}</Alert>}
        <div>
          {loading && error === "" ? (
            <div className="my-5 text-center">
              <ClipLoader loading color="#74c5ed" size="3em" />
            </div>
          ) : (
            <div
              className={`${styles.item} ${!showControls && styles.collapsed} ${
                overflow ? styles.overflow : styles.hide
              } `}
            >
              {contentCovered.length !== 0 && (
                <TopicSelection
                  content={contentCovered}
                  handleChoose={(val) => setChosenTopic(val)}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {!loading && (
        <div className="my-3">
          <div>
            {Object.keys(question).length === 0 ? (
              <div className="text-center">
                <h5>Please select a topic to begin.</h5>
              </div>
            ) : (
              <QuestionDisplay
                question={question.question}
                questionName={question.name}
                next={setNext}
                repeat={repeat}
                tryAgain={tryAgain}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default YourChoice;
