import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import axios from "axios";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { faBookReader } from "@fortawesome/free-solid-svg-icons";
import RoundedBtn from "./RoundedBtn";
import Alert from "./Alert";
import StyledInput from "./StyledInput";
import PlainInput from "./PlainInput";
import checkToken from "../system/auth/checkToken";
import Dropdown from "./Dropdown";

const ClassModal = ({
  show,
  onHide,
  onAction,
  adding,
  className,
  changeClassName,
  danger,
  pupilList,
  pupils,
  addPupil,
  editing,
  c,
  isGCSE,
  handleClassLevel,
  classLevel,
}) => {
  const history = useHistory();
  const [emailAddress, setEmailAddress] = useState("");
  const [classname, setClassname] = useState(className);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const options = [
    { id: 1, title: "Foundation", value: 1 },
    { id: 2, title: "Higher", value: 2 },
  ];

  useEffect(() => {
    setClassname(className);
  }, [className]);

  useEffect(() => {
    setAlert({});
  }, [show]);

  const add = (email) => {
    const added = addPupil(email);
    if (added) {
      setEmailAddress("");
    }
  };

  const addingBody = () => {
    return (
      <div>
        {danger !== "" && <Alert variant="danger">{danger}</Alert>}
        <PlainInput
          value={className}
          handleChange={(val) => changeClassName(val)}
          label="Class Name"
          width={-1}
        />
        {isGCSE && (
          <div className="text-center mt-1">
            <Dropdown
              icon={faBookReader}
              width={-1}
              title={
                // eslint-disable-next-line no-nested-ternary
                classLevel === 0
                  ? "Level"
                  : classLevel === 1
                  ? "Foundation"
                  : "Higher"
              }
              options={options}
              handleChange={(newClass) => {
                handleClassLevel(newClass);
              }}
              centered
            />
          </div>
        )}
        <hr />
        <h5>Pupils</h5>
        <StyledInput
          value={emailAddress}
          handleChange={(val) => setEmailAddress(val)}
          autoCompleteOptions={pupils}
          width={-1}
          label="Pupil to add"
        />

        <div style={{ marginTop: "0.2em" }}>
          <RoundedBtn
            text="Add Pupil"
            variant="light"
            width={-1}
            onClick={() => {
              add(emailAddress);
            }}
          />
        </div>
        <ul className="list-group mt-3">
          {pupilList.map((pupil) => {
            return <li className="list-group-item">{pupil.email}</li>;
          })}
        </ul>
      </div>
    );
  };

  const editingBody = () => {
    return (
      <div>
        {danger !== "" && <Alert variant="danger">{danger}</Alert>}
        <PlainInput
          value={classname}
          handleChange={(val) => setClassname(val)}
          label="Class Name"
          width={-1}
        />
      </div>
    );
  };

  const deletingBody = () => {
    return (
      <>
        <b>{`Are you sure you want to delete class ${className}?`}</b>
        <p>{`This action will not delete pupils who are associated with ${className}.`}</p>
      </>
    );
  };

  const startLoad = () => {
    return setTimeout(() => {
      setLoading(true);
    }, 500);
  };

  const stopLoad = (timer) => {
    clearTimeout(timer);
    setLoading(false);
  };

  const edit = async () => {
    const start = startLoad();
    setAlert({});
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    if (classname === "") {
      stopLoad(start);
      setAlert({
        msg: JSON.stringify("Please use a valid name"),
        variant: "danger",
      });
      return false;
    }
    try {
      await axios.patch(
        `${process.env.REACT_APP_DOMAIN}/classes`,
        { classId: c.classId, name: classname },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
        }
      );
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setAlert({
          msg: JSON.stringify(e.response.data.error),
          variant: "danger",
        });
      } else {
        setAlert({
          msg: "A problem occurred. Please try again.",
          variant: "danger",
        });
      }
      stopLoad(start);
      return false;
    }
    stopLoad(start);
    setAlert({ variant: "success", msg: "Class name changed." });
    // eslint-disable-next-line no-param-reassign
    c.name = classname;
    return true;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {/* eslint-disable-next-line no-nested-ternary */}
          {editing ? "Edit Class" : adding ? "Add Class" : "Delete Class"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {alert.msg && <Alert variant={alert.variant}>{alert.msg}</Alert>}
        {loading ? (
          <div className="my-5">
            <ClipLoader loading color="#74c5ed" size="3em" />
          </div>
        ) : (
          <div>
            {editing ? (
              <div>{editingBody()}</div>
            ) : (
              <div>{adding ? addingBody() : deletingBody()}</div>
            )}
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <RoundedBtn text="Close" onClick={onHide} />
        {editing ? (
          <RoundedBtn text="Save" onClick={edit} variant="success" width={8} />
        ) : (
          <RoundedBtn
            text={adding ? "Add" : "Delete"}
            onClick={onAction}
            variant={adding ? "success" : "danger"}
            width={8}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

ClassModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  adding: PropTypes.bool,
  className: PropTypes.string,
  changeClassName: PropTypes.func,
  danger: PropTypes.string,
  pupilList: PropTypes.arrayOf(PropTypes.object),
  pupils: PropTypes.arrayOf(PropTypes.object),
  addPupil: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  c: PropTypes.objectOf(PropTypes.any),
  isGCSE: PropTypes.bool,
  handleClassLevel: PropTypes.func,
  classLevel: PropTypes.number,
};

ClassModal.defaultProps = {
  show: false,
  adding: false,
  className: "",
  changeClassName: undefined,
  danger: "",
  pupilList: [],
  pupils: [],
  editing: false,
  c: {},
  isGCSE: false,
  handleClassLevel: undefined,
  classLevel: 0,
};

export default ClassModal;
