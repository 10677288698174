import React, { useState, useEffect } from "react";
import { faHistory, faPlus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import * as jwt from "jsonwebtoken";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import RoundedBtn from "./RoundedBtn";
import AddRelationModal from "./AddRelationModal";
import Alert from "./Alert";
import PupilList from "./PupilList";
import PlainInput from "./PlainInput";
import Dropdown from "./Dropdown";
import checkToken from "../system/auth/checkToken";

const Pupils = ({ pupilList, answers, classList }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({});
  const [email, setEmail] = useState("");
  const [pupils, setPupils] = useState([]);
  const [filteredPupils, setFilteredPupils] = useState([]);
  const [waiting, setWaiting] = useState(0);
  const [filter, setFilter] = useState("");
  const [summaryPeriod, setSummaryPeriod] = useState(7);
  const [ddTitle, setDDTitle] = useState("");
  const [allAnswers, setAllAnswers] = useState({});
  const [filteredAnswers, setFilteredAnswers] = useState([]);
  const [summary, setSummary] = useState({ total: 0, correct: 0 });
  const [pupilClasses, setPupilClasses] = useState([]);
  const options = [
    { id: 0, title: "Last 7 days", value: 7 },
    { id: 1, title: "Last 14 days", value: 14 },
    { id: 2, title: "Last 30 days", value: 30 },
    { id: 3, title: "Last 90 days", value: 90 },
    { id: 4, title: "All-time", value: -1 },
  ];
  const [classOptions, setClassOptions] = useState([]);
  const [selectedClass, setSelectedClass] = useState({
    name: "Filter by Class",
    id: 0,
  });

  const chooseRelevantData = () => {
    if (summaryPeriod === 7) {
      return allAnswers.seven;
    }
    if (summaryPeriod === 14) {
      return allAnswers.fourteen;
    }
    if (summaryPeriod === 30) {
      return allAnswers.thirty;
    }
    if (summaryPeriod === 90) {
      return allAnswers.ninety;
    }
    return allAnswers.allTime;
  };

  useEffect(() => {
    let dropdownTitle = "";
    if (summaryPeriod !== -1) {
      dropdownTitle = `Last ${summaryPeriod} days`;
    } else {
      dropdownTitle = "All-time";
    }
    setDDTitle(dropdownTitle);
  }, [summaryPeriod]);

  useEffect(() => {
    if (pupilList) {
      setAllAnswers({ ...answers });
      setFilteredAnswers({ ...answers });
      setPupils(pupilList);
      setPupilClasses(classList);
      const opts = [];
      const possibleClasses = [];
      for (let i = 0; i < classList.length; i += 1) {
        if (!possibleClasses.includes(classList[i].classId)) {
          possibleClasses.push(classList[i].classId);
          opts.push({
            id: classList[i].classId,
            title: classList[i].name,
            value: classList[i].classId,
          });
        }
      }
      if (opts.length !== 0) {
        opts.unshift({ id: 0, title: "None", value: 0 });
      }
      setClassOptions(opts);
      let waits = 0;
      for (let i = 0; i < pupilList.length; i += 1) {
        const rel = pupilList[i];
        if (
          !rel.confirmed &&
          rel.createdBy !== jwt.decode(localStorage.getItem("mcmToken")).userId
        ) {
          waits += 1;
        }
      }
      setWaiting(waits);
      const ans = { ...answers };
      for (let i = 0; i < pupilList.length; i += 1) {
        const thisPupil = pupilList[i];
        thisPupil.answers = [];
        for (let j = 0; j < ans.length; j += 1) {
          const thisAnswer = ans[j];
          if (thisPupil.pupilId === thisAnswer.userId) {
            thisPupil.answers.push(thisAnswer);
            ans.splice(j, 1);
            j -= 1;
          }
        }
      }
      setLoading(false);
    }
  }, [answers, pupilList]);

  useEffect(() => {
    let total = 0;
    let correct = 0;
    if (summaryPeriod === -1 && filteredAnswers.allTime) {
      filteredAnswers.allTime.forEach((pupilsAnswerData) => {
        total += pupilsAnswerData.total;
        correct += pupilsAnswerData.correct;
      });
    } else {
      switch (summaryPeriod) {
        case 7: {
          if (filteredAnswers.seven) {
            filteredAnswers.seven.forEach((pupilsAnswerData) => {
              total += pupilsAnswerData.total;
              correct += pupilsAnswerData.correct;
            });
          }
          break;
        }
        case 14: {
          if (filteredAnswers.fourteen) {
            filteredAnswers.fourteen.forEach((pupilsAnswerData) => {
              total += pupilsAnswerData.total;
              correct += pupilsAnswerData.correct;
            });
          }
          break;
        }
        case 30: {
          if (filteredAnswers.thirty) {
            filteredAnswers.thirty.forEach((pupilsAnswerData) => {
              total += pupilsAnswerData.total;
              correct += pupilsAnswerData.correct;
            });
          }
          break;
        }
        case 90: {
          if (filteredAnswers.ninety) {
            filteredAnswers.ninety.forEach((pupilsAnswerData) => {
              total += pupilsAnswerData.total;
              correct += pupilsAnswerData.correct;
            });
          }
          break;
        }
        default: {
          break;
        }
      }
      // const dateRange = new Date();
      // dateRange.setDate(dateRange.getDate() - summaryPeriod);
      // filteredAnswers.forEach((entry) => {
      //   const qDate = entry.dateAnswered.split(/[- :]/);
      //   const date = new Date(
      //     qDate[0],
      //     qDate[1] - 1,
      //     qDate[2],
      //     qDate[3],
      //     qDate[4],
      //     qDate[5]
      //   );
      //   if (date >= dateRange) {
      //     total += 1;
      //     if (entry.correct) {
      //       correct += 1;
      //     }
      //   }
      // });
    }
    setSummary({ total, correct });
  }, [filteredAnswers, summaryPeriod]);

  useEffect(() => {
    const filterStr = (pupil) => {
      const name = `${pupil.fname} ${pupil.lname}`.replace(/\s/g, "");
      if (
        name.toLowerCase().indexOf(filter.toLowerCase().replace(/\s/g, "")) !==
        -1
      ) {
        return true;
      }
      return false;
    };
    let ps = [...pupils];
    if (selectedClass.id !== 0) {
      const uids = [];
      for (let i = 0; i < pupilClasses.length; i += 1) {
        const p = pupilClasses[i];
        if (p.classId === selectedClass.id) {
          uids.push(p.userId);
        }
      }
      ps = ps.filter((p) => uids.includes(p.pupilId));
    }
    let filtered = ps;
    if (filter !== "") {
      filtered = filtered.filter(filterStr);
    }
    const answersFiltered = {
      allTime: [],
      seven: [],
      fourteen: [],
      thirty: [],
      ninety: [],
    };
    for (let i = 0; i < filtered.length; i += 1) {
      const pupil = filtered[i];
      for (let j = 0; j < allAnswers.allTime.length; j += 1) {
        const answer = allAnswers.allTime[j];
        if (pupil.pupilId === answer.userId) {
          answersFiltered.allTime.push(answer);
        }
      }
      for (let j = 0; j < allAnswers.seven.length; j += 1) {
        const answer = allAnswers.seven[j];
        if (pupil.pupilId === answer.userId) {
          answersFiltered.seven.push(answer);
        }
      }
      for (let j = 0; j < allAnswers.fourteen.length; j += 1) {
        const answer = allAnswers.fourteen[j];
        if (pupil.pupilId === answer.userId) {
          answersFiltered.fourteen.push(answer);
        }
      }
      for (let j = 0; j < allAnswers.thirty.length; j += 1) {
        const answer = allAnswers.thirty[j];
        if (pupil.pupilId === answer.userId) {
          answersFiltered.thirty.push(answer);
        }
      }
      for (let j = 0; j < allAnswers.ninety.length; j += 1) {
        const answer = allAnswers.ninety[j];
        if (pupil.pupilId === answer.userId) {
          answersFiltered.ninety.push(answer);
        }
      }
    }
    setFilteredPupils(filtered);
    setFilteredAnswers(answersFiltered);
  }, [filter, pupils, allAnswers, selectedClass]);

  const validateEmail = (e) => {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(String(e).toLowerCase());
  };

  const startLoad = () => {
    return setTimeout(() => {
      setLoading(true);
    }, 500);
  };

  const stopLoad = (timer) => {
    clearTimeout(timer);
    setLoading(false);
  };

  const addPupil = async () => {
    setAlert({});
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    if (!validateEmail(email)) {
      setAlert({
        variant: "danger",
        modal: true,
        msg: "Please enter a valid email address.",
      });
      return false;
    }
    setShowModal(false);
    const start = startLoad();
    try {
      await axios.post(
        `${process.env.REACT_APP_DOMAIN}/relations`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
        }
      );
      setPupils([{ confirmed: false, email }, ...pupils]);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setAlert({ variant: "danger", msg: e.response.data.error });
      } else {
        setAlert({
          variant: "danger",
          msg: "A problem occurred. Please try again.",
        });
      }
      stopLoad(start);
      return false;
    }
    setAlert({ variant: "success", msg: "Invitation sent!" });
    setEmail("");
    stopLoad(start);
    return true;
  };

  const respond = async (response, pupilId) => {
    const start = startLoad();
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    try {
      await axios.patch(
        `${process.env.REACT_APP_DOMAIN}/relations`,
        { accept: response, otherId: pupilId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
        }
      );
      const data = [...pupils];
      for (let i = 0; i < data.length; i += 1) {
        const relation = data[i];
        if (relation.pupilId === pupilId) {
          const msg = response
            ? `You have added ${relation.fname} ${relation.lname} to your list of pupils.`
            : `You have rejected ${relation.fname} ${relation.lname} from sharing their data.`;
          if (response) {
            relation.confirmed = true;
          } else {
            data.splice(i, 1);
          }
          setAlert({
            variant: "success",
            msg,
          });
        }
      }
      let waits = 0;
      for (let i = 0; i < data.length; i += 1) {
        const rel = data[i];
        if (
          !rel.confirmed &&
          rel.createdBy !== jwt.decode(localStorage.getItem("mcmToken")).userId
        ) {
          waits += 1;
        }
      }
      setWaiting(waits);
      setPupils(data);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setAlert({
          variant: "danger",
          msg: JSON.stringify(e.response.data.error),
        });
      } else {
        setAlert({
          variant: "danger",
          msg: "A problem occurred. Please try again.",
        });
      }
    }
    stopLoad(start);
    return true;
  };

  const onDelete = async (pupil) => {
    const start = startLoad();
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    if (!pupil.createdBy) {
      setAlert({
        variant: "danger",
        msg: "Cannot delete a user that is linked by the school.",
      });
      stopLoad(start);
      return false;
    }
    try {
      await axios.delete(`${process.env.REACT_APP_DOMAIN}/relations`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
        data: {
          otherId: pupil.pupilId,
        },
      });
      const data = [...pupils];
      let ind = -1;
      for (let i = 0; i < data.length; i += 1) {
        if (data[i].pupilId === pupil.pupilId) {
          ind = i;
        }
      }
      data.splice(ind, 1);
      setAlert({
        variant: "success",
        msg: `Successfully removed ${pupil.fname} ${pupil.lname}.`,
      });
      setPupils(data);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setAlert({
          variant: "danger",
          msg: JSON.stringify(e.response.data.error),
        });
      } else {
        setAlert({
          variant: "danger",
          msg: "A problem occurred. Please try again.",
        });
      }
    }
    stopLoad(start);
    return true;
  };

  return (
    <div className="card text-center">
      <h4 className="card-header">Pupils</h4>
      <div className="card-body">
        <AddRelationModal
          danger={alert.modal ? alert.msg : ""}
          show={showModal}
          onHide={() => setShowModal(false)}
          onAction={() => addPupil()}
          email={email}
          emailChanged={setEmail}
          entity="pupil"
        />
        <div>
          <div>
            <div className="text-center">
              {loading ? (
                <div className="my-5">
                  <ClipLoader loading color="#74c5ed" size="3em" />
                </div>
              ) : (
                <div>
                  <div className="mt-1">
                    <RoundedBtn
                      text="Add Pupil"
                      width={10}
                      icon={{ icon: faPlus, position: "left" }}
                      onClick={() => setShowModal(true)}
                    />
                    <div className="mt-3">
                      <Dropdown
                        icon={faHistory}
                        title={ddTitle}
                        options={options}
                        handleChange={(newPeriod) => {
                          setSummaryPeriod(newPeriod);
                        }}
                        centered
                      />
                    </div>
                    {classList.length !== 0 && (
                      <div className="mt-3">
                        <Dropdown
                          title={
                            selectedClass.id === 0
                              ? "Filter by Class"
                              : selectedClass.name
                          }
                          options={classOptions}
                          handleChange={(id) => {
                            for (let i = 0; i < classOptions.length; i += 1) {
                              if (classOptions[i].value === id) {
                                setSelectedClass({
                                  id,
                                  name: classOptions[i].title,
                                });
                                break;
                              }
                            }
                          }}
                          centered
                          width={15}
                        />
                      </div>
                    )}

                    {(typeof alert.modal === "undefined" || !alert.modal) &&
                      typeof alert.msg !== "undefined" && (
                        <div className="mt-3">
                          <Alert variant={alert.variant}>{alert.msg}</Alert>
                        </div>
                      )}
                  </div>
                  {waiting !== 0 && (
                    <div className="mt-3">
                      <span style={{ color: "#196fc6", fontWeight: "600" }}>
                        {`You have ${waiting} new ${
                          waiting > 1 ? "invitations" : "invitation"
                        }!`}
                      </span>
                    </div>
                  )}
                  <div className="mt-3">
                    <PlainInput
                      placeholder="Who are you looking for?"
                      handleChange={(val) => {
                        setFilter(val);
                      }}
                      value={filter}
                      width={-1}
                    />
                  </div>
                  {filteredPupils.length !== 0 && (
                    <div
                      style={{
                        textAlign: "left",
                        marginTop: "1em",
                        color: "#777",
                      }}
                    >
                      <span>
                        {`${
                          summary.total
                        } questions answered ${String.fromCharCode(0x000b7)} ${
                          summary.total === 0
                            ? 0
                            : Math.round(
                                (summary.correct / summary.total) * 100
                              )
                        }% correct`}
                      </span>
                    </div>
                  )}
                  <div className="text-left mt-3">
                    <PupilList
                      data={filteredPupils}
                      onRespondToInvite={respond}
                      onDelete={onDelete}
                      allAnswerData={chooseRelevantData()}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Pupils.propTypes = {
  pupilList: PropTypes.arrayOf(PropTypes.object).isRequired,
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  classList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Pupils;
