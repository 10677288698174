import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import HeaderLogo from "../system/images/admin/headerlogo.png";

const Navigation = ({
  isAuthenticated,
  accountBadgeCount,
  role,
  staffView,
}) => {
  const [collapse, setCollapse] = useState(false);

  const setMenuLink = () => {
    if (role === "STAFF" || role === "STAFF_ADMIN") {
      if (staffView === 0) {
        return "Menu";
      }
      return "Home";
    }
    if (role === "SITE_ADMIN") {
      return "Admin Panel";
    }
    return "Menu";
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light"
      style={{ backgroundColor: "rgb(166, 217, 248)" }}
    >
      <div className="container">
        <Link
          to="/"
          className="navbar-brand"
          onClick={() => setCollapse(false)}
        >
          <img
            src={HeaderLogo}
            height="50"
            className="d-inline-block align-top"
            alt="Mr. Carter Maths"
          />
        </Link>
        <button
          onClick={() => setCollapse(true)}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className={`collapse navbar-collapse ${collapse ? "show" : ""}`}
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav ml-auto">
            <Link
              to={isAuthenticated ? "/" : "/register"}
              className="nav-item nav-link"
            >
              {isAuthenticated ? setMenuLink() : "Register"}
            </Link>
            <Link
              to={isAuthenticated ? "/account" : "/login"}
              className="nav-item nav-link"
              onClick={() => setCollapse(false)}
            >
              {isAuthenticated ? "Account" : "Sign In"}
              {isAuthenticated && accountBadgeCount > 0 && (
                <span className="badge badge-light ml-1">
                  {accountBadgeCount}
                </span>
              )}
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  isAuthenticated: PropTypes.bool,
  accountBadgeCount: PropTypes.number,
  role: PropTypes.string.isRequired,
  staffView: PropTypes.number.isRequired,
};

Navigation.defaultProps = {
  isAuthenticated: false,
  accountBadgeCount: 0,
};

export default Navigation;
