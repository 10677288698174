import { Fraction } from "./Fraction";
import { NumberSetError, ArgumentError } from "../MCError";
import { Graph } from "../MCQuestion";
import { cleaner } from "./cleaner";
/**
 * Class Handling Polynomials.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @author Darren Carter
 * @example
 * // Creates the polynomial $3x^2+2x+1$
 * p1 = new Polynomial([3,2,1])
 * // Creates the polynomial $4y^4+3$
 * p2 = new Polynomial([4,0,0,0,3],'y')
 */

// Things to add - inequalities (might be better in quadratics)
class Polynomial {
  /**
   * Creates a Polynomial.
   * @param {number[]} coefficients The coefficients of the polynomial in decreasing power.
   * @param {string} [variable='x'] The varible of the polynomial.
   */
  constructor(coefficients, variable = "x") {
    if (!Array.isArray(coefficients)) {
      throw new NumberSetError(
        `Expected an array, got ${coefficients} instead`
      );
    }
    if (typeof variable !== "string") {
      throw new NumberSetError(`Expected a string, got ${variable} instead`);
    }
    this.coefficients = coefficients;
    while (this.coefficients[0] === 0) {
      this.coefficients.shift();
    }
    this.variable = variable;
  }

  /**
   * Adds two Polynomials.
   *
   * @param {Polynomial} 	poly What to add.
   * @returns {Polynomial} A new polynomial that is the sum of the instance and the input.
   * @throws {TypeError}   The argument should be a polynomial.
   *
   * @example
   * // Adds $x^2+x+1$ to $3x^3+2x^2+x$
   * p1 = new Polynomial([1,1,1])
   * p2 = new Polynomial([3,2,1,0])
   * console.log(p1.add(p2))
   */
  add(poly) {
    if (!(poly instanceof Polynomial)) {
      throw new NumberSetError(`Expected a polynomial, got ${poly} instead`);
    }

    const p1 = this.coefficients;
    const p2 = poly.coefficients;
    const diff = p1.length - p2.length;
    const out = [];
    // Makes arrays same size to align powers
    if (diff > 0) {
      for (let i = 0; i < diff; i += 1) {
        p2.unshift(0);
      }
    }
    if (diff < 0) {
      for (let i = 0; i > diff; i -= 1) {
        p1.unshift(0);
      }
    }
    for (let i = 0; i < p1.length; i += 1) {
      out.push(p1[i] + p2[i]);
    }
    return new Polynomial(out, this.variable);
  }

  /**
   * Subtracts two Polynomials.
   *
   * @param {Polynomial} 	poly What to subtract.
   * @returns {Polynomial} A new polynomial that is the difference of the instance and the input.
   * @throws {TypeError}   The argument should be a polynomial.
   *
   * @example
   * // Subtracts $x^2+x+1$ from $3x^3+2x^2+x$
   * p1 = new Polynomial([1,1,1])
   * p2 = new Polynomial([3,2,1,0])
   * console.log(p2.add(p1))
   */
  subtract(poly) {
    if (!(poly instanceof Polynomial)) {
      throw new NumberSetError(`Expected a polynomial, got ${poly} instead`);
    }

    const p1 = this.coefficients;
    const p2 = poly.coefficients;
    const diff = p1.length - p2.length;
    const out = [];
    // Makes arrays same size to align powers
    if (diff > 0) {
      for (let i = 0; i < diff; i += 1) {
        p2.unshift(0);
      }
    }
    if (diff < 0) {
      for (let i = 0; i > diff; i -= 1) {
        p1.unshift(0);
      }
    }
    for (let i = 0; i < p1.length; i += 1) {
      out.push(p1[i] - p2[i]);
    }
    return new Polynomial(out, this.variable);
  }

  /**
   * Multiply Polynomial by Polynomial.
   *
   * @param {Polynomial | Number} factor What to multiply by (number or polynomial).
   * @returns {Polynomial} A new polynomial that is the product of the instance and the input.
   * @throws {TypeError}   The argument should be a polynomial.
   *
   * @example
   * // Multiplys $x^2+x+1$ by $3x^3+2x^2+x$
   * p1 = new Polynomial([1,1,1])
   * p2 = new Polynomial([3,2,1,0])
   * console.log(p1.multiply(p2))
   */
  multiply(factor) {
    if (typeof factor === "number") {
      return new Polynomial(
        this.coefficients.map(function f1(element) {
          return element * factor;
        }),
        this.variable
      );
    }

    if (!(factor instanceof Polynomial)) {
      throw new NumberSetError(
        `Expected a polynomial or number, got ${factor} instead`
      );
    }
    const p1 = this.coefficients;
    const p2 = factor.coefficients;
    const range = Math.max(p1.length, p2.length);
    const out = new Array(p1.length + p2.length - 1).fill(0);
    for (let i = p1.length + p2.length - 2; i >= 0; i -= 1) {
      for (let j = 0; j < range; j += 1) {
        if (j < p1.length && i - j < p2.length && i - j >= 0) {
          out[i] += p1[j] * p2[i - j];
        }
      }
    }
    return new Polynomial(out, this.variable);
  }

  /**
   * Raises Polynomial to an integer power.
   *
   * @param {number} power Power to raise the instance by.
   * @returns {Polynomial} A new polynomial that is the the instance raised to the input power.
   * @throws {NumberSetError} The power should be an integer.
   *
   * @example
   * // Cube (1+x)
   * let p1 = new Polynomial([1,1])
   * console.log(p1.pow(3))
   */
  pow(power) {
    if (typeof power !== "number" || Math.round(power) !== power) {
      throw new NumberSetError(`Expected an integer, got ${power} instead`);
    }
    // throw new MCError.NumberSetError('Expected a positive integer')
    let out = new Polynomial(this.coefficients, this.variable);
    for (let i = 0; i <= power - 2; i += 1) {
      out = out.multiply(this);
    }
    return out;
  }

  /**
   * @summary Finds the derivative of the polynomial.
   *
   * @returns {Polynomial} A new polynomial that is the derivative of the instance.
   *
   * @example
   * // Find the derivative of $x^2+3x+1$
   * let p1 = new Polynomial([1,3,1])
   * console.log(p1.derivative())
   */

  derivative() {
    const p = this.coefficients;
    const out = [];
    for (let i = 0; i < p.length - 1; i += 1) {
      out.push(p[i] * (p.length - i - 1));
    }
    return new Polynomial(out, this.variable);
  }

  /**
   * Finds the integral of the polynomial, with 0 instead of +c.
   *
   * @returns {Polynomial} A new polynomial that is the integral of the instance.
   *
   * @example
   * // Find the integral of $x^2+3x+1$
   * let p1 = new Polynomial([1,3,1])
   * console.log(p1.integral())
   */

  integral() {
    const p = this.coefficients;
    const out = [];
    for (let i = 0; i < p.length; i += 1) {
      out.push(p[i] / (p.length - i));
    }
    out.push(0);
    return new Polynomial(out, this.variable);
  }

  /**
   * Evaluates the polynomial.
   *
   * @param {number} coordinate Coordinate to evaluate at.
   * @returns {number} The value of the instance evaluated at the input value.
   * @throws {NumberSetError} The coordinate should be a number.
   *
   * @example
   * // Evaluate $x^2-2x+1$ at $x=2$
   * let p1 = new Polynomial([1,-2,1])
   * console.log(p1.evaluate(2))
   */
  evaluate(coordinate) {
    if (typeof coordinate !== "number") {
      throw new NumberSetError(`Expected a number, got ${coordinate} instead`);
    }
    const p1 = this.coefficients;
    let out = 0;
    for (let i = 0; i < p1.length; i += 1) {
      out += p1[i] * coordinate ** (p1.length - i - 1);
    }
    return out;
  }

  /**
   * Shows working for evaluating the polynomial.
   *
   * @param {number} coordinate Coordinate to evaluate at.
   * @returns {string[]} Three lines of working out.
   * @throws {NumberSetError} The coordinate should be a number.
   *
   * @example
   * // Show working for evaluating $t^2-2t+1$ at $t=2$
   * const p3 = new Polynomial([1,-2,1],'t').evaluateWorking(2)
   * console.log(p3[0])
   * console.log(p3[1])
   * console.log(p3[2])
   */
  evaluateWorking(coordinate) {
    if (typeof coordinate !== "number") {
      throw new NumberSetError(`Expected a number, got ${coordinate} instead`);
    }
    const replaceMe = this.variable;
    const re = new RegExp(replaceMe, "g");
    const line1 = `HEADING Evaluating $f(${
      this.variable
    })=${this.toString()}$ at $${this.variable}=${coordinate}$`;
    const line2 = `$f(${coordinate})=${this.toString().replace(
      re,
      `(${coordinate})`
    )}`;
    const line3 = `$f(${coordinate})=${this.evaluate(coordinate)}$`;
    return [line1, line2, line3];
  }

  /**
   * Finds the equation of the line normal to the polynomial at a given coordinate
   *
   * @param {number} coordinate Coordinate to find the normal at.
   * @returns {polynomial} The normal line, in polynomial form.
   * @throws {NumberSetError} The coordinate should be a number.
   *
   * @example
   * // Find the normal line to $x^2-2x+1$ at $x=2$
   * let p1 = new Polynomial([1,-2,1])
   * console.log(p1.normal(2))
   */
  normal(coordinate) {
    if (typeof coordinate !== "number") {
      throw new NumberSetError(`Expected a number, got ${coordinate} instead`);
    }
    const m = -1 / this.derivative().evaluate(coordinate);
    return new Polynomial([m, this.evaluate(coordinate) - m * coordinate], "x");
  }

  /**
   * Shows the working out for calulating the line normal to a polynomial
   *
   * @param {number} coordinate Coordinate to find the normal at.
   * @returns {string[]} Each line of the working out.
   * @throws {NumberSetError} The coordinate should be a number.
   *
   * @example
   * //The working for finding the normal to $x^2-2x+1$ at $x=2$.
   * const p1 = new Polynomial([1,-2,1])
   * const working = p1.normalWorking(2)
   * console.log(working[0])
   * console.log(working[1])
   * console.log(working[2])
   * console.log(working[3])
   * console.log(working[4])
   * console.log(working[5])
   * console.log(working[6])
   * console.log(working[7])
   * console.log(working[8])
   * console.log(working[9])
   * console.log(working[10])
   */
  normalWorking(coordinate) {
    if (typeof coordinate !== "number") {
      throw new NumberSetError(`Expected a number, got ${coordinate} instead`);
    }
    const normalLine = this.normal(coordinate);
    const line1 = `HEADING Derivative of $y=${this.toString()}$`;
    const line2 = `$\\frac{dy}{dx}=${this.derivative().toString()}$.`;
    const line3 = `HEADING The gradient of the tangent to $${this.toString()}$ at $x=${coordinate}$`;
    const line4 = `$m=${this.derivative()
      .toString()
      .replace(/x/g, `(${coordinate})`)}$`;
    const line5 = `$m=${this.derivative().evaluate(coordinate)}$`;
    const line6 = `$\\therefore$ Gradient of normal $=${normalLine.coefficients[0]}$ as perpendicular gradients have a product of $-1$`;
    const line7 = `Heading $y$ coordinate of $y=${this.toString()}$ at $x=${coordinate}$`;
    const line8 = `$y=${this.toString().replace(/x/g, `(${coordinate})`)}$`;
    const line9 = `$y=${this.evaluate(coordinate)}$`;
    const line10 = `$\\therefore$ Equation of normal to $${this.toString()}$ at $x=${coordinate}$ is $y-${this.evaluate(
      coordinate
    )}=${normalLine.coefficients[0]}(x-${coordinate})$.`;
    const line11 = `Simplifying, $y=${normalLine.toString()}$`;
    return [
      cleaner(line1),
      cleaner(line2),
      cleaner(line3),
      cleaner(line4),
      cleaner(line5),
      cleaner(line6),
      cleaner(line7),
      cleaner(line8),
      cleaner(line9),
      cleaner(line10),
      cleaner(line11),
    ];
  }

  /**
   * Finds the equation of the line tangent to the polynomial at a given coordinate
   *
   * @param {number} coordinate Coordinate to find the tangent at.
   * @returns {polynomial} The tangent line, in polynomial form.
   * @throws {NumberSetError} The coordinate should be a number.
   *
   * @example
   * // Find the tangent line to $x^2-2x+1$ at $x=2$
   * let p1 = new Polynomial([1,-2,1])
   * console.log(p1.tangent(2))
   */
  tangent(coordinate) {
    if (typeof coordinate !== "number") {
      throw new NumberSetError(`Expected a number, got ${coordinate} instead`);
    }
    const m = this.derivative().evaluate(coordinate);
    return new Polynomial([m, this.evaluate(coordinate) - m * coordinate], "x");
  }

  /**
   * Shows the working out for calulating the line tangent to a polynomial
   *
   * @param {number} coordinate Coordinate to find the tangent at.
   * @returns {string[]} Each line of the working out.
   * @throws {NumberSetError} The coordinate should be a number.
   *
   * @example
   * //The working for finding the tangent to $x^2-2x+1$ at $x=2$.
   * const p1 = new Polynomial([1,-2,1])
   * const working = p1.tangentWorking(2)
   * console.log(working[0])
   * console.log(working[1])
   * console.log(working[2])
   * console.log(working[3])
   * console.log(working[4])
   * console.log(working[5])
   * console.log(working[6])
   * console.log(working[7])
   * console.log(working[8])
   * console.log(working[9])
   */
  tangentWorking(coordinate) {
    if (typeof coordinate !== "number") {
      throw new NumberSetError(`Expected a number, got ${coordinate} instead`);
    }
    const tangentLine = this.tangent(coordinate);
    const line1 = `HEADING Derivative of $y=${this.toString()}$`;
    const line2 = `$\\frac{dy}{dx}=${this.derivative().toString()}$.`;
    const line3 = `HEADING The gradient of the tangent to $${this.toString()}$ at $x=${coordinate}$`;
    const line4 = `$m=${this.derivative()
      .toString()
      .replace(/x/g, `(${coordinate})`)}$`;
    const line5 = `$m=${this.derivative().evaluate(coordinate)}$`;
    const line6 = `Heading $y$ coordinate of $y=${this.toString()}$ at $x=${coordinate}$`;
    const line7 = `$y=${this.toString().replace(/x/g, `(${coordinate})`)}$`;
    const line8 = `$y=${this.evaluate(coordinate)}$`;
    const line9 = `$\\therefore$ Equation of tangent to $${this.toString()}$ at $x=${coordinate}$ is $y-${this.evaluate(
      coordinate
    )}=${tangentLine.coefficients[0]}(x-${coordinate})$.`;
    const line10 = `Simplifying, $y=${tangentLine.toString()}$.`;
    return [
      line1,
      line2,
      line3,
      line4,
      line5,
      line6,
      line7,
      line8,
      line9,
      line10,
    ];
  }

  /**
   * Outputs the polynomial as a string.
   *
   * @returns {string} The polynomial wrote as a string.
   *
   * @example
   * // Writes $x^2+3x+1$ as a string
   * let p1 = new Polynomial([1,3,1])
   * console.log(p1.toString())
   * // Writes $(2)^2+3(2)+1$ as a string
   * let p2 = new Polynomial([1,3,1])
   * console.log(p2.toString('('+2+')'))
   */

  toString() {
    let out = "";
    const { length } = this.coefficients;
    // Sorts (a-bx) cases for binomials etc.
    if (length === 2) {
      const a = this.coefficients[1];
      const b = this.coefficients[0];
      const aFraction = new Fraction(a);
      const bFraction = new Fraction(b);
      if (a > 0 && b < 0) {
        return cleaner(
          `${aFraction.toString()}${bFraction.toString()}${this.variable}`
        );
      }
    }

    for (let i = 0; i < length; i += 1) {
      // In the form ax^b
      const a = this.coefficients[i];
      const b = length - i - 1;
      const bFraction = new Fraction(b);
      // Is the coefficient 0?
      if (a !== 0) {
        if (i !== 0 && a > 0) {
          out += "+";
        }
        // x^0
        if (b === 0) {
          out += cleaner(`${a} `);
        } // x^1
        else if (b === 1) {
          if (a === 1) {
            out += this.variable;
          } else if (a === -1) {
            out += `-${this.variable}`;
          } else {
            out += cleaner(`${a} `) + this.variable;
          }
        } else if (a === 1) {
          out += `${this.variable}^{${bFraction.toString()}}`;
        } else if (a === -1) {
          out += `-${this.variable}^{${bFraction.toString()}}`;
        } else {
          out += `${
            cleaner(`${a} `) + this.variable
          }^{${bFraction.toString()}}`;
        }
      }
    }
    return out;
  }

  /**
   * Finds the roots of the polynomial. Currently only works for quadratics, cubics etc require proper numerical methods.
   *
   * @returns {numbers[] | string[]} Roots of the polynomial, or strings if it has no roots.
   *
   * @example
   * // Finds the roots of x^2-3x+2.
   * const p1 = Polynomial.generateFromRoots([1,2]).roots()
   * console.log(p1[0])
   * console.log(p1[1])
   */
  roots() {
    const { length } = this.coefficients;
    if (length !== 3) {
      throw new ArgumentError(
        `Can only do quadratics so far, input was degree ${length}`
      );
    }
    if (length === 3) {
      const a = this.coefficients[0];
      const b = this.coefficients[1];
      const c = this.coefficients[2];
      const discriminant = b * b - 4 * a * c;
      if (discriminant < 0) {
        return ["No Real Roots", "No Real Roots"];
      }
      const root1 = (-b + Math.sqrt(discriminant)) / (2 * a);
      const root2 = (-b - Math.sqrt(discriminant)) / (2 * a);
      return [root1, root2];
    }
    return [];
  }

  /**
   * Quadratic formula
   *
   * @returns{string}
   */
  quadraticFormula() {
    return cleaner(
      `\\frac{${-1 * this.coefficients[1]} \\pm\\sqrt{${
        this.coefficients[1] * this.coefficients[1] -
        4 * this.coefficients[0] * this.coefficients[2]
      }}}{${2 * this.coefficients[0]}}`
    );
  }

  /**
     * @summary Completes the Square for a given quadratic.
     *
     * @parameters {string} y THe varible the poly equals
     *
     * @returns {string[]} each element of array is a line of working for the completed square.
     *
     * @example
     * let p1 = new Polynomial([1,3,1])
     * console.log(p1.completeSquare())

     * let p2 = new Polynomial([2,3,1])
     * console.log(p2.completeSquare())
     */
  completeSquare(y = "y") {
    const { length } = this.coefficients;
    if (length !== 3) {
      throw new ArgumentError(`Can only do quadratics`);
    }
    if (length === 3) {
      if (this.coefficients[0] === 1) {
        // const a = 1; <- this is unused, not my code tho (JP)
        const b = this.coefficients[1];
        const c = new Fraction(this.coefficients[2]);
        const insideBracket = new Polynomial([1, b / 2], this.variable);
        const boverTwoSquared = new Fraction(
          (this.coefficients[1] / 2) * (this.coefficients[1] / 2)
        );

        let cWithSign = c.toString();
        if (c.toFloat() > 0) {
          cWithSign = `+${cWithSign}`;
        }

        const finalConstant = new Fraction(
          -(this.coefficients[1] / 2) * (this.coefficients[1] / 2) + c
        );
        let finalConstantWithSign = finalConstant.toString();
        if (finalConstant.toFloat() > 0) {
          finalConstantWithSign = `+${finalConstantWithSign}`;
        }

        let line1 = ``;
        if (y === "") {
          line1 = `HEADING Completing the square of $ ${this.toString()}$`;
        } else {
          line1 = `HEADING Completing the square of $ ${y}=${this.toString()}$`;
        }

        let line2 = ``;
        if (cWithSign !== 0) {
          line2 = `$${y}=(${insideBracket.toString()})^2-${boverTwoSquared.toString()}${cWithSign}$`;
        } else {
          line2 = `$${y}=\\left(${insideBracket.toString()}\\right)^2-${boverTwoSquared.toString()}$`;
        }
        const line3 = `$${y}=\\left(${insideBracket.toString()}\\right)^2${finalConstantWithSign}$`;

        return [line1, line2, line3];
      }
      const a = this.coefficients[0];
      const b = this.coefficients[1];
      const c = this.coefficients[2];
      const aFactoredOut = new Polynomial([1, b / a, c / a], this.variable);
      const bHalfed = new Polynomial([1, b / (2 * a)], this.variable);
      const squaretoSubtract = new Fraction((b / (2 * a)) * (b / (2 * a)));

      const cOvera = new Fraction(c / a);
      let cOveraWithSign = cOvera.toString();
      if (c / a > 0) {
        cOveraWithSign = `+${cOveraWithSign}`;
      }

      const finalConstantinSquare = new Fraction(
        -(b / (2 * a)) * (b / (2 * a)) + c / a
      );
      let finalConstantinSquareWithSign = finalConstantinSquare.toString();
      if (-(b / (2 * a)) * (b / (2 * a)) + c / a > 0) {
        finalConstantinSquareWithSign = `+${finalConstantinSquareWithSign}`;
      }

      const finalConstant = new Fraction(
        (-(b / (2 * a)) * (b / (2 * a)) + c / a) * a
      );
      let finalConstantWithSign = finalConstant.toString();
      if (finalConstant.toFloat() > 0) {
        finalConstantWithSign = `+${finalConstantWithSign}`;
      }
      let line1 = ``;
      if (y === "") {
        line1 = `HEADING Completing the square of $ ${this.toString()}$`;
      } else {
        line1 = `HEADING Completing the square of $ ${y}=${this.toString()}$`;
      }
      const line2 = `$${y}=${a}\\left[${aFactoredOut.toString()}\\right]$`;
      let line3 = ``;
      if (cOveraWithSign !== 0) {
        line3 = `$${y}=${a}\\left[\\left(${bHalfed.toString()}\\right)^2-${squaretoSubtract}${cOveraWithSign}\\right]$`;
      }
      const line4 = `$${y}=${a}\\left[\\left(${bHalfed.toString()}\\right)^2${finalConstantinSquareWithSign}\\right]$`;
      let line5 = `$${y}=${a}\\left(${bHalfed.toString()}\\right)^2${finalConstantWithSign}$`;
      if (a < 0 && finalConstant.toFloat() > 0) {
        line5 = `$${y}=${finalConstant.toString()}${a}\\left(${bHalfed.toString()}\\right)^2$`;
      }

      return [line1, line2, line3, line4, line5];
    }
    return [];
  }

  /**
   * @summary Finds Minimum by Completing the square.
   *
   * @returns {String[]} each element of array is a line of working for the turning point from completed square.
   *
   * @example
   * //Complete the square on x^2+2x+3
   * const p1 = new Polynomial([1,2,3])
   * console.log(p1.turningPointFromCompletingSquare)
   */
  turningPointFromCompletingSquare() {
    const { length } = this.coefficients;
    if (length !== 3) {
      throw new ArgumentError(`Can only do quadratics`);
    }
    if (length === 3) {
      const outputArray = this.completeSquare();
      const a = this.coefficients[0];
      const b = this.coefficients[1];
      const c = this.coefficients[2];
      const x = new Fraction(-b / (2 * a));
      const y = new Fraction(-a * (b / (2 * a)) * (b / (2 * a)) + c);

      outputArray.push(
        `$\\therefore$ turning point at $(${x.toString()},${y.toString()})$`
      );
      return outputArray;
    }
    return [];
  }

  /**
   * @summary Finds equation of line of symmetry by compeleting the square.
   *
   * @returns {String[]} each element of array is a line of working for the turning point from completed square.
   *
   * @example
   * //Find line of symmetry x^2+2x+3
   * const p1 = new Polynomial([1,2,3])
   * console.log(p1.equationOfLineFromCompletingSquare)
   */
  equationOfSymmetryFromCompletingSquare() {
    const { length } = this.coefficients;
    if (length !== 3) {
      throw new ArgumentError(`Can only do quadratics`);
    }
    if (length === 3) {
      const outputArray = this.turningPointFromCompletingSquare();
      const a = this.coefficients[0];
      const b = this.coefficients[1];
      // const c = this.coefficients[2];  <- this is unused, not my code tho (JP)
      const x = new Fraction(-b / (2 * a));

      outputArray.push(`$\\therefore$ equation of line of symmetry $y=${x}$`);
      return outputArray;
    }
    return [];
  }

  /**
   * Generates a polynomial with given roots.
   *
   * @param {number[]} roots The array of numbers to be roots of the new polynomial
   * @param {string} [variable=this.variable] Optional, will be ht evariable of the new polynomial.
   * @returns {string} The polynomial wrote as a string.
   *
   * @example
   * //Generate a cubic with roots 1, 2, and 3.
   * const p1 = Polynomial.generateFromRoots([1,2,3])
   * console.log(p1.toString())
   */
  static generateFromRoots(roots, variable = "x") {
    let out = new Polynomial([1, -roots[0]], variable);
    for (let i = roots.length - 1; i >= 1; i -= 1) {
      out = out.multiply(new Polynomial([1, -roots[i]], variable));
    }
    return out;
  }

  /**
   * Returns the coefficients of the polynomial
   *
   * @returns {number[]} THe coefficient array.
   *
   * @example
   * p1 = new Polynomial([1,2,3])
   * console.log(p1.getCoefficients())
   */
  getCoefficients() {
    return this.coefficients;
  }

  /**
   * DO NOT USE, Gets the real rrots of a polynomial, DO NOT USE
   * It uses really jank numerical methods, so it only used for aproximating the range to graph the polynomial.
   */
  realRoots() {
    const roots = [];
    let f = new Polynomial(JSON.parse(JSON.stringify(this.getCoefficients())));
    let stepNumber = 0;
    while (
      f.newtonMethod() !== "No Root" &&
      stepNumber < 1000 &&
      f.getCoefficients().length > 3
    ) {
      const root = f.newtonMethod();
      roots.push(root);
      f = this.factorOut(f, root);
      stepNumber += 1;
    }
    if (stepNumber > 999) {
      return "Method Failed";
    }
    if (f.getCoefficients().length === 3) {
      if (f.roots()[0] !== "No Real Roots") {
        roots.push(f.roots()[0]);
        roots.push(f.roots()[1]);
      }
    }
    if (f.getCoefficients().length === 2) {
      roots.push(-f.getCoefficients()[1] / f.getCoefficients()[0]);
    }
    return roots;
  }

  /** Used by Graph to find the aproximate range to plot, don't use this */
  plottingRange(zoom = 1.1) {
    const roots = this.realRoots();
    if (this.getCoefficients().length > 1) {
      const deriv = this.derivative();
      const turningPoints = deriv.realRoots();
      const xrange = [].concat(roots, turningPoints);
      const yrange = [];
      for (let i = turningPoints.length - 1; i >= 0; i -= 1) {
        yrange.push(this.evaluate(turningPoints[i]));
      }
      xrange.push(0);
      yrange.push(0);

      let x1 = Math.max(...xrange) * zoom;
      let x2 = Math.min(...xrange) * zoom;
      let y1 = Math.max(...yrange) * zoom;
      let y2 = Math.min(...yrange) * zoom;
      if (x1 === Infinity || x1 === -Infinity || Number.isNaN(Number(x1))) {
        x1 = 0;
      }
      if (x2 === Infinity || x2 === -Infinity || Number.isNaN(Number(x2))) {
        x2 = 0;
      }
      if (y1 === Infinity || y1 === -Infinity || Number.isNaN(Number(y1))) {
        y1 = 0;
      }
      if (y2 === Infinity || y2 === -Infinity || Number.isNaN(Number(y2))) {
        y2 = 0;
      }

      // [+_x,y size, step size]
      const sizes = [
        [5, 1],
        [10, 2],
        [20, 5],
        [50, 10],
        [100, 20],
        [200, 20],
        [500, 100],
        [1000, 200],
      ];
      let size = 0;
      while (
        x1 > sizes[size][0] ||
        x2 < -sizes[size][0] ||
        y1 > sizes[size][0] ||
        y2 < -sizes[size][0]
      ) {
        size += 1;
        if (size === sizes.length - 1) {
          break;
        }
      }

      return [
        sizes[size][0],
        -sizes[size][0],
        sizes[size][0],
        -sizes[size][0],
        sizes[size][1],
        sizes[size][1],
      ];
    }
    return "ADD CODE FOR LINEAR GRAPHS";
  }

  /** Finds the closest root to the startpoint, again don't use me. */
  newtonMethod(startPoint = 0.123456789, tolerance = 0.00001) {
    let stepNumber = 0;
    let x = startPoint;
    while (
      Math.abs(this.evaluate(x)) > tolerance &&
      stepNumber < 1000 &&
      Math.abs(x) < 1000000
    ) {
      x -= this.evaluate(x) / this.derivative().evaluate(x);
      x = Math.round(100000000 * x) / 100000000;
      stepNumber += 1;
    }
    if (stepNumber > 999) {
      return "No Root";
    }
    return x;
  }

  /** Takes a factor of (x-r) from a polynomial f
   *
   * @param [object] f a polynomial
   * @param [number] r the value in (x-r) to factor out.
   *
   * @returns [polynomial] A new polynomial
   */
  factorOut(f, r) {
    const p = f.getCoefficients();
    for (let i = p.length - 1; i >= 0; i -= 1) {
      p[i] /= p[0];
    }
    const q = [];
    const n = p.length;
    for (let i = 0; i < n - 1; i += 1) {
      let sum = 0;
      for (let j = i + 1; j < n; j += 1) {
        sum += p[j] * r ** (i - j - 1);
      }

      sum = Math.round(100000000 * sum) / 100000000;
      q.push(sum);
    }
    return new Polynomial(q, this.variable);
  }

  /**
   * Returns The Graph For The Intersections
   *
   * @returns [graph]
   *
   * @example
   * const p2 = new MCMaths.Polnomial([4,1,-1,-3.5,1]);
   * question.addGraph("question", p2.graph());
   */
  graph() {
    const graphSize = this.plottingRange();
    const graph = new Graph(...graphSize);
    graph.plot(this.functionString(), graphSize[1], graphSize[0]);
    return graph;
  }

  // Returns the function as a string for Dec's new function input.
  functionString() {
    const n = this.coefficients.length - 1;
    let out = `${this.coefficients[0]}*x**${n}`;
    for (let i = 1; i <= n; i += 1) {
      out += `+${this.coefficients[i]}*x**${n - i}`;
    }
    return out;
  }
}

export { Polynomial };
