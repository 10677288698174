import { Graph } from "../MCQuestion";
/**
 * Normal Distribution Models.
 * @memberof module:MCMaths
 * @author James Pickersgill
 */

class Normal {
  /**
   * Creates a Normal Distribution Object.
   * @param {number} mean Mean.
   * @param {number} sd Standard Deviation.
   */
  constructor(mean = 0, sd = 1) {
    this.mean = mean;
    this.sd = sd;
  }

  /**
   * Outputs a string in the form $N($mean,sd$)$.
   *
   * @returns {string}
   */
  toString() {
    return `N(${this.mean},${this.sd}^2)`;
  }

  /**
   * Returns the pdf of the normal at the given value of $x$.
   *
   * @param {number} x
   *
   * @returns {number}
   */
  pdf(x) {
    return (
      Math.exp(-0.5 * ((x - this.mean) / this.sd) ** 2) /
      (this.sd * Math.sqrt(2 * Math.PI))
    );
  }

  /**
   * Returns the cdf of the normal at the given value of $x$.
   *
   * @param {number} x
   *
   * @returns {number}
   */
  cdf(x1) {
    const x = (x1 - this.mean) / this.sd;
    const t = 1 / (1 + 0.2315419 * Math.abs(x));
    const d = 0.3989423 * Math.exp((-x * x) / 2);
    let prob =
      d *
      t *
      (0.3193815 +
        t * (-0.3565638 + t * (1.781478 + t * (-1.821256 + t * 1.330274))));
    if (x > 0) prob = 1 - prob;
    return prob;
  }

  /**
   * Returns a sample from the distribution.
   *
   * @returns {number}
   */
  sample() {
    const u = Math.random() * 2 * Math.PI;
    const e = -2 * Math.log(Math.random());
    return this.sd * Math.sqrt(e) * Math.sin(u) + this.mean;
  }

  /**
   * Returns an array of sample from the distribution.
   *
   * @param {number} n The number of samples to generate
   *
   * @returns {number[]}
   */
  samples(n) {
    const out = [];
    for (let i = 0; i < n; i += 1) {
      out.push(this.sample());
    }
    return out;
  }

  /**
   * Returns a graph of the distribution.
   *
   * @param {number} x0 A verticle line plotted on the graph at $x=x0$.
   * @param {number} x1 A verticle line plotted on the graph at $x=x1$.
   *
   * @returns {Graph}
   */
  graph(x0 = "none", x1 = "none") {
    const xlower = Math.ceil(this.mean - this.sd * 3);
    const xupper = Math.ceil(this.mean + this.sd * 3);
    const p = this.pdf(this.mean);
    const pr = Math.ceil(p * 10) / 10;
    const graph = new Graph(xupper, xlower, pr, 0, 1, 0.1);

    graph.plot(
      `Math.exp(-0.5 * ((x - ${this.mean}) / ${this.sd}) ** 2) /
            (${this.sd} * Math.sqrt(2 * Math.PI))`,
      xupper,
      xlower
    );

    if (x0 !== "none") {
      const xh = this.pdf(x0);
      graph.addParametric(`${x0} + 0 * t`, `t * ${xh}`, 0, 1);
    }
    if (x1 !== "none") {
      const xh = this.pdf(x1);
      graph.addParametric(`${x1} + 0 * t`, `t * ${xh}`, 0, 1);
    }

    return graph;
  }

  /**
   * Returns the Inverse Normal for a probability 0<p<1.
   *
   * @param {number} p The probability to use.
   * @param {number} [accuracy] Number of terms to exapand to for the taylor series used. 1000 takes about 5ms so leave about there.
   *
   * @returns {number}
   */
  // https://en.wikipedia.org/wiki/Normal_distribution#Quantile_function
  inverseNormal(p, accuracy = 1000) {
    const x = 2 * p - 1;
    // https://en.wikipedia.org/wiki/Error_function#Inverse_functions
    const c = [1];
    for (let i = 1; i < accuracy; i += 1) {
      let sum = 0;
      for (let m = 0; m <= i - 1; m += 1) {
        sum += (c[m] * c[i - 1 - m]) / ((m + 1) * (2 * m + 1));
      }
      c.push(sum);
    }
    let finalSum = 0;
    for (let i = 0; i < accuracy; i += 1) {
      finalSum +=
        (c[i] / (2 * i + 1)) * ((Math.sqrt(Math.PI) * x) / 2) ** (2 * i + 1);
    }
    return this.mean + Math.sqrt(2) * this.sd * finalSum;
  }
}

export { Normal };
