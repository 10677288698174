import { NumberSetError, ArgumentError } from "../MCError";
/**
 * @summary Returns a random integer between min (inclusive) and max (inclusive).
 *
 * @description Returns a value which is no lower than min and no greater than max. The returned value could be equal to min or max.
 *
 * @since 0.1.0
 *
 * @memberof module:MCRandom
 *
 * @param   {number} min        An integer which the returned value should be greater than or equal to.
 * @param   {number} max        An integer which the returned value should be less than or equal to.
 * @param   {number} [step=1]       The step in which possible values will occur, starting from min.
 * @returns {number}            A random number, greater than or equal to min and less than or equal to max.
 *                              If step is present then will be min + a multiple of step.
 * @throws  {NumberSetError}    Arguments are numbers but not integers.
 * @throws  {TypeError}         Arguments are not numbers.
 * @throws  {ArgumentError}     Argument max should be greater than or equal to min. Argument step should be greater than 0.
 *
 * @example
 * // random integer between 10 and 20
 * const n = MCRandom.randomInt(10, 20);
 * // random even integer between 0 and 20 (inclusive)
 * const even = MCRandom.randomInt(0, 20, 2);
 */
function randomInt(min, max, step) {
  if (typeof min !== "number") {
    throw new TypeError(`expected an integer but got ${typeof min} instead`);
  }
  if (typeof max !== "number") {
    throw new TypeError(`expected an integer but got ${typeof min} instead`);
  }
  if (!Number.isInteger(min)) {
    throw new NumberSetError(
      `expected number belonging to the set of integers but got ${min} instead`
    );
  }
  if (!Number.isInteger(max)) {
    throw new NumberSetError(
      `expected number belonging to the set of integers but got ${max} instead`
    );
  }
  if (max < min) {
    throw new ArgumentError(`expected max to be greater than or equal to min`);
  }
  if (typeof step === "undefined") {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  if (typeof step !== "number") {
    throw new TypeError(`expected an integer but got ${typeof step} isntead`);
  }
  if (!Number.isInteger(step)) {
    throw new NumberSetError(
      `expected number belonging to the set of integers but got ${step} instead`
    );
  }
  if (step < 1) {
    throw new ArgumentError(
      `expected a step greater than or equal to 1 but got ${step} instead`
    );
  }
  const possibleNumbers = [min];
  for (let i = min; i <= max - step; i += step) {
    possibleNumbers.push(i + step);
  }
  const chosen = possibleNumbers[randomInt(0, possibleNumbers.length - 1)];
  return chosen;
}

export { randomInt };
