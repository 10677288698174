import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
// eslint-disable-next-line import/no-cycle
import QuestionDisplay from "../QuestionDisplay";
import * as questions from "../../system/questions/index";
import Alert from "../Alert";

const TryAgain = () => {
  const location = useLocation();
  const history = useHistory();
  const [question, setQuestion] = useState({});
  const [alert, setAlert] = useState("");
  useEffect(() => {
    let q;
    try {
      q = questions[
        `${location.state.level}_${location.state.major.replace(
          /\s+/g,
          ""
        )}_${location.state.minor.replace(/\s+/g, "")}_${
          location.state.type
        }_Q${location.state.number}`
      ]();
    } catch (e) {
      setAlert("A problem occurred.");
    }
    setQuestion(q);
  }, [location.state]);

  return (
    <div className="container">
      <div className="text-center my-4">
        <h4>Try Again</h4>
      </div>

      {alert === "" ? (
        <QuestionDisplay
          question={question}
          questionName={`${location.state.level}_${location.state.major.replace(
            /\s+/g,
            ""
          )}_${location.state.minor.replace(/\s+/g, "")}_${
            location.state.type
          }_Q${location.state.number}`}
          next={() => history.push("/review")}
          nextText="Go Back"
          repeat
        />
      ) : (
        <div className="my-4">
          <Alert variant="danger">{alert}</Alert>
        </div>
      )}
    </div>
  );
};

export default TryAgain;
