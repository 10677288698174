import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import RoundedBtn from "./RoundedBtn";
import StyledInput from "./StyledInput";

const AddUserToSchoolModal = ({ show, onHide, addUser, emails }) => {
  const [email, setEmail] = useState("");
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Assign User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StyledInput
          value={email}
          handleChange={(val) => setEmail(val)}
          autoCompleteOptions={emails}
          label="User Email"
          width={-1}
        />
      </Modal.Body>
      <Modal.Footer>
        <RoundedBtn
          text="Add User"
          variant="light"
          onClick={() => {
            addUser(email);
            setEmail("");
          }}
          width={12}
        />
      </Modal.Footer>
    </Modal>
  );
};

AddUserToSchoolModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
  emails: PropTypes.arrayOf(PropTypes.string).isRequired,
};

AddUserToSchoolModal.defaultProps = {
  show: false,
};

export default AddUserToSchoolModal;
