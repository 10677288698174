/**
 * Product Rule.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Input two functions, will return the derivative of their product.
 * // I.e. for $2e^{3x}\\sin(x)$, you would use two functions, $2e^{3x}$ and $\\sin(x)$.
 * let p1 = new Exponential(2,'e',3)
 * let s1 = new Trig(1,'sin',1,1)
 * let cr = new ProductRule(p1,s1)
 */

class ProductRule {
  constructor(func1, func2) {
    this.func1 = func1;
    this.func2 = func2;
  }

  /**
   * Prints the product of functions as a string.
   * @returns {string}
   */
  toString() {
    let output = "";
    if (this.func1.constructor.name === "Polynomial") {
      output += `\\left(${this.func1.toString()}\\right)`;
    } else {
      output += this.func1.toString();
    }

    if (this.func2.constructor.name === "Polynomial") {
      output += `\\left(${this.func2.toString()}\\right)`;
    } else {
      output += this.func2.toString();
    }
    return output;
  }

  /**
   * Returns the derivative of the product of functions as a string.
   * @returns {string}
   * @example
   * let p1 = new Exponential(2,'e',3)
   * let s1 = new Trig(1,'sin',1,1,'h')
   * let cr = new ChainRule(p1,s1)
   * console.log('Chain Derivative: $'+cr.derivative()+'$')
   */
  derivative() {
    return `${new ProductRule(
      this.func1.derivative(),
      this.func2
    ).toString()}+${new ProductRule(
      this.func1,
      this.func2.derivative()
    ).toString()}`;
  }

  /**
   * Returns the working for the rule
   *
   * @returns {string[]}
   */
  derivativeWorking() {
    const output = [
      `HEADING Using the product rule:`,
      `$f(x)=${this.func1.toString()}$`,
      `$f'(x)=${this.func1.derivative().toString()}$`,
      `$g(x)=${this.func2.toString()}$`,
      `$g'(x)=${this.func2.derivative().toString()}$`,
      `$\\big(f(x)g(x)\\big)'=f'(x)g(x)+f(x)g'(x)$`,
      `$=${this.derivative()}$`,
    ];
    return output;
  }

  /**
   * Evaluates the derivative
   *
   * @param {number} x
   *
   * @returns {number}
   */
  evaluate(x) {
    return (
      this.func1.derivative().evaluate(x) * this.func2.evaluate(x) +
      this.func1.evaluate(x) * this.func2.derivative().evaluate(x)
    );
  }
}

export { ProductRule };
