import React from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import RoundedBtn from "./RoundedBtn";

const DeleteRelationModal = ({
  show,
  onHide,
  onAction,
  personName,
  entity,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {entity === "sadmin" && "Remove Staff Member"}
          {entity === "teacher" && "Remove Teacher"}
          {entity === "pupil" && "Remove Pupil"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {entity === "sadmin" ? (
          <b>{`Are you sure you want to remove ${personName} from this school?`}</b>
        ) : (
          <b>
            {`By removing this ${
              entity === "pupil" ? "student" : "teacher"
            } you are withdrawing ${
              entity === "pupil" ? "your" : "their"
            } access to ${
              entity === "pupil" ? "their" : "your"
            } data. Are you sure you want to remove ${personName}?`}
          </b>
        )}
      </Modal.Body>
      <Modal.Footer>
        <RoundedBtn text="Close" onClick={onHide} />
        <RoundedBtn text="Remove" onClick={onAction} variant="dark" width={8} />
      </Modal.Footer>
    </Modal>
  );
};

DeleteRelationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  personName: PropTypes.string,
  entity: PropTypes.string.isRequired,
};

DeleteRelationModal.defaultProps = {
  personName: "",
};

export default DeleteRelationModal;
