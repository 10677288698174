import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Dropdown from "./Dropdown";
import RoundedBtn from "./RoundedBtn";
import Alert from "./Alert";
import NoQuestions from "../system/topics/NoQuestions";
import { getSiteID } from "../system/utils/getSite";

const TopicSelection = ({ content, handleChoose }) => {
  const [level, setLevel] = useState(0);
  const [major, setMajor] = useState("");
  const [minor, setMinor] = useState("");
  const [error, setError] = useState("");
  const [topics, setTopics] = useState(content);
  const [levelOptions, setLevelOptions] = useState([]);

  const site = getSiteID();

  const handleSubmit = () => {
    // do some validation for topic triple here
    // check topic was passed in through content
    let found = false;
    for (let i = 0; i < content.length; i += 1) {
      const topic = content[i];
      if (
        topic.level === level &&
        topic.major === major &&
        topic.minor === minor
      ) {
        found = true;
      }
    }
    if (!found) {
      setError("Please form a valid selection.");
    } else {
      handleChoose(`${level}_${major}_${minor}`);
    }
  };

  useEffect(() => {
    let foundAS = false;
    let foundA2 = false;
    let foundNumber = false;
    let foundAlgebra = false;
    let foundRatio = false;
    let foundGeometry = false;
    let foundProb = false;

    for (let i = 0; i < content.length; i += 1) {
      const topic = content[i];
      if (topic.level === "AS") {
        foundAS = true;
      } else if (topic.level === "A2") {
        foundA2 = true;
      } else if (topic.level === "Number") {
        foundNumber = true;
      } else if (topic.level === "Algebra") {
        foundAlgebra = true;
      } else if (topic.level === "RatioProportionAndRatesOfChange") {
        foundRatio = true;
      } else if (topic.level === "Geometry") {
        foundGeometry = true;
      } else if (topic.level === "ProbabilityAndStatistics") {
        foundProb = true;
      }
    }
    const opts = [];
    if (foundAS) {
      opts.push({ id: 0, title: "AS", value: "AS" });
    }
    if (foundA2) {
      opts.push({ id: 1, title: "A2", value: "A2" });
    }
    if (foundNumber) {
      opts.push({ id: 0, title: "Number", value: "Number" });
    }
    if (foundAlgebra) {
      opts.push({ id: 1, title: "Algebra", value: "Algebra" });
    }
    if (foundRatio) {
      opts.push({
        id: 2,
        title: "RatioProportionAndRatesOfChange",
        value: "RatioProportionAndRatesOfChange",
      });
    }
    if (foundGeometry) {
      opts.push({ id: 3, title: "Geometry", value: "Geometry" });
    }
    if (foundProb) {
      opts.push({
        id: 4,
        title: "ProbabilityAndStatistics",
        value: "ProbabilityAndStatistics",
      });
    }
    setLevelOptions(opts);
  }, [content]);

  useEffect(() => {
    let tops = [...content];
    const noqs = [];
    for (let i = 0; i < NoQuestions.length; i += 1) {
      noqs.push(
        `${NoQuestions[i].level}_${NoQuestions[i].major}_${NoQuestions[i].minor}`
      );
    }
    tops = tops.filter(
      (t) => !noqs.includes(`${t.level}_${t.major}_${t.minor}`)
    );
    if (level !== 0) {
      for (let i = 0; i < tops.length; i += 1) {
        const topic = tops[i];
        if (topic.level !== level) {
          tops.splice(i, 1);
          i -= 1;
        }
      }
    }
    setTopics(tops);
  }, [level, content]);

  useEffect(() => {
    setMajor("");
    setMinor("");
  }, [level]);

  useEffect(() => {
    setMinor("");
  }, [major]);

  const display = (str) => {
    let theTitle = _.startCase(str);
    theTitle = theTitle.replace("And", "and");
    theTitle = theTitle.replace("By", "by");
    theTitle = theTitle.replace("Of", "of");
    theTitle = theTitle.replace("Newtons", "Newton's");
    theTitle = theTitle.replace("A 2 ", "");
    theTitle = theTitle.replace("3 D", "3D");
    theTitle = theTitle.replace("Counter Example", "Counterexample");
    return theTitle;
  };

  const getMajors = () => {
    const majors = [];
    const values = [];
    for (let i = 0; i < topics.length; i += 1) {
      const topic = topics[i];
      if (!values.includes(topic.major)) {
        majors.push({ id: i, title: display(topic.major), value: topic.major });
        values.push(topic.major);
      }
    }
    return majors;
  };

  const getMinors = () => {
    const tops = [...topics];
    if (major !== "") {
      for (let i = 0; i < tops.length; i += 1) {
        const topic = tops[i];
        if (topic.major !== major) {
          tops.splice(i, 1);
          i -= 1;
        }
      }
    }
    const minors = [];
    const values = [];
    for (let i = 0; i < tops.length; i += 1) {
      const topic = tops[i];
      if (!values.includes(topic.minor)) {
        minors.push({ id: i, title: display(topic.minor), value: topic.minor });
        values.push(topic.minor);
      }
    }
    return minors;
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-4 my-1">
          <div className="text-center">
            <Dropdown
              // eslint-disable-next-line no-nested-ternary
              title={level === 0 ? (site === 0 ? "Area" : "Level") : level}
              width={-1}
              options={levelOptions}
              handleChange={setLevel}
            />
          </div>
        </div>

        <div className="col-md-4 my-1">
          <div className="text-center">
            <Dropdown
              title={
                // eslint-disable-next-line no-nested-ternary
                major === "" ? (site === 0 ? "Topic" : "Area") : display(major)
              }
              width={-1}
              handleChange={setMajor}
              options={getMajors()}
              disabled={level === 0 || getMajors().length === 0}
            />
          </div>
        </div>
        <div className="col-md-4 my-1">
          <div className="text-center">
            <Dropdown
              title={
                // eslint-disable-next-line no-nested-ternary
                minor === "" ? (site === 0 ? "Skill" : "Topic") : display(minor)
              }
              width={-1}
              handleChange={setMinor}
              options={getMinors()}
              disabled={major === "" || getMinors().length === 0}
            />
          </div>
        </div>
      </div>
      {error !== "" && (
        <div className="mt-2 text-center">
          <Alert variant="danger">
            <span>{error}</span>
          </Alert>
        </div>
      )}
      <div className="row my-1">
        <div className="col-12">
          <RoundedBtn
            width={-1}
            text="Choose Topic"
            variant="light"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

TopicSelection.propTypes = {
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleChoose: PropTypes.func.isRequired,
};

export default TopicSelection;
