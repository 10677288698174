import { Fraction } from "./Fraction";
// TODO:
// ADD EVALUTATION FUNCTION.
/**
 * Trig Objects.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Creates a new trig of $0.5\\sin(2x)$
 * const s1 = new Trig(0.5,'sin',1,2)
 */

class Trig {
  /**
   * Creates a trig object in the form $a*trig1^b(cx+d)*trig2^e(cx+d)$, where
   * a -      coefficient
   * trig1 -  type
   * b -      power
   * c -      argumentCoefficient
   * x -      variable
   * d -      argumentConstant
   * trig2 -  type2
   * e -      power2
   *
   * @param {number} [coefficient = 1]            The coefficient of the object.
   * @param {string} [type='sin']                 The type of trig object, currently sin, cos, tan, sec, cosec, cot.
   * @param {number} [power=1]                    The power of the trig object.
   * @param {number} [argumentCoefficient='1']    The coefficient of the argument.
   * @param {string} [variable='x']               The variable of the object.
   * @param {number} [argumentCoefficient = 0]    Any constant to add to the variable.
   * @param {string} [type2 = 'none']             A second trig function to multiply the first by, only use none, sin, cos, when creating a new trig objects. This is used mainly for returning derivatives.
   * @param {number} [power2=1]                   The power of the second trig function.
   *
   * @returns {Trig}      A new trig object.
   */
  constructor(
    coefficient = 1,
    type = "sin",
    power = 1,
    argumentCoefficient = 1,
    variable = "x",
    argumentConstant = 0,
    type2 = "none",
    power2 = 1
  ) {
    this.coefficient = coefficient;
    this.type = type;
    this.power = power;
    this.argumentCoefficient = argumentCoefficient;
    this.variable = variable;
    this.argumentConstant = argumentConstant;

    this.type2 = type2;
    this.power2 = power2;
  }

  /**
   * Prints the trig object as a string.
   * @returns {string}
   */
  toString() {
    let out = "";
    if (this.coefficient !== 1) {
      if (this.coefficient === -1) {
        out += "-";
      } else {
        out += new Fraction(this.coefficient).toString();
      }
    }
    out += `\\${this.type}`;
    if (this.power !== 1) {
      out += `^${this.power}`;
    }
    out += "\\left(";
    if (this.argumentCoefficient !== 1) {
      if (this.argumentCoefficient === -1) {
        out += "-";
      } else {
        out += new Fraction(this.argumentCoefficient).toString();
      }
    }
    out += this.variable;
    if (this.argumentConstant < 0) {
      out += new Fraction(this.argumentConstant).toString();
    }
    if (this.argumentConstant > 0) {
      out += `+${new Fraction(this.argumentConstant).toString()}`;
    }
    out += "\\right)";

    if (this.type2 !== "none") {
      out += this.type2;
      if (this.power2 !== 1) {
        out += `^${this.power2}`;
      }
      out += "\\left(";
      if (this.argumentCoefficient !== 1) {
        if (this.argumentCoefficient === -1) {
          out += "-";
        } else {
          out += new Fraction(this.argumentCoefficient).toString();
        }
      }
      out += this.variable;
      if (this.argumentConstant < 0) {
        out += new Fraction(this.argumentConstant).toString();
      }
      if (this.argumentConstant > 0) {
        out += `+${new Fraction(this.argumentConstant).toString()}`;
      }
      out += "\\right)";
    }

    return out;
  }

  /**
   * Returns the derivative of trig functions.
   *
   * @decription Currently works for sin, cos, tan, sec, cosec, cot and powers of sin/cos.
   *
   * @returns {Trig} A new trig object
   */
  derivative() {
    if (this.power === 1) {
      if (this.type === "sin" && this.type2 === "none") {
        return new Trig(
          this.coefficient * this.argumentCoefficient,
          "cos",
          1,
          this.argumentCoefficient,
          this.variable,
          this.argumentConstant
        );
      }
      if (this.type === "cos" && this.type2 === "none") {
        return new Trig(
          -this.coefficient * this.argumentCoefficient,
          "sin",
          1,
          this.argumentCoefficient,
          this.variable,
          this.argumentConstant
        );
      }
      if (this.type === "tan" && this.type2 === "none") {
        return new Trig(
          this.coefficient * this.argumentCoefficient,
          "sec",
          2,
          this.argumentCoefficient,
          this.variable,
          this.argumentConstant
        );
      }
      if (this.type === "sec" && this.type2 === "none") {
        return new Trig(
          this.coefficient * this.argumentCoefficient,
          "sec",
          1,
          this.argumentCoefficient,
          this.variable,
          this.argumentConstant,
          "tan",
          1
        );
      }
      if (this.type === "cosec" && this.type2 === "none") {
        return new Trig(
          -this.coefficient * this.argumentCoefficient,
          "cosec",
          1,
          this.argumentCoefficient,
          this.variable,
          this.argumentConstant,
          "cot",
          1
        );
      }
      if (this.type === "cot" && this.type2 === "none") {
        return new Trig(
          -this.coefficient * this.argumentCoefficient,
          "cosec",
          2,
          this.argumentCoefficient,
          this.variable,
          this.argumentConstant
        );
      }
    }
    if (this.power > 1) {
      if (this.type === "sin" && this.type2 === "none") {
        return new Trig(
          this.coefficient * this.argumentCoefficient * this.power,
          "sin",
          this.power - 1,
          this.argumentCoefficient,
          this.variable,
          this.argumentConstant,
          "cos",
          1
        );
      }
      if (this.type === "cos" && this.type2 === "none") {
        return new Trig(
          -this.coefficient * this.argumentCoefficient * this.power,
          "cos",
          this.power - 1,
          this.argumentCoefficient,
          this.variable,
          this.argumentConstant,
          "sin",
          1
        );
      }
    }
    return new Trig();
  }

  /**
   * Returns the integral of trig functions.
   *
   * @decription Currently works for sin, cos.
   *
   * @returns {Trig} A new trig object
   */
  integral() {
    if (this.power === 1) {
      if (this.type === "sin" && this.type2 === "none") {
        return new Trig(
          -this.coefficient / this.argumentCoefficient,
          "cos",
          1,
          this.argumentCoefficient,
          this.variable,
          this.argumentConstant
        );
      }
      if (this.type === "cos" && this.type2 === "none") {
        return new Trig(
          this.coefficient / this.argumentCoefficient,
          "sin",
          1,
          this.argumentCoefficient,
          this.variable,
          this.argumentConstant
        );
      }
    }
    return "NOT YET IMPLIMENTED, contact James if you want this added.";
  }

  evaluate(x) {
    if (this.type2 === "none") {
      if (this.type === "sin") {
        return (
          this.coefficient *
          Math.sin(this.argumentCoefficient * x + this.argumentConstant) **
            this.power
        );
      }
      if (this.type === "cos") {
        return (
          this.coefficient *
          Math.cos(this.argumentCoefficient * x + this.argumentConstant) **
            this.power
        );
      }
      if (this.type === "tan") {
        return (
          this.coefficient *
          Math.tan(this.argumentCoefficient * x + this.argumentConstant) **
            this.power
        );
      }
      if (this.type === "cosec") {
        return (
          this.coefficient *
          Math.sin(this.argumentCoefficient * x + this.argumentConstant) **
            -this.power
        );
      }
      if (this.type === "sec") {
        return (
          this.coefficient *
          Math.cos(this.argumentCoefficient * x + this.argumentConstant) **
            -this.power
        );
      }
      if (this.type === "cot") {
        return (
          this.coefficient *
          Math.tan(this.argumentCoefficient * x + this.argumentConstant) **
            -this.power
        );
      }
    }
    return "NOT YET IMPLIMENTED, contact James";
  }

  functionString() {
    const temp = `${this.coefficient}*Math.sin(${this.argumentCoefficient}*x+${this.argumentConstant})**${this.power}`;
    if (this.type === "sin" && this.type2 === "none") {
      return temp;
    }
    if (this.type === "cos" && this.type2 === "none") {
      return temp.replace("sin", "cos");
    }
    if (this.type === "tan" && this.type2 === "none") {
      return temp.replace("sin", "tan");
    }
    if (this.type === "cosec" && this.type2 === "none") {
      return `1/(${temp})`;
    }
    if (this.type === "sec" && this.type2 === "none") {
      return `1/(${temp.replace("sin", "cos")})`;
    }
    if (this.type === "cot" && this.type2 === "none") {
      return `1/(${temp.replace("sin", "tan")})`;
    }
    return "NOT YET IMPLIMENTED, contact James";
  }
}

export { Trig };
