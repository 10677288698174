import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as jwt from "jsonwebtoken";
import axios from "axios";
import Error404 from "./components/pages/Error404";
import Navigation from "./components/Navigation";
// eslint-disable-next-line import/no-cycle
import Topics from "./components/pages/Topics";
// eslint-disable-next-line import/no-cycle
import WorkSmart from "./components/pages/WorkSmart";
import Development from "./components/pages/Development";
import GCSEDevelopment from "./components/pages/GCSEDevelopment";
import ModuleTest from "./components/pages/ModuleTest";
// eslint-disable-next-line import/no-cycle
import History from "./components/pages/History";
import LandingPage from "./components/pages/LandingPage";
import Register from "./components/pages/Register";
import Account from "./components/pages/Account";
import ProtectedRoute from "./components/ProtectedRoute";
import SignIn from "./components/pages/SignIn";
// eslint-disable-next-line import/no-cycle
import StudentMenu from "./components/pages/StudentMenu";
// eslint-disable-next-line import/no-cycle
import YourChoice from "./components/pages/YourChoice";
import ChangePassword from "./components/pages/ChangePassword";
import Teachers from "./components/pages/Teachers";
import Admin from "./components/pages/Admin";
import Footer from "./components/Footer";
// eslint-disable-next-line import/no-cycle
import TryAgain from "./components/pages/TryAgain";
import StaffMenu from "./components/pages/StaffMenu";
import checkToken from "./system/auth/checkToken";
import AuthVerifier from "./components/AuthVerifier";
import exampleData from "./system/dummy/examplePupil";
import Terms from "./components/pages/Terms";
import useWindowDimensions from "./system/hooks/useWindowDimensions";
import Privacy from "./components/pages/Privacy";
import ForgotPassword from "./components/pages/ForgotPassword";
import PasswordReset from "./components/pages/PasswordReset";
import { getSiteID } from "./system/utils/getSite";

export const AppContext = React.createContext({
  pupilData: { content: [], answers: [] },
  setPupilData: () => {},
  role: "",
  adminData: { schools: [], users: [] },
  setAdminData: () => {},
});

const App = () => {
  const site = getSiteID();
  if (site === 0) document.title = "MrCarterMaths: GCSE";
  if (site === 1) document.title = "MrCarterMaths: A-Level";
  const { width } = useWindowDimensions();
  const [authenticated, setAuthenticated] = useState(checkToken());
  const [role, setRole] = useState(
    localStorage.getItem("mcmToken") !== null
      ? jwt.decode(localStorage.getItem("mcmToken")).role
      : ""
  );
  const [userRelatedData, setUserRelatedData] = useState({});
  const [pupilData, setPupilData] = useState({
    content: null,
    answers: null,
  });
  const pupilValue = {
    pupilData,
    setPupilData,
    role:
      localStorage.getItem("mcmToken") !== null
        ? jwt.decode(localStorage.getItem("mcmToken")).role
        : "",
  };
  const [adminData, setAdminData] = useState({});
  const adminValue = {
    adminData,
    setAdminData,
    role:
      localStorage.getItem("mcmToken") !== null
        ? jwt.decode(localStorage.getItem("mcmToken")).role
        : "",
  };
  const [staffData, setStaffData] = useState({});
  // 1 for staff view, 0 for pupil view
  const [staffView, setStaffView] = useState(1);

  const checkAuth = () => {
    if (localStorage.getItem("mcmToken") !== null) {
      setRole(jwt.decode(localStorage.getItem("mcmToken")).role);
      setAuthenticated(true);
    } else {
      setRole(null);
      setAuthenticated(false);
    }
  };

  const removeAccountNotifs = (remaining) => {
    const data = { ...userRelatedData };
    data.shareInvites = remaining;
    if (data.shareInvites === 0) {
      delete data.shareInvites;
    }
    setUserRelatedData(data);
  };

  const displayMessage = () => {
    if (typeof userRelatedData.error !== "undefined") {
      return userRelatedData.error;
    }
    if (userRelatedData.shareInvites > 0) {
      return "A teacher has requested for you to share your data with them. Go to your account now to check it out!";
    }
    return "";
  };

  useEffect(() => {
    const relationsEndpoint = `${process.env.REACT_APP_DOMAIN}/relations`;
    const contentEndpoint = `${process.env.REACT_APP_DOMAIN}/content`;
    const answersEndpoint = `${process.env.REACT_APP_DOMAIN}/answers`;
    if (authenticated && (role.includes("PUPIL") || role === "DEV")) {
      const relationsResponse = axios.get(relationsEndpoint, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
        params: {
          site,
        },
      });
      const contentResponse = axios.get(contentEndpoint, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
      });
      const answersResponse = axios.get(answersEndpoint, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
      });
      axios
        .all([relationsResponse, contentResponse, answersResponse])
        .then(
          axios.spread((...responses) => {
            const relations = responses[0].data;
            const content = responses[1].data;
            const answers = responses[2].data;
            // use/access the results
            let count = 0;
            for (let i = 0; i < relations.length; i += 1) {
              const relation = relations[i];
              if (
                !relation.confirmed &&
                relation.createdBy !==
                  jwt.decode(localStorage.getItem("mcmToken")).userId
              ) {
                count += 1;
              }
            }
            setUserRelatedData((data) => ({
              ...data,
              shareInvites: count,
            }));
            setPupilData({ content, answers });
          })
        )
        .catch(() => {
          setUserRelatedData((data) => ({
            ...data,
            error:
              "We lost the connection. Please check your internet connection.",
          }));
        });
    }
    if (authenticated && role === "SITE_ADMIN") {
      const usersEndpoint = `${process.env.REACT_APP_DOMAIN}/admin-user`;
      const schoolsEndpoint = `${process.env.REACT_APP_DOMAIN}/schools`;
      const analyticsEndpoint1 = `${process.env.REACT_APP_DOMAIN}/admin-analytics/mostActiveSchool`;
      const analyticsEndpoint2 = `${process.env.REACT_APP_DOMAIN}/admin-analytics/totalQuestions`;
      const usersResponse = axios.get(usersEndpoint, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
      });
      const schoolsResponse = axios.get(schoolsEndpoint, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
      });
      const analyticsResponse1 = axios.get(analyticsEndpoint1, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
      });
      const analyticsResponse2 = axios.get(analyticsEndpoint2, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
      });
      axios
        .all([
          usersResponse,
          schoolsResponse,
          analyticsResponse1,
          analyticsResponse2,
        ])
        .then(
          axios.spread((...responses) => {
            const users = responses[0].data;
            const schools = responses[1].data;
            const analytics1 = responses[2].data;
            const analytics2 = responses[3].data;
            setAdminData({
              users,
              schools,
              analytics: { mostActive: analytics1[0], totals: analytics2 },
            });
          })
        )
        .catch((e) => {
          setAdminData({
            error: `Error ${e.response.status}: ${e.response.data.error}`,
          });
        });
    }
    if (authenticated && (role === "STAFF" || role === "STAFF_ADMIN")) {
      const contentResponse = axios.get(contentEndpoint, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
      });
      const questionAnswersResponse = axios.get(answersEndpoint, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
      });
      const pupilsAnswersEndpoint = `${process.env.REACT_APP_DOMAIN}/answers/pupils`;
      const pupilsEndpoint = `${process.env.REACT_APP_DOMAIN}/relations`;
      const pupilClassesEndpoint = `${process.env.REACT_APP_DOMAIN}/classes`;
      const answersResponse = axios.get(pupilsAnswersEndpoint, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
      });
      const pupilsResponse = axios.get(pupilsEndpoint, {
        params: { site },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
      });
      const pupilClassesResponse = axios.get(pupilClassesEndpoint, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
      });
      const staffEndpoint = `${process.env.REACT_APP_DOMAIN}/staff-admin`;
      const staffResponse =
        role === "STAFF_ADMIN"
          ? axios.get(staffEndpoint, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
              },
            })
          : Promise.resolve();
      const classesEndpoint = `${process.env.REACT_APP_DOMAIN}/staff-admin/classes`;
      const classesResponse =
        role === "STAFF_ADMIN"
          ? axios.get(classesEndpoint, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
              },
            })
          : Promise.resolve();
      axios
        .all([
          answersResponse,
          pupilsResponse,
          pupilClassesResponse,
          staffResponse,
          classesResponse,
          contentResponse,
          questionAnswersResponse,
        ])
        .then(
          axios.spread((...responses) => {
            const answers = responses[0].data;
            const pupils = responses[1].data;
            const pupilClasses = responses[2].data;
            const staff = responses[3] ? responses[3].data : [];
            const classes = responses[4] ? responses[4].data : [];
            if (pupils.length === 0 && answers.length === 0) {
              setStaffData({ ...exampleData, pupilClasses, staff, classes });
            } else {
              setStaffData({ pupils, answers, staff, classes, pupilClasses });
            }
            const content = responses[5].data;
            const qAnswers = responses[6].data;
            setPupilData({ content, answers: qAnswers });
          })
        )
        .catch(() => {
          setStaffData((data) => ({
            ...data,
            error:
              "We lost the connection. Please check your internet connection.",
          }));
        });
    }
  }, [authenticated, role]);

  return (
    <AppContext.Provider
      value={role === "SITE_ADMIN" ? adminValue : pupilValue}
    >
      <Router>
        <Navigation
          isAuthenticated={authenticated}
          role={role}
          staffView={staffView}
          accountBadgeCount={userRelatedData.shareInvites}
        />
        <div style={{ paddingBottom: width > 750 ? "0px" : "60px" }}>
          <Switch>
            <Route exact path="/">
              {!authenticated && <LandingPage />}
              {authenticated && (role.includes("PUPIL") || role === "DEV") && (
                <StudentMenu
                  info={displayMessage()}
                  content={userRelatedData.content}
                  answers={userRelatedData.answers}
                />
              )}
              {authenticated &&
                (role === "STAFF" || role === "STAFF_ADMIN") &&
                staffView === 1 && (
                  <StaffMenu
                    data={staffData}
                    admin={role === "STAFF_ADMIN"}
                    setView={setStaffView}
                  />
                )}
              {authenticated &&
                (role === "STAFF" || role === "STAFF_ADMIN") &&
                staffView === 0 && (
                  <StudentMenu
                    info={displayMessage()}
                    content={userRelatedData.content}
                    answers={userRelatedData.answers}
                    role={role}
                    setView={setStaffView}
                  />
                )}
              {authenticated && role === "SITE_ADMIN" && (
                <Admin data={adminData} />
              )}
            </Route>
            <ProtectedRoute path="/work-smart" isAuthenticated={authenticated}>
              <WorkSmart />
            </ProtectedRoute>
            <ProtectedRoute path="/your-choice" isAuthenticated={authenticated}>
              <YourChoice />
            </ProtectedRoute>
            <ProtectedRoute
              path="/content-covered"
              isAuthenticated={authenticated}
            >
              <Topics
                showClassControls={
                  (role === "STAFF" || role === "STAFF_ADMIN") &&
                  staffView === 1
                }
              />
            </ProtectedRoute>
            <ProtectedRoute path="/try-again" isAuthenticated={authenticated}>
              {role === "PUPIL" ? <TryAgain /> : <Error404 />}
            </ProtectedRoute>
            <ProtectedRoute path="/review" isAuthenticated={authenticated}>
              <History />
            </ProtectedRoute>

            <Route path="/register">
              <Register
                handleAuthentication={checkAuth}
                isAuthenticated={authenticated}
              />
            </Route>
            <Route path="/login">
              <SignIn
                handleAuthentication={checkAuth}
                isAuthenticated={authenticated}
              />
            </Route>
            <ProtectedRoute
              path="/development/:questionID"
              isAuthenticated={authenticated}
            >
              {role === "DEV" || role === "SITE_ADMIN" ? (
                <Development />
              ) : (
                <Error404 />
              )}
            </ProtectedRoute>
            <ProtectedRoute
              path="/GCSEdevelopment/:questionID"
              isAuthenticated={authenticated}
            >
              {role === "DEV" || role === "SITE_ADMIN" ? (
                <GCSEDevelopment />
              ) : (
                <Error404 />
              )}
            </ProtectedRoute>
            <ProtectedRoute path="/account" isAuthenticated={authenticated}>
              <Account
                handleAuthentication={checkAuth}
                shareRequests={userRelatedData.shareInvites}
                role={role}
              />
            </ProtectedRoute>
            <ProtectedRoute path="/jp/modules" isAuthenticated={authenticated}>
              {role === "DEV" || role === "SITE_ADMIN" ? (
                <ModuleTest />
              ) : (
                <Error404 />
              )}
            </ProtectedRoute>
            <ProtectedRoute
              path="/change-password"
              isAuthenticated={authenticated}
            >
              <ChangePassword />
            </ProtectedRoute>
            <ProtectedRoute path="/teachers" isAuthenticated={authenticated}>
              {role === "PUPIL" ? (
                <Teachers removeNotifs={removeAccountNotifs} />
              ) : (
                <Error404 />
              )}
            </ProtectedRoute>
            <Route path="/terms">
              <Terms />
            </Route>
            <Route path="/privacy-policy">
              <Privacy />
            </Route>
            <Route path="/forgot-password/:token">
              {authenticated ? <Error404 /> : <PasswordReset />}
            </Route>
            <Route path="/forgot-password">
              {authenticated ? <Error404 /> : <ForgotPassword />}
            </Route>
            <Route>
              <Error404 />
            </Route>
          </Switch>
        </div>
        <Footer />
        <AuthVerifier setAuth={(val) => setAuthenticated(val)} />
      </Router>
    </AppContext.Provider>
  );
};

export default App;
