import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import ClipLoader from "react-spinners/ClipLoader";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Alert from "./Alert";
import PlainInput from "./PlainInput";
import RoundedBtn from "./RoundedBtn";
import AddUserToSchoolModal from "./AddUserToSchoolModal";
import checkToken from "../system/auth/checkToken";
import { AppContext } from "../App";

const SchoolModal = ({ show, onHide, schoolData, users, anon }) => {
  const { setAdminData, adminData } = useContext(AppContext);
  const [schoolValues, setSchoolValues] = useState({
    schoolName: {
      value: schoolData ? schoolData.schoolName : "",
      changed: false,
    },
    addressLine1: {
      value: schoolData ? schoolData.addressLine1 : "",
      changed: false,
    },
    addressLine2: {
      value: schoolData ? schoolData.addressLine2 : "",
      changed: false,
    },
    city: { value: schoolData ? schoolData.city : "", changed: false },
    postcode: { value: schoolData ? schoolData.postcode : "", changed: false },
    country: { value: schoolData ? schoolData.country : "", changed: false },
    email: { value: schoolData ? schoolData.email : "", changed: false },
    phone: { value: schoolData ? schoolData.phone : "", changed: false },
    capacity: { value: schoolData ? schoolData.capacity : "0", changed: false },
  });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({});
  const [userEmails, setUserEmails] = useState([]);
  const [adding, setAdding] = useState(false);
  const [userList, setUserList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const emails = [];
    for (let i = 0; i < users.length; i += 1) {
      const user = users[i];
      emails.push(user.email);
    }
    setUserEmails(emails);
  }, [users]);

  const startLoad = () => {
    return setTimeout(() => {
      setLoading(true);
    }, 500);
  };

  const stopLoad = (timer) => {
    clearTimeout(timer);
    setLoading(false);
  };

  const setError = (msg) => {
    setAlert({ variant: "danger", message: msg });
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const validate = () => {
    const errs = [];
    const allNumbers = /^[0-9]+$/;

    if (!validateEmail(schoolValues.email.value)) {
      errs.push("Please enter a valid email address");
    }
    if (!allNumbers.test(schoolValues.phone.value)) {
      errs.push("Invalid phone number.");
    }
    if (!allNumbers.test(schoolValues.capacity.value)) {
      errs.push("Please enter a valid capacity, 0 if unlimited.");
    }
    if (errs.length !== 0) {
      setError(errs[0]);
      return false;
    }
    return true;
  };

  const createSchool = async () => {
    const start = startLoad();
    setAlert({});
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    if (!validate()) {
      stopLoad();
      return false;
    }
    schoolValues.capacity.value = parseInt(schoolValues.capacity.value, 10);
    const d = {};
    const keys = Object.keys(schoolValues);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      d[key] = schoolValues[key].value;
    }
    d.users = userList;
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/schools`,
        d,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
          },
        }
      );
      setAdminData({ ...adminData, schools: [res.data, ...adminData.schools] });
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setError(JSON.stringify(e.response.data.error));
      } else {
        setError("A problem occurred. Please try again.");
      }
      stopLoad(start);
      return false;
    }
    stopLoad(start);
    setAlert({ variant: "success", message: "School has been added." });
    return true;
  };

  const editSchool = async () => {
    const start = startLoad();
    setAlert({});
    if (!checkToken()) {
      localStorage.removeItem("mcmToken");
      history.push("/login");
      return false;
    }
    if (!validate()) {
      stopLoad(start);
      return false;
    }
    schoolValues.capacity.value = parseInt(schoolValues.capacity.value, 10);
    const data = {};
    const keys = Object.keys(schoolValues);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (schoolValues[key].changed) {
        data[key] = schoolValues[key].value;
      }
    }
    if (Object.keys(data).length === 0) {
      setError("Nothing changed.");
      stopLoad(start);
      return false;
    }
    data.schoolId = schoolData.schoolId;
    try {
      await axios.patch(`${process.env.REACT_APP_DOMAIN}/schools`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("mcmToken")}`,
        },
      });
      const newVersion = {};
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        newVersion[key] = schoolValues[key].value;
      }
      newVersion.schoolId = schoolData.schoolId;
      newVersion.createdAt = schoolData.createdAt;
      const newSchools = [];
      for (let i = 0; i < adminData.schools.length; i += 1) {
        if (adminData.schools[i].schoolId === schoolData.schoolId) {
          newSchools.push(newVersion);
        } else {
          newSchools.push(adminData.schools[i]);
        }
      }
      setAdminData({ ...adminData, schools: newSchools });
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setError(JSON.stringify(e.response.data.error));
      } else {
        setError("A problem occurred. Please try again.");
      }
      stopLoad(start);
      return false;
    }
    stopLoad(start);
    setAlert({ variant: "success", message: "Details changed." });
    return true;
  };

  const addUserToList = (email) => {
    setAlert({});
    setAdding(false);
    if (userList.includes(email)) {
      setAlert({ variant: "danger", message: "Already added to list." });
    } else if (!userEmails.includes(email)) {
      setAlert({ variant: "danger", message: "User doesn't exist locally." });
    } else {
      setUserList([email, ...userList]);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <AddUserToSchoolModal
        onHide={() => setAdding(false)}
        addUser={addUserToList}
        emails={userEmails}
        show={adding}
      />
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {schoolData ? "Edit School" : "Add School"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="my-5 text-center">
            <ClipLoader loading color="#74c5ed" size="3em" />
          </div>
        ) : (
          <div>
            {alert.message && (
              <Alert variant={alert.variant}>{alert.message}</Alert>
            )}
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <PlainInput
                    label="School Name"
                    width={-1}
                    value={anon ? "**********" : schoolValues.schoolName.value}
                    handleChange={(val) => {
                      setSchoolValues({
                        ...schoolValues,
                        schoolName: { value: val, changed: true },
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <PlainInput
                    label="Address Line 1"
                    width={-1}
                    value={
                      anon ? "**********" : schoolValues.addressLine1.value
                    }
                    handleChange={(val) => {
                      setSchoolValues({
                        ...schoolValues,
                        addressLine1: { value: val, changed: true },
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <PlainInput
                    label="Address Line 2"
                    width={-1}
                    value={
                      anon ? "**********" : schoolValues.addressLine2.value
                    }
                    handleChange={(val) => {
                      setSchoolValues({
                        ...schoolValues,
                        addressLine2: { value: val, changed: true },
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <PlainInput
                    label="City"
                    width={-1}
                    value={anon ? "**********" : schoolValues.city.value}
                    handleChange={(val) => {
                      setSchoolValues({
                        ...schoolValues,
                        city: { value: val, changed: true },
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <PlainInput
                    label="Postcode"
                    width={-1}
                    value={anon ? "**********" : schoolValues.postcode.value}
                    handleChange={(val) => {
                      setSchoolValues({
                        ...schoolValues,
                        postcode: { value: val, changed: true },
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <PlainInput
                    label="Country"
                    width={-1}
                    value={schoolValues.country.value}
                    handleChange={(val) => {
                      setSchoolValues({
                        ...schoolValues,
                        country: { value: val, changed: true },
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <PlainInput
                    label="Email"
                    width={-1}
                    value={anon ? "**********" : schoolValues.email.value}
                    handleChange={(val) => {
                      setSchoolValues({
                        ...schoolValues,
                        email: { value: val, changed: true },
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <PlainInput
                    label="Phone"
                    width={-1}
                    value={anon ? "**********" : schoolValues.phone.value}
                    handleChange={(val) => {
                      setSchoolValues({
                        ...schoolValues,
                        phone: { value: val, changed: true },
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6">
                  <PlainInput
                    label="Capacity"
                    width={-1}
                    value={schoolValues.capacity.value}
                    handleChange={(val) => {
                      setSchoolValues({
                        ...schoolValues,
                        capacity: { value: val, changed: true },
                      });
                    }}
                  />
                </div>
              </div>
            </form>

            {!schoolData && (
              <div>
                <hr />
                <h4>Users</h4>
                <RoundedBtn
                  text="Add User"
                  width={-1}
                  onClick={() => setAdding(true)}
                />
                <ul className="list-group mt-3">
                  {userList.map((user) => {
                    return <li className="list-group-item">{user}</li>;
                  })}
                </ul>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <RoundedBtn
          text={schoolData ? "Save" : "Create"}
          onClick={schoolData ? editSchool : createSchool}
          variant="success"
          disabled={anon}
        />
      </Modal.Footer>
    </Modal>
  );
};

SchoolModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  schoolData: PropTypes.objectOf(PropTypes.any).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  anon: PropTypes.bool,
};

SchoolModal.defaultProps = {
  show: false,
  anon: false,
};

export default SchoolModal;
