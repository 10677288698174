import { Graph } from "../MCQuestion";
import { cleaner } from ".";
/**
 * Class Handling Vector.
 * @memberof module:MCMaths
 * @author James Pickersgill
 */

class Vector {
  /**
   * Creates a new vector
   *
   * @param {number} i i component of the vector.
   * @param {number} j j component of the vector.
   * @param {number} k k component of the vector, leave as 'none' for 2d vectors.
   */
  constructor(i, j, k = "none") {
    this.i = i;
    this.j = j;
    this.k = k;
  }

  /**
   * Prints the vector as a string (Vector Form).
   * @returns {string}
   */
  toString() {
    if (this.k === "none") {
      return cleaner(
        `\\begin{pmatrix} ${this.i} \\\\ ${this.j} \\end{pmatrix}`
      );
    }
    return cleaner(
      `\\begin{pmatrix} ${this.i} \\\\ ${this.j} \\\\ ${this.k} \\end{pmatrix}`
    );
  }

  /**
   * Prints the vector as a string (IJK Form).
   * @returns {string}
   */
  toStringIJK() {
    let out = "";
    if (this.i !== 0) {
      out += `${this.i}\\mathbf{i}`;
    }
    if (this.i === 0 && this.j !== 0) {
      out += `${this.j}\\mathbf{j}`;
    }
    if (this.i !== 0 && this.j !== 0) {
      out += `+${this.j}\\mathbf{j}`;
    }
    if (this.k !== "none") {
      if (this.i !== 0 || this.j !== 0) {
        out += `+${this.k}\\mathbf{k}`;
      }
      if (this.i === 0 && this.j === 0) {
        out += `${this.k}\\mathbf{k}`;
      }
    }
    return cleaner(out);
  }

  /**
   * The dot productof two vectors
   *
   * @param {vector} vec2 The vector to dot this with.
   *
   * @returns {number}
   */
  dot(vec2) {
    if (this.k === "none") {
      return this.i * vec2.i + this.j * vec2.j;
    }
    return this.i * vec2.i + this.j * vec2.j + this.k * vec2.k;
  }

  /**
   * Returns a string in the form v1 dot v2 = number
   *
   * @param {vector} vec2 The vector to dot this with.
   *
   * @returns {string}
   */
  dotWorking(vec2) {
    return `${this.toString()} \\cdot ${vec2.toString()} = ${this.dot(vec2)}`;
  }

  /**
   * Adds a vector to this vector
   *
   * @param {vector} vec2 The vector to add.
   *
   * @returns {vector}
   */
  add(vec2) {
    if (this.k === "none") {
      return new Vector(this.i + vec2.i, this.j + vec2.j);
    }
    return new Vector(this.i + vec2.i, this.j + vec2.j, this.k + vec2.k);
  }

  /**
   * Subtracts a vector from this vector
   *
   * @param {vector} vec2 The vector to subtract.
   *
   * @returns {vector}
   */
  sub(vec2) {
    if (this.k === "none") {
      return new Vector(this.i - vec2.i, this.j - vec2.j);
    }
    return new Vector(this.i - vec2.i, this.j - vec2.j, this.k - vec2.k);
  }

  /**
   * Adds a vector to this vector
   *
   * @param {number} n The value to multiply by.
   *
   * @returns {vector}
   */
  multiply(n) {
    if (this.k === "none") {
      return new Vector(this.i * n, this.j * n);
    }
    return new Vector(this.i * n, this.j * n, this.k * n);
  }

  /**
   * Finds the magnitude of this vector
   *
   * @returns {number}
   */
  magnitude() {
    if (this.k === "none") {
      return Math.sqrt(this.i ** 2 + this.j ** 2);
    }
    return Math.sqrt(this.i ** 2 + this.j ** 2 + this.k ** 2);
  }

  /**
   * A string in the form |vector|=number
   *
   * @returns {string}
   */
  magnitudeWorking() {
    return `\\left|${this.toString()}\\right| = ${this.magnitude()}`;
  }

  /**
   * Graphically adds two vectors
   *
   * @param {vector} vec2 The vector to add.
   *
   * @returns {vector}
   *
   * @example
   * const vec1 = new MCMaths.Vector(1, 2);
   * const vec2 = new MCMaths.Vector(3, 2);
   * question.addGraph("question", vec1.addGraph(vec2));
   */
  addGraph(vec2, range = 5) {
    const stepSize = Math.ceil(range / 10);

    const graph = new Graph(range, -range, range, -range, stepSize, stepSize);

    const { i } = this;
    const { j } = this;
    function f(x) {
      return (x * j) / i;
    }
    graph.plot(f, 0, i);

    const i2 = vec2.i;
    const j2 = vec2.j;

    function g(x) {
      return (j2 / i2) * (x - i) + j;
    }
    graph.plot(g, i, i + i2);

    function h(x) {
      return ((j + j2) / (i + i2)) * x;
    }
    graph.plot(h, 0, i + i2);

    return graph;
  }

  /**
   * Returns the components of the vector
   *
   * @returns {number[]} The components array.
   */
  getComponents() {
    return [this.i, this.j, this.k];
  }

  /**
   * Returns the vector normalised
   *
   * @returns {Vector}
   */
  normalise() {
    if (this.i < 0) {
      return this.multiply(-1 / this.magnitude());
    }

    return this.multiply(1 / this.magnitude());
  }

  /**
   * Tests if another vector is parallel to this one.
   *
   * @param {Vector} vec The vector to compare to.
   *
   * @returns {bool}
   */
  isParallel(vec) {
    if (this.normalise().toStringIJK() === vec.normalise().toStringIJK()) {
      return true;
    }

    return false;
  }

  /**
   * Returns the cross product of this and another vector.
   *
   * @param {Vector} vec The vector to cross with.
   *
   * @returns {Vector}
   */
  cross(vec) {
    // used in vector skills q7 for triangle area
    if (this.k !== "none") {
      return new Vector(
        this.j * vec.k - this.k - vec.j,
        this.k * vec.i - vec.i * this.k,
        this.i * vec.j - this.j * vec.i
      );
    }

    return new Vector(0, 0, this.i * vec.j - this.j * vec.i);
  }

  /**
   * Returns the bearing of the vector.
   *
   * @param {bool} round Outputs a rounded value or not.
   *
   * @returns {number}
   */
  bearing(round = false) {
    if (this.i >= 0 && this.j >= 0) {
      if (round === false) {
        return (Math.atan(this.i / this.j) * 180) / Math.PI;
      }
      return Math.round((Math.atan(this.i / this.j) * 180) / Math.PI);
    }
    if (this.j < 0) {
      if (round === false) {
        return 180 + (Math.atan(this.i / this.j) * 180) / Math.PI;
      }
      return Math.round(180 + (Math.atan(this.i / this.j) * 180) / Math.PI);
    }
    if (this.i < 0 && this.j >= 0) {
      if (round === false) {
        return 360 + (Math.atan(this.i / this.j) * 180) / Math.PI;
      }
      return Math.round(360 + (Math.atan(this.i / this.j) * 180) / Math.PI);
    }
    return "Error In Vector Bearing.";
  }

  /**
   * Returns the working for the bearing of the vector.
   *
   * @param {bool} round Outputs a rounded value or not.
   *
   * @returns {string[]}
   */
  bearingWorking(round = true) {
    const output = [`HEADING Finding Bearing:`];
    if (this.i >= 0 && this.j >= 0) {
      output.push(
        `$\\displaystyle = \\tan^{-1}\\left(\\frac{${this.j}}{${this.i}}\\right)$`,
        `$=${this.bearing(round)}^\\circ$`
      );
    }
    if (this.j < 0) {
      output.push(
        `$\\displaystyle = 180+\\tan^{-1}\\left(\\frac{${this.j}}{${this.i}}\\right)$`,
        `$=${this.bearing(round)}^\\circ$`
      );
    }
    if (this.i < 0 && this.j >= 0) {
      output.push(
        `$\\displaystyle = 360+\\tan^{-1}\\left(\\frac{${this.j}}{${this.i}}\\right)$`,
        `$=${this.bearing(round)}^\\circ$`
      );
    }
    return output;
  }

  /**
   * Returns the angle between this and another vector.
   *
   * @param {vec} Vector to find the angle with.
   *
   * @returns {number}
   */
  angle(vec) {
    return (
      (Math.acos(this.dot(vec) / (this.magnitude() * vec.magnitude())) * 180) /
      Math.PI
    );
  }

  /**
   * Rotates this vector by the angle.
   *
   * @param {Number} [x] Angle to rotate around the $x$-axis by, in degrees.
   * @param {Number} [y] Angle to rotate around the $y$-axis by, in degrees.
   * @param {Number} [z] Angle to rotate around the $z$-axis by, in degrees.
   *
   * @returns {Vector}
   */
  rotate(x1, y1, z1) {
    const x = (x1 * Math.PI) / 180;
    const y = (y1 * Math.PI) / 180;
    const z = (z1 * Math.PI) / 180;
    if (this.k === "none") {
      return "Not yet Implimented";
    }
    // https://en.wikipedia.org/wiki/Rotation_matrix#In_three_dimensions
    const rMatrix = [
      [
        Math.cos(x) * Math.cos(y),
        Math.cos(x) * Math.sin(y) * Math.sin(y) - Math.sin(x) * Math.cos(z),
        Math.cos(x) * Math.sin(y) * Math.cos(z) + Math.sin(x) * Math.sin(z),
      ],
      [
        Math.sin(x) * Math.cos(y),
        Math.sin(x) * Math.sin(y) * Math.sin(z) + Math.cos(x) * Math.cos(z),
        Math.sin(x) * Math.sin(y) * Math.cos(z) - Math.cos(x) * Math.sin(z),
      ],
      [-Math.sin(y), Math.cos(y) * Math.sin(z), Math.cos(x) * Math.cos(z)],
    ];
    const i =
      this.i * rMatrix[0][0] + this.j * rMatrix[0][1] + this.k * rMatrix[0][2];
    const j =
      this.i * rMatrix[1][0] + this.j * rMatrix[1][1] + this.k * rMatrix[1][2];
    const k =
      this.i * rMatrix[2][0] + this.j * rMatrix[2][1] + this.k * rMatrix[2][2];
    return new Vector(i, j, k);
  }
}

export { Vector };
