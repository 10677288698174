/**
 * Chain Rule.
 * @memberof module:MCMaths
 * @author James Pickersgill
 * @example
 * // Input two functions, the seocond function wil be used as the variable of the first function.
 * // I.e. for $2e^{3\\sin(h)}$, you would use two functions, $2e^{3x}$ and $\\sin(h)$.
 * let p1 = new Exponential(2,'e',3)
 * let s1 = new Trig(1,'sin',1,1,'h')
 * let cr = new ChainRule(p1,s1)
 */

class ChainRule {
  constructor(func, variable) {
    this.func = func;
    this.variable = variable;
  }

  /**
   * Prints the chained function as a string.
   * @returns {string}
   */
  toString() {
    const temp = Object.assign(
      Object.create(Object.getPrototypeOf(this.func)),
      this.func
    );
    temp.variable = `\\left(${this.variable.toString()}\\right)`;
    return temp.toString();
  }

  /**
   * Returns the derivative of the chained function as a string.
   * @returns {string}
   * @example
   * let p1 = new Exponential(2,'e',3)
   * let s1 = new Trig(1,'sin',1,1,'h')
   * let cr = new ChainRule(p1,s1)
   * console.log('Chain Derivative: $'+cr.derivative()+'$')
   */
  derivative() {
    return `\\left(${this.variable
      .derivative()
      .toString()}\\right)\\left(${new ChainRule(
      this.func.derivative(),
      this.variable
    ).toString()}\\right)`;
  }

  /**
   * Returns the working of the derivative of the chained function as a string array.
   * @returns {string[]}
   * @example
   * let p1 = new Exponential(2,'e',3)
   * let s1 = new Trig(1,'sin',1,1,'h')
   * let cr = new ChainRule(p1,s1)
   * console.log('Question: Find $\\frac{d}{dh}'+cr.toString()+'$')
   * console.log(cr.derivativeWorking())
   */
  derivativeWorking() {
    const v = this.variable.variable;
    const out = ["HEADING Chain Rule"];
    out.push(
      `$\\displaystyle \\frac{dy}{d${v}}= \\frac{dy}{du} \\frac{du}{d${v}} $`
    );
    out.push(`$\\displaystyle u = ${this.variable.toString()}$`);

    const temp = Object.assign(
      Object.create(Object.getPrototypeOf(this.func)),
      this.func
    );
    temp.variable = "u";
    out.push(`$\\displaystyle y = ${temp.toString()}$`);

    out.push(
      `$\\displaystyle\\frac{du}{d${v}} = ${this.variable
        .derivative()
        .toString()}$`
    );
    out.push(
      `$\\displaystyle\\frac{dy}{du} = ${temp.derivative().toString()}$`
    );

    out.push(
      `$\\displaystyle \\therefore \\frac{dy}{d${v}} = ${this.derivative()}$`
    );

    return out;
  }

  evaluate(x) {
    return (
      this.variable.derivative().evaluate(x) *
      this.func.derivative().evaluate(this.variable.evaluate(x))
    );
  }
}
export { ChainRule };
